<script>
  import { onMount, onDestroy } from "svelte";
  import { themeStore } from "../../stores";
  import { nanoid } from "nanoid";
  import { aceCompleterStore } from "../designer/ace/ace.store";

  export let value = "";
  export let mode = "javascript"; // 'javascript', 'json';
  export let height = "20vh"; // any height value
  export let fullcontainer = false;
  export let readOnly = false;

  let editor;
  let themeStoreUnsubscribe;
  let uuid;
  let oldValue;
  let initValue = value;
  let aceCompleter = [];

  $: changedValue(value);

  let aceCompleterStoreUnsubscribe = aceCompleterStore.subscribe((res) => {
    aceCompleter = res;
  });

  /**
   * @param {string} value
   */
  function changedValue(value) {
    // if (!value) value = "";
    if (!editor) return;
    // if (value === initValue) return;
    if (value === oldValue) return;
    editor.setValue(value, -1);
    oldValue = value;
  }

  onMount(() => {
    uuid = nanoid(10);
    setTimeout(() => {
      initEditor();
      themeStoreUnsubscribe = themeStore.subscribe((res) => {
        toggleTheme(res);
      });
    }, 100);
  });

  onDestroy(() => {
    aceCompleterStoreUnsubscribe();
    // value = undefined;
    editor?.destroy();
    editor?.container?.remove();
    if (themeStoreUnsubscribe) themeStoreUnsubscribe();
  });

  /**
   * @param {string} [which]
   */
  function toggleTheme(which) {
    if (!which) editor.setTheme("ace/theme/chrome");
    else if (which === "light") editor.setTheme("ace/theme/chrome");
    else if (which === "dark") editor.setTheme("ace/theme/twilight");
  }

  function initEditor() {
    // @ts-ignore
    ace.require("ace/ext/language_tools");
    // @ts-ignore
    editor = ace.edit("editor_" + uuid, {
      wrap: true,
      showGutter: true,
      firstLineNumber: 1,
      blockScrolling: Infinity,
      enableBasicAutocompletion: true,
      enableLiveAutocompletion: true,
      enableSnippets: true,
      readOnly,
    });
    toggleTheme();

    editor.session.setMode("ace/mode/" + mode);
    // editor.session.setMode('ace-builds/src-noconflict/mode-javascript');
    editor.setValue(value, -1);
    oldValue = value;
    editor.resize();
    addCompleters();
    editor.session.on("change", function (/** @type {any} */ _delta) {
      const newValue = editor.getValue();
      if ((!newValue || newValue === "") && !initValue) {
        value = initValue;
        oldValue = value;
        return;
      }
      value = newValue;
      oldValue = value;
    });
  }

  function addCompleters() {
    if (!editor) return;
    let len = editor.completers.length;
    let ix = len - 1 < 0 ? 0 : len - 1;
    editor.completers[ix].getCompletions = function (
      editor,
      session,
      pos,
      prefix,
      callback
    ) {
      callback(null, aceCompleter);
    };
  }

  /**
   * @param {string} text
   */
  export function insertTextToCurrentCursor(text) {
    const currentCursorPosition = editor.getCursorPosition();
    editor.session.insert(currentCursorPosition, text);
  }
</script>

<div
  class="d-block mb-3"
  class:ace-container-full={fullcontainer}
  class:ace-container={!fullcontainer}
  style:height
>
  <div
    id={"editor_" + uuid}
    class:ace-editor-full={fullcontainer}
    class:ace-editor={!fullcontainer}
  />
</div>

<style type="text/css" media="screen">
  .ace-container {
    /* min-height: 300px; */
    /* height: 20vh; */
    position: relative;
  }
  .ace-container-full {
    /* position: relative; */
  }
  .ace-editor {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    border: 1px solid gray;
  }
  .ace-editor-full {
    position: absolute;
    top: 16px;
    right: 16px;
    bottom: 16px;
    left: 16px;
    border: 1px solid gray;
  }
</style>
