<script>
  import { onDestroy, onMount } from "svelte";
  import TaskExeCompList from "../task-exe-comp-list.svelte";
  import { substores, taskExeBusinessObject } from "../task-exe-store";
  import taskExeVisibilityTree from "../task-exe-visibility-tree";

  /** @type {{id: string; columns: any[]}|undefined} */
  export let item = undefined;
  export let props = {};
  /** @type {{id: string; type: string;}[]} */
  export let parent = undefined;
  export let visibilityFromParent = "editable";
  /** @type {{id: string; tplId: string;}|null} */
  export let templateProps = null;

  let myVisibility;
  let dynamicBoStore;
  let currentParents = [];
  const pathToStore = parent
    .filter((el) => el.type === "tpl-preview")
    .map((el) => el.id)
    .join("_");

  $: updateParentsList(parent);

  let visibilityStoreUnsubscribe;
  const visibilityStore = taskExeVisibilityTree.checkVisibilityStore(
    item,
    parent,
  );
  if (visibilityStore) {
    visibilityStoreUnsubscribe = visibilityStore.subscribe(
      (/** @type {string} */ res) => {
        myVisibility = res;
      },
    );
  }

  $: checkVisWithParent(myVisibility, visibilityFromParent); 

  function checkVisWithParent(_myVisibility, _visibilityFromParent) {
    myVisibility = taskExeVisibilityTree.checkVisOfElementWithParent(_visibilityFromParent, _myVisibility, props)
  }

  onMount(() => {
    if (pathToStore) {
      if (substores.bos?.[pathToStore].data) {
        dynamicBoStore = substores.bos[pathToStore].data;
      } else {
        dynamicBoStore = taskExeBusinessObject;
      }
    } else {
      dynamicBoStore = taskExeBusinessObject;
    }
  });

  onDestroy(() => {
    if (visibilityStoreUnsubscribe) visibilityStoreUnsubscribe();
  });

  /**
   * @param {{ id: string; type: string; }[]} parent
   */
  function updateParentsList(parent) {
    currentParents = [...parent, props];
  }
</script>

{#if myVisibility !== "hidden"}
  <div
    id={item.id}
    class={props.class || ""}
    class:container={!props.isFluid}
    class:container-fluid={props.isFluid}
    style={props.style}
  >
    {#each item.columns as child}
      <TaskExeCompList
        bind:item={child}
        bind:parent
        bind:visibilityFromParent={myVisibility}
        bind:templateProps
      />
    {/each}
  </div>
{/if}
