<script>
  import {
    ArrowDownUp,
    CheckSquare,
    Eye,
    Pencil,
    Plus,
    SortDown,
    SortUp,
    Square,
    X,
  } from "svelte-bootstrap-icons";
  import { t } from "../../../services/i18n.service";
  import { onDestroy, onMount } from "svelte";
  import {
    substores,
    taskExeBusinessObject,
    taskExeErrorStore,
    taskExeStore,
  } from "../task-exe-store";
  import TaskExeTplI18nLabel from "./task-exe-tpl-i18n-label.svelte";
  import taskExeErrorAndVisibilityService from "../task-exe-error-and-visibility.service";
  import apiService from "../../../services/api.service";
  import taskExeApi from "../task-exe.api";
  import { get } from "svelte/store";
  import { isLoading } from "../../../stores";
  import FieldDateFormated from "../../utils/field-date-formated.svelte";
  import taskExeVisibilityTree from "../task-exe-visibility-tree";

  /** @type {{id: string;}|undefined} */
  export let item = undefined;
  export let props = {};
  /** @type {{id: string; type: string;}[]} */
  export let parent = undefined;
  export let visibilityFromParent = "editable";
  /** @type {{id: string; tplId: string;}|null} */
  export let templateProps = null;

  const icoHeight = 24;
  let hasError = false;
  let showHelp = false;
  let value = undefined;
  let myVisibility;
  let dynamicBoStore;
  let taskExeBusinessObjectUnsubscribe;
  let taskExeErrorStoreUnsubscribe;
  let selectWidth =
    props.setts?.rowSelection === "multiple"
      ? 50
      : props.setts?.rowSelection === "single"
        ? 5
        : 0;
  const colSpanMessage =
    (props.setts?.cols || []).length +
    (props.setts?.rowSelection && props.setts?.columnActions
      ? 3
      : props.setts?.rowSelection || props.setts?.columnActions
        ? 2
        : 1);
  let tableSelection = [];
  const pathToStore = parent
    .filter((el) => el.type === "tpl-preview")
    .map((el) => el.id)
    .join("_");
  const pathToStoreNamed = pathToStore ? pathToStore : "general";
  const widgetId = pathToStore ? pathToStore + "_" + item.id : item.id;

  const debounceCheckValueTable = apiService.debounce(checkValue, 100);

  $: updateBo(value);

  let visibilityStoreUnsubscribe;
  const visibilityStore = taskExeVisibilityTree.checkVisibilityStore(
    item,
    parent,
  );
  if (visibilityStore) {
    visibilityStoreUnsubscribe = visibilityStore.subscribe(
      (/** @type {string} */ res) => {
        myVisibility = res;
      },
    );
  }

  $: checkVisWithParent(myVisibility, visibilityFromParent); 

  function checkVisWithParent(_myVisibility, _visibilityFromParent) {
    myVisibility = taskExeVisibilityTree.checkVisOfElementWithParent(_visibilityFromParent, _myVisibility, props)
  }

  onMount(() => {
    props.isFirstValidation = true;

    if (pathToStore) {
      if (substores.bos?.[pathToStore]?.data) {
        dynamicBoStore = substores.bos[pathToStore].data;
      } else {
        dynamicBoStore = taskExeBusinessObject;
      }
    } else {
      dynamicBoStore = taskExeBusinessObject;
    }

    const firstValue = apiService.getNestedFromPath(
      get(dynamicBoStore),
      props.varName,
    );
    value = checkValue(firstValue);

    dynamicBoStore.ensureValue(props.varName, value, [pathToStoreNamed]);

    taskExeErrorStoreUnsubscribe = taskExeErrorStore.subscribe(() => {
      if (props.isFirstValidation === true) return;
      const resultErrVars =
        taskExeErrorAndVisibilityService.getErrorVars(props);
      hasError = resultErrVars.hasError;
      showHelp = resultErrVars.showHelp;
    });

    taskExeBusinessObjectUnsubscribe = dynamicBoStore.subscribe((bo) => {
      if (!$taskExeStore) return;
      const newValue = apiService.getNestedFromPath(bo, props.varName);
      debounceCheckValueTable(newValue);
    });

    setTimeout(() => {
      props.isFirstValidation = false;
    }, 100);
  });

  onDestroy(() => {
    taskExeBusinessObjectUnsubscribe();
    taskExeErrorStoreUnsubscribe();
    if (visibilityStoreUnsubscribe) visibilityStoreUnsubscribe();
  });

  /**
   * @param {{ cols: any[]; rows: any[]; }} value
   */
  function updateBo(value) {
    if (!value) return;
    dynamicBoStore.ensureValue(props.varName, value, [pathToStoreNamed]);
  }

  /**
   * @param {any} oldValue
   */
  function checkValue(oldValue) {
    let newValue = {
      cols: [],
      rows: [],
      selected: [],
      selectedItems: [],
    };
    if (!oldValue) oldValue = {};
    newValue.cols = props?.setts?.cols || oldValue.cols || [];
    newValue.rows = oldValue.rows || [];
    newValue.selected = oldValue.selected || [];
    newValue.selectedItems = [
      ...newValue.selected.map((el) => newValue.rows[el]),
    ];

    // set columns for value:
    if (!newValue.cols || newValue.length === 0 || !newValue.cols?.[0]?.value) {
      const colsObj = {};
      (newValue.rows || []).forEach((r) => {
        Object.keys(r).forEach((col) => {
          colsObj[col] = { type: "text" };
        });
      });
      newValue.cols = Object.keys(colsObj).map((col) => {
        return { value: col, label: col, type: "text" };
      });
    }
    return newValue;
  }

  /**
   * @param {number} columnIx
   */
  function sortBy(columnIx) {
    let column = value.cols[columnIx];
    if (!column.sortBy) column.sortBy = "+";
    else if (column.sortBy === "+") column.sortBy = "-";
    else column.sortBy = null;

    // if there is not attached button to sort, then sort automatically
    if (!props.setts?.btnSort) {
      // sort rows by column:
      value.rows.sort((a, b) => {
        if (a[value.cols[columnIx].value] < b[value.cols[columnIx].value])
          return column.sortBy === "+" ? -1 : 1;
        else if (a[value.cols[columnIx].value] > b[value.cols[columnIx].value])
          return column.sortBy === "-" ? -1 : 1;
        else return 0;
      });
      value = value;
      return;
    }

    // else sort manualy calling the attached button
    value = value;
    taskExeApi.submit({
      id: props.setts.btnSort,
      boStore: dynamicBoStore,
      tplId: templateProps?.tplId,
    });
  }

  /**
   * @param {number} [rowIndex]
   */
  function toggleSelection(rowIndex) {
    let selected = [];
    let selectedItems = [];
    const selectionType = props.setts?.rowSelection;
    if (!selectionType) return;
    if (selectionType === "single") {
      // get value of selection:
      const isSelected = tableSelection?.[rowIndex] === true;
      if (isSelected) {
        selected = [rowIndex];
        tableSelection = [];
        tableSelection[rowIndex] = true;
      } else {
        selected = [];
        tableSelection = [];
      }
    } else if (selectionType === "multiple") {
      selected = [];
      tableSelection.forEach((el, ix) => {
        if (el === true) selected.push(ix);
      });
    }

    if (selected.length) {
      selectedItems = [...selected.map((el) => value.rows[el])];
    }

    value.selected = selected;
    value.selectedItems = selectedItems;
    // value = value;
    if (props.setts?.btnSelect) {
      taskExeApi.submit({
        id: props.setts.btnSelect,
        boStore: dynamicBoStore,
        tplId: templateProps?.tplId,
      });
    }
  }

  /**
   * @param {string} action
   * @param {number} rowIndex
   */
  function submitAction(action, rowIndex) {
    // deselect all rows
    // value.rows.forEach((r) => {
    //   r.___selected = false;
    // });
    // select current row:
    // value.rows[rowIndex].___selected = true;
    value.selected = [rowIndex];
    value.selectedItems = [value.rows[rowIndex]];
    // value = value;
    // submit action:
    taskExeApi.submit({
      id: props.setts[action],
      boStore: dynamicBoStore,
      tplId: templateProps?.tplId,
    });
  }
</script>

{#if myVisibility !== "hidden"}
  <div id={item.id} class="mb-3">
    {#if props.label}
      {#if !props.hideLabel}
        <!-- svelte-ignore a11y-label-has-associated-control -->
        <label class="form-check-label">
          <TaskExeTplI18nLabel bind:props />
        </label>
      {/if}
    {/if}
    {#if $taskExeStore.definition.objects?.[item.id]?.setts}
      <div
        class:table-responsive={$taskExeStore.definition.objects[item.id].setts
          .responsive}
      >
        <table
          class="table {props.class || ''}"
          style={props.style}
          class:table-striped={$taskExeStore.definition.objects[item.id]?.setts
            ?.striped}
          class:table-bordered={$taskExeStore.definition.objects[item.id]?.setts
            ?.bordered}
          class:table-hover={$taskExeStore.definition.objects[item.id]?.setts
            ?.hover}
          class:table-sm={$taskExeStore.definition.objects[item.id]?.setts
            ?.condensed}
        >
          {#if !$taskExeStore.definition.objects[item.id]?.setts?.hideHeader}
            <thead>
              <tr>
                {#if $taskExeStore.definition.objects[item.id]?.setts?.rowSelection}
                  <th class="text-center" style="width: {selectWidth}px;">
                    {#if ["multiple"].includes($taskExeStore.definition.objects[item.id]?.setts?.rowSelection)}
                      <div>
                        <button
                          class="btn btn-sm border-0 m-0 p-0"
                          disabled={myVisibility === "disabled" || $isLoading}
                          on:click={() => {
                            // deselect all values:
                            tableSelection = [];
                            toggleSelection();
                          }}
                        >
                          <Square
                            width={icoHeight - 6}
                            height={icoHeight - 6}
                          />
                        </button>
                        <button
                          class="btn btn-sm border-0 m-0 p-0"
                          disabled={myVisibility === "disabled" || $isLoading}
                          on:click={() => {
                            // select all values:
                            tableSelection = Array(value.rows.length).fill(
                              true,
                            );
                            toggleSelection();
                          }}
                        >
                          <CheckSquare
                            width={icoHeight - 6}
                            height={icoHeight - 6}
                          />
                        </button>
                      </div>
                    {/if}
                  </th>
                {/if}
                {#each value?.cols || [] as h, hIx}
                  <th>
                    <!-- {#if h?.label} -->
                    {h.label}
                    <!-- {/if} -->
                    {#if h.allowSort && !h.sortBy}
                      <button
                        class="btn btn-sm border-0 m-0 p-0"
                        disabled={myVisibility === "disabled" || $isLoading}
                        on:click={() => {
                          sortBy(hIx);
                        }}
                      >
                        <ArrowDownUp
                          width={icoHeight - 6}
                          height={icoHeight - 6}
                        />
                      </button>
                    {/if}
                    {#if h.allowSort && h.sortBy === "-"}
                      <button
                        class="btn btn-sm border-0 m-0 p-0"
                        disabled={myVisibility === "disabled" || $isLoading}
                        on:click={() => {
                          sortBy(hIx);
                        }}
                      >
                        <SortDown
                          width={icoHeight - 4}
                          height={icoHeight - 4}
                        />
                      </button>
                    {/if}
                    {#if h.allowSort && h.sortBy === "+"}
                      <button
                        class="btn btn-sm border-0 m-0 p-0"
                        disabled={myVisibility === "disabled" || $isLoading}
                        on:click={() => {
                          sortBy(hIx);
                        }}
                      >
                        <SortUp width={icoHeight - 4} height={icoHeight - 4} />
                      </button>
                    {/if}
                  </th>
                {/each}
                {#if $taskExeStore.definition.objects[item.id].setts.columnActions}
                  <th>
                    {#if $taskExeStore.definition.objects[item.id].setts.btnAddRow}
                      <button
                        class="btn btn-sm btn-outline-primary"
                        disabled={myVisibility === "disabled" || $isLoading}
                        on:click={() => {
                          taskExeApi.submit({
                            id: $taskExeStore.definition.objects[item.id].setts
                              .btnAddRow,
                            boStore: dynamicBoStore,
                            tplId: templateProps?.tplId,
                          });
                        }}
                      >
                        <Plus width={icoHeight} height={icoHeight} />
                        {#if !props.setts?.btnWithoutLabels}
                          {$t("generic.add")}
                        {/if}
                      </button>
                    {/if}
                    {#if $taskExeStore.definition.objects[item.id].setts.btnDeleteSelectedRows}
                      <button
                        class="btn btn-sm btn-outline-danger"
                        disabled={myVisibility === "disabled" || $isLoading}
                        on:click={() => {
                          taskExeApi.submit({
                            id: $taskExeStore.definition.objects[item.id].setts
                              .btnDeleteSelectedRows,
                            boStore: dynamicBoStore,
                            tplId: templateProps?.tplId,
                          });
                        }}
                      >
                        <X width={icoHeight} height={icoHeight} />
                        {#if !props.setts?.btnWithoutLabels}
                          {$t("generic.delSel")}
                        {/if}
                      </button>
                    {/if}
                  </th>
                {/if}
              </tr>
            </thead>
          {/if}
          <tbody>
            {#each value?.rows || [] as r, rIx}
              <tr>
                {#if $taskExeStore.definition.objects[item.id]?.setts?.rowSelection}
                  <td class="text-center">
                    {#if $taskExeStore.definition.objects[item.id].setts.rowSelection === "multiple"}
                      <input
                        class="form-check-input"
                        type="checkbox"
                        bind:checked={tableSelection[rIx]}
                        disabled={myVisibility === "disabled" || $isLoading}
                        on:change={() => {
                          toggleSelection(rIx);
                        }}
                      />
                    {/if}
                    {#if $taskExeStore.definition.objects[item.id].setts.rowSelection === "single"}
                      <input
                        class="form-check-input"
                        type="checkbox"
                        bind:checked={tableSelection[rIx]}
                        disabled={myVisibility === "disabled" || $isLoading}
                        on:change={() => {
                          toggleSelection(rIx);
                        }}
                      />
                    {/if}
                  </td>
                {/if}
                {#each value?.cols || [] as h}
                  <td>
                    {#if h.type === "text"}
                      <div>
                        <span>{r[h.value] || ""}</span>
                      </div>
                    {/if}
                    {#if h.type === "input" && r?.[h.value]}
                      <div>
                        <input
                          class="form-control"
                          type="text"
                          bind:value={r[h.value]}
                          disabled={myVisibility === "disabled" || $isLoading}
                        />
                      </div>
                    {/if}
                    {#if h.type === "textarea" && r?.[h.value]}
                      <div>
                        <textarea
                          class="form-control"
                          rows="3"
                          bind:value={r[h.value]}
                          disabled={myVisibility === "disabled" || $isLoading}
                        />
                      </div>
                    {/if}
                    {#if h.type === "select" && r?.[h.value]}
                      <div>
                        <select
                          class="form-control"
                          bind:value={r[h.value]}
                          disabled={myVisibility === "disabled" || $isLoading}
                        >
                          <option value={null}>{$t("generic.select")}</option>
                          <option value="1">{$t("generic.option")} 1</option>
                          <option value="2">{$t("generic.option")} 2</option>
                          <option value="3">{$t("generic.option")} 3</option>
                        </select>
                      </div>
                    {/if}
                    {#if h.type === "datetimetext" && r?.[h.value]}
                      <div class="text-end">
                        <FieldDateFormated
                          bind:date={r[h.value]}
                          format={h.datetimeFormat}
                        />
                      </div>
                    {/if}
                  </td>
                {/each}
                {#if $taskExeStore.definition.objects?.[item.id]?.setts?.columnActions}
                  <td>
                    {#if $taskExeStore.definition.objects[item.id].setts.btnViewRow}
                      <button
                        class="btn btn-sm btn-outline-primary"
                        disabled={myVisibility === "disabled" || $isLoading}
                        on:click={() => {
                          submitAction("btnViewRow", rIx);
                        }}
                      >
                        <Eye width={icoHeight - 4} height={icoHeight - 4} />
                        {#if !props.setts?.btnWithoutLabels}
                          {$t("generic.view")}
                        {/if}
                      </button>
                    {/if}
                    {#if $taskExeStore.definition.objects[item.id].setts.btnEditRow}
                      <button
                        class="btn btn-sm btn-outline-primary"
                        disabled={myVisibility === "disabled" || $isLoading}
                        on:click={() => {
                          submitAction("btnEditRow", rIx);
                        }}
                      >
                        <Pencil width={icoHeight - 4} height={icoHeight - 4} />
                        {#if !props.setts?.btnWithoutLabels}
                          {$t("generic.edit")}
                        {/if}
                      </button>
                    {/if}
                    {#if $taskExeStore.definition.objects[item.id].setts.btnDeleteRow}
                      <button
                        class="btn btn-sm btn-outline-danger"
                        disabled={myVisibility === "disabled" || $isLoading}
                        on:click={() => {
                          submitAction("btnDeleteRow", rIx);
                        }}
                      >
                        <X width={icoHeight - 4} height={icoHeight - 4} />
                        {#if !props.setts?.btnWithoutLabels}
                          {$t("generic.delete")}
                        {/if}
                      </button>
                    {/if}
                  </td>
                {/if}
              </tr>
            {:else}
              <tr>
                <td class="text-muted text-center" colspan={colSpanMessage}>
                  {$t("msgs.msgNoDataAvailable")}
                </td>
              </tr>
            {/each}
          </tbody>
        </table>
      </div>
    {/if}
  </div>
{/if}
