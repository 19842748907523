<script>
	import { House, Play } from "svelte-bootstrap-icons";
	import { t } from "../../services/i18n.service";
	import { inboxExposedStore, inboxStateStore } from "../inbox/inbox-store";
	import TaskExeTplI18nLabel from "../task/task-exe-templates/task-exe-tpl-i18n-label.svelte";
	import { location, push } from "svelte-spa-router";
	import { onDestroy } from "svelte";

	let sites = [];
	let totalSites = 0;
	let currentSite;

	const inboxExposedStoreUnsubscribe = inboxExposedStore.subscribe((res) => {
		sites = res.sites;
		totalSites = res.totalSites || 0;
	});

	const locationUnsubscribe = location.subscribe((res) => {
		if (!res || res === "/") {
			currentSite = null;
			return;
		}
		const arrParams = res.split("/");
		if (arrParams[1] === "sites" && arrParams[2]) {
			currentSite = arrParams[2];
		}
	});

	onDestroy(() => {
		inboxExposedStoreUnsubscribe();
		locationUnsubscribe();
	});

	/**
	 * @param {{ tid: any; dashboard?: boolean; }} dashboard
	 */
	function startDashboard(dashboard) {
		inboxStateStore.setState("sites");
		push(`/sites/${dashboard.tid}`);
		// taskExeCommandStore.command({
		// 	action: "open",
		// 	details: { details: { tid: dashboard.tid, dashboard: true } },
		// });
	}

	function returnToMyTasks() {
		inboxStateStore.setState("normal");
		push("/");
	}
</script>

<li class="m-2 text-muted">
	{$t("generic.sites")}
</li>
<li class:selected-item={!currentSite}>
	<!-- svelte-ignore a11y-click-events-have-key-events -->
	<!-- svelte-ignore a11y-no-static-element-interactions -->
	<!-- svelte-ignore a11y-missing-attribute -->
	<a class="dropdown-item" on:click={returnToMyTasks}>
		<House></House>
		{$t("generic.home")}
	</a>
</li>
{#each sites as item}
	<li class:selected-item={currentSite === item._id}>
		<!-- svelte-ignore a11y-click-events-have-key-events -->
		<!-- svelte-ignore a11y-no-static-element-interactions -->
		<!-- svelte-ignore a11y-missing-attribute -->
		<a
			class="dropdown-item"
			on:click={() => {
				startDashboard({ tid: item._id, dashboard: true });
			}}
		>
			<Play></Play>
			<TaskExeTplI18nLabel bind:props={item} varProp="name" />
		</a>
	</li>
{/each}
<li class="m-2 text-muted">
	<hr style="margin: 0px;" />
</li>
