<script>
  import { onDestroy, onMount } from "svelte";
  import TaskExeTplI18nLabel from "./task-exe-tpl-i18n-label.svelte";
  import {
    substores,
    taskExeBusinessObject,
    taskExeErrorStore,
    taskExeStore,
  } from "../task-exe-store";
  import taskExeApi from "../task-exe.api";
  import { isLoading } from "../../../stores";
  import dayjs from "dayjs";
  import apiService from "../../../services/api.service";
  import { get } from "svelte/store";
  import taskExeErrorAndVisibilityService from "../task-exe-error-and-visibility.service";
  import { currentLanguage } from "../../../services/i18n.service";
  import taskExeVisibilityTree from "../task-exe-visibility-tree";

  /** @type {{id: string;}|undefined} */
  export let item = undefined;
  export let props = {};
  /** @type {{id: string; type: string;}[]} */
  export let parent = undefined;
  export let visibilityFromParent = "editable";
  /** @type {{id: string; tplId: string;}|null} */
  export let templateProps = null;

  let value, date, time;
  let hasError = false;
  let showHelp = false;
  let myVisibility;
  let dynamicBoStore;
  let taskExeBusinessObjectUnsubscribe;
  let taskExeErrorStoreUnsubscribe;
  const FORMATS = {
    DATE: "YYYY-MM-DD",
    TIME: "HH:mm",
  };
  const pathToStore = parent
    .filter((el) => el.type === "tpl-preview")
    .map((el) => el.id)
    .join("_");
  const pathToStoreNamed = pathToStore ? pathToStore : "general";
  const widgetId = `${pathToStore ? pathToStore + "_" + item.id : item.id}`;

  let visibilityStoreUnsubscribe;
  const visibilityStore = taskExeVisibilityTree.checkVisibilityStore(
    item,
    parent,
  );
  if (visibilityStore) {
    visibilityStoreUnsubscribe = visibilityStore.subscribe(
      (/** @type {string} */ res) => {
        myVisibility = res;
      },
    );
  }

  $: checkVisWithParent(myVisibility, visibilityFromParent); 

  function checkVisWithParent(_myVisibility, _visibilityFromParent) {
    myVisibility = taskExeVisibilityTree.checkVisOfElementWithParent(_visibilityFromParent, _myVisibility, props)
  }

  onMount(() => {
    props.isFirstValidation = true;

    if (pathToStore) {
      if (substores.bos?.[pathToStore]?.data) {
        dynamicBoStore = substores.bos[pathToStore].data;
      } else {
        dynamicBoStore = taskExeBusinessObject;
      }
    } else {
      dynamicBoStore = taskExeBusinessObject;
    }

    const firstValue = apiService.getNestedFromPath(
      get(dynamicBoStore),
      props.varName,
    );
    dynamicBoStore.ensureValue(props.varName, firstValue, [pathToStoreNamed]);

    const valueBo = apiService.getNested(
      get(dynamicBoStore),
      apiService.check.nestedProps(props.varName),
    );
    if (valueBo) {
      date = dayjs(valueBo).format(FORMATS.DATE);
      time = dayjs(valueBo).format(FORMATS.TIME);
    }

    taskExeErrorStoreUnsubscribe = taskExeErrorStore.subscribe(() => {
      if (props.isFirstValidation === true) return;
      const resultErrVars =
        taskExeErrorAndVisibilityService.getErrorVars(props);
      hasError = resultErrVars.hasError;
      showHelp = resultErrVars.showHelp;
    });

    taskExeBusinessObjectUnsubscribe = dynamicBoStore.subscribe((bo) => {
      if (!$taskExeStore) return;
      const newTempValue = apiService.getNestedFromPath(bo, props.varName);
      // if (value && newTempValue && value?.toJSON() !== newTempValue?.toJSON()) {
      if (
        value &&
        newTempValue &&
        checkDate(value) !== checkDate(newTempValue)
      ) {
        value = newTempValue;
        if (value) {
          const newDate = dayjs(newTempValue);
          date = newDate.format(FORMATS.DATE);
          time = newDate.format(FORMATS.TIME);
        }
      }
    });

    setTimeout(() => {
      props.isFirstValidation = false;
    }, 100);
  });

  /**
   * @param {any} date
   */
  function checkDate(date) {
    if (!date) return date;
    if (typeof date === "string") return date;
    if (date?.toJSON) return date.toJSON();
    return date;
  }

  onDestroy(() => {
    taskExeBusinessObjectUnsubscribe();
    taskExeErrorStoreUnsubscribe();
    if (visibilityStoreUnsubscribe) visibilityStoreUnsubscribe();
  });

  function valueChanged() {
    if (!time && date) {
      value = dayjs(`${date}`, "DD.MM.YYYY");
    } else if (!date && !time) {
      const timeArr = time.split(":");
      value = dayjs().set("hour", timeArr[0]).set("minute", timeArr[1]);
    } else {
      value = dayjs(`${date} ${time}`, "DD.MM.YYYY HH:mm");
    }
    dynamicBoStore.ensureValue(props.varName, value, [pathToStoreNamed]);
    taskExeErrorAndVisibilityService.checkValidation(props);
    taskExeApi.submit({
      id: item.id,
      boStore: dynamicBoStore,
      tplId: templateProps?.id,
    });
  }
</script>

{#if myVisibility !== "hidden"}
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div id={item.id} class="mb-3">
    <label class="form-label d-block" for="dt_{widgetId}">
      <TaskExeTplI18nLabel bind:props />{props.required ? "*" : ""}
    </label>
    {#if props.datetimeType === "date"}
      <input
        id="dt_{widgetId}"
        type="date"
        class="form-control {props.class || ''}"
        class:is-invalid={hasError}
        style={props.style}
        aria-labelledby={props.label_i18n
          ? props.label_i18n[$currentLanguage]
          : props.label}
        max={props.maxDate}
        min={props.minDate}
        bind:value={date}
        on:change={valueChanged}
        disabled={myVisibility === "disabled" || $isLoading}
      />
    {:else if props.datetimeType === "time"}
      <input
        id="dt_{widgetId}"
        type="time"
        class="form-control {props.class || ''}"
        class:is-invalid={hasError}
        style={props.style}
        aria-labelledby={props.label_i18n
          ? props.label_i18n[$currentLanguage]
          : props.label}
        max={props.maxDate}
        min={props.minDate}
        bind:value={time}
        on:change={valueChanged}
        disabled={myVisibility === "disabled" || $isLoading}
      />
    {:else}
      <input
        id="dtd_{widgetId}"
        type="date"
        class="form-control d-inline-block w-auto {props.class || ''}"
        class:is-invalid={hasError}
        style={props.style}
        aria-labelledby={props.label_i18n
          ? props.label_i18n[$currentLanguage]
          : props.label}
        max={props.maxDate}
        min={props.minDate}
        bind:value={date}
        on:change={valueChanged}
        disabled={myVisibility === "disabled" || $isLoading}
      />
      <input
        id="dtt_{widgetId}"
        type="time"
        class="form-control d-inline-block w-auto {props.class || ''}"
        class:is-invalid={hasError}
        style={props.style}
        aria-labelledby={props.label_i18n
          ? props.label_i18n[$currentLanguage]
          : props.label}
        max={props.maxDate}
        min={props.minDate}
        bind:value={time}
        on:change={valueChanged}
        disabled={myVisibility === "disabled" || $isLoading}
      />
    {/if}

    {#if showHelp}
      <div id={props.id + "-HelpBlock"} class="invalid-feedback d-block">
        {props?.validationMsg_i18n?.[$currentLanguage]
          ? props.validationMsg_i18n[$currentLanguage]
          : props.validationMsg}
      </div>
    {/if}
  </div>
{/if}
