<script>
  import { createEventDispatcher, onMount } from "svelte";
  import { nanoid } from "nanoid";
  export let label = undefined;
  export let name = undefined;
  export let value = undefined;
  export let placeholder = "";
  export let type = "text";
  export let invalid = undefined;
  export let disabled = false;
  export let required = false;
  export let onlyinput = false;
  export let autocomplete = undefined;
  export let autofocus = false;
  const dispatch = createEventDispatcher();
  let uuid;
  onMount(() => {
    uuid = nanoid(10);
  });

  const callback = (type) => {
    if (type === "changed") return dispatch("callback");
    else return;
  };
  function keyDownPressed(e) {
    if (e.keyCode === 13) {
      dispatch("key-enter");
    }
  }
</script>

{#if onlyinput}
  <input
    type="text"
    id="{uuid}-{label}"
    name={name ? name : `${uuid}-${label}`}
    class="form-control {invalid ? 'is-invalid' : ''}"
    {placeholder}
    bind:value
    {disabled}
    {autofocus}
    on:change={() => {
      callback("changed");
    }}
    on:keypress={keyDownPressed}
  />
{:else}
  <div class="mb-3">
    {#if label}
      <label for="{uuid}-{label}">{label}{required ? " *" : ""}</label>
    {/if}
    {#if type === "number"}
      <input
        type="number"
        id="{uuid}-{label}"
        name={name ? name : `${uuid}-${label}`}
        class="form-control {invalid ? 'is-invalid' : ''}"
        {placeholder}
        bind:value
        {disabled}
        {autofocus}
        on:change={() => {
          callback("changed");
        }}
        on:keypress={keyDownPressed}
      />
    {:else if type === "password"}
      <input
        type="password"
        id="{uuid}-{label}"
        name={name ? name : `${uuid}-${label}`}
        class="form-control {invalid ? 'is-invalid' : ''}"
        {placeholder}
        bind:value
        {disabled}
        {autofocus}
        on:change={() => {
          callback("changed");
        }}
        on:keypress={keyDownPressed}
        {autocomplete}
      />
    {:else}
      <input
        type="text"
        id="{uuid}-{label}"
        name={name ? name : `${uuid}-${label}`}
        class="form-control {invalid ? 'is-invalid' : ''}"
        {placeholder}
        bind:value
        {disabled}
        {autofocus}
        on:change={() => {
          callback("changed");
        }}
        on:keypress={keyDownPressed}
      />
    {/if}
  </div>
{/if}
