import { isLoading } from "../../stores";

const sessions = {};

export default {
  setSession,
  hasSession,
  consumeSession,
  clearSessions,
}

/**
 * @param {string} resId
 * @param {any} next
 */
function setSession(resId, next) {
  isLoading.set(true);
  sessions[resId] = next;
}

/**
 * @param {string} resId
 */
function hasSession(resId) {
  return sessions[resId] ? true : false;
}

/**
 * @param {string} resId
 * @param {any} data
 */
function consumeSession(resId, data) {
  // console.log('... consuming session', resId, data);
  // check if there is an active session:
  const hasSession = Object.keys(sessions).includes(resId);
  if (!hasSession) {
    isLoading.set(false);
    return;
  }

  // call and update sessionId data:
  sessions[resId](data);
  isLoading.set(false);
  sessions[resId] = undefined;
  // console.log('... consumed session', resId, data, sessions);
}

function clearSessions() {
  Object.keys(sessions).forEach(sessionKey => {
    sessions[sessionKey] = undefined;
  })
  isLoading.set(false);
}

