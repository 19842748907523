<script>
  import { onDestroy, onMount } from "svelte";
  import taskExeErrorAndVisibilityService from "../task-exe-error-and-visibility.service";
  import Chart from "./../../utils/chart.svelte";
  import apiService from "../../../services/api.service";
  import {
    substores,
    taskExeBusinessObject,
    taskExeErrorStore,
    taskExeStore,
  } from "../task-exe-store";
  import { get } from "svelte/store";
  import taskExeVisibilityTree from "../task-exe-visibility-tree";

  /** @type {{id: string;}|undefined} */
  export let item = undefined;
  export let props = {};
  /** @type {{id: string; type: string;}[]} */
  export let parent = undefined;
  export let visibilityFromParent = "editable";
  /** @type {{id: string; tplId: string;}|null} */
  export let templateProps = null;

  let hasError = false;
  let showHelp = false;
  let value;
  let myVisibility;
  let dynamicBoStore;
  let taskExeBusinessObjectUnsubscribe;
  let taskExeErrorStoreUnsubscribe;
  const pathToStore = parent
    .filter((el) => el.type === "tpl-preview")
    .map((el) => el.id)
    .join("_");
  const pathToStoreNamed = pathToStore ? pathToStore : "general";
  const types = ["line", "bar", "pie"];
  let config = undefined;
  let config1 = {
    type: props?.chartType || types[randomIntFromInterval(0, 2)],
    data: {
      labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
      datasets: [
        {
          label: "Dataset 1",
          data: getRandomNumber(),
          // data: [10, 50, 30, 100, 90, 40, 70],
          // borderColor: Utils.CHART_COLORS.red,
          // backgroundColor: Utils.transparentize(Utils.CHART_COLORS.red, 0.5),
        },
        {
          label: "Dataset 2",
          data: getRandomNumber(),
          // data: [70, 40, 90, 100, 30, 50, 10],
          // borderColor: Utils.CHART_COLORS.blue,
          // backgroundColor: Utils.transparentize(Utils.CHART_COLORS.blue, 0.5),
        },
      ],
    },
    options: {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: props.label || "Chart.js Chart",
        },
      },
    },
  };

  // $: changedChartType(props?.chartType);
  // $: changedChartLabel(props?.label);

  let visibilityStoreUnsubscribe;
  const visibilityStore = taskExeVisibilityTree.checkVisibilityStore(
    item,
    parent,
  );
  if (visibilityStore) {
    visibilityStoreUnsubscribe = visibilityStore.subscribe(
      (/** @type {string} */ res) => {
        myVisibility = res;
      },
    );
  }

  $: checkVisWithParent(myVisibility, visibilityFromParent); 

  function checkVisWithParent(_myVisibility, _visibilityFromParent) {
    myVisibility = taskExeVisibilityTree.checkVisOfElementWithParent(_visibilityFromParent, _myVisibility, props)
  }

  onMount(() => {
    props.isFirstValidation = true;

    if (pathToStore) {
      if (substores.bos?.[pathToStore]?.data) {
        dynamicBoStore = substores.bos[pathToStore].data;
      } else {
        dynamicBoStore = taskExeBusinessObject;
      }
    } else {
      dynamicBoStore = taskExeBusinessObject;
    }

    const firstValue = apiService.getNestedFromPath(
      get(dynamicBoStore),
      props.varName,
    );
    // console.log("... first value", JSON.stringify(firstValue));
    if (firstValue) config = firstValue;
    dynamicBoStore.ensureValue(props.varName, firstValue, [pathToStoreNamed]);

    taskExeErrorStoreUnsubscribe = taskExeErrorStore.subscribe(() => {
      if (props.isFirstValidation === true) return;
      const resultErrVars =
        taskExeErrorAndVisibilityService.getErrorVars(props);
      hasError = resultErrVars.hasError;
      showHelp = resultErrVars.showHelp;
    });

    taskExeBusinessObjectUnsubscribe = dynamicBoStore.subscribe((bo) => {
      if (!$taskExeStore) return;
      const newTempValue = apiService.getNestedFromPath(bo, props.varName);
      if (value !== newTempValue) value = newTempValue;

      if (value) config = value;
      else config = config1;
    });

    setTimeout(() => {
      props.isFirstValidation = false;
    }, 100);
  });

  onDestroy(() => {
    taskExeBusinessObjectUnsubscribe();
    taskExeErrorStoreUnsubscribe();
    if (visibilityStoreUnsubscribe) visibilityStoreUnsubscribe();
  });

  function changedChartLabel(label) {
    // console.log("... label");
    config.options.plugins.title.text = label || "Chart.js Chart";
  }

  function changedChartType(type) {
    config.type = type || types[randomIntFromInterval(0, 2)];
  }

  function getRandomNumber() {
    const myData = Array(7)
      .fill(1)
      .map(() => {
        return randomIntFromInterval(0, 100);
      });
    return myData;
  }

  function randomIntFromInterval(min, max) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
</script>

{#if myVisibility !== "hidden"}
  <div id={item.id} class="mb-3">
    {#if config?.type}
      <div class={props.class || ""} style={props.style}>
        <Chart bind:type={config.type} bind:config />
      </div>
    {/if}
  </div>
{/if}

<!-- {JSON.stringify(config)} -->
<!-- <hr /> -->
<!-- {JSON.stringify($taskExeBusinessObject)} -->
