<script>
  import { onDestroy, onMount } from "svelte";
  import { taskExeStore, substores } from "../task-exe-store";
  import TaskExeCompList from "../task-exe-comp-list.svelte";
  import apiService from "../../../services/api.service";
  import taskExeApi from "../task-exe.api";
  import taskExeVisibilityTree from "../task-exe-visibility-tree";
  import { t } from "../../../services/i18n.service";

  /** @type {{id: string; columns: any[]}|undefined} */
  export let item = undefined;
  export let props = {};
  /** @type {{id: string; type: string}[]} */
  export let parent = undefined;
  export let visibilityFromParent = "editable";
  /** @type {{id: string; tplId: string;}|null} */
  export let templateProps = null;

  const STATUSES = {
    LOADING: "loading",
    TPL_FOUND: "tpl-found",
    TPL_NOT_FOUND: "tpl-not-found",
    TPL_RECURSIVE: "tpl-recursive",
  };
  let myVisibility;
  let dynamicBoStore;
  let currentParents = [];
  let taskExeBusinessObjectUnsubscribe;
  let status = STATUSES.LOADING;

  const debounceCalculateVisibility = apiService.debounce(
    taskExeVisibilityTree.checkVisibilityForWidgetCategory,
    // INFO: leave the value with 50 because it seams that less doesn't
    // trigger the visibilit change after store.updateBO
    50,
  );

  $: updateParentsList(parent);

  const pathParents = parent
    .filter((el) => el.type === "tpl-preview")
    .map((el) => el.id)
    .join("_");
  const pathOfId =
    pathParents.length > 0 ? pathParents + "_" + item.id : item.id;

  let visibilityStoreUnsubscribe;
  const visibilityStore = taskExeVisibilityTree.checkVisibilityStore(
    item,
    parent,
  );
  if (visibilityStore) {
    visibilityStoreUnsubscribe = visibilityStore.subscribe((res) => {
      myVisibility = res;
    });
  }

  $: checkVisWithParent(myVisibility, visibilityFromParent); 

  function checkVisWithParent(_myVisibility, _visibilityFromParent) {
    myVisibility = taskExeVisibilityTree.checkVisOfElementWithParent(_visibilityFromParent, _myVisibility, props)
  }

  onMount(() => {
    // INFO: dynamicBoStore is actualy the BO Store generated by the template!!!
    dynamicBoStore = substores.bos?.[pathOfId]?.data;
    if (!dynamicBoStore) {
      status = STATUSES.TPL_RECURSIVE;
      return;
    }

    const isTemplateActivated =
      taskExeApi.getLoadedTemplateActivation.check(pathOfId);
    if (!isTemplateActivated) {
      // NOTE: after loading the UI scan the definition.objects after templates and load them all
      // and then reference them here
      // load template and add it to ui definition:
      const uiTpl = taskExeApi.getLoadedTemplate(props.tplId);
      if (uiTpl) {
        // NOTE: check if the template objects is already in place in order to not update every thing again
        // this update may be only because of a visibility update form hidden to visibile

        const dropzone = uiTpl.definition.dropzone.Dropzone;
        const objects = uiTpl.definition.objects;
        item.columns = [...dropzone];
        taskExeStore.update((store) => {
          store.definition.objects = {
            ...store.definition.objects,
            ...objects,
          };
          return store;
        });

        taskExeApi.getLoadedTemplateActivation.add(pathOfId);

        status = STATUSES.TPL_FOUND;

        // INFO: initiation of Business Object substore is made
        // on while loading the task and not here
        // because the {#key} tag in widgets resets the template widgets
        // and also resets the substores associated!!!

        // INFO: this is moved after updating the UI with the template's elements
        // setTimeout(() => {
        taskExeBusinessObjectUnsubscribe = dynamicBoStore.subscribe((bo) => {
          if (!$taskExeStore) return;
          debounceCalculateVisibility(pathOfId, bo);
        });
        // }, 10);
      } else {
        status = STATUSES.TPL_NOT_FOUND;
      }
    } else {
      // setTimeout(() => {
      taskExeBusinessObjectUnsubscribe = dynamicBoStore.subscribe((bo) => {
        if (!$taskExeStore) return;
        debounceCalculateVisibility(pathOfId, bo);
      });
      status = STATUSES.TPL_FOUND;
      // }, 10);
    }
  });

  onDestroy(() => {
    if (taskExeBusinessObjectUnsubscribe) taskExeBusinessObjectUnsubscribe();
    if (visibilityStoreUnsubscribe) visibilityStoreUnsubscribe();
  });

  /**
   * @param {any} parent
   */
  function updateParentsList(parent) {
    currentParents = [...parent, props];
  }
</script>

<!-- TEMPLATE: {JSON.stringify(props.id)} / uiTplFound: {uiTplFound} -->
<!-- TEMPLATE: {JSON.stringify(props)} -->
<!-- PARENTS: {parent} -->
<!-- <div class=""> -->
<!--   template: {JSON.stringify(props.name)} -->
<!-- </div> -->
<!-- TEMPLATE ({pathOfId}): <br>  -->
<!-- BO: {JSON.stringify($dynamicBoStore)} -->

<!-- TEMPLATE ({item.id}): {myVisibility} / {props.visibility} -->
{#if status === STATUSES.TPL_RECURSIVE}
  <div class="text-danger">{$t("msgs.recursiveTpl")} ({props.name})</div>
{:else if status === STATUSES.TPL_NOT_FOUND}
  <div class="text-danger">{$t("msgs.noTplFound")}</div>
{:else if status === STATUSES.LOADING}
  {$t("generic.loading")}
{:else if myVisibility !== "hidden"}
  <div id={item.id} class="tpl-preview">
    {#each item?.columns as child}
      <TaskExeCompList
        bind:item={child}
        bind:parent={currentParents}
        bind:visibilityFromParent={myVisibility}
        templateProps={props}
      />
    {/each}
  </div>
{/if}
