<script>
  import { onMount } from "svelte";
  import InboxRedesign from "../components/inbox/inbox-redesign.svelte";
  import Inbox from "../components/inbox/inbox.svelte";
  import { inboxStateStore } from "../components/inbox/inbox-store";

  onMount(() => {
    inboxStateStore.setState("normal");
  });
</script>

<InboxRedesign></InboxRedesign>
<!-- <hr /> -->
<!-- <Inbox /> -->
