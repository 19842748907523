import axios from "axios";

export default {
  get,
  count,
}


/**
 * @param {object} query
 */
function get(query) {
  return axios.get('/api/rest/bo/log', {params: query});
}

/**
 * @param {object} query
 */
function count(query) {
  return axios.get('/api/rest/bo/log', { params: { ...query, countDoc: true } });
}

