<script>
  import { createEventDispatcher } from "svelte";
  import { t, tNow } from "../../services/i18n.service";
  import UtilModal from "../utils/util-modal.svelte";
  import InstanceCheckCompMigration from "./instance-check-comp-migration.svelte";
  const dispatch = createEventDispatcher();

  let modalShow = false;
  export let item;
  const modalButtons = [
    { label: tNow("generic.close"), action: "close", class: "btn-secondary" },
  ];

  export function init() {
    modalShow = true;
  }

  /**
   * @param {string} action
   */
  function modalClose(action) {
    if ("close" === action) {
      modalShow = false;
      dispatch("closed");
    }
  }
</script>

<UtilModal
  title={$t("diaInspMigration.title")}
  autoClose={false}
  autofocus={true}
  closeOnEscape={true}
  open={modalShow}
  {modalButtons}
  size="lg"
  onClosed={modalClose}
>
  {#if item}
    <InstanceCheckCompMigration
      bind:instance={item}
      on:close={() => {
        modalClose("close");
      }}
    />
  {/if}
</UtilModal>
