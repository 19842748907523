<script>
  import { push } from "svelte-spa-router";
  import authService from "../services/auth.service";

  export let params = {};

  $: checkParamsTkn(params.wild);

  /**
   * @param {string} tkn
   */
  function checkParamsTkn(tkn) {
    if (!tkn) return;
    activateSocial(tkn);
  }

  /**
   * @param {string} tkn
   */
  function activateSocial(tkn) {
    if (!tkn) {
      push("/login");
      return;
    }
    authService.activateSocial(tkn, (err, res) => {
      if (err) {
        push("/login");
        return;
      }
      push("/");
    });
  }
</script>

redirecting....
