<script>
  import TaskExeCompList from "../task-exe-comp-list.svelte";
  import TaskExeTplI18nLabel from "./task-exe-tpl-i18n-label.svelte";
  import { fade, fly } from "svelte/transition";
  import { quintOut } from "svelte/easing";
  import { taskExeStore } from "../task-exe-store";

  /** @type {{id: string; columns: any[]}|undefined} */
  export let item = undefined;
  export let props = {};
  export let parent = undefined;
  export let visibilityFromParent = "editable";
  /** @type {{id: string; tplId: string;}|null} */
  export let templateProps = null;

  let modalComponent;
  // INFO: Modal should be always editable!!!
  let myVisibility = "editable";
  const showBackdrop = false;

  $: open = props.opened || false;
  $: focus(open, modalComponent);
  // $: changedVisibility(visibilityFromParent, newVisibility);

  /**
   * @param {boolean} open
   * @param {{ focus: () => void; }} modalComponent
   */
  function focus(open, modalComponent) {
    if (modalComponent && open) modalComponent.focus();
  }
</script>

{#if open}
  <div
    class="modal"
    id={item.id}
    tabindex="-1"
    role="dialog"
    aria-labelledby="Modal {props.name}"
    aria-hidden={false}
    bind:this={modalComponent}
    on:keyup={(ev) => {
      if (!props.noCloseByEscOrBackDrop) return;
      if (ev.keyCode === 27)
        $taskExeStore.definition.objects[item.id].opened = false;
    }}
  >
    <div
      class="modal-dialog {props.classSize ? props.classSize : 'modal-sm'}"
      role="document"
      in:fly={{ y: -50, duration: 300 }}
      out:fly={{ y: -50, duration: 300, easing: quintOut }}
    >
      <div class="modal-content {props.class || ''}" style={props.style}>
        <div class="modal-header">
          <h5 class="modal-title" id="sampleModalLabel">
            <TaskExeTplI18nLabel bind:props />
          </h5>
        </div>
        <div class="modal-body">
          {#each item.columns as child}
            <TaskExeCompList
              bind:item={child}
              bind:parent
              bind:visibilityFromParent={myVisibility}
              bind:templateProps
            />
          {/each}
        </div>
      </div>
    </div>
  </div>
  <!-- {#if showBackdrop} -->
  <div class="modal-backdrop show" transition:fade={{ duration: 150 }} />

  <!-- {/if} -->
{/if}

<style>
  .modal {
    display: block;
  }
  .modal-fullscreen {
    width: 90vw;
    max-width: none;
    height: auto;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .modal-maximized {
    width: 90vw;
    max-width: none;
    height: auto;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
  }
</style>
