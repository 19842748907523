<script>
  import { onMount } from "svelte";
  import { MoonStars, Sun } from "svelte-bootstrap-icons";
  import storageService from "../../services/storage.service";
  import { themeStore } from "../../stores";

  let theme = "light";

  onMount(() => {
    toggleTheme();
  });

  /**
   * @param {string} [_theme]
   */
  function toggleTheme(_theme) {
    if (!_theme) {
      const savedTheme = storageService.get("theme");
      if (savedTheme) theme = savedTheme;
      else theme = "light";
    } else theme = _theme;
    storageService.save("theme", theme);
    themeStore.set(theme);
    document
      .getElementsByTagName("html")[0]
      .setAttribute("data-bs-theme", theme);
  }
</script>

<div class="btn-group" role="group" aria-label="Theme switcher">
  {#if theme === "dark"}
    <button
      type="button"
      class="btn btn-outline-secondary"
      on:click={() => toggleTheme("light")}
    >
      <Sun width="20" height="20" />
    </button>
  {:else}
    <button
      type="button"
      class="btn btn-outline-secondary"
      on:click={() => toggleTheme("dark")}
    >
      <MoonStars width="20" height="20" />
    </button>
  {/if}
</div>
