<script>
  import InstanceCheckInstances from "../components/instance-check/instance-check-instances.svelte";
  import InstanceCheckTasks from "../components/instance-check/instance-check-tasks.svelte";
</script>

<div class="row">
  <div class="col-md-6 mb-3">
    <InstanceCheckInstances />
  </div>
  <div class="col-md-6">
    <InstanceCheckTasks />
  </div>
</div>
