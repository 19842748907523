<script>
  import { X } from "svelte-bootstrap-icons";
  import { t } from "../services/i18n.service";
  import UtilCardTitle from "../components/utils/util-card-title.svelte";
  import { pop } from "svelte-spa-router";
  import ProcessCompSimulation from "../components/designer/process/process-comps/process-comp-simulation.svelte";
  import { onMount } from "svelte";
  // import { designerCurrentItemStore } from "../components/designer/designer.store";
  import { get } from "svelte/store";
  import ProcessCompPerformance from "../components/designer/process/process-comps/process-comp-performance.svelte";

  export let params = {};
  let icoHeight = 24;

  onMount(() => {
    // const item = get(designerCurrentItemStore);
    // if (!item) {
    //   designerCurrentItemStore.open(params.pid);
    // }
  });

  function close() {
    pop();
  }
</script>

<div class="card">
  <div class="card-header bg-primary d-flex">
    <UtilCardTitle title={$t("procDiagram.title")} />
    <div class="ms-auto">
      <button
        class="btn btn-outline-secondary border-0 text-white"
        on:click={() => close()}
      >
        <X width={icoHeight} height={icoHeight} />
      </button>
    </div>
  </div>
  <div class="card-body view-height">
    <ProcessCompSimulation processId={params.pid} />
  </div>
</div>

<style>
  .view-height {
    height: calc(100vh - 180px);
    /* height: calc(100vh - 380px); */
    overflow-y: scroll;
  }
</style>
