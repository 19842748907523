import { get } from "svelte/store";
import { isAuth, userStore } from "../stores";
import { io } from "socket.io-client";
import authService from "./auth.service";
import { push } from "svelte-spa-router";
import constsService from "./consts.service";
import storage from "./storage.service";
import toasterService from "./toaster.service";
import { tNow } from "./i18n.service";
import taskExeApiCallsService from "../components/task/task-exe-api-calls.service";
import { inboxCommandStore, inboxStateStore } from "../components/inbox/inbox-store";
import { taskExeBusinessObject, taskExeCommandStore, taskExeInfo } from "../components/task/task-exe-store";

let o = {
  date: new Date(),
  environment: '',
  url: null,
  socket: null,
  disconnectedByUser: true,
  start: () => prepData(startBinding),
  checkReconnect,
  setConnectionMessage,
  clearMessage,
  stop,
  send: sendMessage,
  cbs: {},
  callbackOnConnect: {},
  user: null,
  msgLostConnection: null,
  firstConnection: true,
  setEnvironment,
};

userStore.subscribe(res => {
  // console.log('... user store subscribe in ws', res)
  if (!res) {
    // o.checkReconnect();
    return;
  }
  // console.log('>>> AM USER STORE')
  o.user = res;
  // if (!o.user) {
  //   o.user = res;
  //   // o.stop();
  //   console.log('>>> START WS')
  //   setTimeout(() => {
  //     o.start();
  //   }, 100)
  // } else {
  //   console.log('>>> CHECK WS')
  //   o.checkReconnect();
  // }
})

isAuth.subscribe(res => {
  if (!res) return;
  // console.log('>>> AM USER AUTH')
  // console.log('>>> START WS')
  o.stop();
  setTimeout(() => {
    o.start();
  }, 100)
})

export default o;

/**
 * @param {string} env
 */
function setEnvironment(env) {
  o.environment = env ? env + '-' : '';
  o.url = window.location.protocol + '//' + window.location.hostname + (window.location.hostname === 'localhost' ? `:12002/${o.environment}portal` : `/${o.environment}portal`);
  // console.log('... configuring ws env', o);
}

/**
 * @param {any} type
 * @param {any} data
 */
function sendMessage(type, data) {
  if (o?.socket?.connected) {
    o.socket.emit(type, data);
  } else {
    // o.checkReconnect();
    o.send(type, data);
  }
}

/**
 * @param {(arg0: any[]) => void} next
 */
function prepData(next) {
  const user = o.user;
  if (user) {
    o.user = user;
    let rooms = [];
    // get orgIds:
    o.user.organisations.forEach((/** @type {{ _id: any; }} */ it) => {
      rooms.push(it._id);
    })
    // get roleIds:
    o.user.roles.forEach((/** @type {any} */ it) => {
      rooms.push(it);
    })
    next(rooms);
  } else {
    console.error('WS: Cannot get user!!!');
  }
}

/**
 * @param {string[]} rooms
 */
function startBinding(rooms) {
  if (o.socket) return;

  o.socket = io(o.url, {
    path: constsService.SERVER_PATH + '/socket.io',
    upgrade: false,
    timeout: 3600000,
    // pingTimeout: 300,
    // pingInterval: 1000,
    reconnection: true,
    agent: false,
    transports: [
      "websocket",
      // 'polling',
      // 'long-polling'
    ],
    // rejectUnauthorized:   false,
    auth: (cb) => {
      let token;

      const expirationTime = authService.checkTknAccessExpiration();
      // console.log('expirationTime', expirationTime);
      if (expirationTime <= 1000 * 60 * 1) {
        authService.refreshToken((err, res) => {
          // console.log('... auth refresh token', err, res);
          if (err) {
            push('login');
            return;
          }
          token = res;
          cb({ token });
        })
      } else {
        token = storage.get(constsService.APP_NAME);
        // console.log('... token', token)
        // console.log('tkn de dat 2', token);
        cb({ token })
      }
    },
    secure: true,
    // withCredentials: true,
    // extraHeaders: {
    //   "my-custom-header": "abcd"
    // },
    // query: {token: $api.session.getTkn()}
  });

  o.socket.on('connect_error', (err) => {
    // console.log('WS Connection failed', err);
    if (err) {
      if (err.message === 'not authorized') {
        if (['jwt expired'].indexOf(((err.data || {}).details || {}).message) > -1) {
          o.stop();
          o.socket = undefined;
          console.log('... logout from ws')
          authService.logout();
          // setTimeout(() => {
          //   o.start();
          // }, 100)
        } else {
          // console.log('ar trebui sa trimit mesaj');
          toasterService.error({ msg: err.data.msg })
        }
      } else if (err.message === 'websocket error') {
        o.setConnectionMessage();
      } else {
        toasterService.error({ msg: err })
        o.stop();
        o.socket = undefined;
        setTimeout(() => {
          o.start();
        }, 100)
      }
      return;
    }
    // if (!o.socket.query) o.socket.query = {};
    // o.socket.query.token = $api.session.getTkn();
    // o.socket.connect();
    o.stop();
    o.socket = undefined;
    setTimeout(() => {
      o.start();
    }, 100)
    // o.checkReconnect();
  });

  o.socket.on('reconnect_failed', (err) => {
    console.log('WS Reconnection failed', err);
    // o.checkReconnect();
  });

  o.socket.on('connect', (_socket) => {
    o.disconnectedByUser = false;

    // FIXME: collaboration
    // console.log('... collaboration')
    // $rootScope.collaboration.checkedOutEditor = false;
    o.clearMessage();
    if (o.firstConnection) {
      o.firstConnection = false;
    } else {
      Object.keys(o.callbackOnConnect).forEach(fnKey => {
        o.callbackOnConnect[fnKey]();
      });
    }
  })

  o.socket.on('error', (error) => {
    // console.log('JWT ERR', JSON.stringify(error));
    console.error('WS ERR', error, o);
    if (error === 'Authentication error') {
      // window.o = o;
      // $api.msg({type: 'error', title: 'error', msg: error})
      // o.socket.query.token = $api.session.getTkn();
      // o.stop();
      // o.checkReconnect();
      // o.socket.connect();
      // $timeout(() => {
      //   o.start();
      // })
    }
  });

  o.socket.on('disconnect', (error) => {
    console.error(error);
    // o.stop();
    // $timeout(() => {
    //   o.start();
    // })
    // o.checkReconnect();
  });

  o.socket.on('message', (error) => {
    console.error(error);
  });

  o.socket.on('collaboration::request-report', (data) => {
    // FIXME: collaboration
    // console.log('... collaboration request-report')
    // $rootScope.collaboration[data.srvId] = {
    //   users: {},
    //   msg: ''
    // };
    // $rootScope.$apply();
    const user = get(userStore) || {};
    o.socket.emit('collaboration::report', {
      srvId: data.srvId,
      firstName: user.firstName,
      lastName: user.lastName,
      id: user._id,
      socket: o.socket.id,
    });
    // $timeout(() => {
    // FIXME: collaboration
    // console.log('... collaboration report-editor')
    // if ($rootScope.collaboration.checkedOutEditor) o.socket.emit('collaboration::report-editor', {
    //   srvId: data.srvId,
    //   userId: o.user._id
    // })
    // }, 300)

  });

  o.socket.on('collaboration::report-editor', (data) => {
    // FIXME: collaboration
    // console.log('... collaboration report-editor')
    // if (!$rootScope.collaboration.checkedOutEditor) {
    //   $rootScope.$apply(() => {
    //     $rootScope.collaboration.checkedOutBy = data.userId;

    //     $timeout(() => {
    //       $api.msg({ type: 'warning', title: $translate.instant('msgs.msgUITplsCannotAddMultipleItemsTitle'), msg: $translate.instant('msgs.msgTltipCollaborationCheckedIn') });
    //     }, 300)
    //   });
    // }
  })

  o.socket.on('collaboration::report', (data) => {
    // FIXME: collaboration
    // console.log('... collaboration report')
    if (o.user._id === data.id) {
      data.firstName = 'You';
      data.lastName = null;
    }

    // if (!$rootScope.collaboration[data.srvId]) $rootScope.collaboration[data.srvId] = {};
    // if (!$rootScope.collaboration[data.srvId].users) $rootScope.collaboration[data.srvId].users = {};
    // if (!$rootScope.collaboration[data.srvId].sessions) $rootScope.collaboration[data.srvId].sessions = {};
    // $rootScope.collaboration[data.srvId].users[data.id] = data;

    // if (!$rootScope.collaboration[data.srvId].sessions[data.id]) $rootScope.collaboration[data.srvId].sessions[data.id] = new Set();
    // $rootScope.collaboration[data.srvId].sessions[data.id].add(data.socket);

    // const participantsLength = Object.keys($rootScope.collaboration[data.srvId].users).length;
    // $rootScope.collaboration[data.srvId].msg = participantsLength; // + ' ' + (participantsLength===1 ? $translate.instant('generic.participant'): $translate.instant('generic.participants'));
    // if (!$rootScope.collaboration.checkedOutEditor) $rootScope.collaboration.checkedOutEditor = false;

    // $rootScope.$apply();
  });

  o.socket.on('collaboration::service-checkedout', (data) => {
    // FIXME: collaboration
    // console.log('... collaboration service-checkedout')
    // $rootScope.$apply(() => {
    //   $rootScope.collaboration.checkedOutBy = data.userId;
    //   if (data.userId === o.user._id) $rootScope.collaboration.checkedOutEditor = true;
    //   else {
    //     $rootScope.collaboration.checkedOutEditor = false;

    //     $timeout(() => {
    //       if (data.userId) {
    //         const user = ((($rootScope.collaboration[data.id] || {}).users || {})[data.userId] || {}).firstName;
    //         // service is edited by user
    //         $api.msg({ type: 'warning', title: $translate.instant('msgs.msgUITplsCannotAddMultipleItemsTitle'), msg: $translate.instant('msgs.msgCollaborationCheckinWithUser', { user: user ? `by ${user}` : '' }) });
    //       } else {
    //         // service is not edited. you can check out the service
    //         $api.msg({ type: 'success', title: $translate.instant('msgs.msgUITplsCannotAddMultipleItemsTitle'), msg: $translate.instant('msgs.msgCollaborationCheckinWithoutUser') });
    //       }
    //       // $api.msg({type: 'warning', title: $translate.instant('msgs.msgUITplsCannotAddMultipleItemsTitle'), msg: $translate.instant('msgs.msgTltipCollaborationCheckedIn')});
    //     }, 300)
    //   }

    // });
  })

  o.socket.on('collaboration::refresh-srv', (data) => {
    // FIXME: collaboration
    // console.log('... collaboration refresh-srv')
    // if ($rootScope.collaboration.checkedOutEditor) return;

    // refresh the service:
    // FIXME: cannot refresh the service because on every save all the others would receive message that service is not checked out!!!
    // First, get rid of it and then try enabling this.
    // on the other hand, until collaboration on the same service is posible, one with the service not checked out cannot test some widgets because the service would re-open itself on every save action
    // $rootScope.$broadcast(data.srvType == 'process' ? 'bpmnio' : data.srvType, {
    //   action: 'open',
    //   id: data.srvId,
    //   act2: 'refresh'
    // });
  })


  o.socket.on('refresh::procbucket::services', (data) => {
    // FIXME: collaboration
    // console.log('... collaboration refresh-srv')
    // refresh services only if user is in the same process bucket
    // const url = $location.path();
    // const arrurl = url.split('/').filter(c => c !== '');
    // if (arrurl.length === 2 && arrurl[0] === 'designer' && arrurl[1] === data.paid) {
    //   $rootScope.$broadcast("designer", {
    //     action: data.action
    //   }, 0);
    // }
  })


  o.socket.on("event", function(data) {
    // console.log('... received event >>>', data, o);

    // FIXME: fix messages that comes 2 times
    if (data?.action === 'refresh') {
      if (data?.msgCode) {
        toasterService.success({ msg: tNow(data.msgCode.code, data.msgCode.obj) })
      } else if (data?.msg) {
        toasterService.success({ msg: data.msg });
      }
      inboxCommandStore.command('refresh');

    } else if (data?.action === 'refresh exposed') {
      inboxCommandStore.command('refresh exposed');

    } else if (data?.action === 'show msg') {
      if (data?.msgCode) {
        toasterService.info({ msg: tNow(data.msgCode.code, data.msgCode.obj) })
      } else if (data?.msg) {
        toasterService.info({
          msg: data?.msg,
          // showCloseButton: true,
          timeout: 0
        });
      }

      // update bo if there is an open task:
      if (data?.details?.bo) {
        // check if there is a task opened:
        const taskInfo = get(taskExeInfo);
        if (taskInfo) {
          // check if there is restriction of task ids:
          if (!data.details?.uis) {
            taskExeBusinessObject.updateBO(data.details.bo, true, ['general']);
          } else if (
            data.details.uis.includes(taskInfo.hid) ||
            data.details.uis.includes(taskInfo.pc_hid)
          ) {
            taskExeBusinessObject.updateBO(data.details.bo, true, ['general']);
          }
        }
      }
    } else if (data?.details?.msg?.includes('[I 4000]')) {
      // do nothing
    } else {
      if (data?.details?.error) {
        let msg = data.details.error;
        toasterService.error({ msg });
        // consume session in case of error:
        taskExeApiCallsService.consumeSession(data.details.resId, data.details);
      } else {
        // if (!data?.details?.tid)
        // toasterService.success({ msg: tNow('wsFact.msgProcStarted') });
      }
    }

    if (data?.details?.tid) {
      const inboxState = get(inboxStateStore);
      if (inboxState.state === 'normal') {
        push(`/task/${data.details.tid}`)
      } else {
      }
      taskExeCommandStore.command({
        action: 'open',
        details: data
      })
    }


    // FIXME: collaboration
    // console.log('... event resId')
    if (data?.details?.resId && taskExeApiCallsService.hasSession(data.details.resId)) {
      if (data.details?.error) {
        let context = Object.keys(data.details.context)
          .map((key) => `<div>${key}: ${data.details.context[key]}</div>`);
        let msg = `
          <div><b>${data.details.error}</b></div>
          ${context.join('')}
        `;
        toasterService.error({
          title: tNow('generic.error'),
          msg: msg
        });
      }
      taskExeApiCallsService.consumeSession(data.details.resId, data.details);
    }
  });

  o.socket.on("identify", function(data) {
    // const user = get(userStore)._id;
    // console.log('... identify', o.user, {
    //   rooms: rooms,
    //   userId: o.user._id,
    //   mobileTkn: undefined
    // })
    o.socket.emit('room', {
      rooms: rooms,
      userId: o.user._id,
      mobileTkn: undefined
    });
  });
}

function checkReconnect() {
  // console.log('.... checking connection');
  setTimeout(() => {
    if (!o.disconnectedByUser) {
      if (!(o.socket || {}).connected) {
        // $api.session.logout();
        if (!o.msgLostConnection) {
          o.setConnectionMessage();
        }

        setTimeout(() => {
          // FIXME: api calls
          // console.log('.... apiCalls clear')
          // $apiCalls.clear();
          if (o.socket) {
            o.socket.connect();
          } else {
            o.socket = null;
            o.start();
          }
        }, 100)
      }
    }
  }, 0)
}

function setConnectionMessage() {
  if (!o.msgLostConnection) {
    o.msgLostConnection = toasterService.error({
      title: tNow('wsFact.connection'),
      // body: obj.msg, 
      msg: tNow('apiFact.lostConnection'),
      timeout: 0
    })
    // $rootScope.$apply();
  }
}

function clearMessage() {
  if (o.msgLostConnection) {
    o.msgLostConnection?.remove();
    // toaster.clear(o.msgLostConnection);
    // toaster.clear();
    setTimeout(() => {
      toasterService.success({
        title: tNow('wsFact.connection'),
        msg: tNow('wsFact.msgConnAlive')
      })
    }, 0)
  }
  o.msgLostConnection = undefined;
}

function stop() {
  o.disconnectedByUser = true;
  if (o?.socket) {
    o.socket.emit("disconnectMe", {
      userId: o.user._id
    });
    o.socket.close()
    o.socket = null;
  }
}


