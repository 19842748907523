<script>
  import { tooltip } from "./../../../services/tooltip.service";
  import { onDestroy, onMount } from "svelte";
  import {
    CaretLeft,
    CaretLeftSquare,
    CaretRight,
    CaretRightSquare,
    InfoLg,
  } from "svelte-bootstrap-icons";
  import apiService from "../../../services/api.service";
  import { t } from "../../../services/i18n.service";
  import userService from "../../user/user.service";
  import FieldInputButton from "../../utils/field-input-button.svelte";
  import UtilPagination from "../../utils/util-pagination.svelte";
  import roleService from "./role.service";

  export let item;
  let users = [];
  let usersNo = 0;
  let icoHeight = 20;
  let searchRoleUsers;
  let query = {
    sort: "-timestamp",
    limit: 8,
    skip: 0,
  };

  onMount(() => {
    search();
  });

  onDestroy(() => {});

  function search() {
    query.skip = 0;
    getUsers();
    countUsers();
  }

  function getCurrentRole() {
    roleService.getOne(item._id, { withUsers: true }).then((res) => {
      item = res.data;
    });
  }

  function getUsers() {
    const newQuery = { ...query, orgId: item.orgId };
    userService.get(newQuery).then((res) => {
      users = res.data;
      // const newUsers = transformUsers(users);
      // users = [...newUsers];
    });
  }

  function countUsers() {
    const newQuery = { ...query, orgId: item.orgId };
    userService
      .count(newQuery)
      .then((res) => {
        usersNo = res.data[0];
      })
      .catch((err) => console.error(err));
  }

  /**
   * @param {{ _id: any; roles: any[]; }} user
   */
  function addUserToRole(user) {
    roleService
      .addUsersToRole({ org: item.orgId, role: item._id, users: [user._id] })
      .then(() => {
        getCurrentRole();
      });
  }

  /**
   * @param {{ roles?: any; _id?: string; }} [user]
   */
  function removeUserFromRole(user) {
    roleService
      .removeUsersFromRole({
        org: item.orgId,
        role: item._id,
        users: [user._id],
      })
      .then(() => {
        getCurrentRole();
      });
  }

  function addAllUsersToRole() {
    const usersToAdd = users.map((el) => el._id);
    if (usersToAdd.length === 0) return;
    roleService
      .addUsersToRole({
        org: item.orgId,
        role: item._id,
        users: usersToAdd,
      })
      .then(() => {
        getCurrentRole();
      });
  }

  function removeAllUsersFromRole() {
    const usersToRemove = item.users.map((el) => el._id);
    if (usersToRemove.length === 0) return;
    roleService
      .removeUsersFromRole({
        org: item.orgId,
        role: item._id,
        users: usersToRemove,
      })
      .then(() => {
        getCurrentRole();
      });
  }
</script>

{#if item?._id}
  <div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div class="card">
              <div class="card-header bg-primary text-white d-flex">
                {$t("generic.users")}
                <button
                  class="btn btn-sm btn-outline-success text-white ms-auto border-0"
                  on:click={addAllUsersToRole}
                >
                  {$t("generic.addAll")}
                  <CaretRightSquare width={icoHeight} height={icoHeight} />
                </button>
              </div>
              <div class="card-body">
                <FieldInputButton
                  hideLabel={true}
                  bind:value={query.q}
                  buttonIcon="search"
                  placeholder={$t("generic.searchFor")}
                  fn={(ev) => {
                    if (!(!ev || ev.keyCode === 13)) return;
                    search();
                  }}
                />
                <ul class="list-group mb-3 view-height-1">
                  {#each users as user}
                    <li class="list-group-item d-flex">
                      <div class="me-auto">
                        {user.firstName}
                        {user.lastName}
                        {#key user}
                          <div class="text-muted">
                            <span use:tooltip={user.email}>
                              <InfoLg width={icoHeight} height={icoHeight} />
                              {apiService.convert.mask(user.email, 15, "#")}
                            </span>
                          </div>
                        {/key}
                      </div>
                      <button
                        class="btn btn-outline-primary p-2 border-0"
                        on:click={() => addUserToRole(user)}
                      >
                        <CaretRight width={icoHeight} height={icoHeight} />
                      </button>
                    </li>
                  {/each}
                </ul>
                <UtilPagination
                  bind:query
                  bind:totalData={usersNo}
                  on:callData={getUsers}
                />
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="card">
              <div class="card-header bg-primary text-white d-flex">
                {$t("generic.usersInRole")}
                <button
                  class="btn btn-sm btn-outline-danger text-white ms-auto border-0"
                  on:click={removeAllUsersFromRole}
                >
                  <CaretLeftSquare width={icoHeight} height={icoHeight} />
                  {$t("generic.remAll")}
                </button>
              </div>
              <div class="card-body">
                <FieldInputButton
                  placeholder={$t("generic.searchFor")}
                  buttonIcon="search"
                  hideLabel={true}
                  bind:value={searchRoleUsers}
                />
                {#if item.users}
                  <ul class="list-group view-height-1">
                    {#each item.users.filter( (/** @type {{ email: string; firstName: string; lastName: string; }} */ el) => {
                        if (!searchRoleUsers) return el;
                        if (searchRoleUsers) {
                          if (el.email
                              .toLowerCase()
                              .includes(searchRoleUsers.toLowerCase())) return el;
                          if (el.firstName
                              .toLowerCase()
                              .includes(searchRoleUsers.toLowerCase())) return el;
                          if (el.lastName
                              .toLowerCase()
                              .includes(searchRoleUsers.toLowerCase())) return el;
                        }
                      } ) as user}
                      <li class="list-group-item d-flex">
                        <button
                          class="btn btn-outline-primary me-3 p-2 border-0"
                          on:click={() => removeUserFromRole(user)}
                        >
                          <CaretLeft width={icoHeight} height={icoHeight} />
                        </button>
                        <div>
                          {user.firstName}
                          {user.lastName}
                          {#key user}
                            <div class="text-muted">
                              <span use:tooltip={user.email}>
                                <InfoLg width={icoHeight} height={icoHeight} />
                                {apiService.convert.mask(user.email, 15, "#")}
                              </span>
                            </div>
                          {/key}
                        </div>
                      </li>
                    {/each}
                  </ul>
                {/if}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
{:else}
  <div>{$t("roleDirective.msgNoRoleOpen")}</div>
{/if}
