import jq from "jquery";

/**
 * @param {any} element
 * @param {string} text
 */
export function tooltip(element, text) {
  let div;
  let myText = text;

  /**
   * @param {{ pageX: number; pageY: number; }} event
   */
  function mouseOver(event) {
    if (div) return;
    if (!myText) return;
    div = document.createElement('div');
    div.textContent = myText;
    div.style.fontSize = '.8rem';
    div.style.zIndex = '99999999';
    div.style.position = 'absolute';
    div.style.top = `${event.pageX + 5}px`;
    div.style.left = `${event.pageY + 5}px`;
    div.classList.add("tsk-tooltip", "bg-body-tertiary", "border", "border-primary", "text-primary", "p-2", "rounded", "rounded-3", "shadow");
    document.body.appendChild(div);
  }

  /**
   * @param {{ pageX: number; pageY: number; }} event
   */
  function mouseMove(event) {
    if (!div) return;
    const maxWidth = event.pageX + 5 + div.offsetWidth;
    const diff = window.innerWidth - maxWidth;
    div.style.left = `${event.pageX + 5 + (diff < 0 ? diff - 5 : 0)}px`;
    div.style.top = `${event.pageY + 5}px`;
  }

  function mouseLeave() {
    if (div) {
      div.remove();
      div = null;
    }
    jq('.tsk-tooltip').remove();
  }

  element.addEventListener('mouseover', mouseOver);
  element.addEventListener('mouseleave', mouseLeave);
  element.addEventListener('mousemove', mouseMove);
  element.addEventListener('click', mouseLeave);
  element.addEventListener('mouseup', mouseLeave);

  return {
    /**
     * @param {string} text
     */
    update(text) {
      myText = text;
    },

    destroy() {
      element.removeEventListener('mouseover', mouseOver);
      element.removeEventListener('mouseleave', mouseLeave);
      element.removeEventListener('mousemove', mouseMove);
      element.removeEventListener('mouseup', mouseMove);
      jq('.tsk-tooltip').remove();
    }
  }
}
