<script>
  import { onMount } from "svelte";
  import appManagementService from "../components/app-management/app-management.service";
  import UtilCardTitle from "../components/utils/util-card-title.svelte";
  import { t, tNow } from "../services/i18n.service";
  import { ChevronDown, ChevronUp } from "svelte-bootstrap-icons";
  import FieldDateFormated from "../components/utils/field-date-formated.svelte";
  import FieldSwitch from "../components/utils/field-switch.svelte";

  let exposed = {};
  let exposedOrdered = [];
  const icoHeight = 24;
  let vis = {
    orgs: {},
    pas: {},
    procs: {},
    dashs: {},
  };

  onMount(() => {
    getExposedAll();
  });

  function transformExposed() {
    const nexposed = JSON.parse(JSON.stringify(exposed));
    const { orgs, pas } = nexposed;
    delete nexposed.orgs;
    delete nexposed.pas;
    exposedOrdered = [];
    Object.entries(nexposed).forEach(([orgKey, orgPas]) => {
      const org = {
        info: orgs[orgKey],
        pas: [],
      };
      Object.entries(orgPas).forEach(([paKey, paObjs]) => {
        const pa = {
          info: pas[paKey],
          processes: [],
          dashboards: [],
        };
        if (paObjs?.processes)
          Object.entries(paObjs.processes).forEach(([_procKey, procObj]) => {
            const proc = {
              info: procObj[0],
              vers: procObj,
            };
            pa.processes.push(proc);
          });
        if (paObjs?.dashboards)
          Object.entries(paObjs.dashboards).forEach(([_dashKey, dashObj]) => {
            const dash = {
              info: dashObj[0],
              vers: dashObj,
            };
            pa.dashboards.push(dash);
          });
        // order procs:
        let procNames = pa.processes.map((el) => el.info.name).sort();
        const newProcList = Array(procNames.length).fill(null);
        pa.processes.forEach((proc) => {
          const newIndex = procNames.findIndex((el) => el === proc.info.name);
          newProcList[newIndex] = proc;
        });
        pa.processes = [...newProcList];
        // order dashs:
        let dashNames = pa.dashboards.map((el) => el.info.name).sort();
        const newDashList = Array(dashNames.length).fill(null);
        pa.dashboards.forEach((dash) => {
          const newIndex = dashNames.findIndex((el) => el === dash.info.name);
          newDashList[newIndex] = dash;
        });
        pa.dashboards = [...newDashList];
        org.pas.push(pa);
      });
      // order pas:
      let pasNames = org.pas.map((el) => el.info.name).sort();
      const newPasList = Array(pasNames.length).fill(null);
      org.pas.forEach((pa) => {
        const newIndex = pasNames.findIndex((el) => el === pa.info.name);
        newPasList[newIndex] = pa;
      });
      org.pas = [...newPasList];
      exposedOrdered.push(org);
    });

    // order orgs:
    const orgNames = exposedOrdered.map((el) => el.info.name).sort();
    const newOrgList = Array(orgNames.length).fill(null);
    exposedOrdered.forEach((org) => {
      const newIndex = orgNames.findIndex((el) => el === org.info.name);
      newOrgList[newIndex] = org;
    });
    exposedOrdered = [...newOrgList];
  }

  function getExposedAll() {
    appManagementService.getExposedAll().then((res) => {
      exposed = res.data;
      transformExposed();
    });
  }

  /**
   * @param {string} action
   * @param {string} type
   * @param {any} obj
   */
  function toggleStatus(action, type, obj) {
    appManagementService.toggleStatus(action, type, obj).then((res) => {
      exposed = res.data;
      transformExposed();
    });
  }

  /**
   * @param {{ processes: {}; dashboards: {}; }} pa
   */
  function getBucketInfo(pa) {
    let arr = [];
    let procNo;
    let dashNo;
    if (pa?.processes) {
      procNo = Object.keys(pa.processes).length;
      // arr.push(`${procNo} Process${procNo>1 ? 'es' : ''}`);
      arr.push(`${tNow("appMgmt.procs")}: ${procNo}`);
    }
    if (pa?.dashboards) {
      dashNo = Object.keys(pa.dashboards).length;
      // arr.push(`${dashNo} Dashboard${dashNo>1 ? 's' : ''}`);
      arr.push(`${tNow("appMgmt.dashs")}: ${dashNo}`);
    }

    return `${arr.join(" | ")}`;
  }
</script>

<div class="card">
  <div class="card-header bg-primary">
    <UtilCardTitle title={$t("appMgmt.title")} />
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-sm-12">
        <!-- <p>Organisations</p> -->
        <!-- <p>Manage your exposed apps</p> -->
        <ul class="list-group">
          {#each exposedOrdered as org}
            <li class="list-group-item">
              <!-- svelte-ignore a11y-click-events-have-key-events -->
              <strong
                class="selectable-item"
                on:click={() => {
                  if (!vis.orgs?.[org.info._id])
                    vis.orgs[org.info._id] = { show: false };
                  vis.orgs[org.info._id].show = !vis.orgs[org.info._id].show;
                }}
              >
                <span>{$t("appMgmt.orgName")}:</span>
                <span class="text-primary">{org.info.name}</span>
                {#if vis.orgs?.[org.info._id]?.show}
                  <ChevronUp width={icoHeight} height={icoHeight} />
                {:else}
                  <ChevronDown width={icoHeight} height={icoHeight} />
                {/if}
              </strong>

              {#if vis.orgs?.[org.info._id]?.show}
                <ul class="list-group">
                  {#each org.pas as pa}
                    {#if pa?.info}
                      <li class="list-group-item">
                        <!-- svelte-ignore a11y-click-events-have-key-events -->
                        <strong
                          class="selectable-item"
                          on:click={() => {
                            if (!vis.pas?.[pa.info._id])
                              vis.pas[pa.info._id] = { show: false };
                            vis.pas[pa.info._id].show =
                              !vis.pas[pa.info._id].show;
                          }}
                        >
                          <span>{$t("appMgmt.procBucket")}:</span>
                          <span class="text-primary">{pa?.info?.name} </span>
                          {#if vis.pas?.[pa?.info?._id]?.show}
                            <ChevronUp width={icoHeight} height={icoHeight} />
                          {:else}
                            <ChevronDown width={icoHeight} height={icoHeight} />
                          {/if}
                        </strong>
                        <div
                          class="text-muted"
                          class:mb-3={vis.pas?.[pa?.info?._id]?.show}
                        >
                          {getBucketInfo(pa)}
                        </div>

                        <div class="row">
                          {#if pa?.processes}
                            <div class="col-md-6">
                              {#if vis.pas?.[pa?.info?._id]?.show}
                                <ul class="list-group">
                                  <li>
                                    <strong>{$t("appMgmt.procs")}</strong>
                                  </li>
                                  {#each pa.processes || [] as p}
                                    {#if p}
                                      <li class="list-group-item">
                                        <ul>
                                          <li class="text-primary">
                                            <b>{p?.info?.name}</b>
                                            {#each p.vers as proc}
                                              {#if proc?.exposable}
                                                <span class="text-primary">
                                                  <b
                                                    ><FieldDateFormated
                                                      date={proc.installed_at}
                                                      format="DD/MM/YYYY HH:mm:SSS"
                                                    /></b
                                                  >
                                                  <span class="badge bg-primary"
                                                    >{proc.exposable
                                                      ? $t("appMgmt.enabled")
                                                      : ""}</span
                                                  >
                                                </span>
                                              {/if}
                                            {/each}
                                            <button
                                              class="btn btn-link"
                                              on:click={() => {
                                                if (!vis.procs?.[p.info._id])
                                                  vis.procs[p.info._id] = {
                                                    showAll: false,
                                                  };
                                                vis.procs[p.info._id].showAll =
                                                  !vis.procs[p.info._id]
                                                    .showAll;
                                              }}
                                            >
                                              {#if vis.procs?.[p.info._id]?.showAll}
                                                <ChevronUp
                                                  width={icoHeight}
                                                  height={icoHeight}
                                                />
                                              {:else}
                                                <ChevronDown
                                                  width={icoHeight}
                                                  height={icoHeight}
                                                />
                                              {/if}
                                            </button>
                                          </li>
                                          {#if vis.procs?.[p.info._id]?.showAll}
                                            {#each p.vers as proc}
                                              <li>
                                                <button
                                                  class="btn btn-link"
                                                  on:click={() => {
                                                    toggleStatus(
                                                      proc.exposable
                                                        ? "disable"
                                                        : "activate",
                                                      "proc",
                                                      proc
                                                    );
                                                  }}
                                                >
                                                  <FieldSwitch
                                                    label=""
                                                    bind:value={proc.exposable}
                                                  />
                                                </button>
                                                <small>
                                                  <b>{proc.name}</b>
                                                  {$t(
                                                    "appMgmt.procInstalledOn"
                                                  )}
                                                  <b
                                                    ><FieldDateFormated
                                                      date={proc.installed_at}
                                                      format="DD/MM/YYYY HH:mm:SSS"
                                                    /></b
                                                  >
                                                </small>
                                              </li>
                                            {/each}
                                          {/if}
                                        </ul>
                                      </li>
                                    {/if}
                                  {/each}
                                </ul>
                              {/if}
                            </div>
                          {/if}
                          {#if pa?.dashboards}
                            <div class="col-md-6">
                              {#if vis.pas?.[pa.info._id]?.show}
                                <ul class="list-group">
                                  <li>
                                    <strong>{$t("appMgmt.dashs")}</strong>
                                  </li>
                                  {#each pa.dashboards || [] as p}
                                    {#if p}
                                      <li class="list-group-item">
                                        <ul>
                                          <li class="text-primary">
                                            <b>{p?.info?.name}</b>
                                            {#if p}
                                              {#each p.vers as proc}
                                                {#if proc?.exposable}
                                                  <span class="text-primary">
                                                    <b
                                                      ><FieldDateFormated
                                                        date={proc.installed_at}
                                                        format="DD/MM/YYYY HH:mm:SSS"
                                                      /></b
                                                    >
                                                    <span
                                                      class="badge bg-primary"
                                                      >{proc.exposable
                                                        ? "Enabled"
                                                        : ""}</span
                                                    >
                                                  </span>
                                                {/if}
                                              {/each}
                                            {/if}
                                            <button
                                              class="btn btn-link"
                                              on:click={() => {
                                                if (!vis.dashs?.[p.info._id])
                                                  vis.dashs[p.info._id] = {
                                                    showAll: false,
                                                  };
                                                vis.dashs[p.info._id].showAll =
                                                  !vis.dashs[p.info._id]
                                                    .showAll;
                                              }}
                                            >
                                              {#if vis.dashs?.[p.info._id]?.showAll}
                                                <ChevronUp
                                                  width={icoHeight}
                                                  height={icoHeight}
                                                />
                                              {:else}
                                                <ChevronDown
                                                  width={icoHeight}
                                                  height={icoHeight}
                                                />
                                              {/if}
                                            </button>
                                          </li>
                                          {#if vis.dashs?.[p.info._id]?.showAll}
                                            {#each p.vers as proc}
                                              <li>
                                                <button
                                                  class="btn btn-link"
                                                  on:click={() => {
                                                    toggleStatus(
                                                      proc.exposable
                                                        ? "disable"
                                                        : "activate",
                                                      "dash",
                                                      proc
                                                    );
                                                  }}
                                                >
                                                  <FieldSwitch
                                                    label=""
                                                    bind:value={proc.exposable}
                                                  />
                                                </button>
                                                <small>
                                                  <b>{proc.name}</b>
                                                  {$t(
                                                    "appMgmt.dashInstalledOn"
                                                  )}
                                                  <b
                                                    ><FieldDateFormated
                                                      date={proc.installed_at}
                                                      format="DD/MM/YYYY HH:mm:SSS"
                                                    /></b
                                                  >
                                                </small>
                                              </li>
                                            {/each}
                                          {/if}
                                        </ul>
                                      </li>
                                    {/if}
                                  {/each}
                                </ul>
                              {/if}
                            </div>
                          {/if}
                        </div>
                      </li>
                    {/if}
                  {/each}
                </ul>
              {/if}
            </li>
          {/each}
        </ul>
      </div>
    </div>
  </div>
</div>

<style>
  ul {
    list-style: none;
    padding: 0px;
  }
</style>
