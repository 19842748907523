const storage = window.sessionStorage;
const storageLocal = window.localStorage;

export default {
  save,
  get,
  remove,
  data: {
    get: getData,
    remove: removeData,
    save: saveData,
  }
}

/**
 * @param {string} where
 * @param {any} what
 */
function save(where, what) {
  // storage.setItem(where, JSON.stringify(what));
  storage.setItem(where, what);
}

/**
 * @param {string} from
 * @param {undefined} [parse]
 */
function get(from, parse) {
  let result;
  result = storage.getItem(from);
  // if (!parse) {
  //   result = storage.getItem(from);
  // } else {
  //   try {
  //     result = JSON.parse(storage.getItem(from));
  //   } catch (err) {
  //     console.error(err);
  //   }
  // }
  return result;
}

/**
 * @param {string} what
 */
function remove(what) {
  storage.removeItem(what);
}

/**
 * @param {string} what
 */
function getData(what) {
  const o = storageLocal.getItem('bpm_data');
  let eo = {};
  try {
    eo = JSON.parse(o) || {};
  } catch (err) { }
  return eo[what];
};

/**
* @param {string} what
*/
function removeData(what) {
  const o = storageLocal.getItem('bpm_data');
  let er = {};
  try {
    er = JSON.parse(o) || {};
  } catch (err) { }
  delete er[what];
  storageLocal.setItem('bpm_data', JSON.stringify(er));
};

/**
 * @param {string} what
 * @param {any} value
 */
function saveData(what, value) {
  const o = storageLocal.getItem('bpm_data');
  let eo = {};
  try {
    eo = JSON.parse(o) || {};
  } catch (err) {
  }
  eo[what] = value;
  storageLocal.setItem('bpm_data', JSON.stringify(eo));
};


