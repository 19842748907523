<script>
  import { onDestroy } from "svelte";
  import { currentLanguage, t, tNow } from "../../services/i18n.service";
  import taskService from "../task-designer/task-designer.service";
  import { InstanceCheckCurrentInstanceStore } from "./instance-check.stores";
  import InstanceCheckTaskButtons from "./instance-check-task-buttons.svelte";
  import { isObject } from "underscore/underscore-esm";
  import FieldDateFormated from "../utils/field-date-formated.svelte";

  let currentInstanceId;
  let items = [];
  let query = { sort: "-created_at", limit: 50, skip: 0 };
  let isloading = false;

  const instanceCheckCurrentInstanceUnsubscribe =
    InstanceCheckCurrentInstanceStore.subscribe((res) => {
      currentInstanceId = res;
      // console.log("... task > currentInstanceId", currentInstanceId);
      search(currentInstanceId);
    });

  onDestroy(() => {
    instanceCheckCurrentInstanceUnsubscribe();
  });

  /**
   * @param {string} instanceId
   */
  function search(instanceId) {
    isloading = true;
    if (!instanceId) {
      items = [];
      isloading = false;
      return;
    }
    query.piid = instanceId;
    getItems();
  }

  function getItems() {
    taskService
      .get(query)
      .then((res) => {
        items = res.data;
        isloading = false;
      })
      .catch((err) => {
        console.error(err);
        isloading = false;
      });
  }

  /**
   * @param {{ status: string; }} item
   */
  function statusPath(item) {
    return tNow("instCheck." + item.status);
  }

  /**
   * @param {{ status: any; }} item
   */
  function statusClass(item) {
    let className = "";
    switch (item.status) {
      case "active":
        className = "text-success fw-bold";
        break;
      case "closed":
      case "terminated":
        className = "text-secondary fw-bold";
        break;
      case "failed":
        className = "text-danger fw-bold";
        break;
      case "paused":
        className = "text-warning fw-bold";
        break;
      default:
        break;
    }
    return className;
  }

  /**
   * @param {{ subject: any; subject_i18n: { [x: string]: any; }; }} item
   */
  function getTaskSubject(item) {
    let result = item.subject;
    if (isObject(item?.subject_i18n)) {
      if (item?.subject_i18n[$currentLanguage]) {
        result = item.subject_i18n[$currentLanguage];
      }
    }
    return result;
  }

  /**
   * @param {{ role: { name: string; name_i18n: { [x: string]: any; }; }; }} item
   */
  function getRoleNameI18n(item) {
    let result = item?.role?.name || "";
    if (isObject(item?.role?.name_i18n)) {
      if (item?.role?.name_i18n?.[$currentLanguage]) {
        result = item.role.name_i18n[$currentLanguage];
      }
    }
    return result;
  }

  /**
   * @param {{ user: string; _user: { firstName: any; lastName: any; }; }} item
   */
  function getUser(item) {
    let result = item?.user || "-";
    if (item?._user?.firstName || item?._user?.lastName) {
      result = `${item._user.firstName} ${item._user.lastName}`;
    }
    return result;
  }
</script>

<div class="card border-primary">
  <div class="card-header bg-primary text-white">{$t("instCheck.tasks")}</div>
  <div class="card-body border-0 mb-3">
    <ul class="list-group border-0 mb-3 view-height">
      {#if isloading}
        {#each new Array(3) as _item}
          <li class="list-group-item placeholder-glow">
            <h5 class="placeholder bg-secondary w-50">&nbsp;</h5>
            <span class="placeholder bg-secondary w-100" />
          </li>
        {/each}
      {:else}
        {#each items as item}
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
          <li
            class="list-group-item selectable-item"
            class:bg-primary-subtle={$InstanceCheckCurrentInstanceStore ===
              item?._id}
            on:click={() => {
              // rowClicked(item);
            }}
          >
            <h5 class="d-flex" style="margin:0px">
              <span>
                {getTaskSubject(item)}
              </span>
              <svelte:component this={InstanceCheckTaskButtons} {item} />
            </h5>
            <div>
              <span class="tsk-lbl">{$t("instCheck.dueDate")}:</span>
              <span class="tsk-val">
                <FieldDateFormated date={item.dueDate} />
              </span>
              <span class="tsk-lbl">{$t("generic.created")}:</span>
              <span class="tsk-val">
                <FieldDateFormated date={item.created_at} />
              </span>
              <span class="tsk-lbl">{$t("generic.updated")}:</span>
              <span class="tsk-val">
                <FieldDateFormated date={item.updated_at} />
              </span>
              <span class="tsk-lbl">{$t("instCheck.closed")}:</span>
              <span class="tsk-val">
                <FieldDateFormated date={item.closed_at} />
              </span>
              <span class="tsk-lbl">{$t("generic.status")}:</span>
              <span class="tsk-val {statusClass(item)}">
                {$t(statusPath(item))}
              </span>
              <span class="tsk-lbl">{$t("generic.role")}:</span>
              <span class="tsk-val">
                {getRoleNameI18n(item)}
              </span>
              <span class="tsk-lbl">{$t("generic.user")}:</span>
              <span class="tsk-val">
                {getUser(item)}
              </span>
            </div>
          </li>
        {:else}
          <li class="list-group-item text-muted">
            {$t("msgs.msgNoDataAvailable")}
          </li>
        {/each}
      {/if}
    </ul>
  </div>
</div>

<style>
  .tsk-lbl {
    font-size: 0.8rem;
  }
  .tsk-val {
    font-size: 0.8rem;
    border-bottom: 1px dotted gray;
    margin-right: 20px;
  }
  .view-height {
    max-height: calc(100vh - 300px);
    overflow-y: scroll;
  }
</style>
