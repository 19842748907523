import axios from "axios";

export default {
  get,
  count,
  save,
  remove,
}

/**
 * @param {object} query
 */
function get(query) {
  return axios.get('/api/rest/bo/document', { params: query });
}

/**
 * @param {object} query
 */
function count(query) {
  return axios.get('/api/rest/bo/document', { params: { ...query, countDoc: true } });
}

/**
 * @param {{ _id: string; }} obj
 */
function save(obj) {
  return axios.post('/api/rest/bo/document' + (obj._id ? '/' + obj._id : ''), obj);
}

/**
 * @param {string} id
 */
function remove(id) {
  return axios.delete('/api/rest/bo/document/' + id);
}
