import axios from "axios"
import { derived, writable } from "svelte/store";
import apiService from "./../../../services/api.service";
import { Parser } from "acorn";
import { sortBy, uniq } from "underscore";

let aceCompleter;

function createAceCompleterStore() {
  const { subscribe, set, update } = writable();
  return {
    subscribe,
    set,
    update,
    parseScript,
    getAceCompletions,
  }
}
export let aceCompleterStore = createAceCompleterStore();
export let aceCompleterObjectsStore = derived(aceCompleterStore, value => {
  if (!value) return undefined;
  let result = {};
  value.map((/** @type {{ value: any; }} */ el) => el.value).forEach((/** @type {string} */ el) => {
    const newObj = apiService.convert.createObjFromPathString(result, el);
    result = { ...result, ...newObj }
  })
  return result;
})

function getAceCompletions(pbId) {
  return axios.get('/api/bpm/getAceCompletions', { params: { paid: pbId } })
    .then(res => {
      aceCompleter = res.data.local.map(el => { return { name: el, value: el, score: 0, meta: 'local' } });
      aceCompleter = [...aceCompleter, ...res.data.system];
      aceCompleterStore.set(aceCompleter);
    })
}

function parseScript(script) {
  if (!apiService.check.val(script)) return;
  let ast;
  try {
    ast = Parser.parse(script, {
      ecmaVersion: 'latest'
    });
  } catch (err) { }

  let variablesArray = [];
  ast?.body.forEach(el => {
    extractVariables(el, variablesArray)
  });
  updateAceCompletions(variablesArray);
}

/**
 * @param {any[]} newArr
 */
function updateAceCompletions(newArr) {
  let arr = [...aceCompleter.filter(el => el.value.startsWith('local.')).map(el => el.value), ...newArr];
  arr = sortBy(arr);
  arr = uniq(arr, true);
  arr = arr.filter(el => el !== '' && el.startsWith('local.'));

  aceCompleter = arr.map(el => { return { name: el, value: el, score: 0, meta: 'local' } });
  aceCompleterStore.set(aceCompleter);
}

/**
 * @param {{ type?: any; consequent?: any; test?: any; argument?: any; left?: any; right?: any; cases?: any; body?: any; expression?: any; object?: any; property?: { name: string | number; }; }} element
 * @param {any[]} variablesArray
 */
function extractVariables(element, variablesArray) {
  if (element.type === 'IfStatement') {
    extractVariables(element.consequent, variablesArray);
    extractVariables(element.test, variablesArray);
  }

  if (element.type === 'UnaryExpression') {
    extractVariables(element.argument, variablesArray);
  }

  if (element.type === 'LogicalExpression' || element.type === 'BinaryExpression') {
    extractVariables(element.left, variablesArray);
    extractVariables(element.right, variablesArray);
  }

  if (element.type === 'SwitchStatement') {
    element.cases.forEach(caseElem => {
      caseElem.consequent.forEach(elem => {
        extractVariables(elem, variablesArray);
      })
    })
  }

  if (element.type === 'BlockStatement') {
    element.body.forEach(element => {
      extractVariables(element, variablesArray);
    })
  }

  if (element.type === 'FunctionDeclaration') {
    element.body.body.forEach(element => {
      extractVariables(element, variablesArray);
    })
  }

  if (element.type === 'MemberExpression') {
    variablesArray.push(getVariableName(element));
  }

  if (element.type === 'ExpressionStatement') {
    let leftSide = element.expression.left;
    let rightSide = element.expression.right;
    let string = getVariableName(leftSide);

    if (rightSide && rightSide.type === 'ObjectExpression') {
      string = process(rightSide, string, variablesArray);
    } else if (rightSide && rightSide.type === 'ArrayExpression') {
      variablesArray.push(string);

      rightSide.elements.forEach(memberElement => {
        variablesArray.push(getVariableName(memberElement));
      })
    } else {
      variablesArray.push(string);
    }
  }
}

/**
 * @param {{ object: { object: any; name: string | number; } | any; property: { name: string | number; }; }} [element]
 */
function getVariableName(element) {
  let string = '';
  while (element && element.object) {
    if (!element.object.object) {
      string = element.property.name + (string === '' ? '' : '.') + string;
    }
    if (element.object.name) {
      string = element.object.name + (string === '' ? '' : '.') + string;
    } else {
      string = element.property.name + (string === '' ? '' : '.') + string;
    }
    if (!element.object.object) {
      break;
    } else {
      element = element.object;
    }
  }
  return string;
}

/**
 * @param {{ properties: any[]; }} rightSide
 * @param {string} string
 * @param {any[]} variablesArray
 */
function process(rightSide, string, variablesArray) {
  rightSide.properties.forEach(property => {
    let stringAux = string;
    stringAux += '.' + property.key.name;

    if (property.value.type === 'ObjectExpression') {
      process(property.value, stringAux, variablesArray);
    } else {
      variablesArray.push(stringAux);
    }
  })
}

