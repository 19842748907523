<script>
  import roleService from "../components/designer/role/role.service";
  import Role from "../components/designer/role/role.svelte";
  import Organisation from "../components/organisation/organisation.svelte";

  let selectedOrganisation, selectedProcessBucket, selectedRole;
</script>

<Organisation
  bind:selectedOrganisation
  bind:selectedProcessBucket
  bind:selectedRole
/>
{#if selectedRole}
  <Role bind:item={selectedRole} />
{/if}

