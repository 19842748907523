<script>
  import { createEventDispatcher } from "svelte";
  import Select2 from "svelte-select";
  import { onMount } from "svelte";
  import { nanoid } from "nanoid";
  import { isArray } from "underscore/underscore-esm";
  import { t } from "../../services/i18n.service";
  const dispatch = createEventDispatcher();
  export let type = "select"; // 'select2'
  export let label = undefined;
  export let value = undefined;
  export let disabled = false;
  export let invalid = false;
  export let list = [];
  export let keyval = { key: "key", val: "value" };
  export let withoutPlaceholder = false;
  export let required = false;
  export let placeholder = "generic.select";
  export let multiple = false;
  export let translate = false;
  export let onlyinput = false;

  let uuid;
  let listType =
    ["string", "number"].indexOf(typeof list[0]) > -1
      ? "singlevalue"
      : "keyvalue";
  const checkListType = (/** @type {any[]} */ list) => {
    listType =
      ["string", "number"].indexOf(typeof list[0]) > -1
        ? "singlevalue"
        : "keyvalue";
  };
  let sel2value = [];
  $: checkListType(list);
  $: checkUpdatedValue(value);

  onMount(() => {
    uuid = nanoid(10);
  });

  function checkUpdatedValue(value) {
    if (type === "select2") {
      if (isArray(value) && value?.length > 0) {
        sel2value = value.map((el) => {
          return {
            value: el,
            label: el,
          };
        });
      } else {
        sel2value = value;
        // sel2value = {
        //   value: value,
        //   label: value,
        // };
      }
    }
  }

  function callback() {
    dispatch("callback");
  }

  function clearValue(ev) {
    if (multiple) {
      if (!value || value.length === 0) {
        value = undefined;
      }
      if (!ev.detail) {
        value = undefined;
      }
      if (ev.detail) {
        value = [...value.filter((key) => key !== ev.detail.value)];
      }
    } else {
      value = undefined;
    }
  }
</script>

<div class:mb-3={!onlyinput}>
  {#if label}
    <label for="{uuid}-{label}">{label}{required ? " *" : ""}</label>
  {/if}
  {#if type === "select2"}
    {#if listType === "singlevalue"}
      <Select2
        id="{uuid}-{label}"
        items={list.map((el) => ({ value: el, label: el }))}
        bind:value={sel2value}
        {multiple}
        hasError={invalid}
        {disabled}
        {placeholder}
        on:change={(event) => {
          if (multiple) {
            value =
              event?.detail?.map(
                (/** @type {{ value: any; }} */ el) => el.value
              ) || null;
          } else {
            value = event.detail.value;
          }
          callback();
        }}
        on:clear={(ev) => {
          clearValue(ev);
          callback();
        }}
      />
    {:else}
      <Select2
        id="{uuid}-{label}"
        items={list.map((el) => ({
          value: el[keyval.key],
          label: el[keyval.val],
        }))}
        bind:value={sel2value}
        {multiple}
        hasError={invalid}
        {disabled}
        {placeholder}
        on:change={(event) => {
          if (multiple) {
            value =
              event?.detail?.map(
                (/** @type {{ value: any; }} */ el) => el.value
              ) || null;
          } else {
            value = event.detail.value;
          }
          callback();
        }}
        on:clear={(ev) => {
          clearValue(ev);
          callback();
        }}
      />
    {/if}
  {:else}
    <select
      class="form-select {invalid ? 'is-invalid' : ''}"
      id="{uuid}-{label}"
      bind:value
      {disabled}
      on:change={callback}
    >
      {#if !withoutPlaceholder}
        <option value={null}>
          {#if translate}
            {$t(placeholder)}
          {:else}
            {placeholder}
          {/if}
        </option>
      {/if}
      {#if listType === "singlevalue"}
        {#each list as item}
          <option value={item}>
            {#if translate}
              {$t(item)}
            {:else}
              {item}
            {/if}
          </option>
        {/each}
      {:else}
        {#each list as item}
          <option value={item[keyval.key]}>
            {#if translate}
              {$t(item[keyval.val])}
            {:else}
              {item[keyval.val]}
            {/if}
          </option>
        {/each}
      {/if}
    </select>
  {/if}
</div>
