<script>
  import {
    ChatLeftDots,
    Check2Square,
    CircleFill,
    FilePerson,
    People,
    Person,
    Play,
  } from "svelte-bootstrap-icons";
  import { inboxTaskListCountStore, inboxTaskTagsStore } from "./inbox-store";
  import { onDestroy } from "svelte";
  import { t } from "../../services/i18n.service";

  let itemsNo = 0;
  let tags = [];

  const inboxTaskListCountStoreUnsubscribe = inboxTaskListCountStore.subscribe(
    (res) => {
      itemsNo = res;
    },
  );

  const inboxTaskTagsStoreUnsubscribe = inboxTaskTagsStore.subscribe((res) => {
    tags = res;
  });

  onDestroy(() => {
    inboxTaskListCountStoreUnsubscribe();
    inboxTaskTagsStoreUnsubscribe();
  });
</script>

<div
  class="text-uppercase text-primary fw-bold p-3 w-100 d-flex"
  style="font-size: 0.8rem;"
>
  {$t("instCheck.tasks")}
  <div class="badge bg-primary ms-auto">{itemsNo || 0}</div>
</div>
<div class="list-group">
  <button
    class="list-group-item list-group-item-action d-flex rounded-4"
    class:bg-primary={$inboxTaskTagsStore.includes("active")}
    class:text-white={$inboxTaskTagsStore.includes("active")}
    on:click={() => {
      inboxTaskTagsStore.toggle("active");
    }}
  >
    <div>
      <span
        class="text-primary me-3"
        class:text-white={$inboxTaskTagsStore.includes("active")}
      >
        <Play></Play>
      </span>
      {$t("instCheck.active")}
    </div>
    <!-- <div class="badge text-bg-secondary ms-auto">{itemsNo || 0}</div> -->
  </button>
  <button
    class="list-group-item list-group-item-action d-flex rounded-4"
    class:bg-primary={$inboxTaskTagsStore.includes("closed")}
    class:text-white={$inboxTaskTagsStore.includes("closed")}
    on:click={() => {
      inboxTaskTagsStore.toggle("closed");
    }}
  >
    <div>
      <span
        class="text-primary me-3"
        class:text-white={$inboxTaskTagsStore.includes("closed")}
      >
        <Check2Square></Check2Square>
      </span>
      {$t("instCheck.closed")}
    </div>
    <!-- <div class="badge text-bg-secondary ms-auto">138</div> -->
  </button>
  <button
    class="list-group-item list-group-item-action d-flex rounded-4"
    class:bg-primary={$inboxTaskTagsStore.includes("mytasks")}
    class:text-white={$inboxTaskTagsStore.includes("mytasks")}
    on:click={() => {
      inboxTaskTagsStore.toggle("mytasks");
    }}
  >
    <div>
      <span
        class="text-primary me-3"
        class:text-white={$inboxTaskTagsStore.includes("mytasks")}
      >
        <Person></Person>
      </span>
      {$t("instCheck.onlyMyTasks")}
    </div>
    <!-- <div class="badge text-bg-secondary ms-auto">138</div> -->
  </button>
  <button
    class="list-group-item list-group-item-action d-flex rounded-4"
    class:bg-primary={$inboxTaskTagsStore.includes("roletasks")}
    class:text-white={$inboxTaskTagsStore.includes("roletasks")}
    on:click={() => {
      inboxTaskTagsStore.toggle("roletasks");
    }}
  >
    <div>
      <span
        class="text-primary me-3"
        class:text-white={$inboxTaskTagsStore.includes("roletasks")}
      >
        <People></People>
      </span>
      {$t("instCheck.onlyMyRolesTasks")}
    </div>
    <!-- <div class="badge text-bg-secondary ms-auto">138</div> -->
  </button>
  <button
    class="list-group-item list-group-item-action d-flex rounded-4"
    class:bg-primary={$inboxTaskTagsStore.includes("duedate")}
    class:text-white={$inboxTaskTagsStore.includes("duedate")}
    on:click={() => {
      inboxTaskTagsStore.toggle("duedate");
    }}
  >
    <div>
      <span
        class="text-primary me-3"
        class:text-white={$inboxTaskTagsStore.includes("duedate")}
      >
        <span class="text-warning">
          <CircleFill></CircleFill>
        </span>
      </span>
      {$t("instCheck.dueDate")}
    </div>
    <!-- <div class="badge text-bg-secondary ms-auto">138</div> -->
  </button>
  <button
    class="list-group-item list-group-item-action d-flex rounded-4"
    class:bg-primary={$inboxTaskTagsStore.includes("overdue")}
    class:text-white={$inboxTaskTagsStore.includes("overdue")}
    on:click={() => {
      inboxTaskTagsStore.toggle("overdue");
    }}
  >
    <div>
      <span
        class="text-primary me-3"
        class:text-white={$inboxTaskTagsStore.includes("overdue")}
      >
        <span class="text-danger">
          <CircleFill></CircleFill>
        </span>
      </span>
      {$t("instCheck.overdue")}
    </div>
    <!-- <div class="badge text-bg-secondary ms-auto">138</div> -->
  </button>
</div>

<style>
  .list-group-item {
    border: 0px;
  }
  .list-group-item-action:hover,
  .list-group-item-action:focus {
    background-color: var(--bs-primary-bg-subtle);
  }
</style>
