<script>
  import { onMount } from "svelte";
  import { ArrowDownUp, SortDown, SortUp } from "svelte-bootstrap-icons";
  import { t } from "../../services/i18n.service";

  /*@type {{paths: string[]; cols: {[path]: string;};}} */
  export let setts = undefined;
  export let query = undefined;
  export let queryFn = undefined;
  let defaultSort = undefined;
  const colMap = {
    created_at: "created",
    status: "status",
  };

  onMount(() => {
    if (!setts?.cols) setts.cols = {};
    defaultSort = query.sort;
  });

  $: queryChanged(query);

  /**
   * @param {{ sort: string; }} query
   */
  function queryChanged(query) {
    if (!query?.sort) return;
    if (!setts.cols) setts.cols = {};
    setts.cols = {};
    query.sort.split(",").forEach((col) => {
      // get first char and check its sign:
      const firstChar = col.charAt(0);
      let newCol = col;
      if (firstChar === "-") {
        newCol = col.slice(1);
        setts.cols[newCol] = 1;
      } else {
        setts.cols[newCol] = -1;
      }
    });
  }

  /**
   * @param {string} col
   * @param {number} direction
   */
  function changeSort(col, direction) {
    // let nextDirection;
    if (direction === 0) {
      // nextDirection = 1;
      setts.cols[col] = 1;
    } else if (direction === 1) {
      // nextDirection = -1;
      setts.cols[col] = -1;
    } else {
      // nextDirection = 0;
      setts.cols[col] = 0;
    }
    changeSortQuery();
  }
  function changeSortQuery() {
    let strArr = Object.keys(setts.cols)
      .map((key) => {
        let str;
        if (setts.cols[key] === 1) {
          str = "-" + key;
        } else if (setts.cols[key] === -1) {
          str = key;
        }
        return str;
      })
      .filter((el) => el != null);
    if (strArr.length === 0) {
      query.sort = defaultSort;
      // reset cols:
      setts.cols = {};
    } else {
      query.sort = strArr.join(",");
    }
    queryFn();
  }
</script>

<span class="text-muted">
  {$t("generic.sortBy")}:
</span>
{#each setts?.paths || [] as item}
  <span class="ms-2 text-muted">
    {$t("generic." + colMap[item])}
  </span>
  <span class="text-muted">
    {#if setts?.cols?.[item] === 1}
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <!-- svelte-ignore a11y-no-static-element-interactions -->
      <span
        on:click={() => {
          changeSort(item, 1);
        }}
      >
        <SortDown />
      </span>
    {:else if setts?.cols?.[item] === -1}
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <!-- svelte-ignore a11y-no-static-element-interactions -->
      <span
        on:click={() => {
          changeSort(item, -1);
        }}
      >
        <SortUp />
      </span>
    {:else}
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <!-- svelte-ignore a11y-no-static-element-interactions -->
      <span
        on:click={() => {
          changeSort(item, 0);
        }}
      >
        <ArrowDownUp />
      </span>
    {/if}
  </span>
{/each}

