<script>
  import { ThreeDotsVertical } from "svelte-bootstrap-icons";
  import { t } from "../../../services/i18n.service";
  import { taskExeCommandStore } from "./../task-exe-store";

  export let item = undefined;
  export let isNavbar = false;
  let dropComponent;
</script>

<div class="dropdown">
  <button
    class="btn btn-sm border-0 mt-0 mb-0 pt-0 pb-0"
    type="button"
    data-bs-toggle="dropdown"
    data-bs-auto-close="outside"
    aria-expanded="false"
    on:click|stopPropagation={() => {}}
  >
    <ThreeDotsVertical style="position: relative; top: -2px;" />
  </button>
  <ul
    class="dropdown-menu"
    class:dropdown-menu-end={isNavbar}
    bind:this={dropComponent}
  >
    <li class="selectable-item">
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <!-- svelte-ignore a11y-no-static-element-interactions -->
      <!-- svelte-ignore a11y-missing-attribute -->
      <a
        class="dropdown-item"
        on:click|stopPropagation={() => {
          dropComponent.classList.remove("show");
          taskExeCommandStore.command({
            action: "reassign",
            obj: item,
            withRedirect: isNavbar,
          });
        }}>{$t("home.task.reassign.toUser")}</a
      >
    </li>
    <li class="selectable-item">
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <!-- svelte-ignore a11y-no-static-element-interactions -->
      <!-- svelte-ignore a11y-missing-attribute -->
      <a
        class="dropdown-item"
        on:click|stopPropagation={() => {
          dropComponent.classList.remove("show");
          taskExeCommandStore.command({
            action: "reassign-back-to-group",
            obj: item,
            withRedirect: isNavbar,
          });
        }}>{$t("home.task.reassign.backToGroup")}</a
      >
    </li>
  </ul>
</div>
