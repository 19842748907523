<script>
  import { createEventDispatcher } from "svelte";
  import constsService from "../../services/consts.service";

  const dispatch = createEventDispatcher();
  export let query = {};
  export let totalData = 0;

  const SHOW_PAGES_NO = 5; //constsService.QUERY_PAGE_LIMIT;

  $: queryfn.calcPages(totalData);
  $: queryfn.calcCurPage(query);

  let queryfn = {
    pages: [],
    curPage: 0,
    isFirstPage: true,
    isLastPage: false,
    nextPage: () => {
      if (queryfn.isLastPage) return;
      query.skip += query.limit;
      if (query.skip >= totalData) query.skip -= query.limit;
      queryfn.calcCurPage(query);

      getData();
    },
    prevPage: () => {
      if (queryfn.isFirstPage) return;
      query.skip -= query.limit;
      if (query.skip <= 0) query.skip = 0;
      queryfn.calcCurPage(query);
      getData();
    },
    gotoPage: (page) => {
      // check limits:
      if (query.limit <= 0 || query.limit > 500) {
        if (query.limit <= 0) query.limit = 10;
        if (query.limit > 500) query.limit = 500;
        queryfn.calcPages(totalData);
      }
      // check page:
      if (page < 0) page = 0;
      if (page > queryfn.pages[queryfn.pages.length - 1])
        page = queryfn.pages[queryfn.pages.length - 1];
      query.skip = page * query.limit;
      queryfn.calcCurPage(query);
      getData();
    },
    limitChanged: () => {
      if (query.limit <= 0) query.limit = 10;
      if (query.limit > 500) query.limit = 500;
      queryfn.calcPages(totalData);
    },
    calcPages: (totalData) => {
      if (totalData > 0)
        queryfn.pages = [...Array(Math.ceil(totalData / query.limit)).keys()];
      else queryfn.pages = [];
    },
    calcCurPage: (query) => {
      queryfn.curPage = Math.ceil(query.skip / query.limit);
      const curPageIndex = queryfn.pages.indexOf(queryfn.curPage);
      if ([-1, 0].indexOf(curPageIndex) > -1) {
        queryfn.isFirstPage = true;
        queryfn.isLastPage = false;
        if (queryfn.pages.length === 1) queryfn.isLastPage = true;
      } else if (curPageIndex === queryfn.pages.length - 1) {
        queryfn.isFirstPage = false;
        queryfn.isLastPage = true;
      } else {
        queryfn.isFirstPage = false;
        queryfn.isLastPage = false;
      }
    },
  };

  function getData() {
    dispatch("callData");
  }
</script>

{#if queryfn.pages.length > 1}
  <nav aria-label="...">
    <ul class="pagination justify-content-center">
      <li class="page-item {queryfn.isFirstPage ? 'disabled' : ''}">
        <button
          class="page-link"
          on:click={() => {
            queryfn.gotoPage(0);
          }}
          disabled={queryfn.isFirstPage}>&laquo;&laquo;</button
        >
      </li>
      <li
        class="page-item {queryfn.isFirstPage
          ? 'disabled'
          : ''} d-none d-md-block"
      >
        <button
          class="page-link"
          on:click={queryfn.prevPage}
          disabled={queryfn.isFirstPage}>&laquo;</button
        >
      </li>
      {#if queryfn.curPage - SHOW_PAGES_NO / 2 > 0}
        <li class="page-item d-sm-none d-md-block">
          <button class="page-link">...</button>
        </li>
      {/if}
      {#each queryfn.pages as page}
        {#if page >= queryfn.curPage - SHOW_PAGES_NO / 2 && page <= queryfn.curPage + SHOW_PAGES_NO / 2}
          <li class="page-item {queryfn.curPage === page ? 'active' : ''}">
            <button
              class="page-link"
              on:click={() => {
                queryfn.gotoPage(page);
              }}>{page + 1}</button
            >
          </li>
        {/if}
      {/each}
      {#if queryfn.curPage + SHOW_PAGES_NO / 2 < queryfn.pages.length - 1}
        <li class="page-item">
          <button class="page-link d-sm-none d-md-block">...</button>
        </li>
      {/if}
      <li
        class="page-item {queryfn.isLastPage
          ? 'disabled'
          : ''} d-none d-md-block"
      >
        <button
          class="page-link"
          on:click={queryfn.nextPage}
          disabled={queryfn.isLastPage}>&raquo;</button
        >
      </li>
      <li class="page-item">
        <button
          class="page-link {queryfn.isLastPage ? 'disabled' : ''}"
          on:click={() => {
            queryfn.gotoPage(queryfn.pages.length - 1);
          }}
          disabled={queryfn.isLastPage}>&raquo;&raquo;</button
        >
      </li>
      <!-- <li class="info d-none d-md-block"> -->
      <!--   {queryfn.curPage * query.limit + 1} - {queryfn.curPage * query.limit + -->
      <!--     query.limit} / {totalData} -->
      <!-- </li> -->
    </ul>
    <div class="text-center">
      {queryfn.curPage * query.limit + 1} - {queryfn.curPage * query.limit +
        query.limit} / {totalData}
    </div>
  </nav>
{/if}

<style>
  .info {
    padding-top: 7px;
    margin-left: 10px;
  }
</style>
