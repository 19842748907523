import { get as getFromStore } from "svelte/store";
import { get as _get } from 'underscore';
import { userStore } from "../stores.js";
import api from './api.service';
let org;

export default {
  can,
  hasFeature,
}

userStore.subscribe(res => {
  org = res;
})

/**
 * @param {any} action
 * @param {string | number} obj
 */
function can(action, obj) {
  var isOk = false;

  if (!org) {
    isOk = false;
    // } else if (org.isOwner === true) {
    // 	isOk = true;
  } else if (org.isOwner === true || org.isAdmin === true) {
    var acls = org.acls;
    if (typeof (action) === 'string') {
      if (!acls[obj]) {
        isOk = false;
      } else {
        isOk = acls[obj].indexOf(action) > -1;
      }
    } else {
      isOk = false;
    }
  } else {
    isOk = false;
  }
  return isOk;
}

/**
 * @param {string} feature
 */
function hasFeature(feature) {
  let result = false;
  const feats = _get(getFromStore(userStore), api.check.nestedProps('_feats'));
  if (feats.indexOf(feature) > -1) result = true;
  return result;
}

