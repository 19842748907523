import axios from "axios";

export default {
  getOne,
  getShort,
  save,
  remove,
  install,
  saveAsCurrentVersion,
}
/**
 * @param {string} id
 * @param {any} [query]
 */
function getOne(id, query) {
  return axios.get(`/api/rest/bo/process/${id}`, { params: query });
}


/**
 * @param {any} query
 */
function getShort(query) {
  return axios.get('/api/rest/bo/process', { params: { ...query, qt: 'short' } });
}

/**
 * @param {any} obj
 */
function save(obj) {
  return axios.post('/api/rest/bo/process' + (obj._id ? '/' + obj._id : ''), obj);
}

/**
 * @param {string} id
 */
function remove(id) {
  return axios.delete('/api/rest/bo/process/' + id);
}

/**
 * @param {string} id
 */
function install(id) {
  return axios.post('/api/bpm/installProcess', {}, { params: { pid: id } });
}

/**
 * @param {string} id
 */
function saveAsCurrentVersion(id) {
  return axios.post(`/api/version/saveAsCurrent/process/${id}`)
}
