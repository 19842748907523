<script>
  import { createEventDispatcher } from "svelte";
  import { t } from "../../services/i18n.service";
  import FieldAce from "./field-ace.svelte";
  import FieldJsonTree from "./field-json-tree.svelte";
  import UtilModal from "./util-modal.svelte";
  const dispatch = createEventDispatcher();

  export let title = undefined;
  export let size = "lg";
  export let currentItem = {};
  export let modalShow = false;
  export let onlyView = false;
  let modalButtonsBkup = JSON.stringify([
    {
      label: $t("generic.close"),
      action: "close",
      class: "btn-outline-secondary",
    },
    {
      label: $t("generic.edit"),
      action: "edit",
      class: "btn-primary",
    },
    // {
    //   label: $t("generic.view"),
    //   action: "view",
    //   class: "btn-primary",
    // },
    {
      label: $t("generic.save"),
      action: "save",
      class: "btn-primary",
    },
  ]);
  let modalButtons = [
    {
      label: $t("generic.close"),
      action: "close",
      class: "btn-outline-secondary",
    },
    {
      label: $t("generic.edit"),
      action: "edit",
      class: "btn-primary",
    },
  ];
  let modalContentMode = "view";

  if (onlyView) {
    modalButtons = [modalButtons[0]];
  }

  /**
   * @param {string} action
   */
  function modalClose(action) {
    if ("close" === action) {
      modalContentMode = "view";
      currentItem = undefined;
      modalShow = false;
      return;
    }
    // if ("view" === action) {
    //   currentItem = JSON.parse(currentItem_bkup);
    //   modalContentMode = "view";
    // }
    if ("edit" === action) {
      modalButtons = JSON.parse(modalButtonsBkup);
      modalButtons.splice(1, 1);
      currentItem = JSON.stringify(currentItem, null, 4);
      modalContentMode = "edit";
    }
    if ("save" === action) {
      const docObject = JSON.parse(currentItem);
      dispatch("save", docObject);
    }
  }
</script>

<UtilModal
  {title}
  open={modalShow}
  autoClose={false}
  onClosed={modalClose}
  bind:modalButtons
  {size}
>
  <div>
    {#if modalContentMode === "view"}
      <FieldJsonTree bind:value={currentItem} />
    {/if}
    {#if modalContentMode === "edit"}
      <FieldAce bind:value={currentItem} mode="json" />
    {/if}
  </div>
</UtilModal>
