<script>
  import {
    ArrowDownUp,
    BagPlus,
    CheckLg,
    CloudDownloadFill,
    CreditCard,
    DashLg,
    EyeFill,
    InfoLg,
    PencilFill,
    PersonAdd,
    PersonDash,
    PersonGear,
    PersonSlash,
    PlusLg,
    SortDown,
    SortUp,
    XLg,
  } from "svelte-bootstrap-icons";
  import { createEventDispatcher } from "svelte";
  // import FieldDateFormated from "./field-date-formated.svelte";
  // import FieldSwitch from "./field-switch.svelte";
  import api from "./../../services/api.service";
  import Clock from "svelte-loading-spinners/Clock.svelte";
  import FieldDateFormated from "./field-date-formated.svelte";
  import { tooltip } from "./../../services/tooltip.service";
  import FieldSwitch from "./field-switch.svelte";
  import { t } from "../../services/i18n.service";
  import { isLoading } from "../../stores";

  const dispatch = createEventDispatcher();

  export let query = {};
  export let fns = {};
  export let queryFn = function () {};
  export let dataLoading = false;
  /** @type {{
   * conf: {
   *   header: {add?: object};
   *   items: {
   *     view?: object;
   *     edit?: object;
   *     download?: object;
   *     switch?: object;
   *     delete?: object;
   *     usrGear?: object;
   *     usrAdd?: object;
   *     usrSlash?: object;
   *     usrDash?: object;
   *     buy?: object;
   *     install?: object;
   *   }
   *   setts?: {
   *     icoHeight?: number;
   *   };
   *   rowClick?: string;
   *   selectedRow?: string;
   *   placeholder?: string;
   * };
   * cols: {
   *   lbl: string;
   *   path: string | object;
   *   noPath?: boolean;
   *   tl?: {val?: string; path?: string};
   *   type: string;
   *   sort: ({path: string} | boolean );
   *   class?: string;
   *   cssFn?: string;
   *   slot?: {
   *     comp: any;
   *     location: string;
   *     props?: object;
   *   };
   * }[]
   * }} setts
   */
  export let setts = {
    conf: {
      setts: {
        icoHeight: 18,
      },
      header: {
        add: {},
      },
      items: {
        view: {},
        edit: {},
        download: {},
        switch: { conf: { on: "bg-danger", off: "bg-primary" } },
      },
    },
    cols: [
      { lbl: "Col 1", path: "col1", type: "string", sort: true, class: "" },
      { lbl: "Col 2", path: "col2", type: "string", sort: true, class: "" },
      { lbl: "Col 3", path: "col3", type: "date", sort: false, class: "" },
    ],
  };
  export let data = [
    { col1: "1111", col2: "1111", col3: "1111" },
    { col1: "2222", col2: "2222", col3: "2222" },
    { col1: "3333", col2: "3333", col3: "3333" },
  ];
  export let loading = {};

  let icoHeight = setts?.conf?.setts?.icoHeight || 18;
  const sortCols = {};

  /**
   * @param {{ col1: string; col2: string; col3: string; }} item
   * @param {string} path
   */
  function getComplexValue(item, path) {
    const res = api.getNested(item, ...path.split("."));
    return res;
  }

  /**
   * @param {string} action
   * @param {any} [item]
   */
  function itemAction(action, item) {
    if (action === "download") {
      loading[item._id] = true;
    }
    dispatch(action, item);
  }

  /**
   * @param {string} col
   * @param {number} direction
   */
  function changeSort(col, direction) {
    // let nextDirection;
    if (direction === 0) {
      // nextDirection = 1;
      sortCols[col] = 1;
    } else if (direction === 1) {
      // nextDirection = -1;
      sortCols[col] = -1;
    } else {
      // nextDirection = 0;
      sortCols[col] = 0;
    }
    changeSortQuery();
  }

  function changeSortQuery() {
    let strArr = Object.keys(sortCols)
      .map((key) => {
        let str;
        if (sortCols[key] === 1) {
          str = "-" + key;
        } else if (sortCols[key] === -1) {
          str = key;
        }
        return str;
      })
      .filter((el) => el != null);
    if (strArr.length === 0) {
      query.sort = "-created_at";
    } else {
      query.sort = strArr.join(",");
    }
    queryFn();
  }
</script>

<div class="table-responsive">
  <table class="table table-hover table-striped">
    <thead>
      <tr>
        {#each setts.cols as col}
          <th>
            {col.lbl}
            {#if col.sort}
              <span>
                {#if sortCols[col.sort?.path || col.path] === 1}
                  <!-- svelte-ignore a11y-click-events-have-key-events -->
                  <!-- svelte-ignore a11y-no-static-element-interactions -->
                  <span
                    on:click={() => {
                      changeSort(col.sort?.path || col.path, 1);
                    }}
                  >
                    <SortDown />
                  </span>
                {:else if sortCols[col.sort?.path || col.path] === -1}
                  <!-- svelte-ignore a11y-click-events-have-key-events -->
                  <!-- svelte-ignore a11y-no-static-element-interactions -->
                  <span
                    on:click={() => {
                      changeSort(col.sort?.path || col.path, -1);
                    }}
                  >
                    <SortUp />
                  </span>
                {:else}
                  <!-- svelte-ignore a11y-click-events-have-key-events -->
                  <!-- svelte-ignore a11y-no-static-element-interactions -->
                  <span
                    on:click={() => {
                      changeSort(col.sort?.path || col.path, 0);
                    }}
                  >
                    <ArrowDownUp />
                  </span>
                {/if}
              </span>
            {/if}
          </th>
        {/each}
        {#if setts.conf && (Object.entries(setts.conf.header).length > 0 || Object.entries(setts.conf.items).length > 0)}
          <th>
            <div class="tbl-actions text-end">
              {#if setts.conf.header?.add?.show}
                {#if setts.conf.header.add.list}
                  <div class="dropdown">
                    <button
                      class="btn btn-sm btn-outline-primary dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      id="dropdownMenuButton1"
                    >
                      <PlusLg width={icoHeight} height={icoHeight} />
                    </button>
                    <ul
                      class="dropdown-menu position-fixed"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      {#each setts.conf.header.add.list as item}
                        <li>
                          <!-- svelte-ignore a11y-missing-attribute -->
                          <!-- svelte-ignore a11y-click-events-have-key-events -->
                          <!-- svelte-ignore a11y-no-static-element-interactions -->
                          <a
                            class="dropdown-item"
                            on:click={() => itemAction("add", item)}
                          >
                            {item.val}
                          </a>
                        </li>
                      {/each}
                    </ul>
                  </div>
                {:else}
                  <button
                    class="btn btn-sm btn-outline-primary"
                    on:click={() => itemAction("add")}
                  >
                    <PlusLg width={icoHeight} height={icoHeight} />
                  </button>
                {/if}
              {/if}
            </div>
          </th>
        {/if}
      </tr>
    </thead>
    <tbody>
      {#if dataLoading}
        {#each new Array(query.limit) as _item}
          <tr>
            <td
              class="placeholder-glow"
              colspan={setts.cols.length + (setts.conf.header ? 1 : 0)}
            >
              <span class="placeholder bg-secondary w-100" />
            </td>
          </tr>
        {/each}
      {:else}
        {#each data as item, rowIndex}
          <tr
            class:selected-item={setts?.conf?.selectedRow
              ? fns[setts.conf.selectedRow](item, rowIndex)
              : false}
          >
            {#each setts.cols as col}
              {#if col.type === "date"}
                <!-- svelte-ignore a11y-click-events-have-key-events -->
                <td
                  class={col.class}
                  class:selectable-item={setts?.conf?.rowClick}
                  on:click={() => {
                    if (setts.conf?.rowClick) {
                      fns[setts.conf.rowClick](item, rowIndex);
                    }
                  }}
                >
                  <FieldDateFormated
                    date={item[col.path]}
                    css={fns[col.cssFn] ? fns[col.cssFn](item) : ""}
                  />
                </td>
              {:else if col.type === "boolean"}
                <!-- svelte-ignore a11y-click-events-have-key-events -->
                <td
                  class={col.class}
                  class:selectable-item={setts?.conf?.rowClick}
                  on:click={() => {
                    if (setts.conf?.rowClick) {
                      fns[setts.conf.rowClick](item, rowIndex);
                    }
                  }}
                >
                  {#if !item[col.path]}
                    <DashLg width={24} height={24} />
                  {:else}
                    <CheckLg width={24} height={24} />
                  {/if}
                </td>
              {:else if col.type === "string"}
                <!-- svelte-ignore a11y-click-events-have-key-events -->
                <td
                  class={col.cssFn ? fns[col.cssFn](item) : col.class}
                  class:selectable-item={setts?.conf?.rowClick}
                  on:click={() => {
                    if (setts.conf?.rowClick) {
                      fns[setts.conf.rowClick](item, rowIndex);
                    }
                  }}
                >
                  {#if col?.slot?.comp && col?.slot?.location === "before"}
                    <svelte:component
                      this={col.slot.comp}
                      {...{ item, props: { ...col?.slot?.props } }}
                    />
                  {/if}
                  {#if !col?.noPath}
                    {#if col?.path?.fn}
                      {fns[col.path.fn](item)}
                    {:else}
                      {#if col?.tl?.path && item[col.tl.path]}
                        {#key item}
                          <span use:tooltip={item[col.tl.path]}>
                            <InfoLg width="24" height="24" />
                          </span>
                        {/key}
                      {/if}
                      {#if col?.path?.includes(".")}
                        {getComplexValue(item, col.path) || "-"}
                      {:else}
                        {item?.[col.path] || "-"}
                      {/if}
                    {/if}
                  {/if}
                  {#if col?.slot?.comp && col?.slot?.location === "after"}
                    <svelte:component
                      this={col.slot.comp}
                      {...{ item, props: { ...col?.slot?.props } }}
                    />
                  {/if}
                </td>
              {/if}
            {/each}
            {#if setts.conf && Object.entries(setts.conf.items).length > 0}
              <td>
                <div class="text-end tbl-actions">
                  {#if setts.conf.items.view?.show || (setts.conf.items.view?.visibility ? fns[setts.conf.items.view.visibility](item) : false)}
                    <button
                      class="btn btn-sm btn-outline-primary"
                      on:click={() => itemAction("view", item)}
                    >
                      <EyeFill width={icoHeight} height={icoHeight} />
                    </button>
                  {/if}
                  {#if setts.conf.items.edit?.show || (setts.conf.items.edit?.visibility ? fns[setts.conf.items.edit.visibility](item) : false)}
                    <button
                      class="btn btn-sm btn-outline-primary"
                      on:click={() => itemAction("edit", item)}
                    >
                      <PencilFill width={icoHeight} height={icoHeight} />
                    </button>
                  {/if}
                  {#if setts.conf.items.usrGear?.show || (setts.conf.items.usrGear?.visibility ? fns[setts.conf.items.usrGear.visibility](item) : false)}
                    <button
                      class="btn btn-sm btn-outline-primary"
                      on:click={() => itemAction("userGear", item)}
                    >
                      <PersonGear width={icoHeight} height={icoHeight} />
                    </button>
                  {/if}
                  {#if setts.conf.items.usrAdd?.show || (setts.conf.items.usrAdd?.visibility ? fns[setts.conf.items.usrAdd.visibility](item) : false)}
                    <button
                      class="btn btn-sm btn-outline-primary"
                      on:click={() => itemAction("userAdd", item)}
                    >
                      <PersonAdd width={icoHeight} height={icoHeight} />
                    </button>
                  {/if}
                  {#if setts.conf.items.usrDash?.show || (setts.conf.items.usrDash?.visibility ? fns[setts.conf.items.usrDash.visibility](item) : false)}
                    <button
                      class="btn btn-sm btn-outline-danger"
                      on:click={() => itemAction("userDash", item)}
                    >
                      <PersonDash width={icoHeight} height={icoHeight} />
                    </button>
                  {/if}
                  {#if setts.conf.items.usrSlash?.show || (setts.conf.items.usrSlash?.visibility ? fns[setts.conf.items.usrSlash.visibility](item) : false)}
                    <button
                      class="btn btn-sm btn-outline-danger"
                      on:click={() => itemAction("userSlash", item)}
                    >
                      <PersonSlash width={icoHeight} height={icoHeight} />
                    </button>
                  {/if}
                  {#if setts.conf.items.download?.show || (setts.conf.items.download?.visibility ? fns[setts.conf.items.download.visibility](item) : false)}
                    <button
                      class="btn btn-sm btn-secondary"
                      on:click={() => itemAction("download", item)}
                      disabled={loading[item._id]}
                    >
                      <!-- <Download /> -->
                      {#if loading[item._id]}
                        <Clock size="16" color="#ffc107" />
                      {:else}
                        <CloudDownloadFill
                          width={icoHeight}
                          height={icoHeight}
                        />
                      {/if}
                    </button>
                  {/if}
                  {#if setts.conf.items.buy?.show || (setts.conf.items.buy?.visibility ? fns[setts.conf.items.buy.visibility](item) : false)}
                    <button
                      class="btn btn-sm btn-outline-primary"
                      on:click={() => itemAction("buy", item)}
                    >
                      <CreditCard width={icoHeight} height={icoHeight} />
                    </button>
                  {/if}
                  {#if setts.conf.items.install?.show || (setts.conf.items.install?.visibility ? fns[setts.conf.items.install.visibility](item) : false)}
                    <button
                      class="btn btn-sm btn-outline-primary"
                      on:click={() => itemAction("install", item)}
                    >
                      <BagPlus width={icoHeight} height={icoHeight} />
                    </button>
                  {/if}
                  {#if setts.conf.items.delete?.show || (setts.conf.items.delete?.visibility ? fns[setts.conf.items.delete.visibility](item) : false)}
                    <button
                      class="btn btn-sm btn-outline-danger"
                      on:click={() => itemAction("delete", item)}
                    >
                      <XLg width={icoHeight} height={icoHeight} />
                    </button>
                  {/if}
                  {#if setts.conf.items.switch?.show && (setts.conf.items.switch.visibility ? fns[setts.conf.items.switch.visibility](item) : true)}
                    <FieldSwitch
                      label=""
                      bind:value={item[setts.conf.items.switch.path]}
                      on:callback={() => {
                        itemAction("switch", item);
                      }}
                    />
                  {/if}
                </div>
              </td>
            {/if}
          </tr>
        {:else}
          <tr>
            <td
              class="text-center text-muted"
              colspan={setts.cols.length + (setts.conf.header ? 1 : 0)}
            >
              {#if setts.conf.placeholder}
                {$t(setts.conf.placeholder)}
              {:else}
                {$t("msgs.msgNoDataAvailable")}
              {/if}
            </td>
          </tr>
        {/each}
      {/if}
    </tbody>
  </table>
</div>

<style>
</style>
