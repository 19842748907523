<script>
  import { nanoid } from "nanoid";
  import { onMount, onDestroy } from "svelte";
  import { themeStore } from "../../../../stores";
  import toasterService from "../../../../services/toaster.service";
  import { tNow } from "../../../../services/i18n.service";
  import BpmnViewer from "bpmn-js/lib/NavigatedViewer";
  // import processBpmnSimulationApi from "../process-bpmn-simulation-api";

  export let item = undefined;
  export let topTasks = [];
  export let tasks = [];
  export let tasksAgg = {};
  export let taskslen;
  export let taskDelay = 300;
  let uuid;
  let theme = "light";
  let viewer, canvas, elementRegistry, graphicsFactory;
  export let processStatus;
  let nextAvailableObj;

  const bpmEls = {
    start: ["bpmn:StartEvent"],
    boundaries: ["bpmn:BoundaryEvent"],
  };
  let nextAvailableIds = {};
  let tokens = {
    seq: 0,
    tkns: {},
    avtiveJoinGateways: {},
    setActiveJoinGateway: (/** @type {string | number} */ gtwId) => {
      tokens.avtiveJoinGateways[gtwId] = true;
    },
    remActiveJoinGateway: (/** @type {string | number} */ gtwId) => {
      delete tokens.avtiveJoinGateways[gtwId];
    },
    getActiveJoinGateways: () => {
      return tokens.avtiveJoinGateways;
    },
    createToken: () => {
      tokens.seq++;
      const key = `tkn${tokens.seq}`;
      tokens.tkns[key] = {
        status: "active",
      };
      return key;
    },
    closeToken: (/** @type {string | number} */ tkn) => {
      tokens.tkns[tkn].status = "closed";
    },
    countActiveTokens: () => {
      return Object.keys(tokens.tkns)
        .map((key) => tokens.tkns[key].status)
        .filter((s) => s === "active").length;
    },
    destroy: () => {
      tokens.seq = 0;
      tokens.tkns = {};
    },
  };
  export let processEnded = false;
  let executingSimulation = false;
  let showSequencesInterval;
  let executedSeqs = [];
  let startingElements = [];
  let maxExecutionTimes = 30;
  let stroke = "green";
  let strokeBlack = "black";
  let strokeHighlight = "yellow";
  let R = 255;
  let G = 255;
  let B = 255;

  const themeStoreUnsubscribe = themeStore.subscribe((res) => {
    theme = res;
  });

  onMount(() => {
    uuid = nanoid(10);
    setTimeout(() => {
      init(item.definition);
      // const myapi = processBpmnSimulationApi.start({ uuid, item });
      // myapi.testInit();
    }, 200);
  });

  onDestroy(() => {
    themeStoreUnsubscribe();
  });

  /**
   * @param {string} xml
   */
  function init(xml) {
    if (!xml) {
      viewer.createDiagram();
      return;
    }
    if (viewer) viewer.destroy();
    // @ts-ignore
    // viewer = new window.BpmnJS.Viwer({
    viewer = new BpmnViewer({
      // viewer = new window.BpmnJS.NavigatedViewer({
      // viewer = new BpmnNavigatedViewer({
      // viewer = new BpmnJSViewer({
      // viewer = new BpmnViewer({
      // viewer = new window.BpmnJS.Viwer({
      // viewer = new BpmnJSViewer({
      container: "#bpmn-viewer-" + uuid,
      keyboard: {
        bindTo: document,
      },
    });
    canvas = viewer.get("canvas");
    viewer
      .importXML(xml)
      .then(() => {
        xml = null;
        canvas.zoom("fit-viewport");

        // get all start elements:
        const allEls = elementRegistry.getAll() || [];
        allEls.forEach((/** @type {any} */ el) => {
          if (bpmEls.start.indexOf(el.type) > -1) {
            startingElements.push(el.id);
          }
        });
        showStartingPoints();
      })
      .catch(() => {
        toasterService.error({
          msg: tNow("procDiagram.msgErrRenderProc"),
        });
      });

    elementRegistry = viewer.get("elementRegistry");
    graphicsFactory = viewer.get("graphicsFactory");

    viewer.on("element.click", (/** @type {any} */ ev) => {
      if (Object.keys(nextAvailableIds).length > 0) {
        const sourceObj = ev.element.businessObject.sourceRef || {};
        let sourceElId;
        if (bpmEls.boundaries.indexOf(sourceObj.$type) > -1) {
          sourceElId = sourceObj.attachedToRef.id;
        } else {
          sourceElId = sourceObj.id;
        }
        if (
          nextAvailableIds[sourceElId] &&
          nextAvailableIds[sourceElId].indexOf(ev.element.id) > -1
        ) {
          processStatus = "procDiagram.msgProcessIsRunning";

          // check if sequence come form boundary event
          const isBoundaryNonIntreruptible =
            checkBoundarySequenceNonIntreruptible(ev.element.id);
          if (isBoundaryNonIntreruptible) {
            const tkn = tokens.createToken();
            nextAvailableObj[sourceElId][ev.element.id].tkn = tkn;
            colorElement(
              ev.element,
              nextAvailableObj[sourceElId][ev.element.id].tkn
            );
            // delete scope.nextAvailableObj[ev.element.id];
          } else {
            colorElement(
              ev.element,
              nextAvailableObj[sourceElId][ev.element.id].tkn
            );
            cancelShowSequences(sourceElId);
          }
        }
      } else if (bpmEls.start.indexOf(ev.element.type) > -1) {
        hideStartingPoints();
        if (processEnded === false) {
          processStatus = "procDiagram.msgProcessIsRunning";
          executingSimulation = true;
          const tkn = tokens.createToken();
          colorElement(ev.element, tkn);
        }
      }
    });
  }

  export function reset() {
    startingElements = [];
    showStartingPoints();
    if (showSequencesInterval) {
      Object.keys(showSequencesInterval).forEach((key) => {
        clearInterval(showSequencesInterval[key]);
      });
    }
    tokens.destroy();
    R = 255;
    G = 255;
    B = 255;
    tasks = [];
    tasksAgg = {};
    processStatus = "procDiagram.msgPressStartMessage";
    processEnded = false;
    topTasks = [];
    nextAvailableIds = {};
    executedSeqs = [];
    executingSimulation = false;
    init(item.definition);
  }

  /**
   * @param {any} elId
   */
  function checkBoundarySequenceNonIntreruptible(elId) {
    const el = elementRegistry.get(elId);
    const elSource = el.businessObject.sourceRef;
    let isBoundary = false;
    if (bpmEls.boundaries.indexOf(elSource.$type) > -1) {
      if (elSource.cancelActivity) {
        // go further with the same token
        isBoundary = false;
      } else {
        // create new token and continue
        isBoundary = true;
      }
    } else {
      isBoundary = false;
    }
    return isBoundary;
  }

  function showStartingPoints() {
    // viewer.setColor(startingElements, {
    //   stroke: '#00ff00',
    //   fill: '#ffff00'
    // })
    startingElements.forEach((elid) => {
      elementRegistry.getGraphics(elid).style.cursor = "pointer";
    });
  }

  function hideStartingPoints() {
    startingElements.forEach((elid) => {
      elementRegistry.getGraphics(elid).style.cursor = "auto";
    });
  }

  /**
   * @param {any} element
   * @param {string} tkn
   */
  function colorElement(element, tkn) {
    if (!executingSimulation) return;
    resetColor();
    B = 255 - ((tasksAgg[element.id] || {}).count || 0) * 20;
    if (B <= 0) {
      B = 0;
      G = 255 - ((tasksAgg[element.id] || {}).count - 13 || 0) * 20;
      if (G <= 0) G = 0;
    }

    // show current token:
    /**
     * Removed di from business object
     * see: https://github.com/bpmn-io/bpmn-js/issues/1472
     */
    const businessObject = element;
    const gfx = elementRegistry.getGraphics(element);
    const type = element.waypoints ? "connection" : "shape";
    businessObject.di.set("stroke", "red");
    graphicsFactory.update(type, element, gfx);

    if (type === "connection") executedSeqs = [...executedSeqs, element.id];

    setTimeout(() => {
      // color element as done:
      businessObject.di.set("stroke", stroke);
      businessObject.di.set("fill", `rgb(${R} ${G} ${B})`);
      graphicsFactory.update(type, element, gfx);
      showTask(element);
      getNextElements(element, tkn);
    }, calcTaskDelay());
  }

  function calcTaskDelay() {
    if (taskDelay <= 100) return 30;
    else if (taskDelay <= 250) return 100;
    else if (taskDelay <= 500) return 300;
  }

  /**
   * @param {any} element
   * @param {string} tkn
   */
  function getNextElements(element, tkn) {
    const elType = element.waypoints ? "connection" : "shape";
    // const elBpmType = "bpmn:EndEvent"
    let nextEl;

    if (processEnded === true) {
      // do nothing;
    } else if (elType === "connection") {
      nextEl = elementRegistry.get(element.businessObject.targetRef.id);
    } else {
      let len = (element.businessObject.outgoing || {}).length;
      // check if it's join gateway:
      const incoming = (element.businessObject.incoming || {}).length;
      if ("bpmn:ParallelGateway" === element.type && incoming > 1) {
        // we should wait for the other tokens that are active
        // kill token, save the join gateway and for each closing token check if there is
        // a gateway active. if yes, then create a token for each one and close the process
        tokens.closeToken(tkn);
        tokens.setActiveJoinGateway(element.id);

        const activeTkns = tokens.countActiveTokens();

        if (activeTkns > 0) return;
        else {
          const activeJoinGtwsObj = tokens.getActiveJoinGateways();
          const activeJoinGtwsKeys = Object.keys(activeJoinGtwsObj);
          if (activeJoinGtwsKeys.length > 0) {
            executeActiveJoinGateways(activeJoinGtwsKeys);
          }
        }
        return;
      }

      // check if it has boundary events:
      const boundaries = element.attachers;
      if (boundaries.length > 0) {
        // STOP and show sequencies:
        nextAvailableIds[element.id] = element.businessObject.outgoing.map(
          (/** @type {any} */ e) => e.id
        );
        processStatus = "procDiagram.msgChooseOneSequence";

        boundaries.forEach((/** @type {any} */ b) => {
          // show current steps:
          /**
           * Removed di from business object
           * see: https://github.com/bpmn-io/bpmn-js/issues/1472
           */
          const businessObject = b;
          const gfx = elementRegistry.getGraphics(b.id);
          const type = element.waypoints ? "connection" : "shape";
          businessObject.di.set("stroke", stroke);
          graphicsFactory.update(type, b, gfx);
          nextAvailableIds[element.id] = [
            ...nextAvailableIds[element.id],
            ...b.businessObject.outgoing.map((/** @type {any} */ e) => e.id),
          ];
        });

        showSequences(tkn, element.id);
        return;
      }

      if (len === 1) {
        nextEl = elementRegistry.get(element.businessObject.outgoing[0].id);
        // scope.nextAvailableIds = [];
      } else if (len > 1) {
        if ("bpmn:ParallelGateway" === element.type) {
          tokens.closeToken(tkn);

          element.businessObject.outgoing
            .map((/** @type {any} */ e) => e.id)
            .forEach((/** @type {any} */ seq) => {
              const newel = elementRegistry.get(seq);
              const newTkn = tokens.createToken();
              setTimeout(() => {
                colorElement(newel, newTkn);
              }, taskDelay);
            });
          return;
        }
        // STOP and wait for user decision
        nextAvailableIds[element.id] = element.businessObject.outgoing.map(
          (/** @type {any} */ e) => e.id
        );
        processStatus = "procDiagram.msgChooseOneSequence";
        showSequences(tkn, element.id);
      } else {
        tokens.closeToken(tkn);
        const activeTkns = tokens.countActiveTokens();

        if (activeTkns === 0) {
          // check active gtws:
          const activeJoinGtwsObj = tokens.getActiveJoinGateways();
          const activeJoinGtwsKeys = Object.keys(activeJoinGtwsObj);
          if (activeJoinGtwsKeys.length > 0) {
            executeActiveJoinGateways(activeJoinGtwsKeys);
          } else {
            processEnded = true;
            processStatus = "procDiagram.msgProcessClosed";
            nextAvailableIds = {};
          }
        }
      }
    }
    if (nextEl) {
      setTimeout(() => {
        colorElement(nextEl, tkn);
      }, taskDelay);
    }
  }

  /**
   * @param {any[]} activeJoinGtwsKeys
   */
  function executeActiveJoinGateways(activeJoinGtwsKeys) {
    activeJoinGtwsKeys.forEach((key) => {
      const actualEl = elementRegistry.get(key);
      const nextOut = actualEl.businessObject.outgoing;
      delete tokens.avtiveJoinGateways[key];
      nextOut.forEach((/** @type {any} */ seqObj) => {
        const newTkn = tokens.createToken();
        const newEl = elementRegistry.get(seqObj.id);
        colorElement(newEl, newTkn);
      });
    });
  }

  /**
   * @param {any} element
   */
  function showTask(element) {
    const a = item.activities.find((/** @type {any} */ it) => it.activityId === element.id);
    if (!a) return;
    if (!bpmEls.start.includes(element.type)) {
      tasks = [
        {
          name: a.activityName,
          srvId: a.serviceId,
          srvType: a.serviceKind,
          elId: element.id,
        },
        ...tasks,
      ];
      taskslen = tasks.length;
      if (!tasksAgg[a.activityId]) tasksAgg[a.activityId] = {};
      if (!tasksAgg[a.activityId].name)
        tasksAgg[a.activityId].name = a.activityName;
      if (!tasksAgg[a.activityId].elId)
        tasksAgg[a.activityId].elId = element.id;
      if (!tasksAgg[a.activityId].count) tasksAgg[a.activityId].count = 0;
      tasksAgg[a.activityId].count++;
      if (tasksAgg[a.activityId].count > maxExecutionTimes) {
        executingSimulation = false;
        processStatus = "procDiagram.msgSimStopInfiniteLoop";
      }
      getTopTaskRepetitions();
    }
  }

  /**
   * @param {string | number} elementId
   */
  function cancelShowSequences(elementId) {
    if (showSequencesInterval[elementId])
      clearInterval(showSequencesInterval[elementId]);
    Object.keys(nextAvailableObj[elementId]).forEach((key) => {
      nextAvailableObj[elementId][key].businessObject.di.set(
        "stroke",
        executedSeqs.indexOf(key) > -1 ? stroke : strokeBlack
      );
      graphicsFactory.update(
        nextAvailableObj[elementId][key].type,
        nextAvailableObj[elementId][key].element,
        nextAvailableObj[elementId][key].gfx
      );
    });
    nextAvailableObj[elementId] = {};
  }

  /**
   * @param {string} tkn
   * @param {string | number} elementId
   */
  function showSequences(tkn, elementId) {
    // console.log(scope.nextAvailableIds);
    if (!nextAvailableObj) nextAvailableObj = {};
    nextAvailableObj[elementId] = {};
    nextAvailableIds[elementId].forEach((/** @type {string} */ id) => {
      const element = elementRegistry.get(id);
      /**
       * Removed di from business object
       * see: https://github.com/bpmn-io/bpmn-js/issues/1472
       */
      const businessObject = element;
      const gfx = elementRegistry.getGraphics(element);
      const type = element.waypoints ? "connection" : "shape";
      const oldStrokeValue = businessObject.di.get("stroke");
      nextAvailableObj[elementId][id] = {
        element,
        businessObject,
        gfx,
        type,
        oldStrokeValue,
        tkn,
      };
    });
    if (!showSequencesInterval) showSequencesInterval = {};
    // FIXME: where setInterval should be canceled?
    showSequencesInterval[elementId] = setInterval(() => {
      Object.keys(nextAvailableObj[elementId]).forEach((key) => {
        nextAvailableObj[elementId][key].highlighted =
          !nextAvailableObj[elementId][key].highlighted;
        nextAvailableObj[elementId][key].businessObject.di.set(
          "stroke",
          nextAvailableObj[elementId][key].highlighted
            ? strokeHighlight
            : strokeBlack
        );
        graphicsFactory.update(
          nextAvailableObj[elementId][key].type,
          nextAvailableObj[elementId][key].element,
          nextAvailableObj[elementId][key].gfx
        );
      });
    }, 300);
  }

  function resetColor() {
    R = 255;
    G = 255;
    B = 255;
  }

  function getTopTaskRepetitions() {
    let topTasksTemp = [];
    Object.keys(tasksAgg).map((key) => {
      // topTasks.unshift(tasksAgg[key]);
      topTasksTemp = [tasksAgg[key], ...topTasksTemp];
    });
    topTasksTemp.sort((a, b) => b.count - a.count);
    topTasks = [...topTasksTemp];
  }

  /**
   * @param {{ srvId: string; }} task
   */
  export function runTask(task) {
    window.open(
      // window.location.origin +
      window.location.href.split('diagram')[0] +
        // "/admin/#!/task/" +
        "task/" +
        task.srvId +
        "?standAlone=true",
      "_blank"
    );
  }
</script>

<div
  id={"bpmn-viewer-" + uuid}
  class="bpmn-viewer {theme === 'dark' ? 'bpmn-viewer-dark' : ''}"
  style="width: 100%; height: 100%; position: relative;"
/>

<style global>
  .bpmn-viewer {
    border: 1px solid gray;
  }
  .bpmn-viewer-dark {
    filter: invert(0.9);
  }
</style>
