<script>
  import InboxRedesignExposedTasks from "./inbox-redesign-exposed-tasks.svelte";
  import TasksRedesign from "./tasks-redesign.svelte";
</script>

<div class="d-md-flex">
  <div
    class="d-none d-md-block pe-3 border-end h-100"
    style="width: 300px; height: 50vh;"
  >
    <InboxRedesignExposedTasks></InboxRedesignExposedTasks>
  </div>

  <div class="ps-3 w-100 mb-3">
    <TasksRedesign listType="table"></TasksRedesign>
  </div>

  <!-- <div class="px-3" style="width: 200px;"> -->
  <!--   <div class="card p-3"> -->
  <!--     <div> -->
  <!--     <canvas id="chartContainer1"> </canvas> -->
  <!--     </div> -->
  <!--   </div> -->
  <!-- </div> -->
</div>
