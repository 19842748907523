import { Base64 } from "js-base64";
import { range, flatten } from 'underscore';
import { tNow } from "./i18n.service";
import toasterService from "./toaster.service";

export default {
  check: {
    nestedProps: checkNestedProperty,
    val: checkValue,
    email: checkEmail,
    emails: checkEmails,
  },
  query: {
    reset: queryReset,
    nexPage: queryNextPage,
  },
  getNested,
  getNestedFromPath,
  dataconv: {
    encode: encode,
    decode: decode,
  },
  convert: {
    mask,
    createObjFromPathString,
  },
  msg: {
    insufficientRights,
  },
  debounce: (/** @type {{ apply: (arg0: any, arg1: IArguments) => void; }} */ func, /** @type {number} */ delay) => {
    let timer;
    return function() {
      const context = this;
      const args = arguments;
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(context, args), delay);
    };
  }
}

/**
 * @param {any} data
 * @returns string
 */
function encode(data) {
  return Base64.toBase64(JSON.stringify(data));
}

/**
 * @param {string} data
 * @returns any|undefined
 */
function decode(data) {
  const decodedStr = Base64.decode(data);
  try {
    return JSON.parse(decodedStr);
  } catch (error) {
    console.error(error);
    return undefined
  }
}

/**
* @param {string} prop
*/
function checkNestedProperty(prop) {
  if (!prop) prop = '';
  const arr = prop?.split('.');
  if (arr?.length > 1) return arr;
  else return prop;
}

/**
 * @param {any} obj
 * @param {any[]} args
 */
function getNested(obj, ...args) {
  return flatten(args).reduce((obj, level) => {
    return obj?.[level]
  }, obj)
}

/**
 * @param {any} obj
 * @param {string} path
 */
function getNestedFromPath(obj, path) {
  return getNested(obj, checkNestedProperty(path))
}

/**
 * @param {string} queryStr
 */
function queryReset(queryStr) {
  return JSON.parse(queryStr)
}

/**
 * @param {{ skip: number; limit: any; }} query
 */
function queryNextPage(query) {
  query.skip = Number(query.skip) + Number(query.limit);
  return query;
}

/**
 * @param {any} value
 */
function checkValue(value) {
  return value !== undefined && value !== null && value !== '';
}

/**
 * @param {string} value
 * @param {number} percentage
 * @param {string} [charFill]
 */
function mask(value, percentage, charFill) {
  if (!value) return '';
  const len = value.length;
  const displayPercentage = parseFloat(percentage / 100) || .3;
  const lenDisplay = Math.floor(displayPercentage * len);
  const arrString = [
    value.substr(0, lenDisplay),
    charFill ? range(10).map(el => charFill).join('') : '..........',
    value.substr(len - lenDisplay, len),
  ];
  return arrString.join('');
}

/**
 * @param {object} obj
 * @param {string} path
 */
function createObjFromPathString(obj, path) {
  if (!obj) obj = {};
  const pathArray = path.split('.');
  pathArray.reduce((obj, path) => {
    let element;
    // return path ? (obj[path] = {}) : obj;
    if (path) {
      if (!obj[path]) obj[path] = {};
      element = obj[path];
    } else {
      element = obj;
    }
    return element;
  }, obj)
  return obj;
}

/**
 * @param {string} value
 */
function checkEmail(value) {
  const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return re.test(value);
};

/**
 * @param {string} string
 */
function checkEmails(string) {
  const re = /(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})/gm;
  return string.match(re);
}

function insufficientRights() {
  toasterService.error({ msg: tNow('apiFact.msgInsuffRight'), timeout: 7000 });
}
