<script>
  import { currentLanguage, t } from "../../../services/i18n.service";

  export let item = undefined;
  export let props = {};
  export let varProp = "label";
  export let index = undefined;
  export let defaultLabel = "generic.label";
</script>

{#if !props?.[varProp + "_i18n"]}
  {#if varProp === "arrMultiple"}
    <span>{props?.[varProp].split(",")[index] || $t(defaultLabel)}</span>
  {:else}
    <span>{props?.[varProp] || $t("generic.label")}</span>
  {/if}
{:else if varProp === "arrMultiple"}
  <span
    >{props[varProp + "_i18n"][$currentLanguage].split(",")[index] ||
      $t(defaultLabel)}</span
  >
{:else}
  <span>{props[varProp + "_i18n"][$currentLanguage] || $t(defaultLabel)}</span>
{/if}
