import axios from "axios"

export default {
  startInstance,
}

/**
 * @param {any} process
 */
function startInstance(process) {
  return axios.post('/api/bpm/startInstance', undefined, { params: { pid: process._id, processStartedByUser: 'yes', waitForResponse: false } })
}
