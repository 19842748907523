<script>
  import toasterService from "../../services/toaster.service";
  import instanceService from "../instance/instance.service";
  import FieldDateFormated from "../utils/field-date-formated.svelte";
  import { t, tNow } from "../../services/i18n.service";
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  export let instance = undefined;
  let processVersions = [];

  $: getProcessVersions(instance);

  /**
   * @param {any} [instance]
   */
  function getProcessVersions(instance) {
    if (!instance) return;
    processVersions = [];
    instanceService.getProcessVersions(instance.pid).then((res) => {
      processVersions = res.data;
    });
  }

  /**
   * @param {any} proc
   */
  function migrate(proc) {
    const piid = instance._id;
    const pid = proc._id;
    instanceService
      .migrate(piid, pid)
      .then(() => {
        toasterService.success({ msg: tNow("diaInspMigration.msgSuccess") });
        // getProcessVersions();
        dispatch("close");
      })
      .catch((err) => console.error(err));
  }
</script>

{#if instance}
  <p>{$t("diaInspMigration.desc")}</p>
  <ul class="list-group view-height">
    {#each processVersions as proc}
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <li
        class="list-group-item selectable-item"
        class:selected-item={instance.pid === proc._id}
        on:click={() => migrate(proc)}
      >
        <h4 class="list-group-item-heading">{proc?.name}</h4>
        <span class="text-muted">{$t("diaInspMigration.installedAt")}:</span>
        <span class="text-primary fw-bold">
          <FieldDateFormated date={proc.installed_at} />
        </span>

        <span class="text-muted">{$t("diaInspMigration.isExposed")}:</span>
        <span class="text-primary fw-bold"
          >{$t(proc.exposable ? "generic.yes" : "generic.no")}</span
        >
        <span class="text-muted">{$t("diaInspMigration.curProcVers")}:</span>
        <span class="text-primary fw-bold"
          >{$t(instance.pid === proc._id ? "generic.yes" : "generic.no")}</span
        >
      </li>
    {/each}
  </ul>
{/if}

<style>
  .view-height {
    height: calc(100vh - 800px);
    overflow-y: scroll;
  }
</style>
