<script>
  import { Eye } from "svelte-bootstrap-icons";
  import { t } from "../../services/i18n.service";
  import gdprService from "./gdpr.service";
  import instanceService from "../instance/instance.service";
  import GdprDataInstances from "./gdpr-data-instances.svelte";
  import GdprDataTasks from "./gdpr-data-tasks.svelte";
  import GdprDataVariables from "./gdpr-data-variables.svelte";

  let reportResult;
  let gdprObj = gdprService.getCategsObj();
  let varsInstance = [];
  let loading = true;

  gdprService
    .getReport()
    .then((res) => {
      reportResult = res.data;
      loading = false;
    })
    .catch((err) => {
      console.error(err);
      loading = false;
    });

  function showData(cat, key) {
    varsInstance = getAllVarsFromCategory(cat, key);
  }

  function getAllVarsFromCategory(category, keyCategory) {
    const arr = [];
    if (!reportResult?.gdpr) return [];
    Object.entries(reportResult.gdpr).forEach(([key, val]) => {
      // console.log("... entires", key, val);
      if (!keyCategory) {
        if (Object.keys(val[category]).length > 0) {
          arr.push(key);
        }
      } else {
        if (val[category][keyCategory]) {
          arr.push(key);
        }
      }
    });
    return arr;
  }
</script>

<div>
  <!-- <button class="btn btn-default" style="float: right;" ng-click="Gdpr.getReport()">{{'generic.refresh' | translate}}</button> -->
  <div class="row">
    <div class="col-md-3">
      <div class="card mb-3">
        <div class="card-body">
          <div class="text-muted title-card">
            {$t("gdpr.affectedInsts")}
          </div>
          <div class="text-center text-primary card-number placeholder-glow">
            {#if reportResult?.totals?.instances}
              {reportResult?.totals?.instances || 0}
            {:else if !loading}
              <span class="fs-3 text-muted">
                {$t("msgs.msgNoDataAvailable")}
              </span>
            {:else}
              <span class="placeholder bg-secondary w-100" />
            {/if}
          </div>
        </div>
      </div>
      <div class="card mb-3">
        <div class="card-body">
          <div class="text-muted title-card">
            {$t("gdpr.affectedTasks")}
          </div>
          <div class="text-center text-primary card-number placeholder-glow">
            {#if reportResult?.totals?.tasks}
              {reportResult?.totals?.tasks || 0}
            {:else if !loading}
              <span class="fs-3 text-muted">
                {$t("msgs.msgNoDataAvailable")}
              </span>
            {:else}
              <span class="placeholder bg-secondary w-100" />
            {/if}
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-9">
      <div class="row">
        <div class="col-md-4">
          <div class="card mb-3">
            <div class="card-header bg-primary text-white">
              {$t("generic.categories")}
            </div>
            <div class="card-body">
              <table class="table mcm-table-sticky">
                <thead>
                  <tr>
                    <th>{$t("generic.category")}</th>
                    <th class="text-end">{$t("generic.total")}</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {#each Object.entries(reportResult?.totals?.gdpr?.cat || {}) as [key, val]}
                    <tr>
                      <td>
                        {#if gdprObj?.cats?.[key]}
                          <span>{$t("gdpr." + key + ".lbl")}</span>
                        {:else}
                          <span>{key}</span>
                        {/if}
                      </td>
                      <td class="text-end">{val}</td>
                      <td class="text-end" style="width: 20px;">
                        <button
                          class="btn btn-sm btn-outline-primary"
                          on:click={() => {
                            showData("cat", key);
                          }}
                        >
                          <Eye width={20} height={20} />
                        </button>
                      </td>
                    </tr>
                  {:else}
                    {#if !loading}
                      <tr>
                        <td class="text-muted" colspan="2"
                          >{$t("msgs.msgNoDataAvailable")}</td
                        >
                      </tr>
                    {:else}
                      <tr>
                        <td class="placeholder-glow">
                          <span class="placeholder w-100" />
                        </td>
                        <td class="placeholder-glow">
                          <span class="placeholder w-100" />
                        </td>
                      </tr>
                      <tr>
                        <td class="placeholder-glow">
                          <span class="placeholder w-100" />
                        </td>
                        <td class="placeholder-glow">
                          <span class="placeholder w-100" />
                        </td>
                      </tr>
                    {/if}
                  {/each}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card mb-3">
            <div class="card-header bg-primary text-white">
              {$t("generic.infoType")}
            </div>
            <div class="card-body">
              <table class="table mcm-table-sticky">
                <thead>
                  <tr>
                    <th>{$t("generic.category")}</th>
                    <th class="text-end">{$t("generic.total")}</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {#each Object.entries(reportResult?.totals?.gdpr?.info || {}) as [key, val]}
                    <tr>
                      <td>
                        {#if gdprObj?.infos?.[key]}
                          <span>{$t("gdpr." + key + ".lbl")}</span>
                        {:else}
                          <span>{key}</span>
                        {/if}
                      </td>
                      <td class="text-end">{val}</td>
                      <td class="text-end" style="width: 20px;">
                        <button
                          class="btn btn-sm btn-outline-primary"
                          on:click={() => {
                            showData("info", key);
                          }}
                        >
                          <Eye width={20} height={20} />
                        </button>
                      </td>
                    </tr>
                  {:else}
                    {#if !loading}
                      <tr>
                        <td class="text-muted" colspan="2"
                          >{$t("msgs.msgNoDataAvailable")}</td
                        >
                      </tr>
                    {:else}
                      <tr>
                        <td class="placeholder-glow">
                          <span class="placeholder w-100" />
                        </td>
                        <td class="placeholder-glow">
                          <span class="placeholder w-100" />
                        </td>
                      </tr>
                      <tr>
                        <td class="placeholder-glow">
                          <span class="placeholder w-100" />
                        </td>
                        <td class="placeholder-glow">
                          <span class="placeholder w-100" />
                        </td>
                      </tr>
                    {/if}
                  {/each}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card mb-3">
            <div class="card-header bg-primary text-white">
              {$t("generic.objType")}
            </div>
            <div class="card-body">
              <table class="table mcm-table-sticky">
                <thead>
                  <tr>
                    <th>{$t("generic.category")}</th>
                    <th class="text-end">{$t("generic.total")}</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {#each Object.entries(reportResult?.totals?.gdpr?.obj || {}) as [key, val]}
                    <tr>
                      <td>
                        {#if gdprObj?.objs?.[key]}
                          <span>{$t("gdpr." + key + ".lbl")}</span>
                        {:else}
                          <span>{key}</span>
                        {/if}
                      </td>
                      <td class="text-end">{val}</td>
                      <td class="text-end" style="width: 20px;">
                        <button
                          class="btn btn-sm btn-outline-primary"
                          on:click={() => {
                            showData("obj", key);
                          }}
                        >
                          <Eye width={20} height={20} />
                        </button>
                      </td>
                    </tr>
                  {:else}
                    {#if !loading}
                      <tr>
                        <td class="text-muted" colspan="2"
                          >{$t("msgs.msgNoDataAvailable")}</td
                        >
                      </tr>
                    {:else}
                      <tr>
                        <td class="placeholder-glow">
                          <span class="placeholder w-100" />
                        </td>
                        <td class="placeholder-glow">
                          <span class="placeholder w-100" />
                        </td>
                      </tr>
                      <tr>
                        <td class="placeholder-glow">
                          <span class="placeholder w-100" />
                        </td>
                        <td class="placeholder-glow">
                          <span class="placeholder w-100" />
                        </td>
                      </tr>
                    {/if}
                  {/each}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

{#if varsInstance.length > 0}
  <div class="row">
    <div class="col-md-3">
      <GdprDataVariables bind:reportResult />
    </div>
    <div class="col-md-9">
      <div class="row">
        <div class="col-md-6">
          <GdprDataInstances bind:vars={varsInstance} />
        </div>
        <div class="col-md-6">
          <GdprDataTasks bind:vars={varsInstance} />
        </div>
      </div>
    </div>
  </div>
{/if}

<style>
  .title-card {
    position: relative;
    top: -5px;
  }
  .card-number {
    font-size: 3rem;
  }
</style>
