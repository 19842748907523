import axios from "axios"

export default {
  getTasks,
  getTasksNo,
  getExposed,
}

/**
 * @param {any} query
 */
function getTasks(query) {
  return axios.get('/api/bpm/getTasks', { params: query });
}

/**
 * @param {any} query
 */
function getTasksNo(query) {
  return axios.get('/api/bpm/getTasks', { params: { ...query, countDoc: true } });
}

function getExposed() {
  return axios.get('/api/bpm/exposed');
}
