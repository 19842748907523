<script>
  import { nanoid } from "nanoid";
  import { onMount, onDestroy } from "svelte";
  import { themeStore } from "../../../../stores";
  import toasterService from "../../../../services/toaster.service";
  import { tNow } from "../../../../services/i18n.service";
  import BpmnViewer from "bpmn-js/lib/NavigatedViewer";
  import processService from "../process.service";
  import dashboardService from "../../../dashboards/dashboard.service";
  import jq from "jquery";

  export let processId = undefined;
  export let item = undefined;
  export let processDetails;
  export let activityId = undefined;
  export let showAllVersions = false;
  let lastActivityId;
  let uuid;
  let theme = "light";
  let viewer, canvas, elementRegistry, overlays, graphicsFactory;
  const Rmax = 30,
    Gmax = 140,
    Bmax = 30;

  const themeStoreUnsubscribe = themeStore.subscribe((res) => {
    theme = res;
  });

  $: start(processId);

  $: showHideActivityInfo(activityId);

  $: getDashboardData(showAllVersions);

  onMount(() => {
    uuid = nanoid(10);
  });

  onDestroy(() => {
    themeStoreUnsubscribe();
    viewerDestroy();
  });

  function showHideActivityInfo(activityId) {
    if (activityId) {
      lastActivityId = activityId;
      toggleInfo(activityId, "show");
    } else {
      toggleInfo(lastActivityId, "hide");
    }
  }

  /**
   * @param {string} pid
   */
  function start(pid) {
    // viewerDestroy();
    processService.getOne(pid).then((res) => {
      item = res.data;
      item.tasksInfo = item.activities.reduce((o, el) => {
        o[el.activityId] = el;
        return o;
      }, {});
      getDashboardData(showAllVersions);
    });
  }

  function getDashboardData(showAllVersions) {
    if (!item) return;
    const queryPerf = {};
    if (!showAllVersions) queryPerf.pid = item._id;
    else queryPerf.pcid = item.pc_id;
    dashboardService
      .getDashboardProcessPerformance(queryPerf)
      .then((procInfo) => {
        processDetails = procInfo.data;
        setTimeout(() => {
          init(item.definition);
        }, 200);
      });
  }

  function viewerDestroy() {
    if (viewer) {
      viewer.destroy();
      viewer = undefined;
    }
  }

  /**
   * @param {string} xml
   */
  function init(xml) {
    if (!xml) {
      viewer.createDiagram();
      return;
    }
    // if (viewer) viewer.destroy();
    if (!viewer) {
      viewer = new BpmnViewer({
        container: "#bpmn-viewer-" + uuid,
        keyboard: {
          bindTo: document,
        },
      });
    }
    canvas = viewer.get("canvas");
    viewer
      .importXML(xml)
      .then(() => {
        xml = null;
        canvas.zoom("fit-viewport");

        // viewer.on("element.click", (ev) => {
        //   console.log("... clicked", ev);
        //   // colorElement2(ev.element);
        //   addInfo(ev.element.id);
        // });
        viewer.on("element.hover", (ev) => {
          // console.log("... hover", ev);
          // colorElement2(ev.element);
          activityId = ev.element.id;
          toggleInfo(ev.element.id, "show");
        });
        viewer.on("element.out", (ev) => {
          // console.log("... out", ev);
          // colorElement2(ev.element);
          activityId = undefined;
          toggleInfo(ev.element.id, "hide");
        });

        // if no data do not color anythhing:
        if (processDetails?.instancesInfo?.count > 0) {
          const allEls = elementRegistry.getAll() || [];
          // color diagram based on type of information
          // in order to assure backwards compatibility:
          // take new progress based on intance progress only if data is more then half of found instances:
          if (
            // processDetails?.instancesInfo?.progressCount > 0
            processDetails?.instancesInfo?.progressCount >=
            processDetails?.instancesInfo?.count / 2
          ) {
            allEls.forEach((el) => {
              colorElementBasedOnInstanceProgress(el);
            });
          } else {
            // else go the old fashion way:
            allEls.forEach((el) => {
              colorElement1(el);
              colorElement2(el);
            });
          }
        }
      })
      .catch((err) => {
        console.error("err", err);
        toasterService.error({
          msg: tNow("procDiagram.msgErrRenderProc"),
        });
      });

    elementRegistry = viewer.get("elementRegistry");
    graphicsFactory = viewer.get("graphicsFactory");
    overlays = viewer.get("overlays");
  }

  function toggleInfo(elId, action) {
    // for each task, display no of task, min, max, and avg execution time:
    // should compare with DUE-DATE when exists:
    if (!processDetails?.tasksInfo) return;
    if (!overlays) return;
    Object.entries(processDetails.tasksInfo).forEach(([key, value]) => {
      const id = key;
      // console.log('... id, elId', id, elId)
      if (id !== elId) return;
      if (action === "hide") {
        overlays.remove({ element: id });
        return;
      }
      let min = value.min;
      let avg = value.avg;
      let max = value.max;

      let txt = "";
      let html = jq("<div>", {
        text: txt,
        class: "bg-primary text-white p-2 rounded-3",
        css: {
          display: "block",
          cursor: "pointer",
          width: "200px",
          textAlign: "left",
        },
        // click: function() {
        //     console.log('click')
        //     // openRoles(setts);
        // }
      });
      jq("<div>", {
        text: tNow("procDiagramDirective.tasksNo") + ": " + value.count,
      }).appendTo(html);
      jq("<div>", {
        text:
          tNow("generic.min") +
          ": " +
          (min === "NaN" ? "0" : Number(min).toLocaleString()) +
          "s",
      }).appendTo(html);
      jq("<div>", {
        text:
          tNow("generic.avg") +
          ": " +
          (avg === "NaN" ? "0" : Number(avg).toLocaleString()) +
          "s",
      }).appendTo(html);
      jq("<div>", {
        text:
          tNow("generic.max") +
          ": " +
          (max === "NaN" ? "0" : Number(max).toLocaleString()) +
          "s",
      }).appendTo(html);

      try {
        overlays.add(id, {
          position: {
            // left: 5,
            bottom: -15,
          },
          html: html,
        });
      } catch (err) {
        console.error(err);
      }
    });
  }

  function colorElementBasedOnInstanceProgress(element) {
    const elementInProgress = processDetails.instancesInfo.progress[element.id];
    if (!elementInProgress) return;
    const percentage = elementInProgress / processDetails.instancesInfo.count;
    const R = Rmax - Rmax * percentage + 220;
    const G = Gmax - Gmax * percentage + 20;
    const B = Bmax - Bmax * percentage;
    let Width = 6 * percentage + 4;
    if (Width > 8) Width = 8;
    const businessObject = element;
    const gfx = elementRegistry.getGraphics(element);
    const type = element.waypoints ? "connection" : "shape";
    businessObject.di.set("stroke", `rgb(${R} ${G} ${B})`);
    graphicsFactory.update(type, element, gfx);
    jq(gfx).find(".djs-visual > rect").css("stroke-width", `${Width}px`);
  }

  function colorElement1(element) {
    const R = Rmax;
    const G = Gmax;
    const B = Bmax;
    const businessObject = element;
    const gfx = elementRegistry.getGraphics(element);
    const type = element.waypoints ? "connection" : "shape";
    businessObject.di.set("stroke", `rgb(${R} ${G} ${B})`);
    graphicsFactory.update(type, element, gfx);
  }

  function colorElement2(element) {
    // console.log(".... color element", element);
    const maxPassing = processDetails?.instancesInfo?.count || 0;
    const idElement = element?.id;
    if (!idElement) return;
    if (!processDetails?.tasksInfo?.[idElement]) return;
    const elPassing = processDetails?.tasksInfo?.[idElement]?.count || 0;
    const percentage = elPassing / maxPassing;
    // 255, 200, 0 - yellow
    // 220, 60, 30 - red

    const R = Rmax - Rmax * percentage + 220;
    const G = Gmax - Gmax * percentage + 20;
    const B = Bmax - Bmax * percentage;
    let Width = 6 * percentage + 4;
    if (Width > 8) Width = 8;

    const businessObject = element;
    const gfx = elementRegistry.getGraphics(element);
    const type = element.waypoints ? "connection" : "shape";
    businessObject.di.set("stroke", `rgb(${R} ${G} ${B})`);
    // console.log(".... rgb", R, G, B, Width, percentage, maxPassing, elPassing);
    // businessObject.di.set("stroke:width", `${11}px`);
    // businessObject.di.set("fill", `rgb(${R} ${G} ${B})`);
    graphicsFactory.update(type, element, gfx);
    jq(gfx).find(".djs-visual > rect").css("stroke-width", `${Width}px`);

    // color also the incoming and outgoing elements:
    // get incoming:
    const incoming = element.incoming;
    incoming.forEach((el) => {
      // console.log(".... incoming", el);
      const businessObject = elementRegistry.get(el.id);
      const gfx = elementRegistry.getGraphics(businessObject);
      const type = el.waypoints ? "connection" : "shape";
      // el.di.set("stroke", `rgb(${R} ${G} ${B})`);
      businessObject.di.set("stroke", `red`);
      graphicsFactory.update(type, el, gfx);
    });
    const outgoing = element.outgoing;
    outgoing.forEach((el) => {
      // console.log(".... outgoing", el);
      const businessObject = elementRegistry.get(el.id);
      const gfx = elementRegistry.getGraphics(businessObject);
      const type = el.waypoints ? "connection" : "shape";
      // el.di.set("stroke", `rgb(${R} ${G} ${B})`);
      businessObject.di.set("stroke", `red`);
      graphicsFactory.update(type, el, gfx);
    });
  }
</script>

<div
  id={"bpmn-viewer-" + uuid}
  class="bpmn-viewer {theme === 'dark' ? 'bpmn-viewer-dark' : ''}"
  style="width: 100%; height: 100%; position: relative;"
/>

<style global>
  .bpmn-viewer {
    border: 1px solid gray;
  }
  .bpmn-viewer-dark {
    filter: invert(0.9);
  }
</style>
