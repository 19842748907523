import axios from "axios";
import { derived, writable, get as getFromStore } from "svelte/store";
import { get } from 'underscore';
import api from "../services/api.service";
import storageService from "./storage.service";

const RTL = {
  he: { dir: 'rtl', lang: 'he' },
}

let json;
let langDefault = 'en';
export let langChanged = writable();
export const currentLanguage = writable('en');
export const textDirection = writable();
langChanged.subscribe(res => {
  json = res;
  currentLanguage.set(langDefault)
  let textDir = RTL[langDefault] || { dir: 'ltr', lang: langDefault };
  textDirection.set(textDir);
})
export const locale = writable("en");
export const locales = ['en', 'es', 'ro', 'he']
export const langs = {
  en: { lang: 'en', icon: 'GB.png', code: 'en_EN' },
  es: { lang: 'es', icon: 'ES.png', code: 'es_ES' },
  ro: { lang: 'ro', icon: 'RO.png', code: 'ro_RO' },
  he: { lang: 'he', icon: 'IL.png', code: 'he_IL' },
};
export const langsCodes = locales.map(key => langs[key].code);
export const i18n = translate_now;
export const tNow = translate_now;
export const changeLang = changeLangFn;
export const t = derived([locale, langChanged], ([$locale, $langChanged]) => (key, vars = {}) =>
  translate($langChanged, key, vars)
);
// export const currentLanguage = derived([locale, langChanged], ([$locale, $langChanged]) => $locale);

/**
 * @param {any} props
 * @param {string} path
 */
export function getValueFromI18n(props, path) {
  if (!path) path = 'label';
  if (props?.[path + '_i18n']?.[getFromStore(currentLanguage)])
    return props?.[path + '_i18n']?.[getFromStore(currentLanguage)];
  else return props[path];
}

getTranslation();

/**
 * @param {() => void} [next]
 */
function getTranslation(next) {
  const langSaved = storageService.data.get('lang');
  if (langSaved) {
    if (locales.includes(langSaved)) {
      langDefault = langSaved;
      locale.update(() => langDefault);
    } else {
      langDefault = 'en';
      locale.update(() => 'en');
    }
  };
  let file;
  switch (langDefault) {
    case 'ro':
      file = 'ro_RO.json';
      break;
    case 'es':
      file = 'es_ES.json';
      break;
    case 'he':
      file = 'he_IL.json';
      break;
    default:
      file = 'en_EN.json';
      break;
  }
  const path = 'i18n/' + file;
  axios.get(path, {
    baseURL: '/portal'
  }).then(res => {
    langChanged.set(res.data);
    if (next) next();
  })
}

/**
 * @param {string} key
 * @param {{[x: string]: any;}} vars
 * @param {string} json
 */
function translate(json, key, vars) {
  let text = get(json, api.check.nestedProps(key));
  // Replace any passed in variables in the translation string.
  Object.keys(vars).map((/** @type string */k) => {
    const regex = new RegExp(`{{${k}}}`, "g");
    text = text.replace(regex, vars[k]);
  });

  return text || key;
}

/**
 * @param {string} key
 * @param {{ [x: string]: any; }} [vars]
 */
function translate_now(key, vars) {
  let text = get(json, api.check.nestedProps(key));

  // Replace any passed in variables in the translation string.
  if (vars) {
    Object.keys(vars).map((/** @type string */k) => {
      const regex = new RegExp(`{{${k}}}`, "g");
      text = text.replace(regex, vars[k]);
    });
  }

  return text || key;
}

/**
 * @param {string} lang
 */
function changeLangFn(lang) {
  langDefault = lang;
  locale.update(() => langDefault);
  storageService.data.save('lang', langDefault)
  axios.post('/api/bpm/saveLang', { lang })
    .then(() => {
      getTranslation()
    })
    .catch(err => {
      console.error('... err from changelang', err);
    })
}


