<script>
  import { Play } from "svelte-bootstrap-icons";
  import { t } from "../../../../services/i18n.service";
  import ProcessCompSimulationBpmn from "./process-comp-simulation-bpmn.svelte";
  import { onMount } from "svelte";
  import processService from "../process.service";
  import Slider from "@bulatdashiev/svelte-slider";
  import TaskExeTplI18nLabel from "../../../task/task-exe-templates/task-exe-tpl-i18n-label.svelte";

  export let processId;
  let icoHeight = 24;
  let processEnded;
  let processStatus = "procDiagram.msgPressStartMessage";
  let tasks = [];
  let taskslen;
  let topTasks = [];
  let tasksAgg = {};
  let item;
  let processCompSimulationBpmn;
  let taskDelaySlider = [300];
  $: taskDelay = taskDelaySlider[0];

  onMount(() => {
    processService.getOne(processId).then((res) => (item = res.data));
  });

  /**
   * @param {any} t
   */
  function runTask(t) {
    processCompSimulationBpmn.runTask(t);
  }

  function reset() {
    processCompSimulationBpmn.reset();
  }
</script>

<div>
  <b>
    {$t("procDiagram.lblProcName")}:
    {#if !item?._id}
      #{$t("procDiagram.lblNoActiveProc")}
    {:else}
      <!-- #{item.name} -->
      #<TaskExeTplI18nLabel bind:props={item} varProp="name" />
    {/if}
  </b>
</div>

{#if item?._id}
  <div class="row">
    <div class="col-md-8 view-height">
      <ProcessCompSimulationBpmn
        bind:this={processCompSimulationBpmn}
        bind:tasks
        bind:taskslen
        bind:topTasks
        bind:tasksAgg
        bind:processEnded
        bind:processStatus
        bind:taskDelay
        {item}
      />
    </div>
    <div class="col-md-4">
      {#if !processEnded}
        <button class="btn btn-link" on:click={() => reset()}
          >{$t("generic.reset")}</button
        >
      {:else}
        <button class="btn btn-link" on:click={() => reset()}
          >{$t("generic.replay")}</button
        >
      {/if}

      <div>{$t("procDiagram.lblSimulationSpeed")}: {taskDelaySlider}</div>
      <Slider min="0" max="500" step="5" bind:value={taskDelaySlider} />
      <br />
      <p class="mt-1">
        {$t("procDiagram.lblProcessStatus")}: {$t(processStatus)}
      </p>
      {#if tasks.length > 0}
        <div>
          <p>#{tasks.length} {$t("procDiagram.lblTasks")}</p>
          <ul class="list-group task-container">
            {#each tasks as t, index}
              <li class="list-group-item d-flex">
                <div>
                  #{taskslen - index}
                  {t.name || t.elId}
                </div>
                <!-- {#if t.srvType === "Human"} -->
                <!--   <button -->
                <!--     class="btn btn-sm btn-outline-primary ms-auto" -->
                <!--     on:click={() => runTask(t)} -->
                <!--   > -->
                <!--     <Play width={icoHeight} height={icoHeight} /> -->
                <!--   </button> -->
                <!-- {/if} -->
              </li>
            {/each}
          </ul>
        </div>
      {/if}
      {#if topTasks.length > 0}
        <div class="mt-3">
          <p>{$t("procDiagram.lblTopTasksRepetitions")}</p>
          <ul class="list-group task-container">
            {#each topTasks as t}
              <li class="list-group-item">{t.name || t.elId}: {t.count}</li>
            {/each}
          </ul>
        </div>
      {/if}
    </div>
  </div>
{/if}

<style global>
  .view-height {
    height: calc(100vh - 240px);
    /* height: calc(100vh - 380px); */
    overflow-y: scroll;
  }
  .task-container {
    max-height: 20vh;
    overflow-y: scroll;
  }
</style>
