export default {
  SERVER_PATH: '/portalapis',
  APP_NAME: 'bpm',
  DATEFORMAT: "DD/MM/YYYY HH:mm",
  DATEFULLFORMAT: "DD/MM/YYYY HH:mm:ss",
  NAV_TITLE: null,
  // "NAV_TITLE": "Tasky",
  UPLOAD: {
    url: "/portalapis/api/upload/files",
    method: "POST",
    withCredentials: false,
    autoUpload: true,
    removeAfterUpload: true
  },
  ONPREMISE: false,
  VERSION: "5.0",
  COPYRIGHT: "Copyright © 2017-2024 All rights reserved.",
  QUERY_PAGE_LIMIT: 10,
}

