import { writable, derived } from 'svelte/store';

export let themeStore = writable();
export let layoutFluid = writable();

export let isLoading = writable(false);

export let platformInfoStore = writable();

export let userStore = writable();
export let isAuthWaiting = writable();
export let isAuth = derived(userStore, value => value ? true : false);

export const gridSizeStore = writable();


// Lists:
export let listCounties = writable([]);
export let listYesNo = writable([]);

// objects:
export let fileValidationStore = writable(/** @param {{isOk: Boolean, validation: Object; validationInfo: Object}} */{});
// export let fileStore = writable(fileAcroService.getNew());
export let fileStore = writable();
export let patientStore = writable();

export const credsTempStore = writable();

