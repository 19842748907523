import axios from "axios"

export default {
  getGeneralDPA,
  saveGeneralDPA,
  checkGeneralDPA,
  getCategs,
  getCategsObj,
  getCategory,
  save,
  get,
  getReport,
  data: {
    instances: {
      get: gdpr_get_instances,
      count: gdpr_count_instances,
    },
    tasks: {
      get: gdpr_get_tasks,
      count: gdpr_count_tasks,
    }
  }
}


function getGeneralDPA() {
  return axios.get('/api/gdpr/getGeneral');
}

/**
 * @param {object} obj
 */
function saveGeneralDPA(obj) {
  return axios.post('/api/gdpr/save', obj)
}

/** 
* @returns {{
* cats: {default: boolean; code: string; sel: boolean}[],
* infos: {default: boolean; code: string; sel: boolean}[],
* objs: {default: boolean; code: string; sel: boolean}[],
* }} obj
*/
function getCategs() {
  return {
    cats: [
      { default: true, code: 'public', sel: false },
      { default: true, code: 'general', sel: false },
      { default: true, code: 'confidentialGDPR', sel: false },
      { default: true, code: 'confidential', sel: false },
      { default: true, code: 'highlyConfidential', sel: false },
      { default: true, code: 'highlyConfidentialGDPR', sel: false },
    ],
    infos: [
      { default: true, code: 'banking', sel: false },
      { default: true, code: 'contactInfo', sel: false },
      { default: true, code: 'credentials', sel: false },
      { default: true, code: 'creditCard', sel: false },
      { default: true, code: 'dateOfBirth', sel: false },
      { default: true, code: 'financial', sel: false },
      { default: true, code: 'health', sel: false },
      { default: true, code: 'name', sel: false },
      { default: true, code: 'nationalID', sel: false },
      { default: true, code: 'ssn', sel: false },
      { default: true, code: 'other', sel: false },
    ],
    objs: [
      { default: true, code: 'people', sel: false },
      { default: true, code: 'suppliers', sel: false },
      { default: true, code: 'clients', sel: false },
      { default: true, code: 'invoices', sel: false },
      { default: true, code: 'contracts', sel: false },
    ]
  }
}

function getCategsObj() {
  const categs = getCategs();
  return {
    cats: categs.cats.reduce((o, el) => { o[el.code] = el; return o; }, {}),
    infos: categs.infos.reduce((o, el) => { o[el.code] = el; return o; }, {}),
    objs: categs.objs.reduce((o, el) => { o[el.code] = el; return o; }, {}),
  }
}

/**
 * @param {string} categ
 */
function getCategory(categ) {
  const categs = getCategsObj();
  if (!Object.keys(categs).includes(categ)) return [];
  return JSON.parse(JSON.stringify(categs[categ]));
}

/**
 * @param {{ gdpr: { gdpr: { cat: any[]; info: any[]; obj: any[] }; }[]; }} gdpr
 */
function checkGeneralDPA(gdpr) {
  const cats = gdpr?.gdpr?.[0]?.gdpr?.cat || [];
  const infos = gdpr?.gdpr?.[0]?.gdpr?.info || [];
  const objs = gdpr?.gdpr?.[0]?.gdpr?.obj || [];
  return !(cats.length === 0 && infos.length === 0 && objs.length === 0)
}

/**
 * @param {any} obj
 */
function save(obj) {
  return axios.post('/api/gdpr/save', obj);
}

/**
 * @param {any} paId
 */
function get(paId) {
  return axios.get('/api/gdpr/find', { params: { paId } });
}

function getReport() {
  return axios.get('/api/gdpr/reports');
}


/**
 * @param {string[]} vars
 * @param {any} query
 */
function gdpr_get_instances(vars, query) {
  return axios.post('/api/gdpr/instances', { vars }, { params: query })
}

/**
 * @param {string[]} vars
 * @param {any} query
 */
function gdpr_count_instances(vars, query) {
  return axios.post('/api/gdpr/instances', { vars }, { params: { ...query, countDoc: true } })
}

/**
 * @param {string[]} vars
 * @param {any} query
 */
function gdpr_get_tasks(vars, query) {
  return axios.post('/api/gdpr/tasks', { vars }, { params: query })
}

/**
 * @param {string[]} vars
 * @param {any} query
 */
function gdpr_count_tasks(vars, query) {
  return axios.post('/api/gdpr/tasks', { vars }, { params: { ...query, countDoc: true } })
}
