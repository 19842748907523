<script>
  import { currentLanguage, t, tNow } from "../../services/i18n.service";
  import UtilPagination from "../utils/util-pagination.svelte";
  import UtilSearch from "../utils/util-search.svelte";
  import InstanceCheckInstanceButtons from "./instance-check-instance-buttons.svelte";
  import instanceService from "../instance/instance.service";
  import {
    InstanceCheckCurrentInstanceStore,
    instanceCheckRefreshInstancesStore,
  } from "./instance-check.stores";
  import { get } from "svelte/store";
  import { isObject } from "underscore/underscore-esm";
  import { onMount } from "svelte";
  import storageService from "../../services/storage.service";
  import UtilSort from "../utils/util-sort.svelte";
  import FieldDateFormated from "../utils/field-date-formated.svelte";

  let pinnedItems = [];
  let items = [];
  let itemsNo = 0;
  let query = {
    sort: "-created_at",
    limit: 5, //constsService.QUERY_PAGE_LIMIT,
    skip: 0,
    longRunning: true,
  };
  const queryInit = JSON.stringify(query);
  let instanceCheckRefreshInstancesStoreUnsubscribe;
  let isloading = false;

  // check if there is saved query
  const savedQuery = storageService.data.get("inst-check-query");
  if (savedQuery) query = savedQuery;
  // check if there is saved current instance:
  const savedCurrentInstance = storageService.data.get("inst-check-currinst");
  if (savedCurrentInstance)
    InstanceCheckCurrentInstanceStore.set(savedCurrentInstance);

  onMount(() => {
    instanceCheckRefreshInstancesStoreUnsubscribe =
      instanceCheckRefreshInstancesStore.subscribe(() => {
        search();
      });

    search();
  });

  function search() {
    getItems();
    countItems();
  }

  function getItems() {
    isloading = true;
    // save query:
    storageService.data.save("inst-check-query", query);
    instanceService
      .get(query)
      .then((res) => {
        items = res.data;
        isloading = false;
        checkPinned();
      })
      .catch((err) => {
        console.error(err);
        isloading = false;
      });
  }

  function countItems() {
    instanceService
      .count(query)
      .then((res) => (itemsNo = res.data[0]))
      .catch((err) => console.error(err));
  }

  /**
   * @param {{ status: string; }} item
   */
  function statusPath(item) {
    return tNow("instCheck." + item.status);
  }

  /**
   * @param {{ status: any; }} item
   */
  function statusClass(item) {
    let className = "";
    switch (item.status) {
      case "active":
        className = "text-success fw-bold";
        break;
      case "closed":
      case "terminated":
        className = "text-secondary fw-bold";
        break;
      case "failed":
        className = "text-danger fw-bold";
        break;
      case "paused":
        className = "text-warning fw-bold";
        break;
      default:
        break;
    }
    return className;
  }

  /**
   * @param {any} item
   */
  function togglePin(item) {
    // FIXME: fix changing items when remove item from pinned list
    // console.log(
    //   "... tables 1",
    //   JSON.parse(JSON.stringify(pinnedItems)),
    //   JSON.parse(JSON.stringify(items))
    // );
    const ids = pinnedItems.map((el) => el._id);
    // console.log("ids", ids);
    const indexId = ids.indexOf(item._id);
    // console.log("indexId", indexId);
    if (indexId > -1) {
      // pinnedItems.splice(indexId, 1);
      const filtered = pinnedItems.filter((_it, ix) => ix !== indexId);
      pinnedItems = [...filtered];
      // pinnedItems = pinnedItems.filter((_it, ix) => ix !== indexId);
    } else {
      pinnedItems = [...pinnedItems, JSON.parse(JSON.stringify(item))];
    }
    // console.log(
    //   "... tables 2",
    //   JSON.parse(JSON.stringify(pinnedItems)),
    //   JSON.parse(JSON.stringify(items))
    // );
    checkPinned();
  }

  function checkPinned() {
    const ids = pinnedItems.map((el) => el._id);
    items.forEach((item) => {
      if (ids.includes(item._id)) {
        item.pinned = true;
      } else {
        item.pinned = undefined;
      }
    });
    items = items;
  }

  /**
   * @param {any} item
   * @param {number} [rowIndex]
   */
  function rowClicked(item, rowIndex) {
    InstanceCheckCurrentInstanceStore.set(null);
    // save current instance:
    storageService.data.save("inst-check-currinst", item?._id);
    updateInstance(item).then((item) => {
      items[rowIndex] = item;
      InstanceCheckCurrentInstanceStore.update(() => item?._id);
    });
  }

  function selectedRow(item, rowIndex) {
    if (get(InstanceCheckCurrentInstanceStore) === item._id) return true;
    else return false;
  }

  /**
   * @param {{ _id: any; status: any; hasError: any; error: any; name: any; }} item
   */
  function updateInstance(item) {
    return instanceService
      .getOne(item._id)
      .then((res) => {
        item._id = res.data._id;
        item.status = res.data.status;
        item.hasError = res.data.hasError;
        item.error = res.data.error;
        item.name = res.data.name;
        return item;
      })
      .catch((err) => console.error(err));
  }

  /**
   * @param {{ name: string; subject_i18n: { [x: string]: any; }; }} item
   */
  function getInstanceSubject(item) {
    let result = item.name;
    if (isObject(item?.subject_i18n)) {
      if (item?.subject_i18n[$currentLanguage]) {
        result = item.subject_i18n[$currentLanguage];
      }
    }
    return result;
  }
</script>

<div class="card border-primary mb-3">
  <div class="card-header bg-primary text-white">{$t("instCheck.title")}</div>
  <div class="card-body">
    <UtilSearch
      bind:query
      queryFn={search}
      {queryInit}
      fields={[
        {
          lbl: $t("generic.name"),
          ph: $t("generic.searchFor"),
          path: "q",
          primary: true,
          secondary: true,
        },
        {
          lbl: $t("generic.status"),
          ph: $t("instCheck.allInstances"),
          path: "status",
          primary: true,
          secondary: true,
          type: "select",
          translate: false,
          withoutPlaceholder: true,
          list: [
            { key: undefined, value: $t("instCheck.allInstances") },
            { key: "active", value: $t("instCheck.active") },
            { key: "failed", value: $t("instCheck.failed") },
            { key: "paused", value: $t("instCheck.paused") },
            { key: "terminated", value: $t("instCheck.terminated") },
            { key: "closed", value: $t("instCheck.closed") },
          ],
        },
        {
          lbl: $t("generic.type"),
          ph: $t("instCheck.both"),
          path: "longRunning",
          primary: true,
          secondary: true,
          type: "select",
          translate: false,
          withoutPlaceholder: true,
          list: [
            { key: true, value: $t("instCheck.longRunning") },
            { key: false, value: $t("instCheck.shortRunning") },
            { key: undefined, value: $t("instCheck.both") },
          ],
        },
      ]}
    />

    <div class="text-end">
      <UtilSort
        setts={{ paths: ["created_at", "status"] }}
        bind:query
        queryFn={getItems}
      />
    </div>
    <ul class="list-group border-0 mb-3">
      {#if isloading}
        {#each new Array(query.limit) as _item}
          <li class="list-group-item placeholder-glow">
            <h5 class="placeholder bg-secondary w-50">&nbsp;</h5>
            <span class="placeholder bg-secondary w-100" />
          </li>
        {/each}
      {:else}
        {#each items as item}
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
          <li
            class="list-group-item selectable-item"
            class:selected-item={$InstanceCheckCurrentInstanceStore ===
              item?._id}
            on:click={() => {
              rowClicked(item);
            }}
          >
            <h5 class="d-flex" style="margin:0px">
              <span>
                {#if item?.pino}
                  #{item.pino}
                {/if}
                {item.name}
              </span>
              <svelte:component
                this={InstanceCheckInstanceButtons}
                {item}
                props={{ refresh: search, togglePin }}
              />
            </h5>
            <div>
              <span class="tsk-lbl">{$t("generic.created")}:</span>
              <span class="tsk-val">
                <FieldDateFormated date={item.created_at} />
              </span>
              <span class="tsk-lbl">{$t("generic.updated")}:</span>
              <span class="tsk-val">
                <FieldDateFormated date={item.updated_at} />
              </span>
              <span class="tsk-lbl">{$t("instCheck.closed")}:</span>
              <span class="tsk-val">
                <FieldDateFormated date={item.closed_at} />
              </span>
              <span class="tsk-lbl">{$t("generic.status")}:</span>
              <span class="tsk-val {statusClass(item)}">
                {$t(statusPath(item))}
              </span>
              <span class="tsk-lbl">{$t("generic.user")}:</span>
              <span class="tsk-val">
                {item.user || "-"}
              </span>
            </div>
          </li>
        {:else}
          <li class="list-group-item text-muted">
            {$t("msgs.msgNoDataAvailable")}
          </li>
        {/each}
      {/if}
    </ul>

    <UtilPagination
      bind:query
      bind:totalData={itemsNo}
      on:callData={getItems}
    />
  </div>
</div>

{#if pinnedItems.length > 0}
  <div class="card border-warning mb-3">
    <div class="card-header bg-warning text-white">
      {$t("instCheck.pinnedInstances")}
    </div>
    <div class="card-body">
      <ul class="list-group border-0 mb-3">
        {#each pinnedItems as item}
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
          <li
            class="list-group-item selectable-item"
            class:selected-item={$InstanceCheckCurrentInstanceStore ===
              item?._id}
            on:click={() => {
              rowClicked(item);
            }}
          >
            <h5 class="d-flex" style="margin:0px">
              <span>
                {#if item?.pino}
                  #{item.pino}
                {/if}
                {item.name}
              </span>
              <svelte:component
                this={InstanceCheckInstanceButtons}
                {item}
                props={{ refresh: search, togglePin }}
              />
            </h5>
            <div>
              <span class="tsk-lbl">{$t("generic.created")}:</span>
              <span class="tsk-val">
                <FieldDateFormated date={item.created_at} />
              </span>
              <span class="tsk-lbl">{$t("generic.updated")}:</span>
              <span class="tsk-val">
                <FieldDateFormated date={item.updated_at} />
              </span>
              <span class="tsk-lbl">{$t("instCheck.closed")}:</span>
              <span class="tsk-val">
                <FieldDateFormated date={item.closed_at} />
              </span>
              <span class="tsk-lbl">{$t("generic.status")}:</span>
              <span class="tsk-val {statusClass(item)}">
                {$t(statusPath(item))}
              </span>
              <span class="tsk-lbl">{$t("generic.user")}:</span>
              <span class="tsk-val">
                {item.user || "-"}
              </span>
            </div>
          </li>
        {:else}
          <li class="list-group-item text-muted">
            {$t("msgs.msgNoDataAvailable")}
          </li>
        {/each}
      </ul>
    </div>
  </div>
{/if}

<style>
  .tsk-lbl {
    font-size: 0.8rem;
  }
  .tsk-val {
    font-size: 0.8rem;
    border-bottom: 1px dotted gray;
    margin-right: 20px;
  }
</style>
