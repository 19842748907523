import { writable } from 'svelte/store';

export let InstanceCheckCurrentInstanceStore = writable();


function createInstanceCheckRefreshInstance() {
  const { subscribe, set } = writable();
  return {
    subscribe,
    refresh: () => {
      set(new Date())
    }
  }
}
export const instanceCheckRefreshInstancesStore = createInstanceCheckRefreshInstance();

