<script>
  import { onDestroy, onMount } from "svelte";
  import TaskExeTplI18nLabel from "./task-exe-tpl-i18n-label.svelte";
  import {
    substores,
    taskExeBusinessObject,
    taskExeErrorStore,
    taskExeStore,
  } from "../task-exe-store";
  import taskExeApi from "../task-exe.api";
  import apiService from "../../../services/api.service";
  import { get } from "svelte/store";
  import { isLoading } from "../../../stores";
  import taskExeErrorAndVisibilityService from "../task-exe-error-and-visibility.service";
  import { currentLanguage } from "../../../services/i18n.service";
  import taskExeVisibilityTree from "../task-exe-visibility-tree";

  /** @type {{id: string;}|undefined} */
  export let item = undefined;
  export let props = {};
  /** @type {{id: string; type: string;}[]} */
  export let parent = undefined;
  export let visibilityFromParent = "editable";
  /** @type {{id: string; tplId: string;}|null} */
  export let templateProps = null;

  let hasError = false;
  let showHelp = false;
  let value;
  let myVisibility;
  let dynamicBoStore;
  let taskExeBusinessObjectUnsubscribe;
  let taskExeErrorStoreUnsubscribe;
  const pathToStore = parent
    .filter((el) => el.type === "tpl-preview")
    .map((el) => el.id)
    .join("_");
  const pathToStoreNamed = pathToStore ? pathToStore : "general";
  const widgetId = pathToStore ? pathToStore + "_" + item.id : item.id;

  let visibilityStoreUnsubscribe;
  const visibilityStore = taskExeVisibilityTree.checkVisibilityStore(
    item,
    parent,
  );
  if (visibilityStore) {
    visibilityStoreUnsubscribe = visibilityStore.subscribe(
      (/** @type {string} */ res) => {
        myVisibility = res;
      },
    );
  }

  $: checkVisWithParent(myVisibility, visibilityFromParent); 

  function checkVisWithParent(_myVisibility, _visibilityFromParent) {
    myVisibility = taskExeVisibilityTree.checkVisOfElementWithParent(_visibilityFromParent, _myVisibility, props)
  }

  onMount(() => {
    props.isFirstValidation = true;

    if (pathToStore) {
      if (substores.bos?.[pathToStore]?.data) {
        dynamicBoStore = substores.bos[pathToStore].data;
      } else {
        dynamicBoStore = taskExeBusinessObject;
      }
    } else {
      dynamicBoStore = taskExeBusinessObject;
    }

    const firstValue = apiService.getNestedFromPath(
      get(dynamicBoStore),
      props.varName,
    );
    dynamicBoStore.ensureValue(props.varName, firstValue, [pathToStoreNamed]);

    taskExeErrorStoreUnsubscribe = taskExeErrorStore.subscribe(() => {
      if (props.isFirstValidation === true) return;
      const resultErrVars =
        taskExeErrorAndVisibilityService.getErrorVars(props);
      hasError = resultErrVars.hasError;
      showHelp = resultErrVars.showHelp;
    });

    taskExeBusinessObjectUnsubscribe = dynamicBoStore.subscribe((bo) => {
      if (!$taskExeStore) return;
      const newTempValue = apiService.getNestedFromPath(bo, props.varName);
      if (value !== newTempValue) value = newTempValue;
    });

    setTimeout(() => {
      props.isFirstValidation = false;
    }, 100);
  });

  onDestroy(() => {
    taskExeBusinessObjectUnsubscribe();
    taskExeErrorStoreUnsubscribe();
    if (visibilityStoreUnsubscribe) visibilityStoreUnsubscribe();
  });

  function valueChanged() {
    dynamicBoStore.ensureValue(props.varName, value, [pathToStoreNamed]);
    taskExeErrorAndVisibilityService.checkValidation(props);
    taskExeApi.submit({
      id: item.id,
      boStore: dynamicBoStore,
      tplId: templateProps?.id,
    });
  }
</script>

{#if myVisibility !== "hidden"}
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div id={item.id}>
    <label class="form-label d-block" for="ta_{widgetId}">
      <TaskExeTplI18nLabel bind:props />{props.required ? "*" : ""}
    </label>
    <textarea
      id="ta_{widgetId}"
      class="form-control {props.class || ''}"
      class:is-invalid={hasError}
      class:mb-3={!showHelp}
      style={props.style}
      placeholder={props.placeholder_i18n
        ? props.placeholder_i18n[$currentLanguage]
        : props.placeholder}
      aria-labelledby={props.label_i18n
        ? props.label_i18n[$currentLanguage]
        : props.label}
      bind:value
      on:change={valueChanged}
      disabled={myVisibility === "disabled" || $isLoading}
    />

    {#if showHelp}
      <div id={props.id + "-HelpBlock"} class="invalid-feedback mb-3">
        {props?.validationMsg_i18n?.[$currentLanguage]
          ? props.validationMsg_i18n[$currentLanguage]
          : props.validationMsg}
      </div>
    {/if}
  </div>
{/if}
