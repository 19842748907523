<script>
  import { t, tNow } from "../../services/i18n.service";

  export let reportResult = undefined;
</script>

{#if reportResult?.gdpr}
  <div class="card">
    <div class="card-header bg-primary text-white">
      {$t("generic.variables")}
    </div>
    <div class="card-body">
      <ul class="list-group view-height">
        {#each Object.entries(reportResult.gdpr) as [key, value]}
          <li class="list-group-item">
            <div>
              <span class="text-muted">
                {$t("generic.variable")}
              </span>
              <code> {key} </code>
              <br />
              <div>
                <span class="text-muted">
                  {$t("generic.categories")}:
                </span>
                <span>
                  {Object.keys(value.cat)
                    .map((el) =>
                      tNow(`gdpr.${el}.lbl`) === `gdpr.${el}.lbl`
                        ? el
                        : tNow(`gdpr.${el}.lbl`)
                    )
                    .join(", ") || "-"}
                </span>
              </div>
              <div>
                <span class="text-muted">{$t("generic.infoType")}:</span>
                <span>
                  {Object.keys(value.info)
                    .map((el) =>
                      tNow(`gdpr.${el}.lbl`) === `gdpr.${el}.lbl`
                        ? el
                        : tNow(`gdpr.${el}.lbl`)
                    )
                    .join(", ") || "-"}
                </span>
              </div>
              <div>
                <span class="text-muted">{$t("generic.objType")}:</span>
                <span>
                  {Object.keys(value.obj)
                    .map((el) =>
                      tNow(`gdpr.${el}.lbl`) === `gdpr.${el}.lbl`
                        ? el
                        : tNow(`gdpr.${el}.lbl`)
                    )
                    .join(", ") || "-"}
                </span>
              </div>
            </div>
          </li>
        {/each}
      </ul>
    </div>
  </div>
{/if}

<style>
  .view-height {
    max-height: 600px;
    overflow-y: scroll;
  }
</style>
