<script>
  import { push } from "svelte-spa-router";
  import FieldInput from "../components/utils/field-input.svelte";
  import authService from "../services/auth.service";
  import constsService from "../services/consts.service";
  import { t } from "../services/i18n.service";
  import storageService from "../services/storage.service";
  import { userStore } from "../stores";
  import { onMount } from "svelte";

  let verificationCode;
  let email;

  onMount(() => {
    email = storageService.get("email");
    // console.log("mount activate", email);
  });

  function activate() {
    const data = {
      email,
      verificationCode,
      fromForm: true,
    };
    authService.activate(data, (err, res) => {
      if (err) return;
      push("/");
    });
  }
</script>

<div class="row">
  <div class="col-md-3" />

  <div class="col-md-6">
    <div class="card">
      <div class="card-body">
        <div>
          <h4 class="text-primary">
            {$t("activateAcc.title")}
          </h4>
        </div>
        <div>
          <p>{$t("activateAcc.desc")}</p>

          <FieldInput
            placeholder={$t("activateAcc.phCode")}
            bind:value={verificationCode}
          />

          <div class="d-grid gap-2">
            <button
              class="btn btn-primary"
              on:click={() => {
                activate();
              }}
            >
              {$t("activateAcc.btnActivate")}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-3" />
</div>
