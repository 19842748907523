import { wrap } from 'svelte-spa-router/wrap';
// import { push } from 'svelte-spa-router';
import { isAuth } from './stores';
// import authService from './services/auth';
import { get } from 'svelte/store';
// import { toasts } from 'svelte-toasts';
import Auth from './routes/auth/auth.svelte';
import Home from "./routes/home.svelte";
// import ProcessBuckets from "./routes/process-buckets.svelte";
// import Share from "./routes/share.svelte";
import Connection from "./routes/connection.svelte";
import Log from "./routes/log.svelte";
import Dms from "./routes/dms.svelte";
import Schedule from "./routes/schedule.svelte";
import Gdpr from "./routes/gdpr.svelte";
// import AccountSettings from "./routes/account-settings.svelte";
// import Market from "./routes/market.svelte";
// import Audit from "./routes/audit.svelte";
import InstanceCheck from "./routes/instance-check.svelte";
// import Designer from "./routes/designer.svelte";
import Diagram from "./routes/diagram.svelte";
import Task from "./routes/task.svelte";
// import Compare from './routes/compare.svelte'
// import Documentation from './routes/documentation.svelte'
import Profile from './routes/profile.svelte'
import Dashboards from './routes/dashboards.svelte'
import AppManagement from './routes/app-management.svelte'
import Roles from './routes/roles.svelte'
import Activate from './routes/activate.svelte'
import Social from './routes/social.svelte'
import AccountConfirmation from './routes/accountconfirmation.svelte'
import ForgotPassword from './routes/forgotPassword.svelte'
import Site from './routes/site.svelte'


let routesAuth = {
  '/': packRoute(Home, true),
  '/login': packRoute(Auth, false),
  '/activate': packRoute(Activate, false),
  '/social/:tkn': packRoute(Social, false),
  '/accountconfirmation': packRoute(AccountConfirmation, false),
  '/forgotPassword': packRoute(ForgotPassword, false),
  // '/processBuckets': packRoute(ProcessBuckets, true),
  // '/share': packRoute(Share, true),
  '/connection': packRoute(Connection, true),
  '/log': packRoute(Log, true),
  '/dms': packRoute(Dms, true),
  '/schedules': packRoute(Schedule, true),
  '/gdpr': packRoute(Gdpr, true),
  // '/market': packRoute(Market, true),
  // '/accountSettings': packRoute(AccountSettings, true),
  // '/audit': packRoute(Audit, true),
  '/instancecheck': packRoute(InstanceCheck, true),
  // '/designer/:pbid': packRoute(Designer, true),
  '/diagram/:pid': packRoute(Diagram, true),
  '/task/:tid': packRoute(Task, true),
  // '/sites/:tid': packRoute(Home, true),
  '/sites/:tid': packRoute(Site, true),
  '/roles': packRoute(Roles, true),
  // '/compare/:type/:ids': packRoute(Compare, true),
  // '/documentation/:obj/:id': packRoute(Documentation, true),
  '/profile': packRoute(Profile, true),
  '/dashboards': packRoute(Dashboards, true),
  '/apps': packRoute(AppManagement, true),
  '*': packRoute(Home, true)
}

let routesUnAuth = {
  '/login': Auth,
  '/activate': Activate,
  '/social/*': Social,
  '/accountconfirmation': AccountConfirmation,
  '/forgotPassword': ForgotPassword,
  '*': Auth
}

const routesOnlyForUnauth = [
  '/login',
  '/activate',
  '/social',
  '/accountconfirmation',
  '/forgotPassword',
]


/**
 * @param {any} _event
 */
function conditionsFailed(_event) {
  // const canContinue = get(isAuth);
  // if (!canContinue) {
  //   push('/login');
  //   return;
  // }
}

/**
 * @param {typeof Auth} component
 * @param {boolean} isProtected
 */
function packRoute(component, isProtected) {
  return wrap({
    component: component,
    userData: { isProtected },
    conditions: [
      (/** @type {any} */ detail) => {
        if (detail?.userData?.isProtected) {
          const isAuthed = get(isAuth);
          return isAuthed
        } else {
          return true;
        }
      }
    ]
  })
}

export default { routesAuth, routesUnAuth, conditionsFailed, routesOnlyForUnauth }

