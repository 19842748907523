<script>
  import { onDestroy, onMount } from "svelte";
  import { currentLanguage, t } from "../../../services/i18n.service";
  import TaskExeTplI18nLabel from "./task-exe-tpl-i18n-label.svelte";
  import {
    substores,
    taskExeBusinessObject,
    taskExeErrorStore,
    taskExeStore,
  } from "../task-exe-store";
  import taskExeApi from "../task-exe.api";
  import { isLoading } from "../../../stores";
  import apiService from "../../../services/api.service";
  import { get } from "svelte/store";
  import taskExeErrorAndVisibilityService from "../task-exe-error-and-visibility.service";
  import taskExeVisibilityTree from "../task-exe-visibility-tree";

  /** @type {{id: string;}|undefined} */
  export let item = undefined;
  export let props = {};
  /** @type {{id: string; type: string;}[]} */
  export let parent = undefined;
  export let visibilityFromParent = "editable";
  /** @type {{id: string; tplId: string;}|null} */
  export let templateProps = null;

  let hasError = false;
  let showHelp = false;
  let value;
  let myVisibility;
  let dynamicBoStore;
  let source = [];
  let taskExeBusinessObjectUnsubscribe;
  let taskExeErrorStoreUnsubscribe;
  const pathToStore = parent
    .filter((el) => el.type === "tpl-preview")
    .map((el) => el.id)
    .join("_");
  const pathToStoreNamed = pathToStore ? pathToStore : "general";

  let visibilityStoreUnsubscribe;
  const visibilityStore = taskExeVisibilityTree.checkVisibilityStore(
    item,
    parent,
  );
  if (visibilityStore) {
    visibilityStoreUnsubscribe = visibilityStore.subscribe(
      (/** @type {string} */ res) => {
        myVisibility = res;
      },
    );
  }

  $: checkVisWithParent(myVisibility, visibilityFromParent); 

  function checkVisWithParent(_myVisibility, _visibilityFromParent) {
    myVisibility = taskExeVisibilityTree.checkVisOfElementWithParent(_visibilityFromParent, _myVisibility, props)
  }

  onMount(() => {
    props.isFirstValidation = true;

    if (pathToStore) {
      if (substores.bos?.[pathToStore]?.data) {
        dynamicBoStore = substores.bos[pathToStore].data;
      } else {
        dynamicBoStore = taskExeBusinessObject;
      }
    } else {
      dynamicBoStore = taskExeBusinessObject;
    }

    const firstValue = apiService.getNestedFromPath(
      get(dynamicBoStore),
      props.varName,
    );
    dynamicBoStore.ensureValue(props.varName, firstValue, [pathToStoreNamed]);

    if (props.arrMultiple) {
      if (props.arrMultiple_i18n) {
        source = props.arrMultiple_i18n[$currentLanguage].split(",") || [];
      } else {
        source = props.arrMultiple.split(",") || [];
      }
    } else {
      source =
        apiService.getNestedFromPath(get(dynamicBoStore), props.dataSource) ||
        [];
    }
    dynamicBoStore.ensureValue(props.dataSource, source);

    taskExeErrorStoreUnsubscribe = taskExeErrorStore.subscribe(() => {
      if (props.isFirstValidation === true) return;
      const resultErrVars =
        taskExeErrorAndVisibilityService.getErrorVars(props);
      hasError = resultErrVars.hasError;
      showHelp = resultErrVars.showHelp;
    });

    taskExeBusinessObjectUnsubscribe = dynamicBoStore.subscribe((bo) => {
      if (!$taskExeStore) return;
      // NOTE: It seems this doesn't work on select - the main ideea
      // was to not enter in a cycle when changing the BOs
      // const newTempValue = apiService.getNestedFromPath(bo, props.varName);
      // console.log('>>> new temp value', props.id, newTempValue);
      // console.log('>>> new temp value');
      // if (value !== newTempValue) value = !newTempValue;
      value = apiService.getNestedFromPath(bo, props.varName);
      // INFO: check if we can check the source for new value in order to see if we can update it
      source = apiService.getNestedFromPath(bo, props.dataSource) || [];
    });

    setTimeout(() => {
      props.isFirstValidation = false;
    }, 100);
  });

  onDestroy(() => {
    taskExeBusinessObjectUnsubscribe();
    taskExeErrorStoreUnsubscribe();
    if (visibilityStoreUnsubscribe) visibilityStoreUnsubscribe();
  });

  function valueChanged() {
    dynamicBoStore.ensureValue(props.varName, value, [pathToStoreNamed]);
    taskExeErrorAndVisibilityService.checkValidation(props);
    taskExeApi.submit({
      id: item.id,
      boStore: dynamicBoStore,
      tplId: templateProps?.id,
    });
  }
</script>

{#if myVisibility !== "hidden"}
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div id={item.id} class="mb-3">
    <!-- svelte-ignore a11y-label-has-associated-control -->
    <label class="form-label d-block">
      <TaskExeTplI18nLabel bind:props />{props.required ? "*" : ""}
    </label>
    <select
      id="select_{item.id}"
      class="form-select {props.class || ''}"
      class:is-invalid={hasError}
      style={props.style}
      aria-labelledby={props.label_i18n
        ? props.label_i18n[$currentLanguage]
        : props.label}
      bind:value
      on:change={valueChanged}
      disabled={myVisibility === "disabled" || $isLoading}
    >
      <option value={undefined}
        >{props.placeholder_i18n
          ? props.placeholder_i18n[$currentLanguage]
          : props.placeholder || $t("generic.select")}</option
      >
      {#each source as opt}
        <option value={opt.value ? opt.value : opt}
          >{opt.name ? opt.name : opt}</option
        >
      {/each}
    </select>

    {#if showHelp}
      <div id={props.id + "-HelpBlock"} class="invalid-feedback">
        {props?.validationMsg_i18n?.[$currentLanguage]
          ? props.validationMsg_i18n[$currentLanguage]
          : props.validationMsg}
      </div>
    {/if}
  </div>
{/if}
