import axios from "axios"

export default {
  getOne,
  getShort,
  save,
  addAllUsersToRole,
  remAllUsersFromRole,
  addUsersToRole,
  removeUsersFromRole,
  remove,
  getAll,
}

/**
 * @param {any} id
 * @param {any} [query]
 */
function getOne(id, query) {
  return axios.get(`/api/rest/bo/role/${id}`, { params: query })
}
/**
 * @param {any} query
 */
function getShort(query) {
  return axios.get('/api/rest/bo/role', { params: { ...query, qt: 'short' } });
}

/**
 * @param {any} obj
 */
function save(obj) {
  return axios.post('/api/rest/bo/role' + (obj?._id ? '/' + obj._id : ''), obj);
}

/**
 * @param {string} roleId
 */
function addAllUsersToRole(roleId) {
  return axios.post('/api/bpm/addAllUsersToRole', {}, { params: { roleId } });
}

/**
 * @param {string} roleId
 */
function remAllUsersFromRole(roleId) {
  return axios.post('/api/bpm/removeAllUsersFromRole', {}, { params: { roleId } });
}

/**
 * @param {{org: string; role: string; users: string[];}} obj
 */
function addUsersToRole(obj) {
  return axios.post('/api/bpm/addUsersToRole', obj);
}

/**
 * @param {{org: string; role: string; users: string[];}} obj
 */
function removeUsersFromRole(obj) {
  return axios.post('/api/bpm/removeUsersFromRole', obj);
}

/**
 * @param {string} roleId
 */
function remove(roleId) {
  return axios.delete('/api/rest/bo/role/' + roleId);
}

/**
 * @param {string} orgId
 * @param {any} [query]
 */
function getAll(orgId, query) {
  if (!query) query = {};
  return axios.get('/api/rest/bo/role', { params: { orgId, ...query } });
}
