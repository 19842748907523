<script>
  import { onDestroy, onMount } from "svelte";
  import {
    substores,
    taskExeBusinessObject,
    taskExeErrorStore,
    taskExeStore,
  } from "../task-exe-store";
  import TaskExeTplI18nLabel from "./task-exe-tpl-i18n-label.svelte";
  import taskExeApi from "../task-exe.api";
  import { isLoading } from "../../../stores";
  import apiService from "../../../services/api.service";
  import { get } from "svelte/store";
  import taskExeErrorAndVisibilityService from "../task-exe-error-and-visibility.service";
  import { currentLanguage } from "../../../services/i18n.service";
  import taskExeVisibilityTree from "../task-exe-visibility-tree";

  /** @type {{id: string;}|undefined} */
  export let item = undefined;
  export let props = {};
  /** @type {{id: string; type: string;}[]} */
  export let parent = undefined;
  export let visibilityFromParent = "editable";
  /** @type {{id: string; tplId: string;}|null} */
  export let templateProps = null;

  let hasError = false;
  let showHelp = false;
  let value;
  let myVisibility;
  let dynamicBoStore;
  let taskExeBusinessObjectUnsubscribe;
  let taskExeErrorStoreUnsubscribe;
  const pathToStore = parent
    .filter((el) => el.type === "tpl-preview")
    .map((el) => el.id)
    .join("_");
  const pathToStoreNamed = pathToStore ? pathToStore : "general";
  const widgetId = `tplckbx_${
    pathToStore ? pathToStore + "_" + item.id : item.id
  }`;

  let visibilityStoreUnsubscribe;
  const visibilityStore = taskExeVisibilityTree.checkVisibilityStore(
    item,
    parent,
  );
  if (visibilityStore) {
    visibilityStoreUnsubscribe = visibilityStore.subscribe(
      (/** @type {string} */ res) => {
        myVisibility = res;
      },
    );
  }

  $: checkVisWithParent(myVisibility, visibilityFromParent); 

  function checkVisWithParent(_myVisibility, _visibilityFromParent) {
    myVisibility = taskExeVisibilityTree.checkVisOfElementWithParent(_visibilityFromParent, _myVisibility, props)
  }

  onMount(() => {
    props.isFirstValidation = true;

    if (pathToStore) {
      if (substores.bos?.[pathToStore]?.data) {
        dynamicBoStore = substores.bos[pathToStore].data;
      } else {
        dynamicBoStore = taskExeBusinessObject;
      }
    } else {
      dynamicBoStore = taskExeBusinessObject;
    }

    const firstValue = apiService.getNestedFromPath(
      get(dynamicBoStore),
      props.varName,
    );
    dynamicBoStore.ensureValue(props.varName, firstValue, [pathToStoreNamed]);

    taskExeErrorStoreUnsubscribe = taskExeErrorStore.subscribe(() => {
      if (props.isFirstValidation === true) return;
      const resultErrVars =
        taskExeErrorAndVisibilityService.getErrorVars(props);
      hasError = resultErrVars.hasError;
      showHelp = resultErrVars.showHelp;
    });

    taskExeBusinessObjectUnsubscribe = dynamicBoStore.subscribe((bo) => {
      if (!$taskExeStore) return;
      const newTempValue = apiService.getNestedFromPath(bo, props.varName);
      if (value !== newTempValue) value = newTempValue;
    });

    setTimeout(() => {
      props.isFirstValidation = false;
    }, 100);
  });

  onDestroy(() => {
    taskExeBusinessObjectUnsubscribe();
    taskExeErrorStoreUnsubscribe();
    if (visibilityStoreUnsubscribe) visibilityStoreUnsubscribe();
  });

  function valueChanged() {
    dynamicBoStore.ensureValue(props.varName, value, [pathToStoreNamed]);
    taskExeErrorAndVisibilityService.checkValidation(props);
    taskExeApi.submit({
      id: item.id,
      boStore: dynamicBoStore,
      tplId: templateProps?.id,
    });
  }
</script>

{#if myVisibility !== "hidden"}
  {#if !props.arrMultiple}
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <div id={item.id} class="form-check mb-3">
      <input
        id={widgetId}
        class="form-check-input {props.class || ''}"
        class:is-invalid={hasError}
        style={props.style}
        type="checkbox"
        aria-labelledby={props.label_i18n
          ? props.label_i18n[$currentLanguage]
          : props.label}
        bind:checked={value}
        on:change={valueChanged}
        disabled={myVisibility === "disabled" || $isLoading}
      />
      <label class="form-check-label" for={widgetId}>
        <TaskExeTplI18nLabel bind:props />{props.required ? "*" : ""}
      </label>

      {#if showHelp}
        <div id={props.id + "-HelpBlock"} class="invalid-feedback">
          {props?.validationMsg_i18n?.[$currentLanguage]
            ? props.validationMsg_i18n[$currentLanguage]
            : props.validationMsg}
        </div>
      {/if}
    </div>
  {:else}
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <div id={item.id} class="mb-3">
      <!-- svelte-ignore a11y-label-has-associated-control -->
      <label class="form-label form-check-label">
        <TaskExeTplI18nLabel bind:props />{props.required ? "*" : ""}
      </label>
      <br />
      {#each props?.arrMultiple?.split(",") as _checkField, index}
        <div class="form-check" class:form-check-inline={props.isInline}>
          <input
            id="{widgetId}_{index}"
            class="form-check-input {props.class || ''}"
            class:is-invalid={hasError}
            style={props.style}
            type="checkbox"
            aria-labelledby={props.label_i18n
              ? props.label_i18n[$currentLanguage]
              : props.label}
            value={_checkField}
            bind:group={value}
            on:change={valueChanged}
            disabled={myVisibility === "disabled" || $isLoading}
          />
          <label class="form-check-label" for="{widgetId}_{index}">
            <TaskExeTplI18nLabel bind:props varProp="arrMultiple" {index} />
          </label>
        </div>
      {/each}

      {#if showHelp}
        <div id={props.id + "-HelpBlock"} class="invalid-feedback d-block">
          {props?.validationMsg_i18n?.[$currentLanguage]
            ? props.validationMsg_i18n[$currentLanguage]
            : props.validationMsg}
        </div>
      {/if}
    </div>
  {/if}
{/if}
