// import './../node_modules/bootstrap/dist/css/bootstrap.min.css'
// import './../node_modules/bootstrap/dist/js/bootstrap.min.js'
// import './../node_modules/@popperjs/core/dist/esm/index'
// import bootstrap from 'bootstrap'
// import './../node_modules/@popperjs/core/lib/index'
import './../node_modules/bootstrap/js/index.esm'
// import popperjs from '@popperjs/core'
// import bootstrap from 'bootstrap';
// import './app.css'
// import "flowbite/dist/flowbite.css";
// import 'popperjs';
import constsService from './services/consts.service';
import axios from 'axios';
axios.defaults.baseURL = constsService.SERVER_PATH;
import './services/interceptor.service';
import './app.scss'
import App from './App.svelte'

// setTimeout(() => {
//   var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
// console.log(tooltipTriggerList)
//   var tooltipList = tooltipTriggerList.map(function(tooltipTriggerEl) {
//     return new bootstrap.Tooltip(tooltipTriggerEl)
//   })
// }, 1500)

// const dropdowns = document.querySelectorAll('.dropdown-toggle')
// const dropdown = [...dropdowns].map((dropdownToggleEl) => new bootstrap.Dropdown(dropdownToggleEl, {
//     popperConfig(defaultBsPopperConfig) {
//         return { ...defaultBsPopperConfig, strategy: 'absolute' };
//     }
// }));
// console.log('...dropdown', dropdown)

const app = new App({
  target: document.getElementById('app'),
})

export default app
