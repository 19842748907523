import axios from "axios"

export default {
  getDashboard,
  getOne,
  assignTask,
  // getShort,
  // save,
  // remove,
  // exportUI,
  // install,
  // saveAsCurrentVersion,
}

/**
 * @param {any} query
 */
function getShort(query) {
  return axios.get('/api/rest/bo/human', { params: { ...query, qt: 'short' } });
}

/**
 * @param {string} id
 * @param {any} [query]
 */
function getOne(id, query) {
  return axios.get(`/api/rest/bo/human/${id}`, { params: query });
}

/**
 * @param {string} id
 */
function getDashboard(id) {
  return axios.get(`/api/bpm/getDashboard`, { params: { did: id } });
}

/**
 * @param {string} id
 */
function assignTask(id) {
  return axios.post(`/api/bpm/assignTask`, undefined, { params: { tid: id } });
}

/**
 * @param {any} obj
 */
function save(obj) {
  return axios.post('/api/rest/bo/human' + (obj._id ? '/' + obj._id : ''), obj);
}

/**
 * @param {string} id
 */
function remove(id) {
  return axios.delete('/api/rest/bo/human/' + id);
}

/**
 * @param {string} id
 * @param {boolean} [vers]
 * @param {boolean} [wo] without stylesheet
 */
function exportUI(id, vers, wo) {
  return axios.post('/api/bpm/exportUi2html', undefined, { params: { hid: id, vers, wo } });
}

/**
 * @param {string} id
 * @param {string} confId
 */
function install(id, confId) {
  return axios.post('/api/bpm/installDashboard', undefined, { params: { hid: id, confId } });
}

/**
 * @param {string} id
 */
function saveAsCurrentVersion(id) {
  return axios.post(`/api/version/saveAsCurrent/human/${id}`)
}


