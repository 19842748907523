<script>
  import { tooltip } from "./../../services/tooltip.service";
  import {
    Diagram3,
    ExclamationTriangle,
    FileDiff,
    Info,
    Pause,
    PinAngle,
    Play,
    Stop,
    X,
  } from "svelte-bootstrap-icons";
  import { push } from "svelte-spa-router";
  import apiService from "../../services/api.service";
  import { t, tNow } from "../../services/i18n.service";
  import UtilModalConfimation from "../utils/util-modal-confimation.svelte";
  import instanceService from "../instance/instance.service";
  import UtilBusinessObject from "../utils/util-business-object.svelte";
  import toasterService from "../../services/toaster.service";
  import { isArray, isNumber } from "underscore/underscore-esm";
  import InstanceCheckDiaMigration from "./instance-check-dia-migration.svelte";

  export let item = {};
  export let props = {};
  let icoHeight = 16;
  let tlPosition = "";
  let tlAnimation = "slide";
  let modalConfirmComponent;
  let modalInstanceMigrationComponent;
  // let can = {};
  let currentCommand;
  let modalBoShow = false;
  let businessObject;
  let modalErrorShow = false;
  let instanceError;

  /**
   * @param {string} command
   */
  function commandInstance(command) {
    currentCommand = command;
    switch (command) {
      case "play":
        playInstance();
        break;
      case "pause":
        pauseInstance();
        break;
      case "stop":
        terminateInstance();
        break;
      case "remove":
        deleteInstance();
        break;
      case "info":
        showBo();
        break;
      case "alert":
        showError();
        break;
      case "diagram":
        push(`/diagram/${item.pid}`);
        break;
      case "migrate":
        showMigrate();
        break;
      default:
        break;
    }
  }

  function playInstance() {
    // if (!can.run) {
    //   apiService.msg.insufficientRights();
    //   return;
    // }
    if (item.hasError && apiService.check.val(item.error)) {
      let err;
      try {
        err = JSON.parse(item.error);
      } catch (error) {
        toasterService.error({
          msg: tNow("instCheck.msgCannotReadDetails"),
        });
        return;
      }
      // let inputSeqs = $parse("context.inputSeqs")(err);
      let inputSeqs = err?.context?.inputSeqs;
      if (isArray(inputSeqs) && inputSeqs.length > 0) {
        if (inputSeqs.length === 1) {
          instanceService
            .run(item._id)
            .then(() => {
              props.refresh();
            })
            .catch((err) => console.error(err));
        } else {
          let seqIndex = prompt(tNow("instCheck.msgPromptSeq"), "0");
          if (
            apiService.check.val(seqIndex) &&
            isNumber(Number(seqIndex)) &&
            Number(seqIndex) >= 0
          ) {
            // search sequence
            let seqToContinue = inputSeqs[Number(seqIndex)];
            if (apiService.check.val(seqToContinue)) {
              instanceService
                .run(item._id, seqToContinue)
                .then(() => {
                  props.refresh();
                })
                .catch((err) => console.error(err));
            } else {
              toasterService.error({
                msg: tNow("instCheck.msgNoStart"),
              });
            }
          }
        }
      } else {
        toasterService.error({ msg: tNow("instCheck.msgNoError") });
      }
    } else if (item.status === "paused") {
      // continue process:
      instanceService
        .run(item._id)
        .then(() => {
          props.refresh();
        })
        .catch((err) => console.error(err));
    } else {
      toasterService.error({ msg: tNow("instCheck.msgNoError") });
    }
  }

  function pauseInstance() {
    // if (!can.pause) {
    //   apiService.msg.insufficientRights();
    //   return;
    // }
    modalConfirmComponent.show({
      modalTitle: $t("instCheck.pause"),
      modalBody: $t("instCheck.msgInstPause"),
      modalBtns: [
        {
          label: $t("generic.cancel"),
          action: "close",
          class: "btn-secondary",
        },
        {
          label: $t("instCheck.pause"),
          action: "confirm",
          class: "btn-outline-warning",
        },
      ],
      modalSize: "sm",
      modalHeaderBg: "bg-warning text-white",
    });
  }

  function terminateInstance() {
    // if (!can.terminate) {
    //   apiService.msg.insufficientRights();
    //   return;
    // }
    modalConfirmComponent.show({
      modalTitle: $t("instCheck.terminate"),
      modalBody: $t("instCheck.msgInstTerminate"),
      modalBtns: [
        {
          label: $t("generic.cancel"),
          action: "close",
          class: "btn-secondary",
        },
        {
          label: $t("instCheck.terminate"),
          action: "confirm",
          class: "btn-outline-danger",
        },
      ],
      modalSize: "sm",
      modalHeaderBg: "bg-danger text-white",
    });
  }

  function showError() {
    instanceError = JSON.parse(item.error || {});
    modalErrorShow = true;
  }

  function showMigrate() {
    modalInstanceMigrationComponent.init();
  }

  function showBo() {
    // if (!can.viewMetadata) {
    //   apiService.msg.insufficientRights();
    //   return;
    // }
    businessObject = undefined;
    instanceService
      .getOne(item._id)
      .then((res) => {
        businessObject = res.data?.businessObject || {};
        modalBoShow = true;
      })
      .catch((err) => console.error(err));
  }

  function deleteInstance() {
    // if (!can.delete) {
    //   apiService.msg.insufficientRights();
    //   return;
    // }
    modalConfirmComponent.show({
      modalTitle: $t("instCheck.delete"),
      modalBody: $t("instCheck.msgInstDelete"),
      modalBtns: [
        {
          label: $t("generic.cancel"),
          action: "close",
          class: "btn-secondary",
        },
        {
          label: $t("instCheck.delete"),
          action: "confirm",
          class: "btn-outline-danger",
        },
      ],
      modalSize: "sm",
      modalHeaderBg: "bg-danger text-white",
    });
  }

  /**
   * @param {string} action
   */
  function modalConfirmClose(action) {
    if ("close" === action) {
      return;
    }
    if ("confirm" === action) {
      if ("remove" === currentCommand) {
        instanceService
          .delete(item._id)
          .then(() => {
            toasterService.success({ msg: tNow("generic.deleted") });
            props.refresh();
          })
          .catch((err) => console.error(err));
      }
      if ("stop" === currentCommand) {
        instanceService
          .terminate(item._id)
          .then(() => {
            toasterService.success({ msg: tNow("instCheck.terminated") });
            props.refresh();
          })
          .catch((err) => console.error(err));
      }
      if ("pause" === currentCommand) {
        instanceService
          .pause(item._id)
          .then(() => {
            toasterService.success({ msg: tNow("instCheck.paused") });
            props.refresh();
          })
          .catch((err) => console.error(err));
      }
    }
  }

  /**
   * @param {any} bo
   */
  function saveBO(bo) {
    instanceService
      .updateBO(item._id, bo)
      .then(() => {
        businessObject = undefined;
        modalBoShow = false;
        toasterService.success({ msg: tNow("generic.saved") });
        props.refresh();
      })
      .catch((err) => console.error(err));
  }

  function pinInstance() {
    props.togglePin(item);
  }
</script>

<div class="ms-auto border border-secondary-subtle d-inline-flex">
  <div
    class="btn-group d-block"
    role="group"
    aria-label="Instance buttons"
  >
    {#if ["failed", "paused"].includes(item.status)}
      <button
        class="btn btn-sm btn-outline-success border-0 px-1"
        on:click={() => commandInstance("play")}
      >
        <span use:tooltip={$t("instCheck.playInstance")}>
          <Play width={icoHeight} height={icoHeight} />
        </span>
      </button>
    {/if}

    {#if !["closed", "terminated", "paused"].includes(item.status)}
      <button
        class="btn btn-sm btn-outline-warning border-0 px-1"
        on:click={() => commandInstance("pause")}
      >
        <span use:tooltip={$t("instCheck.pause")}>
          <Pause width={icoHeight} height={icoHeight} />
        </span>
      </button>
    {/if}

    {#if !["closed", "terminated"].includes(item.status)}
      <button
        class="btn btn-sm btn-outline-danger border-0 px-1"
        on:click={() => commandInstance("stop")}
      >
        <span use:tooltip={$t("instCheck.stop")}>
          <Stop width={icoHeight} height={icoHeight} />
        </span>
      </button>
    {/if}

    <button
      class="btn btn-sm btn-outline-secondary border-0 px-1"
      on:click={() => commandInstance("remove")}
    >
      <span use:tooltip={$t("instCheck.delete")}>
        <X width={icoHeight} height={icoHeight} />
      </span>
    </button>

    <button
      class="btn btn-sm btn-outline-info border-0 px-1"
      on:click={() => commandInstance("info")}
    >
      <span use:tooltip={$t("instCheck.info")}>
        <Info width={icoHeight} height={icoHeight} />
      </span>
    </button>

    {#if item.error}
      <button
        class="btn btn-sm btn-outline-danger border-0 px-1"
        on:click={() => commandInstance("alert")}
      >
        <span use:tooltip={$t("instCheck.error")}>
          <ExclamationTriangle width={icoHeight} height={icoHeight} />
        </span>
      </button>
    {/if}

    <button
      class="btn btn-sm btn-outline-secondary border-0 px-1"
      on:click={() => commandInstance("diagram")}
    >
      <span use:tooltip={$t("instCheck.diagram")}>
        <Diagram3 width={icoHeight} height={icoHeight} />
      </span>
    </button>

    {#if !["closed", "terminated"].includes(item.status)}
      <button
        class="btn btn-sm btn-outline-secondary border-0 px-1"
        on:click={() => commandInstance("migrate")}
      >
        <span use:tooltip={$t("instCheck.migrate")}>
          <FileDiff width={icoHeight} height={icoHeight} />
        </span>
      </button>
    {/if}
  </div>

  <div class="btn-group d-block ms-2">
    <button
      class="btn btn-sm btn-outline-secondary border-0 px-1"
      class:btn-outline-secondary={!item.pinned}
      class:btn-warning={item.pinned}
      on:click={() => pinInstance()}
    >
      <!-- <Tooltip -->
      <!--   content={$t("instCheck.diagram")} -->
      <!--   position={tlPosition} -->
      <!--   animation={tlAnimation} -->
      <!-- > -->
      <PinAngle width={icoHeight} height={icoHeight} />
      <!-- </Tooltip> -->
    </button>
  </div>
</div>

<UtilModalConfimation
  bind:this={modalConfirmComponent}
  size="sm"
  on:confirm={() => modalConfirmClose("confirm")}
  on:close={() => modalConfirmClose("close")}
/>

{#if businessObject}
  <UtilBusinessObject
    title={$t("generic.instance") + " #" + $t("generic.bo")}
    size="xl"
    currentItem={businessObject}
    bind:modalShow={modalBoShow}
    on:close={() => (businessObject = undefined)}
    on:save={(ev) => saveBO(ev.detail)}
  />
{/if}

{#if instanceError}
  <UtilBusinessObject
    title={$t("generic.errorDetails")}
    size="xl"
    currentItem={instanceError}
    bind:modalShow={modalErrorShow}
    on:close={() => (instanceError = undefined)}
  />
{/if}

<InstanceCheckDiaMigration
  bind:this={modalInstanceMigrationComponent}
  bind:item
  on:closed={() => props.refresh()}
/>
