<script>
  import constsService from "../../services/consts.service";
  import { t, tNow } from "../../services/i18n.service";
  import UtilPagination from "../utils/util-pagination.svelte";
  import UtilSearch from "../utils/util-search.svelte";
  import UtilTable from "../utils/util-table.svelte";
  import gdprService from "./gdpr.service";

  export let vars = [];

  let loading = false;
  let items = [];
  let itemsNo = 0;
  let query = {
    sort: "-created_at",
    limit: constsService.QUERY_PAGE_LIMIT,
    skip: 0,
  };
  const queryInit = JSON.stringify(query);

  $: search(vars);

  function search(vars) {
    getItems();
    countItems();
  }

  function countItems() {
    gdprService.data.instances
      .count(vars, query)
      .then((res) => (itemsNo = res.data));
  }

  function getItems() {
    loading = true;
    gdprService.data.instances
      .get(vars, query)
      .then((res) => {
        items = res.data;
        loading = false;
      })
      .catch(() => {
        loading = false;
      });
  }

  /**
   * @param {{ status: string; }} item
   */
  function statusPath(item) {
    return tNow("instCheck." + item.status);
  }

  /**
   * @param {{ status: any; }} item
   */
  function statusClass(item) {
    let className = "";
    switch (item.status) {
      case "active":
        className = "text-success fw-bold";
        break;
      case "closed":
      case "terminated":
        className = "text-secondary fw-bold";
        break;
      case "failed":
        className = "text-danger fw-bold";
        break;
      case "paused":
        className = "text-warning fw-bold";
        break;
      default:
        break;
    }
    return className;
  }
</script>

<div class="card border-primary mb-3">
  <div class="card-header bg-primary text-white">
    {$t("instCheck.instances")}
  </div>
  <div class="card-body">
    <UtilSearch
      bind:query
      queryFn={search}
      {queryInit}
      fields={[
        {
          lbl: $t("generic.name"),
          ph: $t("generic.searchFor"),
          path: "q",
          primary: true,
          secondary: true,
        },
        {
          lbl: $t("generic.status"),
          ph: $t("instCheck.allInstances"),
          path: "status",
          primary: true,
          secondary: true,
          type: "select",
          translate: false,
          withoutPlaceholder: true,
          list: [
            { key: undefined, value: $t("instCheck.allInstances") },
            { key: "active", value: $t("instCheck.active") },
            { key: "failed", value: $t("instCheck.failed") },
            { key: "paused", value: $t("instCheck.paused") },
            { key: "terminated", value: $t("instCheck.terminated") },
            { key: "closed", value: $t("instCheck.closed") },
          ],
        },
        {
          lbl: $t("generic.type"),
          ph: $t("instCheck.both"),
          path: "longRunning",
          primary: true,
          secondary: true,
          type: "select",
          translate: false,
          withoutPlaceholder: true,
          list: [
            { key: true, value: $t("instCheck.longRunning") },
            { key: false, value: $t("instCheck.shortRunning") },
            { key: undefined, value: $t("instCheck.both") },
          ],
        },
      ]}
    />

    <UtilTable
      data={items}
      bind:query
      bind:dataLoading={loading}
      queryFn={search}
      fns={{
        statusPath: statusPath,
        statusClass: statusClass,
      }}
      setts={{
        conf: {
          header: {},
          items: {},
        },
        cols: [
          {
            lbl: $t("generic.id"),
            path: "pino",
            type: "string",
            sort: false,
          },
          {
            lbl: $t("generic.name"),
            path: "name",
            type: "string",
            sort: true,
          },
          {
            lbl: $t("generic.status"),
            path: { fn: "statusPath" },
            type: "string",
            sort: { path: "status" },
            cssFn: "statusClass",
          },
        ],
      }}
    />

    <UtilPagination
      bind:query
      bind:totalData={itemsNo}
      on:callData={getItems}
    />
  </div>
</div>
