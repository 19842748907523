<script>
  import DashboardCompInstanceOverview from "../components/dashboards/dashboard-comp-instance-overview.svelte";
  import DashboardCompProcessPerformance from "../components/dashboards/dashboard-comp-process-performance.svelte";
  import DashboardCompTaskOverview from "../components/dashboards/dashboard-comp-task-overview.svelte";
  import DashboardCompUserPerformance from "../components/dashboards/dashboard-comp-user-performance.svelte";

  let maximized = false;
  let dashboard;
</script>

<div class="row">
  <div
    class={maximized && dashboard === "instanceOverview"
      ? "col-sm-12"
      : "col-sm-6 col-md-6 col-lg-3 col-xl-2"}
    class:d-none={maximized && dashboard !== "instanceOverview"}
  >
    <DashboardCompInstanceOverview
      bind:maximizedDashboard={dashboard}
      on:minimize={() => {
        maximized = false;
        dashboard = null;
      }}
      on:maximize={(ev) => {
        dashboard = ev.detail;
        maximized = true;
      }}
    />
  </div>
  <div
    class={maximized && dashboard === "taskOverview"
      ? "col-sm-12"
      : "col-sm-6 col-md-6 col-lg-3 col-xl-2"}
    class:d-none={maximized && dashboard !== "taskOverview"}
  >
    <DashboardCompTaskOverview
      bind:maximizedDashboard={dashboard}
      on:minimize={() => {
        maximized = false;
        dashboard = null;
      }}
      on:maximize={(ev) => {
        dashboard = ev.detail;
        maximized = true;
      }}
    />
  </div>
  <div
    class={maximized && dashboard === "userPerformance"
      ? "col-sm-12"
      : "col-sm-12 col-md-6 col-lg-6 col-xl-3"}
    class:d-none={maximized && dashboard !== "userPerformance"}
  >
    <DashboardCompUserPerformance
      bind:maximizedDashboard={dashboard}
      on:minimize={() => {
        maximized = false;
        dashboard = null;
      }}
      on:maximize={(ev) => {
        dashboard = ev.detail;
        maximized = true;
      }}
    />
  </div>
  <div
    class={maximized && dashboard === "processPerformance"
      ? "col-sm-12"
      : "col-sm-12 col-md-6 col-lg-12 col-xl-5"}
    class:d-none={maximized && dashboard !== "processPerformance"}
  >
    <DashboardCompProcessPerformance
      bind:maximizedDashboard={dashboard}
      on:minimize={() => {
        maximized = false;
        dashboard = null;
      }}
      on:maximize={(ev) => {
        dashboard = ev.detail;
        maximized = true;
      }}
    />
  </div>
</div>
