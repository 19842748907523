<script>
  import { onMount } from "svelte";
  import {
    Info,
    PlusLg,
    Search,
    XLg,
    Flag,
    Eye,
    EyeSlash,
  } from "svelte-bootstrap-icons";
  import { nanoid } from "nanoid";

  export let label = "";
  export let labelButton = "";
  export let buttonIcon = undefined;
  export let value = undefined;
  export let placeholder = undefined;
  export let invalid = undefined;
  export let disabled = false;
  export let fn = (/** @type {any} [_ev] */ _ev) => {};
  export let hideLabel = false;
  export let size = undefined;
  export let type = "text";
  export let name = undefined;
  export let fireEvent = "on-key"; // on-change
  let uuid;
  onMount(() => {
    uuid = nanoid(10);
  });
</script>

{#if !hideLabel}
  <label for="{uuid}-{label}">{label}</label>
{/if}
<div class="input-group {size ? 'input-group-' + size : ''} mb-3">
  {#if type === "text"}
    <input
      type="text"
      id="{uuid}-{label}"
      class="form-control {invalid ? 'is-invalid' : ''}"
      {placeholder}
      aria-label={label}
      aria-describedby={label}
      bind:value
      name={name ? name : `${uuid}-${label}`}
      on:focusin={(ev) => {
        fn(ev);
      }}
      on:change={(ev) => {
        if ("on-change" !== fireEvent) return;
        fn(ev);
      }}
      on:keyup={(ev) => {
        if ("on-key" !== fireEvent) return;
        fn(ev);
      }}
      {disabled}
    />
  {:else if type === "password"}
    <input
      type="password"
      id="{uuid}-{label}"
      class="form-control {invalid ? 'is-invalid' : ''}"
      {placeholder}
      aria-label={label}
      aria-describedby={label}
      bind:value
      name={name ? name : `${uuid}-${label}`}
      on:focusin={(ev) => {
        fn(ev);
      }}
      on:change={(ev) => {
        if ("on-change" !== fireEvent) return;
        fn(ev);
      }}
      on:keyup={(ev) => {
        if ("on-key" !== fireEvent) return;
        fn(ev);
      }}
      {disabled}
    />
  {/if}
  <button
    class="btn btn-outline-secondary {invalid ? 'is-invalid' : ''}"
    type="button"
    id="button-addon2"
    on:click={() => {
      fn();
    }}
    {disabled}
  >
    {#if buttonIcon === "add"}
      <PlusLg />
    {:else if buttonIcon === "rem"}
      <XLg />
    {:else if buttonIcon === "search"}
      <Search />
    {:else if buttonIcon === "info"}
      <Info />
    {:else if buttonIcon === "flag"}
      <Flag />
    {:else if buttonIcon === "eye"}
      <Eye />
    {:else if buttonIcon === "eyeslash"}
      <EyeSlash />
    {/if}
    {labelButton}
  </button>
</div>
