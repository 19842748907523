<script>
  export let cssTblHover = true;
  export let stickyHeader = false;
  export let classCss = "";
  export let height = undefined;
</script>

<!-- FIXME: sticky header doesn't work.... -->
<div
  class="table-responsive"
  style={height ? `max-height: ${height}px; overflow: auto;` : ""}
>
  <table
    class="table {cssTblHover ? 'table-hover' : ''} {stickyHeader
      ? 'table-header-sticky'
      : ''} {classCss}"
  >
    <slot name="thead" />
    <slot name="tbody" />
    <slot name="tfoot" />
  </table>
</div>
