<script>
  import JSONTree from "svelte-json-tree";
  export let value = undefined;
</script>

<div class="json-tree">
  <JSONTree
    {value}
    defaultExpandedLevel={1}
    --json-tree-li-identation="2em"
    --json-tree-li-line-height="1.5"
    --json-tree-font-size="16px"
    --json-tree-font-family="monospace"
  />
</div>
