<script>
  import logService from "../components/logs/log.service";
  import UtilCardTitle from "../components/utils/util-card-title.svelte";
  import UtilPagination from "../components/utils/util-pagination.svelte";
  import UtilSearch from "../components/utils/util-search.svelte";
  import UtilTable from "../components/utils/util-table.svelte";
  import constsService from "../services/consts.service";
  import { t } from "../services/i18n.service";

  let itemsNo = 0;
  let items = [];
  let query = {
    sort: "-timestamp",
    limit: constsService.QUERY_PAGE_LIMIT,
    skip: 0,
  };
  let can = {};
  let isLoading = false;

  search();

  function search() {
    countItems();
    getItems();
  }

  function countItems() {
    logService.count(query).then((res) => (itemsNo = res.data[0]));
  }

  function getItems() {
    isLoading = true;
    logService
      .get(query)
      .then((res) => {
        items = res.data;
        isLoading = false;
      })
      .catch(() => {
        isLoading = false;
      });
  }
</script>

<div class="card">
  <div class="card-header bg-primary">
    <UtilCardTitle
      title={$t("logs.title")}
      desc={$t("home.admin.logs.desc") +
        " | " +
        $t("generic.total") +
        ": " +
        (itemsNo || 0)}
    />
  </div>
  <div class="card-body">
    <UtilSearch
      bind:query
      queryFn={search}
      fields={[
        {
          lbl: $t("generic.type"),
          ph: null,
          path: "kind",
          primary: true,
          secondary: true,
          type: "select",
          translate: false,
          withoutPlaceholder: true,
          list: [
            { key: undefined, value: $t("generic.all") },
            { key: "info", value: $t("generic.info") },
            { key: "error", value: $t("generic.error") },
            { key: "debug", value: $t("generic.debug") },
            { key: "warn", value: $t("generic.warn") },
          ],
        },
        {
          lbl: $t("generic.user"),
          ph: null,
          path: "user",
          primary: true,
          secondary: true,
        },
        {
          lbl: $t("generic.name"),
          ph: null,
          path: "name",
          primary: true,
          secondary: true,
        },
        {
          lbl: $t("generic.trId"),
          ph: null,
          path: "trId",
          primary: true,
          secondary: true,
        },
      ]}
    />

    <UtilTable
      data={items}
      bind:query
      queryFn={search}
      bind:dataLoading={isLoading}
      setts={{
        conf: {
          header: {},
          items: {},
        },
        cols: [
          {
            lbl: $t("generic.date"),
            path: "timestamp",
            type: "date",
            sort: true,
          },
          {
            lbl: $t("generic.type"),
            path: "type",
            type: "string",
            sort: true,
          },
          {
            lbl: $t("generic.id"),
            path: "trId",
            type: "string",
            sort: true,
          },
          {
            lbl: $t("generic.logs"),
            path: "name",
            sort: false,
            type: "string",
            tl: { path: "user" },
          },
        ],
      }}
    />

    <UtilPagination
      bind:query
      bind:totalData={itemsNo}
      on:callData={getItems}
    />
  </div>
</div>
