import { get } from "svelte/store";
import { userStore } from "../../stores";
import { substores, taskExeBusinessObject, taskExeCommandStore, taskExeErrorStore, taskExeInfo, taskExeStore, taskExeTitle } from "./task-exe-store";
import toasterService from "../../services/toaster.service";
import uiService from "../designer/ui/ui.service";
import async from "async";
import taskService from "./task.service";
import { v4 as uuidv4 } from 'uuid'
import taskExeApiCallsService from "./task-exe-api-calls.service";
import { push, replace } from "svelte-spa-router";
import taskExeErrorAndVisibilityService from "./task-exe-error-and-visibility.service";
import { tNow } from "../../services/i18n.service";
import apiService from "../../services/api.service";
import taskExeVisibilityTree from "./task-exe-visibility-tree";
import { inboxStateStore } from "../inbox/inbox-store";

const MAX_LOAD_TEMPLATE = 3;
let loadedTemplates = {};
let loadedTemplatesTimes = {};
const loadedTemplatesFirstUpdate = {
  tpls: {},
  add: (/** @type {string} */ id) => {
    loadedTemplatesFirstUpdate.tpls[id] = true;
  },
  check: (/** @type {string} */ id) => {
    return loadedTemplatesFirstUpdate.tpls[id] || false;
  },
  clear: () => {
    loadedTemplatesFirstUpdate.tpls = {};
  }
};

export default {
  init,
  submit,
  saveTaskContext,
  clear,
  getLoadedTemplate,
  getLoadedTemplateActivation: loadedTemplatesFirstUpdate
}

/**
 * @param {string} id
 */
function getLoadedTemplate(id) {
  if (!id) return undefined;
  return loadedTemplates[id];
}

/**
 * @param {string} tid
 * @param {boolean} [standAlone]
 * @param {boolean} [tranzit]
 */
function init(tid, standAlone, tranzit) {
  // NOTE: we relay on taskExeInfo.status in order to show info in task.svelte
  taskExeInfo.set({ status: tranzit ? 'tranziting' : 'loading' });
  if (tranzit) clear({ status: 'tranziting' });

  if (standAlone) {
    uiService.getDashboard(tid)
      .then(res => {
        const ui = res.data;
        // get i18n taskSubject:
        let taskSubject = {
          default: ui.name,
          i18n: ui.subject_i18n || {},
        };

        const userInfo = get(userStore);
        taskExeStore.set(ui);
        taskExeTitle.set(taskSubject);
        taskExeBusinessObject.set({});
        // get task info:
        taskExeInfo.set({
          tid: tid,
          orgId: ui.orgId,
          paId: ui.paid,
          userId: userInfo._id,
          user: userInfo.email,
          hid: ui._id,
          pc_hid: ui.pc_id,
          standAlone,
          status: tranzit ? 'tranziting' : 'loading',
          task: null,
        })

        // set the templates for current ui:
        loadedTemplates = JSON.parse(JSON.stringify(ui?.definition?.tpls || {}));

        loadTemplatesFromWidgets(ui)
          .then(() => {
            executeIO({
              id: ui._id,
              tid: ui.pc_id,
              pre: ui.pre,
              preProcesses: ui.preProcesses
            }).then(callScriptForUI)
              .then(callProcesses)
              .then(() => {
                taskExeInfo.update(obj => {
                  obj.status = 'loaded';
                  return obj;
                })
                if (tranzit) {
                  if (get(inboxStateStore)?.state === 'normal') {
                    replace('/task/' + tid)
                  }
                }
              })
          })
      })
  } else {
    taskService
      .assignTask(tid)
      .then(/** @type {any} */ resTask => {
        const taskDefinition = resTask.data?.serviceDetails;

        // get i18n taskSubject:
        let taskSubject = {
          default: resTask.data?.subject || resTask.data?.name,
          i18n: resTask.data.subject_i18n || {},
        };

        if (!resTask.data?.serviceDetails) {
          push('/')
          return;
        }
        const userInfo = get(userStore);
        taskExeStore.set(resTask.data.serviceDetails);
        taskExeTitle.set(taskSubject);
        // taskExeBusinessObject.set(resTask.data?.businessObject || {});

        // get task info:
        taskExeInfo.set({
          tid: tid,
          orgId: taskDefinition.orgId,
          paId: taskDefinition.paid,
          userId: userInfo._id,
          user: userInfo.email,
          hid: taskDefinition._id,
          standAlone,
          status: tranzit ? 'tranziting' : 'loading',
          task: resTask.data
        })

        // set the templates for current ui:
        loadedTemplates = JSON.parse(JSON.stringify(taskDefinition?.definition?.tpls || {}));

        loadTemplatesFromWidgets(taskDefinition)
          .then(() => {
            // INFO: it seams that we need to update here the bo 
            // when there are templates inside UI
            taskExeBusinessObject.set(resTask.data?.businessObject || {});
            callScriptForUI({
              id: taskDefinition._id,
              taskId: tid,
              pre: taskDefinition.pre,
              preProcesses: taskDefinition.preProcesses,
            }).then(callProcesses)
              .then(() => {
                taskExeInfo.update(obj => {
                  obj.status = 'loaded';
                  return obj;
                })
                if (tranzit) {
                  if (get(inboxStateStore)?.state === 'normal') {
                    replace('/task/' + tid)
                  } else {

                  }
                }
              })
          })
      })
      .catch(() => {
        push('/')
      })
  }
}

/**
 * @param {any[]} messages
 */
function showMessages(messages) {
  if (messages?.length > 0) {
    messages.forEach(it => {
      toasterService.msg(it)
    })
  }
}

function highlightFields(errors) {
  const taskObjects = get(taskExeStore)?.definition?.objects || {};
  // 1. check for fields by id errors:
  if (errors?.fieldsById?.length > 0) {
    // set ids in models._errors:
    taskExeErrorStore.addIds(errors?.fieldsById);
  }
  // 2. check for fields by varName errors:
  if (errors?.fieldsByVarName?.length > 0) {
    // get ids for all variables identified here:
    let arrIds = [];
    // go through errors:
    errors.fieldsByVarName.forEach((it) => {
      // check every object:
      Object.entries(taskObjects).forEach(([_key, value]) => {
        if (it.replace('local.', '') === value.varName) arrIds.push(value.id);
      })
    })
    // add errors to models:
    taskExeErrorStore.addIds(arrIds);
  }
}


/**
 * @param {{id: string; boStore?: any; tplId?: string;}} setts
 * @param {any} [callbackFromHTMLWidget]
 */
function submit(setts, callbackFromHTMLWidget) {
  let { id, boStore, tplId } = setts;
  const obj = get(taskExeStore)?.definition?.objects?.[id];
  if (!boStore) boStore = taskExeBusinessObject;

  validation({ obj, boStore, tplId })
    .then(callScript)
    .then(openCloseModal)
    .then(callProcess)
    .then(closeTask)
    .catch(errObj => {
      if (errObj?.msg)
        toasterService.msg(errObj)
    })
}

/**
 * @param {{obj: any; boStore: any; tplId: string}} setts
 */
function openCloseModal(setts) {
  const { obj } = setts;
  const promise = new Promise(resolve => {
    if (!obj) return resolve(setts);
    if (obj.modalOpen && obj.modalClose) {
      executeOpenCloseModal(obj.modalClose, false);
    }
    if (obj.modalOpen) {
      executeOpenCloseModal(obj.modalOpen, true);
    }
    if (obj.modalClose) {
      executeOpenCloseModal(obj.modalClose, false);
    }
    return resolve(setts);
  })
  return promise;
}

/**
 * @param {string} modalId
 * @param {boolean} open
 */
function executeOpenCloseModal(modalId, open) {
  taskExeStore.update(ui => {
    ui.definition.objects[modalId].opened = open;
    return ui;
  })
}

/**
 * @param {any} obj
 */
function executeIO(obj) {
  const promise = new Promise(resolve => {
    taskService.executeIOMapping(obj.id)
      .then(res => {
        taskExeBusinessObject.updateBO(res.data || {});
        return resolve(obj);
      })
  })
  return promise;
}

/**
 * @param {any} obj
 */
function callScriptForUI(obj) {
  const promise = new Promise(resolve => {
    if (!obj.pre) return resolve(obj);
    taskService
      .executeServiceWithId(get(taskExeBusinessObject), { id: obj.id, taskId: obj.taskId })
      .then(res => {
        if (res.data?.businessObject)
          taskExeBusinessObject.updateBO(res.data?.businessObject, true);
        return resolve(obj);
      })
      .catch(err => {
        console.error('... call script for ui err', err.data || err);
      })
  })
  return promise;
}

/**
 * @param {any} setts
 */
function validation(setts) {
  let { obj, boStore, tplId } = setts;
  if (!boStore) boStore = taskExeBusinessObject;

  const promise = new Promise((resolve, reject) => {
    const bo = get(boStore);
    const taskObjects = get(taskExeStore)?.definition?.objects || {};

    // check if it's submit button and has before validation:
    if (obj.beforeValidation) {
      // remove all validations:
      taskExeErrorStore.clear();

      if (obj.validationType === "byVariable") {
        const validationVariable = obj.byVariable;
        const validationVariableValue = apiService.getNestedFromPath(bo, validationVariable);
        if (!validationVariableValue) {
          reject({ type: 'warning', msg: tNow('msgs.fillInAllField') })
          return;
        }
        resolve(setts);
      } else if (obj.validationType === 'byFields') {
        const whichValidationArr = (obj.whichValidation || '').split(',') || [];
        whichValidationArr.forEach(it => {
          const objField = taskObjects[it];
          taskExeErrorAndVisibilityService.checkValidation(objField, bo)
        })
        const errors = get(taskExeErrorStore);
        if (errors?.hasErrors) {
          reject({ type: 'warning', msg: tNow('msgs.fillInAllField') })
          return;
        }
        resolve(setts);
      } else if (obj.validationType === "byScript" && apiService.check.val(obj.byScript)) {
        taskService.executeServiceWithId(bo, {
          id: obj.id,
          taskInfo: { ...get(taskExeInfo), tplId },
          validation: true
        })
          .then(res => {
            if (res?.data?.businessObject) {
              boStore.updateBO(res.data.businessObject);
            }
            let messageFromServer = false;

            // show messages:
            if (res.data?.system?.messages?.length > 0) {
              messageFromServer = true;
              showMessages(res.data?.system.messages);
            }

            // check script output:
            if (res.data?.scriptOutput === true) {
              // go next because it's validated!
              resolve(setts);
            } else {
              // check for fields:
              if (res.data?.system?.errors) {
                highlightFields(res.data.system.errors);
              }
              if (!messageFromServer) {
                reject({ type: 'warning', msg: tNow('msgs.fillInAllField') })
              } else {
                reject();
              }
            }
          })
          .catch(() => {
            reject();
          })
      } else {
        taskExeErrorAndVisibilityService.checkValidationForAllFields();
        const errors = get(taskExeErrorStore);
        if (errors?.hasErrors) {
          reject({ type: 'warning', msg: tNow('msgs.fillInAllField') })
          return;
        }
        resolve(setts);
      }
    } else {
      resolve(setts);
    }
  })
  return promise;
}

/**
 * @param {{obj: any; boStore: any; tplId: string;}} setts
 */
function callScript(setts) {
  let { obj, boStore, tplId } = setts;
  if (!boStore) boStore = taskExeBusinessObject;

  const promise = new Promise(resolve => {
    if (!obj.actionScript) return resolve(setts);
    const taskInfo = { ...get(taskExeInfo), tplId };
    delete taskInfo.task;
    taskService
      .executeServiceWithId(
        get(boStore),
        {
          id: obj.id,
          taskInfo
        })
      .then(res => {
        if (res.data?.businessObject)
          boStore.updateBO(res.data?.businessObject, true);

        // check for messages:
        if (res.data?.system?.messages?.length > 0) {
          showMessages(res.data.system.messages);
        }

        // TODO: implement highlight fields

        return resolve(setts);
      })
      .catch(err => {
        console.error('... call script error', err.data);
      })
  })
  return promise;
}

/**
 * @param {{obj: any; boStore: any; tplId: string;}} setts
 */
function callProcess(setts) {
  let { obj, boStore } = setts;
  if (!boStore) boStore = taskExeBusinessObject;

  const promise = new Promise(resolve => {
    if (!obj.attachedProcess) return resolve(setts);
    const resId = uuidv4().split('-').join('');
    taskService
      .startInstance({
        pc_id: obj.attachedProcess._id,
        waitForResponse: obj.attachedProcess.waitForResponse,
        resId
      }, get(boStore))
      .then(res => {
        // TODO: implement error management
        if (!obj.attachedProcess.waitForResponse) {
          toasterService.success({ msg: tNow("wsFact.msgProcStarted") });
          return resolve(setts);
        }
        if (res.data?.details?.synchronous) {
          boStore.updateBO(res.data?.bo, true);
          return resolve(setts);
        } else {
          return taskExeApiCallsService.setSession(resId, (data) => {
            boStore.updateBO(data?.bo, true);
            return resolve(setts);
          })
        }
      })
      .catch(err => {
        console.error('... call process err', err.data);
      })
  })
  return promise;
}

/**
 * @param {any} obj
 */
function callProcesses(obj) {
  const promise = new Promise(resolve => {
    if (!(obj?.preProcesses?.length > 0)) return resolve(obj);
    const bo = get(taskExeBusinessObject);
    async.each(
      obj.preProcesses,
      (process, callback) => {
        const resId = uuidv4().split('-').join('');
        taskService
          .startInstance({
            pc_id: process.pid,
            waitForResponse: process.wait,
            resId
          }, bo)
          .then(res => {
            // TODO: implement error management
            if (!process.wait) return callback();
            if (res.data?.details?.synchronous) {
              taskExeBusinessObject.updateBO(res.data?.bo, true);
              callback();
            } else {
              return taskExeApiCallsService.setSession(resId, (data) => {
                taskExeBusinessObject.updateBO(data?.bo, true);
                callback();
              })
            }
          })
      },
      err => {
        if (err) {
          console.error('... err on call processes', err);
        }
        return resolve(obj);
      }
    )
  })
  return promise;
}

/**
 * @param {{ obj: any; boStore: any; tplId: string;}} setts
 */
function closeTask(setts) {
  // NOTE: closing task should always refer to the taskExeBusinessObject!!!
  const { obj } = setts;
  const boStore = taskExeBusinessObject;

  const promise = new Promise(resolve => {
    // NOTE: if there is no tid that means the UI was already finished and transiting to new UI
    if (!get(taskExeInfo)?.tid) return resolve(setts);

    if (get(taskExeInfo).standAlone) {
      if (obj?.submitTask) {
        // NOTE: we clear on task.svelte / onDestroy event
        // clear();
        // push('/')
        returnHomeFromTask();
      }
      return resolve(setts);
    } else {
      if (obj?.gotoNextTask) {
        // set tranziting status:
        taskExeInfo.update(res => {
          res.status = 'tranziting';
          return res;
        })
      }
      if (obj?.submitTask || obj?.gotoNextTask) {
        taskService
          .closeTask({ tid: get(taskExeInfo).tid, gotoNext: obj?.gotoNextTask || undefined }, get(boStore))
          .then(() => {
            // NOTE: we clear on task.svelte / onDestroy event
            // And the next task is opened in ws.service
            if (obj.gotoNextTask) {
              // set tranziting status:
              taskExeInfo.update(res => {
                res.status = 'tranziting';
                return res;
              })
              // clear({ status: 'tranziting' });
              return resolve(setts);
            } else {
              // push('/')
              returnHomeFromTask();
              return resolve(setts);
            }
          })
          .catch(err => {
            console.error('... close task err', err);
          })
      } else {
        return resolve(setts);
      }
    }
  })
  return promise;
}

function returnHomeFromTask() {
  const inboxState = get(inboxStateStore);
  if (inboxState.state === 'normal') {
    push('/');
  } else {
    taskExeCommandStore.command({ action: 'close' });
  }
}

/**
 * @param {{status: any;}} [setts]
 * @param {() => any} [callback]
 */
function clear(setts, callback) {
  taskExeInfo.set({ status: setts?.status || 'closed' });
  taskExeTitle.set(null);
  taskExeStore.set(null);
  taskExeBusinessObject.set(null);
  loadedTemplates = {};
  loadedTemplatesTimes = {};
  loadedTemplatesFirstUpdate.clear();
  taskExeVisibilityTree.clear();
  substores.clear();
  if (callback) return callback();
}

function saveTaskContext() {
  const tinfo = get(taskExeInfo);
  const tid = tinfo.tid;
  if (tinfo?.standAlone) {
    // clear();
  } else {
    taskService.saveTaskContext(tid, get(taskExeBusinessObject))
      .then(() => {
        // setTimeout(() => {
        // clear();
        // }, 300);
      })
  }
}

function loadTemplatesFromWidgets(ui) {
  const promise = new Promise((resolve, reject) => {
    const uiObjects = ui?.definition?.objects;
    if (!uiObjects) return resolve();
    // INFO: trebuie sa incarcam pentru fiecare widget de tip tpl-preview ui-ul aferent
    // cu numaratoare pentru a nu-l incarca de jdemii de ori!!!
    // search all tpl-preview widgets:
    loadedTemplates[ui._id] = ui;
    loadedTemplatesTimes[ui._id] = 1;
    const templatesIds = [];
    Object.keys(uiObjects).forEach(key => {
      const obj = uiObjects[key];
      if (obj.type === 'tpl-preview') {
        templatesIds.push({
          parent: null,
          id: key,
          path: key,
          parentsKeys: [],
          parents: [],
          tplId: obj.tplId,
          props: obj
        });
      }
    })
    loadTemplates(templatesIds, [...templatesIds], (err, resultTemplates) => {
      if (err) return reject('Error while loading templates');

      // for each template initiate a substore:
      resultTemplates.forEach(tpl => {
        const substoreProps = {
          ...tpl.props,
          path: tpl.path,
          parents: tpl.parents
        };
        substores.initiate(substoreProps);
      })

      // INFO: set stores for widget visibility:
      // set stores for main widgets:
      taskExeVisibilityTree
        .getWidgetVisibilityStore(JSON.parse(JSON.stringify(ui.definition.objects)), null);

      // set stores for dynamic visibility in templates:
      resultTemplates.forEach(tpl => {
        const widgetId = tpl.path;
        const templateObjects = loadedTemplates[tpl.tplId]?.definition?.objects;
        if (!templateObjects) return;
        taskExeVisibilityTree
          .getWidgetVisibilityStore(JSON.parse(JSON.stringify(templateObjects)), widgetId);
      })

      resolve();
    });
  })
  return promise;
}

/**
 * @param {async.IterableCollection<any>} templatesIds
 * @param {any[]} allTemplates
 * @param {{ (err: any, resultTemplates: any): void; (arg0: Error, arg1: undefined): void; }} next
 */
function loadTemplates(templatesIds, allTemplates, next) {
  const newTemplatesIds = [];
  async.each(
    templatesIds,
    (tpl, callback) => {
      if (loadedTemplates[tpl.tplId]) {
        if (loadedTemplatesTimes[tpl.tplId] >= MAX_LOAD_TEMPLATE) {
          return callback();
        } else {
          if (!loadedTemplatesTimes[tpl.tplId]) loadedTemplatesTimes[tpl.tplId] = 0;
          loadedTemplatesTimes[tpl.tplId] += 1;
          const tplObjects = loadedTemplates[tpl.tplId].definition.objects;
          Object.keys(tplObjects).forEach(key => {
            const obj = tplObjects[key];
            if (obj.type === 'tpl-preview') {
              newTemplatesIds.push({
                parent: tpl.id,
                id: key,
                path: tpl.path + '_' + key,
                parentsKeys: [...tpl.parentsKeys, tpl.path],
                parents: allTemplates.filter(el => [...tpl.parentsKeys, tpl.path].includes(el.path)),
                tplId: obj.tplId,
                props: obj
              })
            }
          })
          callback();
        }
      } else {
        console.warn('... sunt pe else');
        callback();
        // uiService
        //   .getOne(tpl.tplId)
        //   .then(res => {
        //     loadedTemplates[tpl.tplId] = res.data;
        //     loadedTemplatesTimes[tpl.tplId] = 1;
        //
        //     const tplObjects = res.data.definition.objects;
        //     Object.keys(tplObjects).forEach(key => {
        //       const obj = tplObjects[key];
        //       if (obj.type === 'tpl-preview') {
        //         newTemplatesIds.push({
        //           parent: tpl.id,
        //           id: key,
        //           path: tpl.path + '_' + key,
        //           parentsKeys: [...tpl.parentsKeys, tpl.path],
        //           parents: allTemplates.filter(el => [...tpl.parentsKeys, tpl.path].includes(el.path)),
        //           tplId: obj.tplId,
        //           props: obj
        //         })
        //       }
        //     })
        //     callback();
        //   })
        //   .catch(err => {
        //     console.error(err);
        //     callback(err);
        //   })
      }
    },
    err => {
      if (err) return next(err);
      allTemplates = [...allTemplates, ...newTemplatesIds];
      if (newTemplatesIds.length === 0) return next(null, allTemplates);
      loadTemplates(newTemplatesIds, allTemplates, next);
    }
  )
}

