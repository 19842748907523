import async from 'async';
import axios from "axios"
import dayjs from "dayjs";
import { credsTempStore, isAuthWaiting, userStore } from './../stores';
import { get } from 'svelte/store';
import { push } from 'svelte-spa-router';
import { toasts } from 'svelte-toasts';
import api from './api.service';
import storageService from './storage.service';
import constsService from './consts.service';
import apiService from './api.service';
import { locale } from './i18n.service';
import toasterService from './toaster.service';

let isRecaptcha = false;

export default {
  login,
  logout,
  signin,
  identifyMe,
  checkAuth,
  checkExpiration,
  decodeToken,
  refreshToken,
  setHeader,
  setHeaderTkn,
  checkTknAccessExpiration,
  changePass,
  resetPass,
  getPlatformInfo,
  activate,
  activateAdminAccountForPortalUser,
  activateSocial,
  forgotPassword,
}

function removeHeaderToken() {
  // axios.defaults.headers.common = {};
  delete axios.defaults.headers.common['Authorization'];
}

/**
 * @param {string} which
 * @param {any} data
 * @param {Function} next - callback
 */
function login(which, data, next) {
  const credsEncoded = api.dataconv.encode(data);
  return async.waterfall([
    callback => {
      // TODO: implement RECAPTCHA
      callback();
    },
  ], (err, recaptcha_tkn) => {
    if (err) return;
    removeHeaderToken();
    return axios.post('/api/login', { i: credsEncoded }, { params: { lang: 'en', __rc: recaptcha_tkn } })
      .then(res => {
        if (!res?.data) {
          console.error('no data to login response')
          return;
        }
        if (res?.data?.action === 'redirect') {
          credsTempStore.set(data)
          return next(null, { redirect: true, url: res.data.url });
        }
        storageService.save(constsService.APP_NAME, res.data.tkn1);
        storageService.save(constsService.APP_NAME + 'gen', res.data.tkn2);
        setHeaderTkn(res.data.tkn1);
        identifyMe((/** @type {undefined} */ err, /** @type {undefined} */ user) => {
          if (next) return next(err, user);
        })
      })
      .catch(err => {
        console.error('... err ', err);
        if (err?.data?.error?.startsWith('[ERR 4455]')) { //'User is not activated'
          storageService.save('email', data.username);
          return next(null, { redirect: true, url: 'activate' })
        }
        if (err?.data?.error?.startsWith('[ERR 4456]')) { //'User is not activated'
          storageService.save('email', data.username);
          return next(null, { redirect: true, url: 'activate' })
        }
        next(err.response);
      })
  })
  // axios.post('/api/auth/login', loginUser)
  //   .then(res => {
  //     let tkna, tknr;
  //     if (res.data?.msg === 'ok') {
  //       tkna = res.data.tkna;
  //       tknr = res.data.tknr;
  //       storage.save('smtrp_tkna', tkna);
  //       storage.save('smtrp_tknr', tknr);
  //       setHeaderTkn(tkna.tkn);
  //       if (getUser) {
  //         identifyMe((/** @type {undefined} */ err, /** @type {undefined} */ user) => {
  //           if (next) return next(err, user);
  //         })
  //       }
  //       if (next) return next();
  //     }
  //   }).catch(err => next(err.response.data))
}

function logout() {
  // clear all toasts:
  toasts.clearAll();
  storageService.remove(constsService.APP_NAME);
  storageService.remove(constsService.APP_NAME + 'gen');
  userStore.set(undefined);
  push('/login');
  // location.reload();
}

/**
 * @param {(err: object, res: object) => void} next
 */
function identifyMe(next) {
  axios.post('/api/identifyMe')
    .then(res => {
      if (next) return next(null, res.data);
    })
    .catch(err => { next(err); })
}

/**
 * @param {Function} next
 */
function refreshToken(next) {
  const tknr = storageService.get(constsService.APP_NAME + 'gen'); //?.tkn;
  if (!tknr) {
    if (next) return next('goto login');
    else logout();
  }

  setHeaderTkn(tknr);
  axios.post('/api/getnewtoken')
    .then(res => {
      if (res.data) {
        storageService.save(constsService.APP_NAME, res.data);
        setHeaderTkn(res.data);
        if (next) return next(null, res.data);
      } else {
        storageService.remove(constsService.APP_NAME);
        storageService.remove(constsService.APP_NAME + 'gen');
        if (next) return next('goto login');
      }
    })
    .catch(err => {
      storageService.remove(constsService.APP_NAME);
      storageService.remove(constsService.APP_NAME + 'gen');
      if (next) return next(err);
      else logout();
    })
}

/**
 * @param {string} tkn
 */
function decodeToken(tkn) {
  const tkndecoded = JSON.parse(atob(tkn.split('.')[1]));
  return tkndecoded;
}

function checkTknAccessExpiration() {
  const now = dayjs().toDate().getTime() / 1000;
  const tkna = storageService.get(constsService.APP_NAME); //?.tkn;
  const tknaDecoded = tkna ? decodeToken(tkna) : {};
  const diff = now - tknaDecoded.exp;
  return diff;
}

/**
 * @param {string} tkn
 */
function checkExpiration(tkn) {
  const decodedTkn = decodeToken(tkn);
  const now = dayjs().toDate().getTime() / 1000;
  const then = decodedTkn.exp;
  const datediff = then - now;
  // if ok return true
  if (datediff < 0) return true;
  return false;
}

function setHeader() {
  const tkn = storageService.get(constsService.APP_NAME); //.tkn;
  if (!tkn) return;
  setHeaderTkn(tkn);
}

/**
 * @param {string} tkn
 */
function setHeaderTkn(tkn) {
  axios.defaults.headers.common = {};
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + tkn;
}

function checkAuth() {
  isAuthWaiting.set(true)
  const promise = new Promise((resolve, reject) => {
    const now = dayjs().toDate().getTime() / 1000;
    let user = get(userStore);
    const tkna = storageService.get(constsService.APP_NAME);
    const tknaDecoded = tkna ? decodeToken(tkna) : {};
    const tknr = storageService.get(constsService.APP_NAME + 'gen');
    const tknrDecoded = tknr ? decodeToken(tknr) : {};
    let datediff_a = now - tknaDecoded.exp,
      datediff_r = now - tknrDecoded.exp,
      needsRefresh = false, gotoLogin = false;

    async.waterfall([
      // check tkns for expiration:
      callback => {
        if (datediff_a < 0) {
          return callback();
        } else if (datediff_r < 0) {
          needsRefresh = true;
          return callback();
        } else {
          gotoLogin = true;
          return callback();
        }
      },
      // check if redirect to login:
      callback => {
        if (gotoLogin) return callback('redirect to login');
        callback();
      },
      // check if needs refresh:
      callback => {
        if (!needsRefresh) return callback();
        refreshToken(err => {
          if (err) return callback(err);
          needsRefresh = false;
          callback();
        })
      },
      // check if user:
      callback => {
        if (user) return callback();
        setHeader();
        identifyMe((err, _user) => {
          if (err) return callback(err);
          userStore.set(_user);
          isAuthWaiting.set(false)
          callback();
        })
      }
    ], err => {
      if (err) {
        // push('/login');
        isAuthWaiting.set(false)
        reject('no user');
        return;
      };
      isAuthWaiting.set(false)
      resolve()
      // push('/')
    })
  })
  return promise;
}

/**
 * @param {string} pass
 * @param {(err: object, res: object) => void} next
 */
function changePass(pass, next) {
  const newpass = pass ?? '';
  if ('' === newpass) {
    toasts.warning({ title: 'Atentie!', description: 'Parola nu este conformă. Reîncercați!' });
    return next('nopass');
  }
  axios
    .post('/api/auth/changePass', { pass })
    .then(res => {
      next();
    }).catch(err => {
      next(err);
    })
}

/** @param {string} username
  */
function resetPass(username) {
  return axios.post('/api/auth/resetPass', { username });
}

function getPlatformInfo() {
  return axios.get('/api/checkSession');
}

/**
 * @param {any} obj
 * @param {any} next
 */
function activate(obj, next) {
  // set new platform parameter
  obj.vers = 'v5';
  const dataEncoded = apiService.dataconv.encode(obj);
  axios.get('/api/activate', { params: { i: dataEncoded } })
    .then(res => {
      storageService.save(constsService.APP_NAME, res.data.tkn1);
      storageService.save(constsService.APP_NAME + 'gen', res.data.tkn2);
      setHeaderTkn(res.data.tkn1);
      identifyMe((/** @type {undefined} */ err, /** @type {undefined} */ user) => {
        userStore.set(user);
        if (next) return next(err, user);
      })
    })
    .catch(err => {
      console.error(err)
      if (next) next(err);
    })
}

/**
 * @param {any} obj
 * @param {string} tkn recaptcha tkn
 * @param {any} onprem
 * @param {(arg0: any, arg1: any) => void} next
 */
function signin(obj, tkn, onprem, next) {
  const dataEncoded = apiService.dataconv.encode(obj);
  removeHeaderToken();
  axios.post('/api/signin', { i: dataEncoded }, { params: { lang: get(locale), __rc: tkn ? tkn : undefined } })
    .then(res => {
      if (res?.data?.action === 'redirect') {
        // $rootScope.data = { username: data.organisation.email, password: data.password };
        credsTempStore.set({ username: obj.organisation.email, password: obj.password })
        if (res?.data?.url) next(null, { redirect: true, url: res.data.url });
      } else if (res.data) {
        storageService.save(constsService.APP_NAME, res.data.tkn1);
        storageService.save(constsService.APP_NAME + 'gen', res.data.tkn2);
        // $rootScope.$broadcast('session', {
        //   isLoggedin: true
        // });
        next(null, { redirect: true, url: 'home' });
      } else {
        if (onprem) {
          next(null, { redirect: true, url: 'home' });
        } else {
          storageService.save('email', obj.email);
          next(null, { redirect: true, url: 'activate' });
        }
      }
    })
    .catch(err => {
      let messagePassInfo = false;
      if (err.response.data.showInfo) {
        messagePassInfo = true;
      } else {
        messagePassInfo = false;
      }
      next(err, { messagePassInfo });
    })
}

/**
 * @param {any} obj
 * @param {any} next
 */
function activateAdminAccountForPortalUser(obj, next) {
  const dataEncoded = apiService.dataconv.encode(obj);
  axios.post('/api/activateAdminAccountForPortalUser', { i: dataEncoded })
    .then(res => {
      storageService.save(constsService.APP_NAME, res.data.tkn1);
      storageService.save(constsService.APP_NAME + 'gen', res.data.tkn2);
      setHeaderTkn(res.data.tkn1);
      identifyMe((/** @type {undefined} */ err, /** @type {undefined} */ user) => {
        userStore.set(user);
        if (next) return next(err, { redirect: true, url: '' });
      })
    })
    .catch(err => {
      next(err);
    })
}

/**
 * @param {any} data
 * @param {any} next
 */
function forgotPassword(data, next) {
  const dataEncoded = apiService.dataconv.encode(data);
  axios.post('/api/forgotPassword', { i: dataEncoded }, { params: { lang: get(locale) } })
    .then(res => {
      if (res.data) {
        storageService.save(constsService.APP_NAME, res.data.tkn1);
        storageService.save(constsService.APP_NAME + 'gen', res.data.tkn2);
        storageService.remove('email');
        setHeaderTkn(res.data.tkn1);
        identifyMe((/** @type {undefined} */ err, /** @type {undefined} */ user) => {
          userStore.set(user);
          if (next) return next(err, { redirect: true, url: '' });
        })
      } else {
        if (next) return next(null, { redirect: false, tokenSent: true })
      }
    })
    .catch(err => {
      console.error(err);
      if (next) next(err);
    })
}

/**
 * @param {string} tknr
 * @param {(err, res) => any} next
 */
function activateSocial(tknr, next) {
  storageService.save(constsService.APP_NAME + 'gen', tknr);
  refreshToken((err, res) => {
    identifyMe((/** @type {undefined} */ err, /** @type {undefined} */ user) => {
      if (err) {
        if (next) next(err);
        return;
      }
      userStore.set(user);
      // refreshTokenBasedOnExistingTokenR();
      if (next) return next();
    })
  })
}
