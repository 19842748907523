<script>
  import { onDestroy, onMount } from "svelte";
  import { taskExeCommandStore } from "../components/task/task-exe-store";
  import Task from "./task.svelte";
  import { inboxStateStore } from "../components/inbox/inbox-store";
  import taskExeApi from "../components/task/task-exe.api";
  import InboxRedesignExposedTasks from "../components/inbox/inbox-redesign-exposed-tasks.svelte";
  import TasksRedesign from "../components/inbox/tasks-redesign.svelte";
  import { t } from "../services/i18n.service";
  import { ArrowBarLeft, ArrowBarRight } from "svelte-bootstrap-icons";

  export let params = {};
  let taskParams = {};
  let showTasks = false;

  $: associateTaskParams(params);

  const taskExeCommandStoreUnsubscribe = taskExeCommandStore.subscribe(
    (res) => {
      if (res?.action === "close") {
        // FIXME: This should not be with setTimeout.
        // Probably the task component should be refactored!!!!
        resetDashboard();
      }
    },
  );

  onMount(() => {
    inboxStateStore.setState("sites");
  });

  onDestroy(() => {
    taskExeCommandStoreUnsubscribe();
  });

  function resetDashboard() {
    taskParams = {};
    taskExeApi.clear(null, () => {
      setTimeout(() => {
        taskParams = { tid: params.tid, dashboard: true };
      }, 500);
    });
  }

  /**
   * @param {{ tid?: any; }} params
   */
  function associateTaskParams(params) {
    if (!params.tid) {
      taskParams = {};
    } else {
      if (taskParams.tid !== params.tid) {
        resetDashboard();
      } else {
        taskParams = { tid: params.tid, dashboard: true };
      }
    }
  }

  function toggleMyTasks() {
    showTasks = !showTasks;
  }
</script>

<!-- params: {JSON.stringify(params)}<br /> -->
<!-- task params: {JSON.stringify(taskParams)}<br /> -->
<!-- inboxStateStore: {JSON.stringify($inboxStateStore)}<br /> -->

<div class="d-md-flex">
  <div
    class="d-none d-md-block pe-3 border-end h-100"
    style="width: 300px; height: 50vh;"
  >
    <InboxRedesignExposedTasks></InboxRedesignExposedTasks>
  </div>

  <div class="ps-3 w-100 mb-3 h-100">
    <div class="row">
      <div
        class="border-end"
        class:col-md-8={showTasks}
        class:col-md-11={!showTasks}
      >
        {#if taskParams?.tid}
          <Task isComponent={true} myParams={taskParams}></Task>
        {/if}
      </div>
      <div
        class="col-md-4"
        class:col-md-4={showTasks}
        class:col-md-1={!showTasks}
      >
        <button class="btn btn-sm btn-link" on:click={toggleMyTasks}>
          {#if showTasks}
            <span>
              {$t("instCheck.onlyMyTasks")}
            </span>
            <ArrowBarRight></ArrowBarRight>
          {:else}
            <ArrowBarLeft></ArrowBarLeft>
            <div
              style="transform: rotate(-90deg); position: relative; top: 30px;"
            >
              {$t("instCheck.onlyMyTasks")}
            </div>
          {/if}
        </button>
        {#if showTasks}
          <TasksRedesign listType="list"></TasksRedesign>
        {/if}
      </div>
    </div>
  </div>

  <!-- <div class="px-3" style="width: 200px;"> -->
  <!--   <div class="card p-3"> -->
  <!--     <div> -->
  <!--     <canvas id="chartContainer1"> </canvas> -->
  <!--     </div> -->
  <!--   </div> -->
  <!-- </div> -->
</div>
