import { get } from "svelte/store";
import { userStore } from '../stores';
import toasterService from './toaster.service';

export default {
  getSystem
}

function getSystem() {
  const user = get(userStore);
  const system = {
    user: {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      _id: user._id,
      organisations: user.organisations,
      roles: user.roles
    },
    msgs: {
      addMsg: (setts) => {
        if (['error', 'warning', 'info', 'success'].includes(setts?.type)) {
          toasterService[setts.type]({ title: setts?.title, msg: setts?.msg, timeout: setts?.timeout });
        }
      }
    }
  };
  system.msgs.addMessage = system.msgs.addMsg;
  return system;
}
