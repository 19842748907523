<script>
  import {
    ChevronDown,
    ChevronUp,
    ExclamationTriangle,
  } from "svelte-bootstrap-icons";
  import { onDestroy, onMount } from "svelte";
  import {
    substores,
    taskExeBusinessObject,
    taskExeStore,
  } from "../task-exe-store";
  import TaskExeTplI18nLabel from "./task-exe-tpl-i18n-label.svelte";
  import TaskExeCompList from "../task-exe-comp-list.svelte";
  import taskExeVisibilityTree from "../task-exe-visibility-tree";

  /** @type {{id: string; columns: any[]}|undefined} */
  export let item;
  export let props = {};
  /** @type {{id: string; type: string;}[]} */
  export let parent = undefined;
  export let visibilityFromParent = "editable";
  /** @type {{id: string; tplId: string;}|null} */
  export let templateProps = null;

  let hasError = false;
  let icoHeight = 24;
  let currentParents = [];
  let myVisibility;
  let dynamicBoStore;
  const pathToStore = parent
    .filter((el) => el.type === "tpl-preview")
    .map((el) => el.id)
    .join("_");

  $: updateParentsList(parent);
  // $: checkHasErrorFromChildren(props?.errors);

  let visibilityStoreUnsubscribe;
  const visibilityStore = taskExeVisibilityTree.checkVisibilityStore(
    item,
    parent,
  );
  if (visibilityStore) {
    visibilityStoreUnsubscribe = visibilityStore.subscribe(
      (/** @type {string} */ res) => {
        myVisibility = res;
      },
    );
  }

  $: checkVisWithParent(myVisibility, visibilityFromParent); 

  function checkVisWithParent(_myVisibility, _visibilityFromParent) {
    myVisibility = taskExeVisibilityTree.checkVisOfElementWithParent(_visibilityFromParent, _myVisibility, props)
  }

  onMount(() => {
    if (pathToStore) {
      if (substores.bos?.[pathToStore]?.data) {
        dynamicBoStore = substores.bos[pathToStore].data;
      } else {
        dynamicBoStore = taskExeBusinessObject;
      }
    } else {
      dynamicBoStore = taskExeBusinessObject;
    }

    if (props.isCollapsedDefault) props.isCollapsed = true;
    else props.isCollapsed = false;
  });

  onDestroy(() => {
    if (visibilityStoreUnsubscribe) visibilityStoreUnsubscribe();
  });

  /**
   * @param {any} parent
   */
  function updateParentsList(parent) {
    // console.log('... update parent list panel', parent);
    currentParents = [...parent, props];
  }

  /**
   * @param {any} errors
   */
  function checkHasErrorFromChildren(errors) {
    if (!errors) {
      hasError = false;
      return;
    }
    hasError = Object.keys(errors).reduce((o, key) => {
      return (o = o || errors[key]);
    }, false);
  }

  function collapseMe() {
    const isCollapsed = !props.isCollapsed;
    if (props.accordionName) {
      checkAccordions(isCollapsed);
    } else {
      props.isCollapsed = isCollapsed;
    }
  }

  /**
   * @param {boolean} open
   */
  function checkAccordions(open) {
    const hasValue = (open ??= false);
    taskExeStore.update((item) => {
      const objects = item.definition.objects;
      const currentObj = objects[props.id];
      Object.keys(objects).forEach((objId) => {
        const obj = objects[objId];
        if (
          obj.type === "panel" &&
          obj.accordionName === currentObj.accordionName
        ) {
          obj.isCollapsed = true;
        }
      });
      currentObj.isCollapsed = hasValue ? open : false;
      return item;
    });
  }
</script>

{#if myVisibility !== "hidden"}
  <div
    id={item.id}
    class="card mb-3 {props.class || ''} {props.headerType
      ? 'border-' + props.headerType
      : ''}"
    style={props.style}
  >
    {#if !props.hiddenHeader}
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <div
        class="card-header {props.headerType
          ? 'bg-' + props.headerType
          : ''} {props.headerTypeOther || ''} d-flex"
      >
        <TaskExeTplI18nLabel bind:props />
        <!-- -- prop_vis: {props.visibility} / parent: {parent?.id} -->
        <!-- NOTE: this is passed from children -->
        <!-- {JSON.stringify(props.errors)} -->
        <!-- newVisibility = {newVisibility} / visibilityFromParent = {visibilityFromParent} -->
        {#if hasError}
          <ExclamationTriangle
            class="ms-3 text-danger"
            width={icoHeight}
            height={icoHeight}
          />
        {/if}
        {#if props.isCollapsible}
          <div class="ms-auto">
            {#if !props.isCollapsed}
              <button class="btn btn-sm border-0 p-0 m-0" on:click={collapseMe}>
                <ChevronUp width={icoHeight} height={icoHeight} />
              </button>
            {:else}
              <button class="btn btn-sm border-0 p-0 m-0" on:click={collapseMe}>
                <ChevronDown width={icoHeight} height={icoHeight} />
              </button>
            {/if}
          </div>
        {/if}
      </div>
    {/if}
    {#if !props.isCollapsed}
      <!-- INFO: transition doesn't work because of it the component doesn't destroy -->
      <div class="card-body p-2">
        <!-- {item?.id} / {props.visibility} / {parent?.id} / {parent?.visibility} / {JSON.stringify(props)} -->
        {#each item?.columns as child}
          <TaskExeCompList
            bind:item={child}
            bind:parent={currentParents}
            bind:visibilityFromParent={myVisibility}
            bind:templateProps
          />
        {/each}
      </div>
    {/if}
  </div>
{/if}
