<script>
  import { createEventDispatcher, onMount } from "svelte";
  import dashboardService from "./dashboard.service";
  import { t, tNow } from "../../services/i18n.service";
  import FieldChart from "../utils/field-chart.svelte";
  import { Fullscreen } from "svelte-bootstrap-icons";
  const dispatch = createEventDispatcher();

  export let maximizedDashboard = undefined;
  const icoHeight = 20;
  const styles = getComputedStyle(document.documentElement);
  const colors = [
    // styles.getPropertyValue("--bs-primary"),
    styles.getPropertyValue("--bs-info"),
    styles.getPropertyValue("--bs-success"),
    styles.getPropertyValue("--bs-warning"),
    styles.getPropertyValue("--bs-danger"),
    styles.getPropertyValue("--bs-indigo"),
  ];
  let userPerformance = {};
  let userChart;
  let isLoading = false;
  const config = JSON.stringify({
    type: "pie",
    data: {},
    options: {
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: false,
          text: "",
        },
      },
    },
  });

  onMount(() => {
    getDashboardUserPerformance();
  });

  function getDashboardUserPerformance() {
    isLoading = true;
    dashboardService.getDashboardUserPerformance().then((res) => {
      userPerformance.series = [];
      userPerformance.labels = [
        tNow("dashs.user.active"),
        tNow("dashs.user.closed"),
        tNow("dashs.user.failed"),
        tNow("dashs.user.paused"),
      ];
      userPerformance.data = [];
      userPerformance.initData = res.data;
      userPerformance.totalUserTasks = 0;

      res.data.forEach((it) => {
        userPerformance.series.push(
          it.user ? it.user : tNow("dashs.user.group")
        );
        userPerformance.data.push([
          it.tasks.active,
          it.tasks.closed,
          it.tasks.failed,
          it.tasks.paused,
        ]);
        userPerformance.totalUserTasks += it.tasks.all;
      });
      const chartUser = JSON.parse(config);
      chartUser.type = "bar";
      chartUser.data = {
        labels: userPerformance.labels,
        datasets: [
          ...userPerformance.series.map((serie, serieIx) => {
            return {
              label: serie,
              data: userPerformance.data[serieIx],
              // backgroundColor: colors,
              hoverOffset: 4,
              borderWidth: 0,
            };
          }),
        ],
      };
      userChart = chartUser;
      userPerformance = userPerformance;
      isLoading = false;
    });
  }
</script>

<div class="card mb-3">
  <div class="d-flex card-header bg-primary text-white">
    <b>{$t("dashs.user.title")}</b>
    <button
      class="btn btn-sm btn-outline-secondary m-0 p-0 border-0 px-2 py-1 ms-auto text-white"
      on:click={() => {
        const maximized = maximizedDashboard === "userPerformance";
        if (maximized) {
          dispatch("minimize", "userPerformance");
        } else {
          dispatch("maximize", "userPerformance");
        }
      }}
    >
      <Fullscreen width={icoHeight - 4} height={icoHeight - 4} />
    </button>
  </div>
  <div class="card-body">
    <div class="row">
      <div
        class:col-md-6={maximizedDashboard === "userPerformance"}
        class:col-md-12={maximizedDashboard !== "userPerformance"}
      >
        {#if userPerformance?.initData?.length > 0}
          {#if userChart}
            <div>
              <FieldChart
                bind:config={userChart}
                height={maximizedDashboard === "userPerformance"
                  ? "600px"
                  : null}
              />
            </div>
          {/if}
        {/if}
      </div>
      <div
        class:col-md-6={maximizedDashboard === "userPerformance"}
        class:col-md-12={maximizedDashboard !== "userPerformance"}
      >
        <div class="table-responsive">
          <table class="table table-striped table-condensed">
            <thead class="text-primary">
              <th>{$t("dashs.user.user")}</th>
              <th class="text-end">{$t("dashs.user.active")}</th>
              <th class="text-end">{$t("dashs.user.closed")}</th>
              <th class="text-end">{$t("dashs.user.failed")}</th>
              <th class="text-end">{$t("dashs.user.paused")}</th>
              <th class="text-end">{$t("dashs.user.total")}</th>
            </thead>
            <tbody>
              {#if isLoading}
                {#each new Array(3) as _item}
                  <tr>
                    {#each new Array(6) as _td}
                      <td class="placeholder-glow">
                        <span class="placeholder bg-secondary w-100" />
                      </td>
                    {/each}
                  </tr>
                {/each}
              {:else}
                {#if userPerformance?.initData?.length === 0}
                  <tr>
                    <td>{$t("dashs.user.msgInsuff")}</td>
                    <td />
                  </tr>
                {/if}
                {#each userPerformance?.initData || [] as item, index}
                  <tr>
                    <td>
                      {item.user ? item.user : $t("dashs.user.assignedToGroup")}
                    </td>
                    <td class="text-end">
                      {Number(item.tasks.active).toLocaleString()}</td
                    >
                    <td class="text-end">
                      {Number(item.tasks.closed).toLocaleString()}</td
                    >
                    <td class="text-end">
                      {Number(item.tasks.failed).toLocaleString()}</td
                    >
                    <td class="text-end">
                      {Number(item.tasks.paused).toLocaleString()}</td
                    >
                    <td class="text-end"
                      >{Number(item.tasks.all).toLocaleString()}</td
                    >
                  </tr>
                {/each}
              {/if}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  .view-height {
    height: calc(100vh-400px);
  }
</style>
