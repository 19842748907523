<script>
  import { fade, fly } from "svelte/transition";
  import { quintOut } from "svelte/easing";
  import { tNow } from "../../services/i18n.service";

  export let open = false;
  export let autoClose = true;
  export let showBackdrop = true;
  export let onClosed = undefined;
  export let title = "Modal title";
  export let modalButtons = [
    { label: tNow("generic.close"), action: "close", class: "btn-secondary" },
  ];
  export let size = "md";
  export let headerHide = false;
  export let closeOnEscape = false;
  export let autofocus = true;
  let modalComponent;

  $: focus(open, modalComponent);

  /**
   * @param {boolean} open
   * @param {{ focus: () => void; }} modalComponent
   */
  function focus(open, modalComponent) {
    if (!autofocus) return;
    if (modalComponent && open) modalComponent.focus();
  }

  const modalClose = (/** @type {string} */ action) => {
    // check if there is validation:
    const validation = modalButtons.find(
      (btn) => btn.action === action
    )?.validation;
    if (validation) {
      let isValid = true;
      if (validation) isValid = validation();
      if (!isValid) return;
    }
    closeModal(action);
  };

  /**
   * @param {string} action
   */
  function closeModal(action) {
    if (autoClose) open = false;
    if (onClosed) {
      onClosed(action);
    }
  }
</script>

{#if open}
  <div
    class="modal"
    id="sampleModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="sampleModalLabel"
    aria-hidden={false}
    bind:this={modalComponent}
    on:keyup={(ev) => {
      if (!closeOnEscape) return;
      if (ev.keyCode === 27) modalClose("close");
    }}
  >
    <div
      class="modal-dialog modal-{size}"
      role="document"
      in:fly={{ y: -50, duration: 300 }}
      out:fly={{ y: -50, duration: 300, easing: quintOut }}
    >
      <div class="modal-content">
        {#if !headerHide}
          <div class="modal-header">
            <h5 class="modal-title" id="sampleModalLabel">{title}</h5>
            <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close" on:click={() => modalClose('close')}>
            <span aria-hidden="true">&times;</span>
          </button> -->
          </div>
        {/if}
        <div class="modal-body modal-body-height">
          <slot />
        </div>
        <div class="modal-footer">
          {#each modalButtons as button}
            <button
              type="button"
              class="btn {button.class || ''}"
              on:click={() => modalClose(button.action)}>{button.label}</button
            >
            <!-- <button type="button" class="btn btn-primary" on:click={() => modalClose('save')}>Save changes</button> -->
          {/each}
        </div>
      </div>
    </div>
  </div>
  {#if showBackdrop}
    <div class="modal-backdrop show" transition:fade={{ duration: 150 }} />
  {/if}
{/if}

<style>
  .modal {
    display: block;
  }
  .modal-fullscreen {
    width: 90vw;
    max-width: none;
    height: 90%;
    margin: auto;
    margin-top: 50px;
  }
  .modal-body-height {
    max-height: calc(100vh - 200px);
    overflow-y: scroll;
  }
</style>
