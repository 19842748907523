<script>
  import { onDestroy, onMount, tick } from "svelte";
  import { currentLanguage, t } from "../../../services/i18n.service";
  import {
    substores,
    taskExeBusinessObject,
    taskExeErrorStore,
    taskExeStore,
  } from "../task-exe-store";
  import TaskExeTplI18nLabel from "./task-exe-tpl-i18n-label.svelte";
  import taskExeApi from "../task-exe.api";
  import { isLoading } from "../../../stores";
  import apiService from "../../../services/api.service";
  import taskExeErrorAndVisibilityService from "./../task-exe-error-and-visibility.service.js";
  import { get } from "svelte/store";
  import taskExeVisibilityTree from "../task-exe-visibility-tree";

  /** @type {{id: string;}|undefined} */
  export let item = undefined;
  export let props = {};
  /** @type {{id: string; type: string;}[]} */
  export let parent = undefined;
  export let visibilityFromParent = "editable";
  /** @type {{id: string; tplId: string;}|null} */
  export let templateProps = null;

  let hasError = false;
  let showHelp = false;
  let value;
  let myVisibility;
  let dynamicBoStore;
  let taskExeBusinessObjectUnsubscribe;
  let taskExeErrorStoreUnsubscribe;
  const pathToStore = parent
    .filter((el) => el.type === "tpl-preview")
    .map((el) => el.id)
    .join("_");
  const pathToStoreNamed = pathToStore ? pathToStore : "general";
  const widgetId = pathToStore ? pathToStore + "_" + item.id : item.id;

  let visibilityStoreUnsubscribe;
  const visibilityStore = taskExeVisibilityTree.checkVisibilityStore(
    item,
    parent,
  );
  if (visibilityStore) {
    visibilityStoreUnsubscribe = visibilityStore.subscribe(
      (/** @type {string} */ res) => {
        myVisibility = res;
      },
    );
  }

  $: checkVisWithParent(myVisibility, visibilityFromParent); 

  function checkVisWithParent(_myVisibility, _visibilityFromParent) {
    myVisibility = taskExeVisibilityTree.checkVisOfElementWithParent(_visibilityFromParent, _myVisibility, props)
  }

  // $: passHasErrorToParent(hasError);

  onMount(() => {
    props.isFirstValidation = true;

    /* check if item is inside template in order to
     * subscribe to a sub store of the
     * business object:
     */

    if (pathToStore) {
      if (substores.bos?.[pathToStore]?.data) {
        dynamicBoStore = substores.bos[pathToStore].data;
      } else {
        dynamicBoStore = taskExeBusinessObject;
      }
    } else {
      dynamicBoStore = taskExeBusinessObject;
    }

    const firstValue = apiService.getNestedFromPath(
      get(dynamicBoStore),
      props.varName,
    );
    dynamicBoStore.ensureValue(props.varName, firstValue, [pathToStoreNamed]);

    taskExeErrorStoreUnsubscribe = taskExeErrorStore.subscribe(() => {
      if (props.isFirstValidation === true) return;
      const resultErrVars =
        taskExeErrorAndVisibilityService.getErrorVars(props);
      hasError = resultErrVars.hasError;
      showHelp = resultErrVars.showHelp;
    });

    taskExeBusinessObjectUnsubscribe = dynamicBoStore.subscribe((bo) => {
      if (!$taskExeStore) return;
      const newTempValue = apiService.getNestedFromPath(bo, props.varName);
      if (value !== newTempValue) value = newTempValue;
    });

    setTimeout(() => {
      props.isFirstValidation = false;
    }, 100);
  });

  onDestroy(() => {
    taskExeBusinessObjectUnsubscribe();
    taskExeErrorStoreUnsubscribe();
    if (visibilityStoreUnsubscribe) visibilityStoreUnsubscribe();
  });

  /**
   * @param {boolean} hasError
   */
  function passHasErrorToParent(hasError) {
    parent.errors = { ...parent.errors, [item.id]: hasError };
  }

  function valueChanged() {
    dynamicBoStore.ensureValue(props.varName, value, [pathToStoreNamed]);
    taskExeErrorAndVisibilityService.checkValidation(props);
    taskExeApi.submit({
      id: item.id,
      boStore: dynamicBoStore,
      tplId: templateProps?.tplId,
    });
  }
</script>

<!-- {JSON.stringify($dynamicBoStore)} -->
{#if myVisibility !== "hidden"}
  <div id={item.id}>
    {#if !props.hideLabel}
      <label for={widgetId} class="form-label">
        <TaskExeTplI18nLabel bind:props />{props.required ? "*" : ""}
      </label>
    {/if}
    {#if !props.hasButton}
      {#if props.inputType === "number"}
        <input
          type="number"
          id={widgetId}
          placeholder={props.placeholder_i18n
            ? props.placeholder_i18n[$currentLanguage]
            : props.placeholder}
          class="form-control {props.class}"
          class:mb-3={!showHelp}
          style={props.style}
          aria-labelledby={props.label_i18n
            ? props.label_i18n[$currentLanguage]
            : props.label}
          bind:value
          on:change={valueChanged}
          disabled={myVisibility === "disabled" || $isLoading}
        />
      {:else if props.inputType === "email"}
        <input
          type="email"
          id={widgetId}
          placeholder={props.placeholder_i18n
            ? props.placeholder_i18n[$currentLanguage]
            : props.placeholder}
          class="form-control {props.class}"
          class:mb-3={!showHelp}
          style={props.style}
          aria-labelledby={props.label_i18n
            ? props.label_i18n[$currentLanguage]
            : props.label}
          bind:value
          on:change={valueChanged}
          disabled={myVisibility === "disabled" || $isLoading}
        />
      {:else if props.inputType === "password"}
        <input
          type="password"
          id={widgetId}
          placeholder={props.placeholder_i18n
            ? props.placeholder_i18n[$currentLanguage]
            : props.placeholder}
          class="form-control {props.class}"
          class:mb-3={!showHelp}
          style={props.style}
          aria-labelledby={props.label_i18n
            ? props.label_i18n[$currentLanguage]
            : props.label}
          bind:value
          on:change={valueChanged}
          disabled={myVisibility === "disabled" || $isLoading}
        />
      {:else}
        <input
          type="text"
          id={widgetId}
          placeholder={props.placeholder_i18n
            ? props.placeholder_i18n[$currentLanguage]
            : props.placeholder}
          class="form-control {props.class || ''}"
          class:is-invalid={hasError}
          class:mb-3={!showHelp}
          style={props.style}
          aria-labelledby={props.label_i18n
            ? props.label_i18n[$currentLanguage]
            : props.label}
          bind:value
          on:change={valueChanged}
          disabled={myVisibility === "disabled" || $isLoading}
        />
      {/if}
    {:else}
      <div class="input-group" class:mb-3={!showHelp}>
        <input
          type="text"
          id={widgetId}
          placeholder={props.placeholder_i18n
            ? props.placeholder_i18n[$currentLanguage]
            : props.placeholder}
          class="form-control {props.class}"
          style={props.style}
          aria-labelledby={props.label_i18n
            ? props.label_i18n[$currentLanguage]
            : props.label}
          bind:value
          on:change={valueChanged}
          disabled={myVisibility === "disabled" || $isLoading}
        />
        <button
          class="btn {props.btnClass
            ? props.btnClass === 'default'
              ? 'btn-outline-secondary'
              : 'btn-' +
                (props.btnClassOutline ? 'outline-' : '') +
                props.btnClass
            : 'btn-outline-secondary'}"
          type="button"
          id="button-addon2"
          disabled={myVisibility === "disabled" || $isLoading}
          on:click={() => {
            taskExeApi.submit({
              id: item.id,
              boStore: dynamicBoStore,
              tplId: templateProps?.tplId,
            });
          }}
        >
          {(props.buttonLabel_i18n
            ? props.buttonLabel_i18n[$currentLanguage]
            : props.buttonLabel) || $t("generic.label")}
        </button>
      </div>
    {/if}

    {#if showHelp}
      <div id={props.id + "-HelpBlock"} class="invalid-feedback mb-3">
        {props?.validationMsg_i18n?.[$currentLanguage]
          ? props.validationMsg_i18n[$currentLanguage]
          : props.validationMsg}
      </div>
    {/if}
  </div>
{/if}

<!-- parent: {JSON.stringify(parent)} / -->
<!-- id: {item.id} <br /> -->
<!-- oldVisibility: {newVisibility_old} <br /> -->
<!-- newVisibility: {newVisibility} <br /> -->
<!-- visibilityFromParent: {visibilityFromParent} <br /> -->
<!-- visibilityVar: {props.visibilityVar}<br /> -->
<!-- props.visibility: {props.visibility}<br /> -->
<!-- hasError: {hasError}<br /> -->
<!-- showHelp: {showHelp}<br /> -->
<!-- {JSON.stringify(props)}/ -->
<!-- {JSON.stringify($taskExeErrorStore)} -->
