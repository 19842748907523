<script>
  import { Fullscreen, FullscreenExit } from "svelte-bootstrap-icons";
  import storageService from "../../services/storage.service";
  import { layoutFluid } from "./../../stores";
  import { onMount } from "svelte";

  let layout = "container-fluid";

  onMount(() => {
    toggleLayout();
  });

  /**
   * @param {string} [which]
   */
  function toggleLayout(which) {
    if (!which) {
      const savedLayout = storageService.get("layout");
      if (savedLayout && savedLayout !== "undefined") layout = savedLayout;
      else layout = "container-fluid";
    } else {
      layout = which;
    }
    storageService.save("layout", layout);
    layoutFluid.set(layout);
  }
</script>

<div class="btn-group" role="group" aria-label="Change layout">
  {#if layout === "container"}
    <button
      type="button"
      class="btn btn-outline-secondary"
      on:click={() => toggleLayout("container-fluid")}
    >
      <Fullscreen width="20" height="20" />
    </button>
  {:else}
    <button
      type="button"
      class="btn btn-outline-secondary"
      on:click={() => toggleLayout("container")}
    >
      <FullscreenExit width="20" height="20" />
    </button>
  {/if}
</div>
