<script>
  import { t } from "../../services/i18n.service";
  import { userStore } from "../../stores";
  import appManagementService from "../app-management/app-management.service";
  import roleService from "../designer/role/role.service";
  import FieldSelect from "../utils/field-select.svelte";

  let structure;
  let orgs = [];
  let pbs = [];
  let roles = [];
  let rolesForOrg = [];
  let selectedOrganisationId;
  let selectedProcessBucketId;
  let selectedRoleId;
  export let selectedOrganisation;
  export let selectedProcessBucket;
  export let selectedRole;

  appManagementService.getExposedAll().then((res) => {
    structure = res.data;
    getOrgMap();
  });

  function getOrgMap() {
    Object.entries(structure.orgs).forEach(([orgId, org]) => {
      // get orgs list:
      orgs.push({ key: orgId, value: org.name });
      // get its process buckets:
      const processBuckets = Object.entries(structure.pas).filter(
        ([pbId, pb]) => {
          if (pb.orgId === orgId) return true;
        }
      );
      // attach cu current org:
      org.pbs = processBuckets.reduce((o, el) => {
        o[el[0]] = el[1];
        return o;
      }, {});
    });
    orgs = orgs;
  }

  function getRolesForOrganisation(orgId) {
    roleService.getAll(orgId, { withUsers: true }).then((res) => {
      rolesForOrg = res.data;
    });
  }
</script>

<div class="row">
  <div class="col-md-4">
    <FieldSelect
      label={$t("generic.org")}
      bind:value={selectedOrganisationId}
      bind:list={orgs}
      translate={true}
      on:callback={() => {
        if (!selectedOrganisationId) {
          pbs = [];
          roles = [];
          selectedOrganisationId = null;
          selectedOrganisation = null;
          selectedProcessBucketId = null;
          selectedProcessBucket = null;
          selectedRoleId = null;
          selectedRole = null;
          return;
        }
        getRolesForOrganisation(selectedOrganisationId);
        selectedOrganisation = structure.orgs[selectedOrganisationId];
        pbs = Object.entries(structure.orgs[selectedOrganisationId].pbs).map(
          ([pbId, pb]) => {
            return { key: pbId, value: pb.name };
          }
        );
        selectedProcessBucketId = null;
        selectedProcessBucket = null;
        selectedRoleId = null;
        selectedRole = null;
      }}
    />
  </div>
  <div class="col-md-4">
    <FieldSelect
      label={$t("appMgmt.procBucket")}
      bind:value={selectedProcessBucketId}
      bind:list={pbs}
      translate={true}
      on:callback={() => {
        if (!selectedProcessBucketId) {
          roles = [];
          selectedProcessBucketId = null;
          selectedProcessBucket = null;
          selectedRoleId = null;
          selectedRole = null;
          return;
        }
        selectedProcessBucket = structure.pas[selectedProcessBucketId];
        selectedRoleId = null;
        selectedRole = null;
        roles = rolesForOrg
          .filter((el) => el.paid === selectedProcessBucketId)
          .map((el) => {
            return { key: el._id, value: el.name };
          });
      }}
    />
  </div>
  <div class="col-md-4">
    <FieldSelect
      label={$t("generic.roles")}
      bind:value={selectedRoleId}
      bind:list={roles}
      translate={true}
      on:callback={() => {
        if (!selectedRoleId) {
          selectedRoleId = null;
          selectedRole = null;
          return;
        }
        // selectedRole = rolesForOrg.find((el) => el._id === selectedRoleId);
        roleService
          .getOne(selectedRoleId, {
            orgId: selectedOrganisationId,
            withUsers: true,
          })
          .then((res) => {
            selectedRole = res.data;
          });
      }}
    />
  </div>
</div>
