<script>
  import { onMount } from "svelte";
  import { gridSizeStore } from "./../../stores";

  let currentGridSize = "xs"; // Default value for grid size

  function getCurrentGridSize() {
    const windowWidth = window.innerWidth;

    if (windowWidth < 576) {
      return "xs";
    } else if (windowWidth >= 576 && windowWidth < 768) {
      return "sm";
    } else if (windowWidth >= 768 && windowWidth < 992) {
      return "md";
    } else if (windowWidth >= 992 && windowWidth < 1200) {
      return "lg";
    } else {
      return "xl";
    }
  }

  onMount(() => {
    // Set the initial grid size on component mount
    currentGridSize = getCurrentGridSize();
    gridSizeStore.set(currentGridSize);

    // Update the grid size whenever the window is resized
    window.addEventListener("resize", () => {
      currentGridSize = getCurrentGridSize();
      gridSizeStore.set(currentGridSize);
    });
  });
</script>

<!-- <div> -->
<!-- Your component's content goes here -->
<!-- You can use the 'currentGridSize' variable in your component's template -->
<!-- <p>Current Grid Size: {currentGridSize}</p> -->
<!-- </div> -->
