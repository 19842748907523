import axios from "axios"

export default {
  get,
  getOne,
  count,
  validate,
  save,
  // getConnections,
  // remove,
  getWhereUsed,
}

/**
 * @param {object} query
 */
function get(query) {
  return axios.get('/api/rest/bo/integrationDefinition', { params: query });
}

/**
 * @param {string} id
 */
function getOne(id) {
  return axios.get('/api/bpm/getIntegrationDef', { params: { id } });
}


/**
 * @param {object} query
 */
function count(query) {
  return axios.get('/api/rest/bo/integrationDefinition', { params: { ...query, countDoc: true } });
}

/**
 * @param {object} obj
 */
function save(obj) {
  return axios.post('/api/bpm/saveIntegrationDefs', obj);
}

function getConnections() {
  return axios.get('/api/bpm/getIntegrationsConnections');
}

/**
 * @param {string} id
 */
function remove(id) {
  return axios.post('/api/bpm/deleteIntegrationDef', undefined, { params: { id } });
}

/**
 * @param {string} id
 */
function getWhereUsed(id) {
  return axios.post('/api/bpm/whereUsedIntegrationDef', undefined, { params: { id } });
}

/**
 * @param {any} obj
 */
function validate(obj) {
  let validation = { isOk: true, info: {} }
  if (!obj.name) validation.info.name = true;
  validation.isOk = Object.keys(validation.info).length === 0;
  return validation;
}
