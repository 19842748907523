import axios from "axios"
import apiService from "../../services/api.service";

export default {
  get,
  count,
  save,
  changePassword,
}

/**
 * @param {any} query
 */
function get(query) {
  return axios.get('/api/rest/bo/user', { params: { ...query } });
}

/**
 * @param {any} query
 */
function count(query) {
  return axios.get('/api/rest/bo/user', { params: { ...query, countDoc: true } });
}

/**
 * @param {any} obj
 */
function save(obj) {
  return axios.post('/api/rest/bo/user/' + obj._id, obj)
}

/**
 * @param {{id: string; email: string; oldPass: string; newPass: string; newPass2: string;}} obj
 */
function changePassword(obj) {
  const dataEncoded = apiService.dataconv.encode(obj);
  return axios.post('/api/bpm/changePassword', { i: dataEncoded });
}

