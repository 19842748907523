import axios from "axios"

export default {
  get,
  count,
  save,
}

/**
 * @param {object} query
 */
function get(query) {
  return axios.get('/api/bpm/getSchedules', { params: query });
}

/**
 * @param {object} query
 */
function count(query) {
  return axios.get('/api/bpm/getSchedulesTotal', { params: {...query, countDoc: true} });
}

/**
 * @param {string} pid
 * @param {object} obj
 */
function save(pid, obj) {
  return axios.post('/api/bpm/saveSchedule', obj, {params: {pid}});
}
