import axios from "axios"

export default {
  setCurrentOrg,
  save,
  unlink,
}

/**
 * @param {string} orgId
 */
function setCurrentOrg(orgId) {
  return axios.post('/api/bpm/setOrganisationAsPrimary', {}, { params: { orgId } })
}

/**
 * @param {any} org
 */
function save(org) {
  return axios.post('/api/rest/bo/organisation/' + org._id, org);
}

/**
 * @param {string} id
 */
function unlink(id) {
  return axios.post('/api/bpm/unlinkOrg?orgId=' + id);

}
