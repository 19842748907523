import { writable } from "svelte/store"
import { isFunction, uniq } from "underscore";
import apiService from "../../services/api.service";

function createTaskExeStore() {
  const { subscribe, set, update } = writable();
  return {
    subscribe,
    set,
    update,
  }
}

export const taskExeStore = createTaskExeStore();

export const taskExeTitle = writable();

// Used f
// Used for Business Local Object throughout the UI:
/**
 * @param {{bo?: any; parentBo?: any; tplProps: any; childrenBOs: string[];}} [setts]
 */
function createTaskExeBusinessObject(setts) {
  const { bo, parentBo, tplProps, childrenBOs } = setts || { bo: {}, childrenBOs: [] };
  const pathOfParents = tplProps ? tplProps.parents.map(el => el.id).join('_') : null;
  const pathOfParentsStore = pathOfParents ? pathOfParents : 'general';
  const currentPathToStore = pathOfParents ? pathOfParents + '_' + tplProps.id : tplProps?.id || 'general';
  const { subscribe, set, update } = writable(bo);
  let currentBo;
  return {
    subscribe,
    set,
    update,
    addChildBo: (substoreId) => {
      childrenBOs.push(substoreId);
    },
    ensureValue: (
      /** @type {string} */ path,
      /** @type {any} */ value,
      /** @type {string[]}  */ excludeChildStoreIds,
      /** @type {boolean}  */ calledFromStore
    ) => {
      // console.log('... ensure value', path, value, excludeChildStoreIds)
      if (!excludeChildStoreIds) excludeChildStoreIds = [];
      if (!path) return;
      // update current bo:
      update(businessObject => {
        if (!businessObject) businessObject = {};
        // check if there is already a value:
        const pathArray = path.split('.');
        const pathArrayLen = pathArray.length;
        pathArray.reduce((obj, path, pathIx) => {
          let element;
          if (path) {
            if (pathIx === pathArrayLen - 1) {
              if (!obj[path]) obj[path] = value;
              else obj[path] = value;
            } else {
              if (!obj[path]) obj[path] = {};
            }
            element = obj[path];
          } else {
            element = obj;
          }
          return element;
        }, businessObject);
        currentBo = businessObject;
        return businessObject;
      })

      // parent bo:
      // check if there is IO Mapping with the parent:
      const mappings = tplProps?.io?.in?.filter(el => el.varMapped ? true : false) || [];
      // ensure value in parent BO:
      if (tplProps && parentBo && mappings.length > 0 && !excludeChildStoreIds.includes(pathOfParentsStore)) {
        // don't update the same store
        // should be the origin of the variable:
        const myPath = path.split('.')[0];
        const myValue = bo[myPath];
        const parentPath = tplProps.io.in.find(el => el.var === myPath);
        if (parentPath) {
          parentBo.ensureValue(parentPath.varMapped, myValue, uniq([...excludeChildStoreIds, currentPathToStore || 'general']), true);
        }
      }
      // update values in sub bo:
      if (childrenBOs.length > 0) {
        childrenBOs.forEach(substoreId => {
          // don't update the same store
          if (excludeChildStoreIds.includes(substoreId)) return;
          // update only if there is a mapping variable:
          const childSubstore = substores.bos[substoreId];
          if (!childSubstore) return;
          const parentPaths = childSubstore.def.io.in.filter(el => el.varMapped ? true : false);
          if (parentPaths.length > 0) {
            parentPaths.forEach(map => {
              const value = currentBo[map.varMapped];
              childSubstore.data.ensureValue(map.var, value, uniq([...excludeChildStoreIds, substoreId || 'general']), true);
            })
          }
        })
      }
    },
    updateBO: (bo, updateOthers, excludeStoreIds) => {
      if (!excludeStoreIds) excludeStoreIds = [];
      if (updateOthers) excludeStoreIds = uniq([...excludeStoreIds, currentPathToStore || 'general']);

      let currentBo;
      // update current bo store:
      update(businessObject => {
        if (!businessObject) businessObject = {};
        Object.assign(businessObject, bo);
        // Object.keys(bo).forEach(key => {
        //   businessObject[key] = bo[key];
        // })
        currentBo = businessObject;
        return businessObject;
      });
      // INFO: check if there is need to update parent and children bo stores:
      // for this check the mappings and for each map do an ensure value

      if (!updateOthers) return;

      // parent bo:
      // check if there is IO Mapping with the parent:
      const mappings = tplProps?.io?.in?.filter(el => el.varMapped ? true : false) || [];
      if (!excludeStoreIds.includes(pathOfParentsStore)) {
        if (tplProps && parentBo && mappings.length > 0) {
          // create temp bo with only mapping values in order to update the parent bo:
          const tempBo = {};
          mappings.forEach(map => {
            const myValue = currentBo[map.var]
            const parentPath = map.varMapped;
            Object.assign(tempBo, { [parentPath]: myValue });
          })
          parentBo.updateBO(tempBo, true, uniq([...excludeStoreIds, pathOfParentsStore || 'general']))
        }
      }

      // return;
      // children:
      if (childrenBOs.length > 0) {
        childrenBOs.forEach(substoreId => {
          // don't update the same store
          if (excludeStoreIds.includes(substoreId)) return;
          // update only if there is a mapping variable:
          const childSubstore = substores.bos[substoreId];
          if (!childSubstore) return;
          const parentPaths = childSubstore.def.io.in.filter(el => el.varMapped ? true : false);
          if (parentPaths.length > 0) {
            const tempBo = {};
            parentPaths.forEach(map => {
              const myValue = currentBo[map.varMapped];
              const childPath = map.var;
              Object.assign(tempBo, { [childPath]: myValue });
            });
            childSubstore.data.updateBO(tempBo, true, uniq([...excludeStoreIds, substoreId || 'general']));
          }
        })
      }
    }
  }
}

export const taskExeBusinessObject = createTaskExeBusinessObject();

// Used for templates local objects:
/**
 * @type {{
 *  bos: {
 *    [k: string]: {
 *      def: any;
 *      data: any;
 *    }
 *  };
 *  length: Number;
 *  clear: () => void;
 *  initiate: (templateProps: {id: string; path: string; parents: any[];}) => void;
 *  excludeLocals: string[]; // used for local BO exclusion when updating a parent BO from local and do not re-update the same local
 *  updateLocals: (bo: any) => void;
 * }} substores
 */
export const substores = {
  bos: {},
  length: 0,
  excludeLocals: [],
  clear: () => {
    Object.keys(substores.bos).forEach(key => {
      const tempstore = substores.bos[key].data;
      // destroy substore;
      if (isFunction(tempstore)) tempstore();
    })
    // clear bos: 
    substores.bos = {};
    substores.length = 0;
    // console.log('... substores clear', substores)
  },
  initiate: (templateProps) => {
    // const tplId = templateProps.id;
    // const storeId = tplId.replace('tpl-preview', '');
    const templatesParentPath = templateProps.parents.map(el => el.id).join('_');
    const pathToStore = templatesParentPath ? templatesParentPath + '_' + templateProps.id : templateProps.id;
    const pathToParentStore = templatesParentPath ? templatesParentPath : null;

    // console.log('... initiate substore', templateProps.id, pathToStore, pathToParentStore)
    // console.table({
    //   id: templateProps.id,
    //   pathToStore,
    //   pathToParentStore
    // })
    // console.log('... substores', substores.bos)
    if (!substores.bos[pathToStore]) {
      // get current value from parent store:
      substores.bos[pathToStore] = {
        def: templateProps,
        data: createTaskExeBusinessObject({
          bo: {},
          parentBo: pathToParentStore ? substores.bos[pathToParentStore].data : taskExeBusinessObject,
          tplProps: templateProps,
          childrenBOs: []
        })
      };
      substores.length = Object.keys(substores.bos).length;

      // link child to parent:
      // check if parent bo is local or sublocal:
      if (!pathToParentStore) {
        taskExeBusinessObject.addChildBo(pathToStore);
      } else {
        substores.bos[pathToParentStore].data.addChildBo(pathToStore);
      }
      // console.log('... substores', substores)
    }
  },
  updateLocals: (bo) => {
    // console.log('>>>> update locals', bo);
    // check every bos and update its local based on properties:
    Object.keys(substores.bos).forEach(key => {
      // console.log('... substore local key', key, substores.excludeLocals)
      // const localToBeExcludedIndex = substores.excludeLocals.indexOf(key);
      // if (localToBeExcludedIndex > -1) {
      //   // remove from exclusion:
      //   substores.excludeLocals.splice(localToBeExcludedIndex, 1);
      //   return;
      // }

      const localBoObj = substores.bos[key];
      // if there is a local mapping create the store:
      if (localBoObj.def?.io?.in?.length > 0) {
        const newBo = {};
        const mappings = localBoObj.def.io.in.map(el => { return { local: el.var, parent: el.varMapped } });
        mappings.forEach(
          (
            /** @type {{ 
             *   parent: string; 
             *   local: string; 
             * }} */
            map
          ) => {
            // console.log('... update locals map', map);
            // if there's no variable mapped on parent then do not map:
            if (!map.parent) return;
            const firstValue = apiService.getNestedFromPath(bo, map.parent);
            newBo[map.local] = firstValue;
          })
        localBoObj.data.updateBO(newBo);
      }
    })
  }
}

// taskExeBusinessObject.subscribe(bo => {
//   if (!substores) return;
//   if (substores.length === 0) return;
//   // update sub-locals:
//   // TODO: uncomment this if it's not working or remove it if it does
//   // substores.updateLocals(bo);
// })

export const taskExeInfo = writable();

function createTaskExeErrorStore() {
  const { subscribe, set, update } = writable({ hasErrors: false, errors: [] });
  return {
    subscribe,
    clear: () => {
      update(obj => {
        obj.errors = [];
        obj.hasErrors = false;
        return obj;
      })
    },
    addIds: (/** @type {string[]} */ ids) => {
      update(obj => {
        ids.forEach(id => {
          if (!obj.errors.includes(id)) obj.errors.push(id);
        })
        obj.hasErrors = obj.errors.length > 0;
        return obj;
      })
    },
    add: (/** @type {string} */ id) => {
      update(obj => {
        if (!obj.errors.includes(id)) obj.errors.push(id);
        obj.hasErrors = obj.errors.length > 0;
        return obj;
      })
    },
    remove: (/** @type {string} */ id) => {
      update(obj => {
        const index = obj.errors.findIndex(el => el === id);
        if (index > -1) obj.errors.splice(index, 1);
        obj.hasErrors = obj.errors.length > 0;
        return obj;
      })

    },
    reset: () => { set({ hasErrors: false, errors: [] }) }
  }
}

export const taskExeErrorStore = createTaskExeErrorStore();

function createTaskExeCommand() {
  const { subscribe, set } = writable();
  return {
    subscribe,
    command: (action) => {
      set(action);
      set();
    }
  }
}

export const taskExeCommandStore = createTaskExeCommand();
