<script>
  import { toString } from "cronstrue/i18n";

  // export let value = "0 0 * 1/1 * ? *";
  export let value = "0 0/5 * 1/1 * ? *";
  // export let value = "* * * * * * *";
  // export let value = '4 0/2 * 1/1 * ? *';
  export let language = "en";
  export let showSeconds = false;
  export let showAdvanced = false;
  export let minutesStep = 1;

  let langs = ["en", "ro", "es"];
  let i18n = {
    en: {
      tab_minutes: "Minutes",
      tab_hourly: "Hourly",
      tab_daily: "Daily",
      tab_weekly: "Weekly",
      tab_monthly: "Monthly",
      tab_yearly: "Yearly",
      every: "Every",
      second_s: "second(s)",
      minute_s: "minute(s)",
      hour_s: "hour(s)",
      day_s: "day(s)",
      day: "day",
      onMinute: "on minute",
      onSecond: "on second",
      at: "at",
      of: "of",
      startTime: "Start time",
      weekDay: "week day",
      mon2fri: "Monday through Friday",
      mon: "Monday",
      tue: "Tuesday",
      wed: "Wednesday",
      thu: "Thursday",
      fri: "Friday",
      sat: "Saturday",
      sun: "Sunday",
      onThe: "On the",
      firstWeekday: "First Weekday",
      lastWeekday: "Last Weekday",
      lastDay: "Last Day",
      ofEvery: "of every",
      month_s: "month(s)",
      first: "First",
      second: "Second",
      third: "Third",
      fourth: "Fourth",
      fifth: "Fifth",
      last: "Last",
      jan: "January",
      feb: "February",
      mar: "March",
      apr: "April",
      may: "May",
      jun: "June",
      jul: "July",
      aug: "August",
      sep: "September",
      oct: "October",
      nov: "November",
      dec: "December",
    },
    ro: {
      tab_minutes: "Minutar",
      tab_hourly: "Orar",
      tab_daily: "Zilnic",
      tab_weekly: "Săptămânal",
      tab_monthly: "Lunar",
      tab_yearly: "Anual",
      every: "În/la fiecare",
      second_s: "secund(e)",
      minute_s: "minut(e)",
      hour_s: "oră(e)",
      day_s: "zi(le)",
      day: "zi",
      onMinute: "la minutul",
      onSecond: "la secunda",
      at: "la",
      of: "a lunii",
      startTime: "Start oră",
      weekDay: "zi a săptămânii",
      mon2fri: "de luni până vineri",
      mon: "luni",
      tue: "marți",
      wed: "miercuri",
      thu: "joi",
      fri: "vineri",
      sat: "sâmbătă",
      sun: "duminică",
      onThe: "În",
      firstWeekday: "Prima zi a săptămânii",
      lastWeekday: "Ultima zi a săptămânii",
      lastDay: "Ultima zi",
      ofEvery: "a fiecărei",
      month_s: "lună(i)",
      first: "Prima",
      second: "A doua",
      third: "A treia",
      fourth: "A patra",
      fifth: "A cincea",
      last: "Ultima",
      jan: "Ianuarie",
      feb: "Februarie",
      mar: "Martie",
      apr: "Aprilie",
      may: "Mai",
      jun: "Iunie",
      jul: "Iulie",
      aug: "August",
      sep: "Septembrie",
      oct: "Octombrie",
      nov: "Noiembrie",
      dec: "Decembrie",
    },
    es: {
      tab_minutes: "Minutos",
      tab_hourly: "Por hora",
      tab_daily: "Diario",
      tab_weekly: "Semanalmente",
      tab_monthly: "Mensual",
      tab_yearly: "Anualmente",
      every: "En/a cada",
      second_s: "segundo(s)",
      minute_s: "minuto(s)",
      hour_s: "hora(s)",
      day_s: "día(s)",
      day: "día",
      onMinute: "en el minuto",
      onSecond: "en segundo",
      at: "a",
      of: "del mes",
      startTime: "Hora de inicio",
      weekDay: "día de la semana",
      mon2fri: "de lunes a viernes",
      mon: "lunes",
      tue: "martes",
      wed: "miércoles",
      thu: "jueves",
      fri: "viernes",
      sat: "sábado",
      sun: "domingo",
      onThe: "En",
      firstWeekday: "Primer día de la semana",
      lastWeekday: "Último día de la semana",
      lastDay: "Último día",
      ofEvery: "de cada",
      month_s: "mes(es)",
      first: "Primero",
      second: "Segundo",
      third: "Tercero",
      fourth: "Cuarto",
      fifth: "Quinto",
      last: "Último",
      jan: "Enero",
      feb: "Febrero",
      mar: "Marzo",
      apr: "Abril",
      may: "Mayo",
      jun: "Junio",
      jul: "Julio",
      aug: "Agosto",
      sep: "Septiembre",
      oct: "Octubre",
      nov: "Noviembre",
      dec: "Deciembre",
    },
  };

  let use24HourTime = true;
  let tabs = [
    // "seconds",
    "minutes",
    "hourly",
    "daily",
    "weekly",
    "monthly",
    "yearly",
    "advanced",
  ];
  let valueTranslated = toString(value, { locale: language });
  const terminations = ["st", "nd", "rd", "th"];
  const seconds = new Array(60).fill(1).map((_el, ix) => ix);
  const minutes = new Array(60)
    .fill(1)
    .map((_el, ix) => {
      if (minutesStep > 1) {
        return ix % minutesStep === 0 ? ix : undefined;
      } else return ix;
    })
    .filter((el) => el !== undefined);
  const hours = new Array(24).fill(1).map((_el, ix) => ix);
  const days = new Array(31).fill(1).map((_el, ix) => ix + 1);
  const weekDaysShort = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];
  const weekDaysMap = {
    MON: t("mon"),
    TUE: t("tue"),
    WED: t("wed"),
    THU: t("thu"),
    FRI: t("fri"),
    SAT: t("sat"),
    SUN: t("sun"),
  };
  const weekDaysNo = [
    { key: "#1", val: t("first") },
    { key: "#2", val: t("second") },
    { key: "#3", val: t("third") },
    { key: "#4", val: t("fourth") },
    { key: "#5", val: t("fifth") },
    { key: "L", val: t("last") },
  ];
  const months = new Array(12).fill(1).map((_el, ix) => ix + 1);
  const monthlyDays = [
    { key: "1W", val: t("firstWeekday") },
    ...days.reduce((o, el) => {
      let termination;
      const dayStr = "" + el;
      const dayStrArr = dayStr.split("");
      const lastNoOfDay = dayStrArr.pop();
      if (lastNoOfDay === "1") termination = terminations[0];
      else if (lastNoOfDay === "2") termination = terminations[1];
      else if (lastNoOfDay === "3") termination = terminations[2];
      else termination = terminations[3];
      // return el + termination + " Day";
      // o.push({ key: dayStr, val: el + termination + " Day" });
      o.push({ key: dayStr, val: el + " " + t('day') });
      return o;
    }, []),
    { key: "LW", val: t("lastWeekday") },
    { key: "L", val: t("lastDay") },
  ];
  const monthNames = [
    { key: 1, val: t("jan") },
    { key: 2, val: t("feb") },
    { key: 3, val: t("mar") },
    { key: 4, val: t("apr") },
    { key: 5, val: t("may") },
    { key: 6, val: t("jun") },
    { key: 7, val: t("jul") },
    { key: 8, val: t("aug") },
    { key: 9, val: t("sep") },
    { key: 10, val: t("oct") },
    { key: 11, val: t("nov") },
    { key: 12, val: t("dec") },
  ];

  /** @type {{
   * seconds?: { seconds?: number, },
   * minutes?: { seconds?: number, minutes?: number, },
   * hourly?: { seconds?: number, minutes?: number, hours?: number, },
   * daily?: {
   *   dailyOpt?: string,
   *   everyDay?: {
   *     seconds?: number,
   *     minutes?: number,
   *     hours?: number,
   *     hourType?: string,
   *     days?: number,
   *   },
   *   everyWeekDay?: {
   *     seconds?: number,
   *     minutes?: number,
   *     hours?: number,
   *     hourType?: string,
   *   }
   * },
   * weekly?: {
   *   MON?: boolean,
   *   TUE?: boolean,
   *   WED?: boolean,
   *   THU?: boolean,
   *   FRI?: boolean,
   *   SAT?: boolean,
   *   SUN?: boolean,
   *   seconds?: number,
   *   minutes?: number,
   *   hours?: number,
   *   hourType?: string,
   * },
   * monthly?: {
   *   monthlyOpt?: string,
   *   specificDay?: {
   *     seconds?: number,
   *     minutes?: number,
   *     hours?: number,
   *     hourType?: string,
   *     day?: string,
   *     month?: number,
   *   },
   *   specificWeekDay?: {
   *     seconds?: number,
   *     minutes?: number,
   *     hours?: number,
   *     hourType?: string,
   *     month?: number,
   *     weekDay?: string,
   *     whichWeekDay?: string,
   *   }
   * },
   * yearly?: {
   *   yearlyOpt?: string,
   *   specificMonthDay?: {
   *     seconds?: number,
   *     minutes?: number,
   *     hours?: number,
   *     hourType?: string,
   *     day?: string,
   *     month?: number,
   *   },
   *   specificMonthWeek?: {
   *     seconds?: number,
   *     minutes?: number,
   *     hours?: number,
   *     hourType?: string,
   *     month?: number,
   *     weekDay?: string,
   *     whichWeekDay?: string,
   *   }
   * },
   * advanced?: string,
   * }} cronSetts
   */
  let cronSetts = {
    // seconds: {
    //   seconds: 0,
    // },
    minutes: {
      seconds: 0,
      minutes: minutes[1],
    },
    hourly: {
      seconds: 0,
      minutes: 0,
      hours: 0,
    },
    daily: {
      dailyOpt: "everyDay",
      everyDay: {
        seconds: 0,
        minutes: 0,
        hours: 0,
        hourType: "AM",
        days: 1,
      },
      everyWeekDay: {
        seconds: 0,
        minutes: 0,
        hours: 0,
        hourType: "AM",
      },
    },
    weekly: {
      MON: true,
      TUE: false,
      WED: false,
      THU: false,
      FRI: false,
      SAT: false,
      SUN: false,
      seconds: 0,
      minutes: 0,
      hours: 0,
      hourType: "AM",
    },
    monthly: {
      monthlyOpt: "specificDay",
      specificDay: {
        seconds: 0,
        minutes: 0,
        hours: 0,
        hourType: "AM",
        day: "1W",
        month: 1,
      },
      specificWeekDay: {
        seconds: 0,
        minutes: 0,
        hours: 0,
        hourType: "AM",
        month: 1,
        weekDay: "MON",
        whichWeekDay: "#1",
      },
    },
    yearly: {
      yearlyOpt: "specificMonthDay",
      specificMonthDay: {
        seconds: 0,
        minutes: 0,
        hours: 0,
        hourType: "AM",
        day: "1W",
        month: 1,
      },
      specificMonthWeek: {
        seconds: 0,
        minutes: 0,
        hours: 0,
        hourType: "AM",
        month: 1,
        weekDay: "MON",
        whichWeekDay: "#1",
      },
    },
    advanced: null,
  };
  let cronSettsBkup = JSON.stringify(cronSetts);
  let tabShow = "minutes";
  let valueBkUp = value;

  parseCron();

  function generateCron() {
    if ("minutes" === tabShow) {
      value = `${cronSetts.minutes.seconds} 0/${cronSetts.minutes.minutes} * 1/1 * ? *`;
    }
    if ("hourly" === tabShow) {
      value = `${cronSetts.hourly.seconds} ${cronSetts.hourly.minutes} 0/${cronSetts.hourly.hours} 1/1 * ? *`;
    }
    if ("daily" === tabShow) {
      if (cronSetts.daily.dailyOpt === "everyDay") {
        value = `${cronSetts.daily.everyDay.seconds} ${
          cronSetts.daily.everyDay.minutes
        } ${hourToCron(
          cronSetts.daily.everyDay.hours,
          cronSetts.daily.everyDay.hourType
        )} 1/${cronSetts.daily.everyDay.days} * ? *`;
      }
      if (cronSetts.daily.dailyOpt === "everyWeekDay") {
        value = `${cronSetts.daily.everyWeekDay.seconds} ${
          cronSetts.daily.everyWeekDay.minutes
        } ${hourToCron(
          cronSetts.daily.everyWeekDay.hours,
          cronSetts.daily.everyWeekDay.hourType
        )} ? * MON-FRI *`;
      }
    }
    if ("weekly" === tabShow) {
      const days = Object.keys(cronSetts.weekly)
        .reduce((o, key) => {
          if (weekDaysShort.includes(key) && cronSetts.weekly[key]) {
            o = [...o, key];
          }
          return o;
        }, [])
        .join(",");
      value = `${cronSetts.weekly.seconds} ${
        cronSetts.weekly.minutes
      } ${hourToCron(
        cronSetts.weekly.hours,
        cronSetts.weekly.hourType
      )} ? * ${days} *`;
    }
    if ("monthly" === tabShow) {
      if ("specificDay" === cronSetts.monthly.monthlyOpt) {
        value = `${cronSetts.monthly.specificDay.seconds} ${
          cronSetts.monthly.specificDay.minutes
        } ${hourToCron(
          cronSetts.monthly.specificDay.hours,
          cronSetts.monthly.specificDay.hourType
        )} ${cronSetts.monthly.specificDay.day} 1/${
          cronSetts.monthly.specificDay.month
        } ? *`;
      }
      if ("specificWeekDay" === cronSetts.monthly.monthlyOpt) {
        value = `${cronSetts.monthly.specificWeekDay.seconds} ${
          cronSetts.monthly.specificWeekDay.minutes
        } ${hourToCron(
          cronSetts.monthly.specificWeekDay.hours,
          cronSetts.monthly.specificWeekDay.hourType
        )} ? 1/${cronSetts.monthly.specificWeekDay.month} ${
          cronSetts.monthly.specificWeekDay.weekDay
        }${cronSetts.monthly.specificWeekDay.whichWeekDay} *`;
      }
    }
    if ("yearly" === tabShow) {
      if ("specificMonthDay" === cronSetts.yearly.yearlyOpt) {
        value = `${cronSetts.yearly.specificMonthDay.seconds} ${
          cronSetts.yearly.specificMonthDay.minutes
        } ${hourToCron(
          cronSetts.yearly.specificMonthDay.hours,
          cronSetts.yearly.specificMonthDay.hourType
        )} ${cronSetts.yearly.specificMonthDay.day} ${
          cronSetts.yearly.specificMonthDay.month
        } ? *`;
      }
      if ("specificMonthWeek" === cronSetts.yearly.yearlyOpt) {
        value = `${cronSetts.yearly.specificMonthWeek.seconds} ${
          cronSetts.yearly.specificMonthWeek.minutes
        } ${hourToCron(
          cronSetts.yearly.specificMonthWeek.hours,
          cronSetts.yearly.specificMonthWeek.hourType
        )} ? ${cronSetts.yearly.specificMonthWeek.month} ${
          cronSetts.yearly.specificMonthWeek.weekDay
        }${cronSetts.yearly.specificMonthWeek.whichWeekDay} *`;
      }
    }
    if ("advanced" === tabShow) {
      value = cronSetts.advanced;
    }
    translateCron();
  }

  function translateCron() {
    valueTranslated = toString(value, { locale: language });
  }

  /**
   * @param {string} which
   */
  function changeTab(which) {
    tabShow = which;
    value = valueBkUp;
    resetCronSetts();
    generateCron();
    translateCron();
  }

  function resetCronSetts() {
    cronSetts = JSON.parse(cronSettsBkup);
    cronSetts.minutes.minutes = minutes[1];
  }

  /**
   * @param {number} hours
   */
  function processHour(hours) {
    if (use24HourTime) {
      return hours;
    } else {
      return ((hours + 11) % 12) + 1;
    }
  }

  /**
   * @param {number} hours
   */
  function getHourType(hours) {
    return use24HourTime ? null : hours >= 12 ? "PM" : "AM";
  }

  function parseCron() {
    const cron = value;
    const segments = cron.split(" ");
    if (segments.length === 6 || segments.length === 7) {
      const [seconds, minutes, hours, dayOfMonth, month, dayOfWeek] = segments;
      if (cron.match(/\d+ 0\/\d+ \* 1\/1 \* \? \*/)) {
        tabShow = "minutes";
        cronSetts.minutes.minutes = parseInt(minutes.substring(2));
        cronSetts.minutes.seconds = parseInt(seconds);
      } else if (cron.match(/\d+ \d+ 0\/\d+ 1\/1 \* \? \*/)) {
        tabShow = "hourly";
        cronSetts.hourly.hours = parseInt(hours.substring(2));
        cronSetts.hourly.minutes = parseInt(minutes);
        cronSetts.hourly.seconds = parseInt(seconds);
      } else if (cron.match(/\d+ \d+ \d+ 1\/\d+ \* \? \*/)) {
        tabShow = "daily";
        cronSetts.daily.dailyOpt = "everyDay";
        cronSetts.daily.everyDay.days = parseInt(dayOfMonth.substring(2));
        const parsedHours = parseInt(hours);
        cronSetts.daily.everyDay.hours = processHour(parsedHours);
        cronSetts.daily.everyDay.hourType = getHourType(parsedHours);
        cronSetts.daily.everyDay.minutes = parseInt(minutes);
        cronSetts.daily.everyDay.seconds = parseInt(seconds);
      } else if (cron.match(/\d+ \d+ \d+ \? \* MON-FRI \*/)) {
        tabShow = "daily";
        cronSetts.daily.dailyOpt = "everyWeekDay";
        const parsedHours = parseInt(hours);
        cronSetts.daily.everyWeekDay.hours = processHour(parsedHours);
        cronSetts.daily.everyWeekDay.hourType = getHourType(parsedHours);
        cronSetts.daily.everyWeekDay.minutes = parseInt(minutes);
        cronSetts.daily.everyWeekDay.seconds = parseInt(seconds);
      } else if (
        cron.match(
          /\d+ \d+ \d+ \? \* (MON|TUE|WED|THU|FRI|SAT|SUN)(,(MON|TUE|WED|THU|FRI|SAT|SUN))* \*/
        )
      ) {
        tabShow = "weekly";
        Object.keys(cronSetts.weekly).forEach((key) => {
          if (weekDaysShort.includes(key)) cronSetts.weekly[key] = false;
        });
        dayOfWeek
          .split(",")
          .forEach((weekDay) => (cronSetts.weekly[weekDay] = true));
        const parsedHours = parseInt(hours);
        cronSetts.weekly.hours = processHour(parsedHours);
        cronSetts.weekly.hourType = getHourType(parsedHours);
        cronSetts.weekly.minutes = parseInt(minutes);
        cronSetts.weekly.seconds = parseInt(seconds);
      } else if (cron.match(/\d+ \d+ \d+ (\d+|L|LW|1W) 1\/\d+ \? \*/)) {
        tabShow = "monthly";
        cronSetts.monthly.monthlyOpt = "specificDay";
        cronSetts.monthly.specificDay.day = dayOfMonth;
        cronSetts.monthly.specificDay.month = parseInt(month.substring(2));
        const parsedHours = parseInt(hours);
        cronSetts.monthly.specificDay.hours = processHour(parsedHours);
        cronSetts.monthly.specificDay.hourType = getHourType(parsedHours);
        cronSetts.monthly.specificDay.minutes = parseInt(minutes);
        cronSetts.monthly.specificDay.seconds = parseInt(seconds);
      } else if (
        cron.match(
          /\d+ \d+ \d+ \? 1\/\d+ (MON|TUE|WED|THU|FRI|SAT|SUN)((#[1-5])|L) \*/
        )
      ) {
        const day = dayOfWeek.substr(0, 3);
        const monthWeek = dayOfWeek.substr(3);
        tabShow = "monthly";
        cronSetts.monthly.monthlyOpt = "specificWeekDay";
        cronSetts.monthly.specificWeekDay.whichWeekDay = monthWeek;
        cronSetts.monthly.specificWeekDay.weekDay = day;
        cronSetts.monthly.specificWeekDay.month = parseInt(month.substring(2));
        const parsedHours = parseInt(hours);
        cronSetts.monthly.specificWeekDay.hours = processHour(parsedHours);
        cronSetts.monthly.specificWeekDay.hourType = getHourType(parsedHours);
        cronSetts.monthly.specificWeekDay.minutes = parseInt(minutes);
        cronSetts.monthly.specificWeekDay.seconds = parseInt(seconds);
      } else if (cron.match(/\d+ \d+ \d+ (\d+|L|LW|1W) \d+ \? \*/)) {
        tabShow = "yearly";
        cronSetts.yearly.yearlyOpt = "specificMonthDay";
        cronSetts.yearly.specificMonthDay.month = parseInt(month);
        cronSetts.yearly.specificMonthDay.day = dayOfMonth;
        const parsedHours = parseInt(hours);
        cronSetts.yearly.specificMonthDay.hours = processHour(parsedHours);
        cronSetts.yearly.specificMonthDay.hourType = getHourType(parsedHours);
        cronSetts.yearly.specificMonthDay.minutes = parseInt(minutes);
        cronSetts.yearly.specificMonthDay.seconds = parseInt(seconds);
      } else if (
        cron.match(
          /\d+ \d+ \d+ \? \d+ (MON|TUE|WED|THU|FRI|SAT|SUN)((#[1-5])|L) \*/
        )
      ) {
        const day = dayOfWeek.substr(0, 3);
        const monthWeek = dayOfWeek.substr(3);
        tabShow = "yearly";
        cronSetts.yearly.yearlyOpt = "specificMonthWeek";
        cronSetts.yearly.specificMonthWeek.whichWeekDay = monthWeek;
        cronSetts.yearly.specificMonthWeek.weekDay = day;
        cronSetts.yearly.specificMonthWeek.month = parseInt(month);
        const parsedHours = parseInt(hours);
        cronSetts.yearly.specificMonthWeek.hours = processHour(parsedHours);
        cronSetts.yearly.specificMonthWeek.hourType = getHourType(parsedHours);
        cronSetts.yearly.specificMonthWeek.minutes = parseInt(minutes);
        cronSetts.yearly.specificMonthWeek.seconds = parseInt(seconds);
      } else {
        if (showAdvanced) {
          tabShow = "advanced";
          cronSetts.advanced = cron;
        } else {
          tabShow = "minutes";
        }
      }
    } else {
      throw new Error(
        "Unsupported cron expression. Expression must be 6 or 7 segments"
      );
    }
  }

  /**
   * @param {number} hour
   * @param {string} hourType
   */
  function hourToCron(hour, hourType) {
    if (use24HourTime) {
      return hour;
    } else {
      return hourType === "AM"
        ? hour === 12
          ? 0
          : hour
        : hour === 12
        ? 12
        : hour + 12;
    }
  }

  /**
   * @param {string} value
   */
  function t(value) {
    if (!langs.includes(language)) language = "en";
    return i18n?.[language]?.[value];
  }
</script>


<div>
  {#each tabs as tab}
    {#if showAdvanced || (!showAdvanced && tab !== "advanced")}
      <button
        class="btn btn-outline-secondary me-1 {tab === tabShow
          ? 'btn-primary text-white'
          : ''}"
        on:click={() => changeTab(tab)}>{t("tab_" + tab)}</button
      >
    {/if}
  {/each}
</div>
<hr />

{#if showAdvanced && tabShow === "advanced"}
  <div class="advanced">
    <input
      class="form-control form-control-sm d-inline-flex w-auto"
      type="text"
      bind:value={cronSetts.advanced}
      on:change={generateCron}
    />
  </div>
{/if}

{#if tabShow === "yearly"}
  <div class="yearly">
    <div>
      <div class="form-check d-inline-flex w-auto">
        <input
          class="form-check-input"
          type="radio"
          name="yearly-opts"
          bind:group={cronSetts.yearly.yearlyOpt}
          value="specificMonthDay"
          on:change={() => generateCron()}
        />
      </div>
      {t("every")}
      <select
        class="form-control form-control-sm d-inline-flex w-auto"
        bind:value={cronSetts.yearly.specificMonthDay.month}
        on:change={() => generateCron()}
      >
        {#each monthNames as item}
          <option value={item.key}>{item.val}</option>
        {/each}
      </select>
      <span class="text-lowercase">
        {t("onThe")}
      </span>
      <select
        class="form-control form-control-sm d-inline-flex w-auto"
        bind:value={cronSetts.yearly.specificMonthDay.day}
        on:change={() => generateCron()}
      >
        {#each monthlyDays as item}
          <option value={item.key}>{item.val}</option>
        {/each}
      </select>
      {t("at")}
      <select
        class="form-control form-control-sm d-inline-flex w-auto"
        bind:value={cronSetts.yearly.specificMonthDay.hours}
        on:change={() => generateCron()}
      >
        {#each hours as item}
          <option value={item}>{item}</option>
        {/each}
      </select>
      :
      <select
        class="form-control form-control-sm d-inline-flex w-auto"
        bind:value={cronSetts.yearly.specificMonthDay.minutes}
        on:change={() => generateCron()}
      >
        {#each minutes as item}
          <option value={item}>{item}</option>
        {/each}
      </select>
      {#if showSeconds}
        :
        <select
          class="form-control form-control-sm d-inline-flex w-auto"
          bind:value={cronSetts.yearly.specificMonthDay.seconds}
          on:change={() => generateCron()}
        >
          {#each seconds as item}
            <option value={item}>{item}</option>
          {/each}
        </select>
      {/if}
    </div>
    <div class="mt-3">
      <div class="form-check d-inline-flex w-auto">
        <input
          class="form-check-input"
          type="radio"
          name="yearly-opts"
          bind:group={cronSetts.yearly.yearlyOpt}
          value="specificMonthWeek"
          on:change={() => generateCron()}
        />
      </div>
      {t("onThe")}
      <select
        class="form-control form-control-sm d-inline-flex w-auto"
        bind:value={cronSetts.yearly.specificMonthWeek.whichWeekDay}
        on:change={() => generateCron()}
      >
        {#each weekDaysNo as item}
          <option value={item.key}>{item.val}</option>
        {/each}
      </select>
      <select
        class="form-control form-control-sm d-inline-flex w-auto"
        bind:value={cronSetts.yearly.specificMonthWeek.weekDay}
        on:change={() => generateCron()}
      >
        {#each Object.entries(weekDaysMap) as [key, val]}
          <option value={key}>{val}</option>
        {/each}
      </select>
      {t("of")}
      <select
        class="form-control form-control-sm d-inline-flex w-auto"
        bind:value={cronSetts.yearly.specificMonthWeek.month}
        on:change={() => generateCron()}
      >
        {#each monthNames as item}
          <option value={item.key}>{item.val}</option>
        {/each}
      </select>
      {t("at")}
      <select
        class="form-control form-control-sm d-inline-flex w-auto"
        bind:value={cronSetts.yearly.specificMonthWeek.hours}
        on:change={() => generateCron()}
      >
        {#each hours as item}
          <option value={item}>{item}</option>
        {/each}
      </select>
      :
      <select
        class="form-control form-control-sm d-inline-flex w-auto"
        bind:value={cronSetts.yearly.specificMonthWeek.minutes}
        on:change={() => generateCron()}
      >
        {#each minutes as item}
          <option value={item}>{item}</option>
        {/each}
      </select>
      {#if showSeconds}
        :
        <select
          class="form-control form-control-sm d-inline-flex w-auto"
          bind:value={cronSetts.yearly.specificMonthWeek.seconds}
          on:change={() => generateCron()}
        >
          {#each seconds as item}
            <option value={item}>{item}</option>
          {/each}
        </select>
      {/if}
    </div>
  </div>
{/if}

{#if tabShow === "monthly"}
  <div class="monthly">
    <div>
      <div class="form-check d-inline-flex w-auto">
        <input
          class="form-check-input"
          type="radio"
          name="monthly-opts"
          bind:group={cronSetts.monthly.monthlyOpt}
          value="specificDay"
          on:change={() => generateCron()}
        />
      </div>
      {t("onThe")}
      <select
        class="form-control form-control-sm d-inline-flex w-auto"
        bind:value={cronSetts.monthly.specificDay.day}
        on:change={() => generateCron()}
      >
        {#each monthlyDays as item}
          <option value={item.key}>{item.val}</option>
        {/each}
      </select>
      {t("ofEvery")}
      <select
        class="form-control form-control-sm d-inline-flex w-auto"
        bind:value={cronSetts.monthly.specificDay.month}
        on:change={() => generateCron()}
      >
        {#each months as item}
          <option value={item}>{item}</option>
        {/each}
      </select>
      {t("month_s")}
      <select
        class="form-control form-control-sm d-inline-flex w-auto"
        bind:value={cronSetts.monthly.specificDay.hours}
        on:change={() => generateCron()}
      >
        {#each hours as item}
          <option value={item}>{item}</option>
        {/each}
      </select>
      :
      <select
        class="form-control form-control-sm d-inline-flex w-auto"
        bind:value={cronSetts.monthly.specificDay.minutes}
        on:change={() => generateCron()}
      >
        {#each minutes as item}
          <option value={item}>{item}</option>
        {/each}
      </select>
      {#if showSeconds}
        :
        <select
          class="form-control form-control-sm d-inline-flex w-auto"
          bind:value={cronSetts.monthly.specificDay.seconds}
          on:change={() => generateCron()}
        >
          {#each seconds as item}
            <option value={item}>{item}</option>
          {/each}
        </select>
      {/if}
    </div>
    <div class="mt-3">
      <div class="form-check d-inline-flex w-auto">
        <input
          class="form-check-input"
          type="radio"
          name="monthly-opts"
          bind:group={cronSetts.monthly.monthlyOpt}
          value="specificWeekDay"
          on:change={() => generateCron()}
        />
      </div>
      {t("onThe")}
      <select
        class="form-control form-control-sm d-inline-flex w-auto"
        bind:value={cronSetts.monthly.specificWeekDay.whichWeekDay}
        on:change={() => generateCron()}
      >
        {#each weekDaysNo as item}
          <option value={item.key}>{item.val}</option>
        {/each}
      </select>
      <select
        class="form-control form-control-sm d-inline-flex w-auto"
        bind:value={cronSetts.monthly.specificWeekDay.weekDay}
        on:change={() => generateCron()}
      >
        {#each weekDaysShort as item}
          <option value={item}>{weekDaysMap[item]}</option>
        {/each}
      </select>
      {t("ofEvery")}
      <select
        class="form-control form-control-sm d-inline-flex w-auto"
        bind:value={cronSetts.monthly.specificWeekDay.month}
        on:change={() => generateCron()}
      >
        {#each months as item}
          <option value={item}>{item}</option>
        {/each}
      </select>
      {t("month_s")}
      {t("at")}
      <select
        class="form-control form-control-sm d-inline-flex w-auto"
        bind:value={cronSetts.monthly.specificWeekDay.hours}
        on:change={() => generateCron()}
      >
        {#each hours as item}
          <option value={item}>{item}</option>
        {/each}
      </select>
      :
      <select
        class="form-control form-control-sm d-inline-flex w-auto"
        bind:value={cronSetts.monthly.specificWeekDay.minutes}
        on:change={() => generateCron()}
      >
        {#each minutes as item}
          <option value={item}>{item}</option>
        {/each}
      </select>
      {#if showSeconds}
        :
        <select
          class="form-control form-control-sm d-inline-flex w-auto"
          bind:value={cronSetts.monthly.specificWeekDay.seconds}
          on:change={() => generateCron()}
        >
          {#each seconds as item}
            <option value={item}>{item}</option>
          {/each}
        </select>
      {/if}
    </div>
  </div>
{/if}

{#if tabShow === "weekly"}
  <div class="weekly">
    <div class="row">
      {#each weekDaysShort as item}
        <div class="col-6">
          <label class="form-check mt-1">
            <input
              class="form-check-input"
              type="checkbox"
              value={item}
              bind:checked={cronSetts.weekly[item]}
              on:change={() => generateCron()}
            />
            {weekDaysMap[item]}
          </label>
        </div>
      {/each}
    </div>
    <div class="mt-3">
      {t("startTime")}:
      <select
        class="form-control form-control-sm d-inline-flex w-auto"
        bind:value={cronSetts.weekly.hours}
        on:change={() => generateCron()}
      >
        {#each hours as item}
          <option value={item}>{item}</option>
        {/each}
      </select>
      :
      <select
        class="form-control form-control-sm d-inline-flex w-auto"
        bind:value={cronSetts.weekly.minutes}
        on:change={() => generateCron()}
      >
        {#each minutes as item}
          <option value={item}>{item}</option>
        {/each}
      </select>
      {#if showSeconds}
        :
        <select
          class="form-control form-control-sm d-inline-flex w-auto"
          bind:value={cronSetts.weekly.seconds}
          on:change={() => generateCron()}
        >
          {#each seconds as item}
            <option value={item}>{item}</option>
          {/each}
        </select>
      {/if}
    </div>
  </div>
{/if}

{#if tabShow === "daily"}
  <div class="daily">
    <div class="entity">
      <div class="form-check d-inline-flex w-auto">
        <input
          class="form-check-input"
          type="radio"
          name="daily-opt"
          bind:group={cronSetts.daily.dailyOpt}
          value="everyDay"
          on:change={() => generateCron()}
        />
      </div>
      {t("every")}
      <select
        class="form-control form-control-sm d-inline-flex w-auto"
        bind:value={cronSetts.daily.everyDay.days}
        on:change={() => generateCron()}
      >
        {#each days as item}
          <option value={item}>{item}</option>
        {/each}
      </select>
      {t("day_s")}
    </div>
    <div class="entity">
      {t("at")}
      <select
        class="form-control form-control-sm d-inline-flex w-auto"
        bind:value={cronSetts.daily.everyDay.hours}
        on:change={() => generateCron()}
      >
        {#each hours as item}
          <option value={item}>{item}</option>
        {/each}
      </select>
      :
      <select
        class="form-control form-control-sm d-inline-flex w-auto"
        bind:value={cronSetts.daily.everyDay.minutes}
        on:change={() => generateCron()}
      >
        {#each minutes as item}
          <option value={item}>{item}</option>
        {/each}
      </select>
      {#if showSeconds}
        :
        <select
          class="form-control form-control-sm d-inline-flex w-auto"
          bind:value={cronSetts.daily.everyDay.seconds}
          on:change={() => generateCron()}
        >
          {#each seconds as item}
            <option value={item}>{item}</option>
          {/each}
        </select>
      {/if}
    </div>

    <div>
      <div class="form-check d-inline-flex w-auto">
        <input
          class="form-check-input"
          type="radio"
          name="daily-opt"
          bind:group={cronSetts.daily.dailyOpt}
          value="everyWeekDay"
          on:change={() => generateCron()}
        />
      </div>
      {t("every")}
      {t("weekDay")}
      ({t("mon2fri")})
      {t("at")}
      <select
        class="form-control form-control-sm d-inline-flex w-auto"
        bind:value={cronSetts.daily.everyWeekDay.hours}
        on:change={() => generateCron()}
      >
        {#each hours as item}
          <option value={item}>{item}</option>
        {/each}
      </select>
      :
      <select
        class="form-control form-control-sm d-inline-flex w-auto"
        bind:value={cronSetts.daily.everyWeekDay.minutes}
        on:change={() => generateCron()}
      >
        {#each minutes as item}
          <option value={item}>{item}</option>
        {/each}
      </select>
      {#if showSeconds}
        :
        <select
          class="form-control form-control-sm d-inline-flex w-auto"
          bind:value={cronSetts.daily.everyWeekDay.seconds}
          on:change={() => generateCron()}
        >
          {#each seconds as item}
            <option value={item}>{item}</option>
          {/each}
        </select>
      {/if}
    </div>
  </div>
{/if}

{#if tabShow === "hourly"}
  <div class="hourly">
    <div class="entity">
      {t("every")}
      <select
        class="form-control form-control-sm d-inline-flex w-auto"
        bind:value={cronSetts.hourly.hours}
        on:change={() => generateCron()}
      >
        {#each hours as item}
          <option value={item}>{item}</option>
        {/each}
      </select>
      {t("hour_s")}
    </div>
    <div class="entity">
      {t("onMinute")}
      <select
        class="form-control form-control-sm d-inline-flex w-auto"
        bind:value={cronSetts.hourly.minutes}
        on:change={() => generateCron()}
      >
        {#each minutes as item}
          <option value={item}>{item}</option>
        {/each}
      </select>
    </div>
    {#if showSeconds}
      <div class="entity">
        {t("onSecond")}
        <select
          class="form-control form-control-sm d-inline-flex w-auto"
          bind:value={cronSetts.hourly.seconds}
          on:change={() => generateCron()}
        >
          {#each seconds as item}
            <option value={item}>{item}</option>
          {/each}
        </select>
      </div>
    {/if}
  </div>
{/if}

{#if tabShow === "minutes"}
  <div class="minutes">
    <div class="entity">
      {t("every")}
      <select
        class="form-control form-control-sm d-inline-flex w-auto"
        bind:value={cronSetts.minutes.minutes}
        on:change={() => generateCron()}
      >
        {#each minutes as item}
          {#if item !== 0}
            <option value={item}>{item}</option>
          {/if}
        {/each}
      </select>
      {t("minute_s")}
    </div>
    {#if showSeconds}
      <div class="entity">
        on second
        <select
          class="form-control form-control-sm d-inline-flex w-auto"
          bind:value={cronSetts.minutes.seconds}
          on:change={() => generateCron()}
        >
          {#each seconds as item}
            <option value={item}>{item}</option>
          {/each}
        </select>
      </div>
    {/if}
  </div>
{/if}

{#if tabShow === "seconds"}
  <div class="seconds">
    <div>
      {t("every")}
      <select
        class="form-control form-control-sm d-inline-flex w-auto"
        bind:value={cronSetts.seconds.seconds}
        on:change={() => generateCron()}
      >
        {#each seconds as item}
          <option value={item}>{item}</option>
        {/each}
      </select>
      {t("second_s")}
    </div>
  </div>
{/if}

<hr />
<div>
  Cron: {value}
</div>
<div>
  Cron translated: {valueTranslated}
</div>

<!-- <hr /> -->
<!-- <pre> -->
<!-- {JSON.stringify(cronSetts, null, 2)} -->
<!-- </pre> -->

<style>
  .entity {
    display: inline-block;
  }
  .form-check {
    position: relative;
    top: 2px;
  }
</style>
