import axios from "axios"

export default {
  getExposedAll,
  toggleStatus,
}

function getExposedAll() {
  return axios.get('/api/bpm/exposedall');
}

/**
 * @param {string} action
 * @param {string} type
 * @param {any} obj
 */
function toggleStatus(action, type, obj) {
  return axios.post('/api/bpm/toggleExposedStatus', { action, type, obj });
}
