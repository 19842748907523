import { writable } from "svelte/store";
import inboxService from "./inbox.service";

function createInboxStateStore() {
  const { subscribe, set, update } = writable();
  const obj = {
    state: 'normal', // normal, sites
  };
  set(obj);
  return {
    subscribe,
    set,
    setState: (state) => {
      update(obj => {
        obj.state = state;
        return obj;
      })
    },
  }
}

export const inboxStateStore = createInboxStateStore();

function createInboxCommandStore() {
  const { subscribe, set } = writable();
  return {
    subscribe,
    command: (/** @type {string} */ _command) => {
      set(_command);
      set(undefined);
    }
  }
}

export const inboxCommandStore = createInboxCommandStore();

// function createInboxTaskQueryStore() {
//   let query = {
//     sort: "-created_at",
//     limit: 10,
//     skip: 0,
//     q: null,
//   };
//   const { subscribe, set, update } = writable(query);
//   return {
//     subscribe,
//     set,
//     update,
//   }
// }
//
// export const inboxTaskQueryStore = createInboxTaskQueryStore();

function createInboxTaskTagsStore() {
  const { subscribe, set, update } = writable(['active']);
  return {
    subscribe,
    set,
    update,
    toggle: (/** @type {string} */ tag) => {
      update(tags => {
        let toggleAction;
        if (tags.includes(tag)) {
          tags = tags.filter(t => t !== tag);
          toggleAction = 'remove';
        } else {
          tags = [tag, ...tags];
          toggleAction = 'add';
        }
        // remove some tags based on others:
        if (toggleAction === 'add') {
          if (tag === 'active') tags = tags.filter(t => t !== 'closed');
          if (tag === 'closed') tags = tags.filter(t => t !== 'active');
          if (tag === 'mytasks') tags = tags.filter(t => t !== 'roletasks');
          if (tag === 'roletasks') tags = tags.filter(t => t !== 'mytasks');
        }
        if (toggleAction === 'remove') {
          if (tag === 'active') tags = [tag, ...tags];
          if (tag === 'closed') tags = [tag, ...tags];
          // if (tag === 'mytasks') tags = [tag, ...tags];
          // if (tag === 'roletasks') tags = [tag, ...tags];
        }
        tags = tags.sort();
        return tags;
      })
    }
  }
}

export const inboxTaskTagsStore = createInboxTaskTagsStore();

function createInboxTaskListStore() {
  const { subscribe, set } = writable([]);
  return {
    subscribe,
    search: (/** @type {any} */ query, /** @type {() => void} */ next) => {
      inboxService
        .getTasks(query)
        .then(res => {
          set(res.data);
          if (next) next();
        })
        .catch(() => {
          set([]);
          if (next) next();
        })
    }
  }
}

export const inboxTaskListStore = createInboxTaskListStore();

function createInboxTaskListCountStore() {
  const { subscribe, set } = writable(0);
  return {
    subscribe,
    count: (/** @type {any} */ query, /** @type {() => void} */ next) => {
      inboxService.getTasksNo(query)
        .then(res => {
          set(res.data[0])
          if (next) next();
        })
        .catch(() => {
          set(0);
          if (next) next();
        })
    }
  }
}

export const inboxTaskListCountStore = createInboxTaskListCountStore();

function createInboxExposedStore() {
  const { subscribe, set } = writable({ exposed: [], total: 0, sites: [], totalSites: 0 });
  return {
    subscribe,
    refresh: () => {
      inboxService.getExposed().then((res) => {
        const exposed = [
          ...(res.data?.processes || []).map((/** @type {any} */ el) => {
            return { itemType: "proc", ...el };
          }),
          ...(res.data?.dashboards || []).map((/** @type {any} */ el) => {
            return { itemType: "dash", ...el };
          }),
        ];
        const total = exposed.length || 0;
        const sites = exposed.filter(i => i.itemType === 'dash' && i?.settings?.isSite === true);
        const totalSites = sites.length || 0;
        set({ exposed, total, sites, totalSites });
      });

    }
  }
}

export const inboxExposedStore = createInboxExposedStore();

