<script>
  import { onMount, createEventDispatcher } from "svelte";
  import { nanoid } from "nanoid";
  const dispatch = createEventDispatcher();

  export let disabled = false;
  export let value = undefined;
  export let label = "switch";
  export let conf = {
    off: "bg-danger",
    on: "bg-success",
  };

  let uuid;

  onMount(() => {
    uuid = nanoid(10);
  });

  const callback = (type) => {
    if (type==='changed') return dispatch('callback');
    else return;
  }
</script>

<div class="form-check form-switch d-inline-block">
  <input
    class="form-check-input {value ? conf.on ?? '' : conf.off ?? ''}"
    type="checkbox"
    role="switch"
    id="flexSwitch-{uuid}"
    bind:checked={value}
    on:change={() => callback('changed')}
    {disabled}
  />
  <label class="form-check-label" for="flexSwitch-{uuid}">&nbsp;{label}</label>
</div>

<style>
  input.form-check-input {
    height: 20px !important;
    width: 40px !important;
  }
</style>

