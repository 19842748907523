<script>
  import { onDestroy } from "svelte";
  import { currentLanguage, t } from "../../services/i18n.service";
  import FieldCheckbox from "../utils/field-checkbox.svelte";
  import UtilCronGenerator from "../utils/util-cron-generator.svelte";

  export let scheduler;
  export let language = "en";

  let unsubscribeCurLang = currentLanguage.subscribe((res) => {
    language = res;
  });

  onDestroy(() => {
    unsubscribeCurLang();
  });
</script>

<FieldCheckbox
  label={$t("generic.cronActive")}
  bind:value={scheduler.isActive}
/>
<UtilCronGenerator
  bind:value={scheduler.schedule}
  minutesStep={5}
  bind:language
/>

