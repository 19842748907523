import axios from "axios"

export default {
  assignTask,
  executeIOMapping,
  executeServiceWithId,
  startInstance,
  closeTask,
  getOne,
  getBO,
  updateBO,
  saveTaskContext,
  getTeamWithUsers,
  getRoleWithUsers,
}

/**
 * @param {string} id
 */
// function assignTask(id) {
//   return axios.post(`/api/bpm/assignTask`, undefined, { params: { tid: id } });
// }

/**
 * @param {string} tid
 * @param {string|null|undefined} [user]
 * @param {boolean} [toGroup]
 */
function assignTask(tid, user, toGroup) {
  return axios.post('/api/bpm/assignTask', undefined, { params: { tid, user, toGroup } });
}

/**
 * @param {string} id
 */
function getOne(id) {
  return axios.get(`/api/rest/bo/task/${id}`, { params: {} });
}

/**
 * @param {string} id
 */
function getBO(id) {
  return axios.get(`/api/rest/bo/task/${id}`, { params: { detailed: 'yes' } });
}

/**
 * @param {string} tid
 */
function executeIOMapping(tid) {
  return axios.post('/api/bpm/executeIOMapping', { obj: { id: tid } });
}

/**
 * @param {any} bo
 * @param {any} obj
 */
function executeServiceWithId(bo, obj) {
  return axios.post('/api/bpm/executeServiceWithId', { businessObject: bo, obj });
}

/**
 * @param {any} params
 * @param {any} bo
 */
function startInstance(params, bo) {
  return axios.post('/api/bpm/startInstance', bo, { params });
}

/**
 * @param {{tid: string; gotoNext?: boolean;}} params
 * @param {any} bo
 */
function closeTask(params, bo) {
  return axios.post('/api/bpm/closeTask', bo || {}, { params });
}

/**
 * @param {any} id Instance ID
 * @param {any} obj Instance Business Object
 */
function updateBO(id, obj) {
  return axios.post('/api/bpm/updateBO', obj, { params: { tid: id } });
}

/**
 * @param {string} id
 * @param {any} bo
 */
function saveTaskContext(id, bo) {
  return axios.post('/api/bpm/saveTaskContext', bo, { params: { tid: id } });
}

/**
 * @param {string} tid
 */
function getTeamWithUsers(tid) {
  return axios.get('/api/bpm/getTeamWithUsers', { params: { tid } });
}

/**
 * @param {string} id
 */
function getRoleWithUsers(id) {
  return axios.get('/api/bpm/getRoleWithUsers', { params: { id } });
}
