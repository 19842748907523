<script>
  import { createEventDispatcher, onMount } from "svelte";
  import { nanoid } from "nanoid";
  export let label = undefined;
  export let value = undefined;
  export let disabled = false;
  export let name = undefined;
  export let withoutLabel = false;
  const dispatch = createEventDispatcher();

  let uuid;

  onMount(() => {
    uuid = nanoid(10);
    if (!name) name = uuid;
  });

  function callback() {
    dispatch("callback");
  }
</script>

<div class="form-check mb-3">
  <input
    class="form-check-input"
    type="checkbox"
    {name}
    value=""
    id="{uuid}-{label}"
    bind:checked={value}
    {disabled}
    on:change={callback}
  />
  {#if !withoutLabel}
    <label class="form-check-label" for="{uuid}-{label}">
      {label}
    </label>
  {/if}
</div>
