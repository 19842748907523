<script>
  import { push, location } from "svelte-spa-router";
  import { t, tNow } from "../../../services/i18n.service";
  import UtilModalConfimation from "../../utils/util-modal-confimation.svelte";
  import taskService from "../task.service";
  import { taskExeCommandStore } from "./../task-exe-store";
  import UtilModal from "../../utils/util-modal.svelte";
  import { onDestroy } from "svelte";
  import async from "async";

  let modalShow = false;
  let modalReassignShow = false;
  let msg, title;
  let modalButtons = [
    { action: "close", label: $t("generic.ok"), class: "btn-secondary" },
  ];
  let role = { users: [] };
  let currentTask, currentRedirect;

  const taskExeCommandStoreUnsubscribe = taskExeCommandStore.subscribe(
    (res) => {
      if (!res) return;
      currentRedirect = null;
      if (res?.action === "reassign-back-to-group") {
        reassignBackToGroup(res.obj, res.withRedirect);
      } else if (res?.action === "reassign") {
        currentRedirect = res.withRedirect;
        reassign(res.obj);
      }
    },
  );

  onDestroy(() => {
    taskExeCommandStoreUnsubscribe();
  });

  /**
   * @param {any} task
   */
  function reassign(task) {
    currentTask = task;
    if (task.allowReassign === false) {
      title = tNow("apiFact.reassTask");
      msg = tNow("apiFact.msgTaskCannotReass");
      modalShow = true;
      return;
    }

    async.waterfall(
      [
        (callback) => {
          if (task?.team?.users?.length > 0) {
            taskService
              .getTeamWithUsers(task._id)
              .then((res) => {
                role.users = res.data;
                callback();
              })
              .catch((err) => callback(err));
          } else if (task?.role?._id) {
            taskService
              .getRoleWithUsers(task.role._id)
              .then((res) => {
                role = res.data;
                callback();
              })
              .catch((err) => callback(err));
          } else {
            taskService
              .getRoleWithUsers(task.role)
              .then((res) => {
                role = res.data;
                callback();
              })
              .catch((err) => callback(err));
          }
        },
      ],
      (err) => {
        if (err) return;

        title = tNow("diaReassign.title");
        modalButtons[0].label = tNow("generic.close");
        modalReassignShow = true;
      },
    );
  }

  /**
   * @param {{email: string;}} user
   */
  function reassignTaskToUser(user) {
    taskService.assignTask(currentTask._id, user.email).then(() => {
      modalReassignShow = false;
      if (currentRedirect) {
        push("/");
      }
    });
  }

  /**
   * @param {any} task
   * @param {boolean} redirect
   */
  function reassignBackToGroup(task, redirect) {
    // check if the task can be reassigned:
    if (task.allowReassign === false) {
      title = tNow("apiFact.reassTask");
      msg = tNow("apiFact.msgTaskCannotReass");
      modalButtons[0].label = tNow("generic.ok");
      modalShow = true;
      return;
    }

    taskService.assignTask(task._id, null, true).then(() => {
      if (redirect) {
        push("/");
      }
    });
  }
</script>

<UtilModalConfimation
  {title}
  autoclose={true}
  body={msg}
  on:close={() => {
    modalShow = false;
  }}
  open={modalShow}
  size="md"
  headerBg=""
  bind:modalButtons
  autofocus={true}
  closeOnEscape={true}
/>

<UtilModal
  open={modalReassignShow}
  {title}
  autoClose={true}
  closeOnEscape={true}
  bind:modalButtons
  size="lg"
  onClosed={(_action) => {
    modalReassignShow = false;
  }}
>
  {#if role?.users?.length > 0}
    <p>{$t("diaReassign.descReassToUser")}</p>
    <ul class="list-group">
      {#each role.users as usr}
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
        <li
          class="list-group-item selectable-item"
          on:click={() => {
            reassignTaskToUser(usr);
          }}
        >
          <div class="fw-bold">
            {usr?.firstName}
            {usr?.lastName}
          </div>
          <div class="">{usr.email}</div>
        </li>
      {:else}
        {$t("diaReassign.noUsersInRole", { role: role?.name })}
      {/each}
    </ul>
  {:else}
    <div class="text-danger text-center">{$t("diaReassign.noUsersInRole", { role: "-" })}</div>
  {/if}
</UtilModal>
