<script>
  import { createEventDispatcher, onMount } from "svelte";
  import dashboardService from "./dashboard.service";
  import { t } from "../../services/i18n.service";
  import FieldSelect from "../utils/field-select.svelte";
  import { ExclamationTriangle, Fullscreen, X } from "svelte-bootstrap-icons";
  import ProcessCompPerformance from "../designer/process/process-comps/process-comp-performance.svelte";
  import UtilTableGeneric from "../utils/util-table-generic.svelte";
  import FieldSwitch from "../utils/field-switch.svelte";
  const dispatch = createEventDispatcher();

  export let maximizedDashboard = undefined;
  const styles = getComputedStyle(document.documentElement);
  const colors = [
    // styles.getPropertyValue("--bs-primary"),
    styles.getPropertyValue("--bs-info"),
    styles.getPropertyValue("--bs-success"),
    styles.getPropertyValue("--bs-warning"),
    styles.getPropertyValue("--bs-danger"),
    styles.getPropertyValue("--bs-indigo"),
  ];
  let procPerformance = {};
  let processDetails;
  let processData;
  let selectedActivityId;
  let showAllVersions = false;
  let valuesExpressedIn = "seconds";
  const icoHeight = 20;
  let isLoading = false;
  const config = JSON.stringify({
    type: "pie",
    data: {},
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: false,
          text: "",
        },
      },
    },
  });

  onMount(() => {
    getDashboardProcessPerformance();
  });

  function getDashboardProcessPerformance() {
    isLoading = true;
    dashboardService
      .getDashboardProcessPerformance()
      .then((res) => {
        procPerformance.processes = res.data;
        isLoading = false;
      })
      .catch(() => (isLoading = false));
    procPerformance = procPerformance;
  }

  function closeDiagram() {
    procPerformance.selectedProcess = null;
  }
</script>

<div class="card mb-3">
  <div class="d-flex card-header bg-primary text-white">
    <b>{$t("dashs.process.title")}</b>
    <button
      class="btn btn-sm btn-outline-secondary m-0 p-0 border-0 px-2 py-1 ms-auto text-white"
      on:click={() => {
        const maximized = maximizedDashboard === "processPerformance";
        if (maximized) {
          dispatch("minimize", "processPerformance");
        } else {
          dispatch("maximize", "processPerformance");
        }
      }}
    >
      <Fullscreen width={icoHeight - 4} height={icoHeight - 4} />
    </button>
  </div>
  <div class="card-body">
    <h5>Select a process to view its performance</h5>
    {#if procPerformance.processes}
      <FieldSelect
        bind:value={procPerformance.selectedProcess}
        translate={true}
        placeholder={$t("dashs.process.phSelectProc")}
        list={procPerformance.processes}
        keyval={{ key: "_id", val: "name" }}
      />
    {/if}

    {#if procPerformance.selectedProcess}
      {#if processData && processDetails}
        <div class="">
          <div>
            <span class="text-primary fw-bold"
              >{$t("procDiagramDirective.processName")}:</span
            >
            <span>{processData?.name}</span>
          </div>
          <div>
            <span class="text-primary">
              {$t("procDiagramDirective.noOfInsts")}:
            </span>
            <span
              >{Number(
                processDetails.instancesInfo.count
              ).toLocaleString()}</span
            >
          </div>
          <div>
            <span class="text-primary">
              {$t("procDiagramDirective.execTime")}:
            </span>

            <span>{$t("generic.min")}:</span>
            <span>
              {processDetails.instancesInfo.min === "NaN"
                ? 0
                : Number(processDetails.instancesInfo.min).toLocaleString()}s
            </span>
            |
            <span>{$t("generic.avg")}:</span>
            <span>
              {processDetails.instancesInfo.avg === "NaN"
                ? 0
                : Number(processDetails.instancesInfo.avg).toLocaleString()}s
            </span>
            |
            <span>{$t("generic.max")}:</span>
            <span>
              {processDetails.instancesInfo.max === "NaN"
                ? 0
                : Number(processDetails.instancesInfo.max).toLocaleString()}s
            </span>
          </div>
          <div>
            <FieldSwitch
              label={$t("generic.allVersions")}
              bind:value={showAllVersions}
            />
          </div>
          <div class="text-muted fs-6 d-flex">
            <div>
              <ExclamationTriangle width={icoHeight} height={icoHeight} />
              {$t("procDiagramDirective.zoomToView")}
            </div>
            <button
              class="btn btn-sm btn-outline-secondary border-0 ms-auto"
              on:click={closeDiagram}
            >
              <X width={icoHeight} height={icoHeight} />
            </button>
          </div>
        </div>
      {/if}
      <div class="row">
        <div
          class:col-md-6={maximizedDashboard === "processPerformance"}
          class:col-md-12={maximizedDashboard !== "processPerformance"}
          style={maximizedDashboard === "processPerformance"
            ? "height: calc(100vh - 400px);"
            : "height: 500px;"}
        >
          <ProcessCompPerformance
            bind:processId={procPerformance.selectedProcess}
            bind:processDetails
            bind:item={processData}
            bind:activityId={selectedActivityId}
            bind:showAllVersions
          />
        </div>
        {#if processDetails?.tasksInfo}
          <div
            class:col-md-6={maximizedDashboard === "processPerformance"}
            class:col-md-12={maximizedDashboard !== "processPerformance"}
          >
            <UtilTableGeneric
              stickyHeader={true}
              height={maximizedDashboard === "processPerformance" ? 600 : 400}
            >
              <thead slot="thead">
                <tr>
                  <th>{$t("generic.activity")}</th>
                  <th>{$t("procDiagramDirective.tasksNo")}</th>
                  <th>{$t("generic.min")} (s)</th>
                  <th>{$t("generic.avg")} (s)</th>
                  <th>{$t("generic.max")} (s)</th>
                </tr>
              </thead>
              <tbody slot="tbody">
                {#if isLoading}
                  {#each new Array(3) as _item}
                    <tr>
                      {#each new Array(6) as _td}
                        <td class="placeholder-glow">
                          <span class="placeholder bg-secondary w-100" />
                        </td>
                      {/each}
                    </tr>
                  {/each}
                {:else}
                  {#each Object.entries(processDetails.tasksInfo) as [key, val]}
                    <!-- svelte-ignore a11y-mouse-events-have-key-events -->
                    <tr
                      class:table-primary={key === selectedActivityId}
                      on:mouseover={() => {
                        selectedActivityId = key;
                      }}
                      on:mouseout={() => {
                        selectedActivityId = undefined;
                      }}
                    >
                      <td
                        >{processData.tasksInfo?.[key]?.activityName || key}</td
                      >
                      <td class="text-end"
                        >{Number(val.count).toLocaleString()}</td
                      >
                      <td class="text-end"
                        >{Number(val.min).toLocaleString()}</td
                      >
                      <td class="text-end"
                        >{Number(val.avg).toLocaleString()}</td
                      >
                      <td class="text-end">
                        {Number(val.max).toLocaleString()}
                        <!-- {#if valuesExpressedIn === "seconds"} -->
                        <!--   {Number(val.max).toLocaleString()} -->
                        <!-- {:else if valuesExpressedIn === "minutes"} -->
                        <!--   {Number(Math.round(val.max / 60)).toLocaleString()} -->
                        <!-- {:else if valuesExpressedIn === "hours"} -->
                        <!--   {Number(Math.round(val.max / 60 / 60)).toLocaleString()} -->
                        <!-- {:else if valuesExpressedIn === "days"} -->
                        <!--   {Number(Math.round(val.max / 60 / 60 / 24)).toLocaleString()} -->
                        <!-- {:else if valuesExpressedIn === "weeks"} -->
                        <!--   {Number(Math.round(val.max / 60 / 60 / 24 / 7)).toLocaleString()} -->
                        <!-- {:else if valuesExpressedIn === "months"} -->
                        <!--   {Number(Math.round( val.max / 60 / 60 / 24 / 7 / 12)).toLocaleString()} -->
                        <!-- {:else} -->
                        <!--   {Number(val.max).toLocaleString()} -->
                        <!-- {/if} -->
                      </td>
                    </tr>
                  {:else}
                    <tr>
                      <td colspan="5" class="text-center text-muted"
                        >{$t("msgs.msgNoDataAvailable")}</td
                      >
                    </tr>
                  {/each}
                {/if}
              </tbody>
            </UtilTableGeneric>
            <!-- <FieldSelect -->
            <!--   onlyinput={true} -->
            <!--   bind:value={valuesExpressedIn} -->
            <!--   withoutPlaceholder={true} -->
            <!--   list={[ -->
            <!--     { key: "seconds", value: "generic.seconds" }, -->
            <!--     { key: "minutes", value: "generic.minutes" }, -->
            <!--     { key: "hours", value: "generic.hours" }, -->
            <!--     { key: "days", value: "generic.days" }, -->
            <!--     { key: "weeks", value: "generic.weeks" }, -->
            <!--     { key: "months", value: "generic.months" }, -->
            <!--   ]} -->
            <!--   translate={true} -->
            <!-- /> -->
          </div>
        {/if}
      </div>
    {/if}
  </div>
</div>
