<script>
  import { onDestroy, onMount } from "svelte";
  import { isLoading } from "../../../stores";
  import taskExeApi from "../task-exe.api";
  import TaskExeTplI18nLabel from "./task-exe-tpl-i18n-label.svelte";
  import { substores, taskExeBusinessObject } from "../task-exe-store";
  import taskExeVisibilityTree from "../task-exe-visibility-tree";

  /** @type {{id: string;}|undefined} */
  export let item = undefined;
  export let props = {};
  /** @type {{id: string; type: string;}[]} */
  export let parent = undefined;
  export let visibilityFromParent = "editable";
  /** @type {{id: string; tplId: string;}|null} */
  export let templateProps = null;

  let myVisibility;
  let dynamicBoStore;
  const pathToStore = parent
    .filter((el) => el.type === "tpl-preview")
    .map((el) => el.id)
    .join("_");

  let visibilityStoreUnsubscribe;
  const visibilityStore = taskExeVisibilityTree.checkVisibilityStore(
    item,
    parent,
  );
  if (visibilityStore) {
    visibilityStoreUnsubscribe = visibilityStore.subscribe(
      (/** @type {string} */ res) => {
        myVisibility = res;
      },
    );
  }

  $: checkVisWithParent(myVisibility, visibilityFromParent); 

  function checkVisWithParent(_myVisibility, _visibilityFromParent) {
    myVisibility = taskExeVisibilityTree.checkVisOfElementWithParent(_visibilityFromParent, _myVisibility, props)
  }

  onMount(() => {
    if (pathToStore) {
      if (substores.bos?.[pathToStore]?.data) {
        dynamicBoStore = substores.bos[pathToStore].data;
      } else {
        dynamicBoStore = taskExeBusinessObject;
      }
    } else {
      dynamicBoStore = taskExeBusinessObject;
    }
  });

  onDestroy(() => {
    if (visibilityStoreUnsubscribe) visibilityStoreUnsubscribe();
  });
</script>

{#if myVisibility !== "hidden"}
  {#if props.classBtnFullWidth}
    <div
      class="mb-3 {props.classBtnFullWidth ? 'd-grid gap-2' : ''}"
      id={item.id}
    >
      <button
        class="btn {props?.btnClass
          ? 'btn-' + (props.btnClassOutline ? 'outline-' : '') + props.btnClass
          : 'btn-outline-primary'} {props.class || ''}"
        style={props.style}
        disabled={myVisibility === "disabled" || $isLoading}
        on:click={() => {
          taskExeApi.submit({
            id: item.id,
            boStore: dynamicBoStore,
            tplId: templateProps?.tplId,
          });
        }}
      >
        <TaskExeTplI18nLabel bind:item bind:props />
      </button>
    </div>
  {:else}
    <button
      id={item.id}
      class="btn mb-3 me-3 {props?.btnClass
        ? 'btn-' + (props.btnClassOutline ? 'outline-' : '') + props.btnClass
        : 'btn-outline-primary'} {props.class || ''}"
      style={props.style}
      disabled={myVisibility === "disabled" || $isLoading}
      on:click={() => {
        taskExeApi.submit({
          id: item.id,
          boStore: dynamicBoStore,
          tplId: templateProps?.tplId,
        });
      }}
    >
      <TaskExeTplI18nLabel bind:item bind:props />
    </button>
  {/if}
{/if}
