<script>
  import { onMount, onDestroy } from "svelte";
  import { Chart } from "chart.js/auto";

  let portfolio;
  let myChart;
  export let height = "300px";
  export let type = "line"; // 'bar' "doughnut" "pie";
  export let data = {
    labels: ["Expenses", "Savings", "Investments"],
    datasets: [
      {
        label: "My First Dataset",
        data: [300, 50, 100],
        backgroundColor: ["#7000e1", "#fc8800", "#00b0e8"],
        hoverOffset: 4,
        borderWidth: 0,
      },
      {
        label: "My 2nd Dataset",
        data: [500, 80, 300],
        backgroundColor: ["#7000e1", "#fc8800", "#00b0e8"],
        hoverOffset: 4,
        borderWidth: 0,
      },
    ],
  };
  export let config = {
    type,
    data: data,
    options: {
      borderRadius: "30",
      responsive: true,
      cutout: "95%",
      spacing: 2,
      interaction: {
        intersect: false,
      },
      plugins: {
        legend: {
          position: "bottom",
          display: true,
          labels: {
            usePointStyle: true,
            padding: 20,
            font: {
              size: 14,
            },
          },
        },
        title: {
          display: true,
          text: "Users' activity",
        },
      },
    },
  };

  $: refreshData(data);

  onMount(() => {
    const ctx = portfolio.getContext("2d");
    myChart = new Chart(ctx, config);
  });

  onDestroy(() => {
    myChart?.destroy();
  });

  /**
   * @param {{ labels: string[]; datasets: any[]; }} data
   */
  function refreshData(data) {
    if (!myChart) return;
    myChart.data.datasets = [...data.datasets];
    myChart.data.labels = [...data.labels];
    myChart.update();
    // myChart?.reset();
  }
</script>

<div class="d-flex justify-content-center">
  <canvas bind:this={portfolio} />
</div>
