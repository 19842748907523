<script>
  import dayjs from "dayjs";
  import constsService from "../../services/consts.service";
  export let date = undefined;
  export let format = constsService.DATEFULLFORMAT;
  export let css = "";

  // export let format = 'DD MMM, YYYY'
  let internalDate;

  const input = (/** @type {string | number | Date | dayjs.Dayjs} */ date) => {
    if (date) {
      internalDate = dayjs(date).format(format);
    } else {
      internalDate = null;
    }
  };
  $: input(date);

  const reformat = (/** @type {string} */ format) => {
    if (!format) format = constsService.DATEFULLFORMAT;
    if (date) internalDate = dayjs(date).format(format);
  };

  $: reformat(format);
</script>

<span class={css}>
  {internalDate || "-"}
</span>
