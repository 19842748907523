<script>
  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();
  export let label = undefined;
  export let value = undefined;
  export let disabled = false;
  export let required = false;
  export let invalid = undefined;
  export let rows = 3;

  /**
   * @param {string} type
   */
  function callback(type) {
    if (type === "changed") return dispatch("callback");
  }
</script>

<div class="mb-3">
  {#if label}
    <label for={label} class="form-label">{label}{required ? " *" : ""}</label>
  {/if}
  <textarea
    class="form-control {invalid ? 'is-invalid' : ''}"
    id={label}
    {rows}
    bind:value
    {disabled}
    on:change={() => callback("changed")}
  />
</div>

<style>
  .form-label {
    margin-bottom: 0px;
  }
</style>

