import axios from "axios"

export default {
  get,
  count,
}

/**
 * @param {any} query
 */
function get(query) {
  return axios.get('/api/rest/bo/task', { params: { ...query } });
}

/**
 * @param {any} query
 */
function count(query) {
  return axios.get('/api/rest/bo/task', { params: { ...query, countDoc: true } });
}
