import axios from "axios"

export default {
  get,
  count,
  getOne,
  delete: remove,
  save,
  updateBO,
  terminate,
  pause,
  run,
  startInstance,
  getProcessVersions,
  migrate,
}

/**
 * @param {any} query
 */
function get(query) {
  return axios.get('/api/rest/bo/instance', { params: { ...query } })
}

/**
 * @param {any} query
 */
function count(query) {
  return axios.get('/api/rest/bo/instance', { params: { ...query, countDoc: true } })
}

/**
 * @param {string} id
 */
function remove(id) {
  return axios.post('/api/bpm/removeInstance', {}, { params: { piid: id } });

}

/**
 * @param {string} id
 */
function getOne(id) {
  return axios.get('/api/rest/bo/instance/' + id);
}

/**
 * @param {any} obj Instance object
 */
function save(obj) {
  return axios.post('/api/rest/bo/instance' + (obj._id ? '/' + obj._id : ''), obj);
}

/**
 * @param {any} id Instance ID
 * @param {any} obj Instance Business Object
 */
function updateBO(id, obj) {
  return axios.post('/api/bpm/updateBO', obj, { params: { piid: id } });
}

/**
 * @param {string} id
 */
function terminate(id) {
  return axios.post('/api/bpm/terminateInstance', {}, { params: { piid: id } });
}

/**
 * @param {string} id
 */
function pause(id) {
  return axios.post('/api/bpm/pauseInstance', {}, { params: { piid: id } });
}

/**
 * @param {string} id
 * @param {string} [sequence]
 */
function run(id, sequence) {
  return axios.post('/api/bpm/continueInstance', {}, { params: { piid: id, seq: sequence } });
}

/**
 * @param {string} pid
 * @param {boolean} waitForResponse
 */
function startInstance(pid, waitForResponse) {
  return axios.post('/api/bpm/startInstance', {}, { params: { pid, waitForResponse } });
}

/**
 * @param {string} pid
 */
function getProcessVersions(pid) {
  return axios.get('/api/bpm/getProcessVersions', { params: { pid } });
}

/**
 * @param {string} piid
 * @param {string} pid
 */
function migrate(piid, pid) {
  return axios.post('/api/bpm/migrateToProcessVersions', {}, { params: { piid, pid } });
}
