import axios from "axios";
import authService from './../services/auth.service';
import constsService from "./consts.service";
import storage from "./storage.service";
import toasterService from "./toaster.service";
import { isLoading } from './../stores';

let refreshingToken = false;
let newTkn;
let URL_TKN_R = '/api/getnewtoken';

axios.interceptors.request.use((request) => {
  isLoading.set(true);
  if (!refreshingToken) return request;
  if (request.url === URL_TKN_R) {
    return request;
  }
})

axios.interceptors.response.use((response) => {
  // Do something with response data
  isLoading.set(false);
  return response;
}, (error) => {
  isLoading.set(false);
  if (!error?.config)
    return Promise.reject(error);

  if (error.config.url === URL_TKN_R && error.response.status === 440) {
    refreshingToken = false;
    authService.logout();
    return Promise.reject(error);
  }
  // Do something with response error
  if (error?.response?.status === 440) {
    if (refreshingToken) {

      return new Promise(resolve => {
        const interval = setInterval(() => {
          if (newTkn && !refreshingToken) {
            error.config.headers = { ...error.config.headers.toJSON() };
            error.config.sent = true;
            error.config.headers['Authorization'] = 'Bearer ' + newTkn;
            clearInterval(interval);
            resolve();
          }
        }, 10);
      }).then(() => {
        return axios.request(error.config);
      })
    }

    // put the current request in waiting;
    refreshingToken = true;
    const tknr = storage.get(constsService.APP_NAME + 'gen'); //?.tkn;
    if (!tknr) {
      refreshingToken = false;
      authService.logout();
      return;
    }
    authService.setHeaderTkn(tknr);
    return axios.post(URL_TKN_R)
      .then(res => {
        if (res.data) {
          storage.save(constsService.APP_NAME, res.data);
          authService.setHeaderTkn(res.data);
          newTkn = res.data;
          refreshingToken = false;
          error.config.headers = {};
          error.config.headers['Authorization'] = 'Bearer ' + res.data;
          return axios.request(error.config);
        } else {
          refreshingToken = false;
          authService.logout();
          return error;
        }
      })
      .catch((err) => {
        refreshingToken = false;
        authService.logout();
        return error;
      });
  }


  if (error?.response?.status === 401) {
  } else if (error?.response?.status === 403) {
    // authService.logout();
    toasterService.msg({ type: 'error', msg: error.response.data.error })
  } else {
    toasterService.msg({ type: 'error', msg: error.response.data.error })
  }

  return Promise.reject(error);
});




