<script>
  import { SyncLoader, BarLoader } from "svelte-loading-spinners";
  import { isLoading } from "../../stores";
</script>

<div class="fixed-top loader">
  {#if $isLoading}
    <!--   <div class="position-absolute top-0 start-50 loader-container"> -->
    <!-- <div class="loader ps-2 pe-2"> -->
    <!-- <SyncLoader size="60" color="#FF3E00" unit="px" duration="1s" /> -->
    <!-- <div class="loader"> -->
    <BarLoader color="#17575e" size="60" unit="px" duration="2s" />
    <!-- </div> -->
    <!-- </div> -->
    <!-- </div> -->
  {/if}
</div>

<style>
  /* .loader { */
  /*   top: 0px; */
  /* height: 20px; */
  /*   z-index: 999; */
  /*   background-color: green; */
  /* } */
  /* .wrapper { */
  /*   z-index: 9000; */
  /* } */
  /* .loader > .wrapper { */
  /*   z-index: 9000; */
  /*   width: 100%; */
  /* background-color: green; */
  /* } */
  /* :component(BarLoader) { */
  /*   z-index: 9000; */
  /*   width: 100%; */
  /* background-color: green; */
  /* } */
</style>
