import { get } from "svelte/store";
import apiService from "../../services/api.service"
import { taskExeBusinessObject, taskExeErrorStore, taskExeStore } from "./task-exe-store";
import { getValueFromI18n } from "../../services/i18n.service";
const VAR_EDITABLE = 'editable';
const WIDGETS_WITHOUT_VALIDATION = ['layout-container', 'layout-row', 'layout-column', 'panel', 'modal', 'button'];
const PROPS_WITHOUT_VALIDATION = ['hidden', 'disabled'];
const MAP_VISIBILITY = {
  'hidden': 3,
  'disabled': 2,
  'editable': 1,
  3: 'hidden',
  2: 'disabled',
  1: 'editable'
}

let taskObjects = {};
let taskObjectsWithVisibilityVars = {};

export default {
  // checkVisibility,
  // checkVisibility_async,
  checkValidation,
  checkValidationForAllFields,
  getErrorVars,
  calculateVisibility,
}

function calculateVisibility(initialVisibility, fromParent, fromItem, props) {
  let result = 'editable';
  if (!fromItem) fromItem = 'editable';
  if (props.initialVisibility.isStatic) {
    if ((props.initialVisibility.visibility ?? "editable") === "editable") {
      result = fromParent;
    } else {
      result = initialVisibility.visibility;
    }
  } else {
    if (fromParent === "editable") {
      result = fromItem;
    } else if (fromParent === "disabled") {
      if (fromItem === "hidden") {
        result = fromItem;
      } else {
        result = fromParent;
      }
    } else {
      result = fromParent;
    }
  }

  // console.log('... vis result', props.id, initialVisibility, fromParent, fromItem, result)
  return result;
}

function getErrorVars(props) {
  const errStore = get(taskExeErrorStore);
  let result = { hasError: false, showHelp: false };

  if (["hidden", "disabled"].includes(props.visibility)) {
    result.hasError = false;
    result.showHelp = false;
    return result;
  }
  const isRequired = props.required;
  const errExists = errStore.errors.includes(props.id);
  const hasErrMessage = apiService.check.val(
    getValueFromI18n(props, 'validationMsg')
  );
  result.hasError = /* isRequired && */ errExists;
  result.showHelp = /* isRequired && */ errExists && hasErrMessage;
  return result;
}

const mappings = {};

function checkVisibility_async(props, visibilityFromParent, newVisibility) {
  if (!visibilityFromParent) visibilityFromParent = MAP_VISIBILITY[1];
  if (!newVisibility) newVisibility = MAP_VISIBILITY[1];
  const promise = new Promise(resolve => {
    // console.log(
    //   'checkVisibility',
    //   props.id,
    //   'parent', visibilityFromParent,
    //   'newVisibility', newVisibility,
    //   props.visibilityVar
    // )
    let result;
    if (!props.visibilityVar) {
      result = visibilityFromParent;
      resolve(result);
    } else {
      if (!mappings[props.id]) mappings[props.id] = {};
      mappings[props.id].vis = newVisibility;
      mappings[props.id].visParent = visibilityFromParent;
      setTimeout(() => {
        // const arr = [MAP_VISIBILITY[visibilityFromParent], MAP_VISIBILITY[newVisibility]].sort();
        const arr = [MAP_VISIBILITY[mappings[props.id].visParent], MAP_VISIBILITY[mappings[props.id].vis]].sort();
        // result = MAP_VISIBILITY[arr.pop()];
        if (mappings[props.id].vis === 'editable') {
          result = mappings[props.id].visParent;
        } else {
          result = MAP_VISIBILITY[arr.pop()];
        }
        // console.log('... calculate visibility', props.id, result, mappings, arr)
        return resolve(result);
      }, 10)
    }
  })
  return promise;
}

function checkVisibility(props, visibilityFromParent, newVisibility) {
  if (!visibilityFromParent) visibilityFromParent = MAP_VISIBILITY[1];
  if (!newVisibility) newVisibility = MAP_VISIBILITY[1];
  // console.log(
  //   'checkVisibility',
  //   props.id,
  //   'parent', visibilityFromParent,
  //   'newVisibility', newVisibility,
  //   props.visibilityVar
  // )
  let result;
  if (!props.visibilityVar) {
    result = visibilityFromParent;
  } else {
    if (!mappings[props.id]) mappings[props.id] = {};
    mappings[props.id].vis = newVisibility;
    mappings[props.id].visParent = visibilityFromParent;
    const arr = [MAP_VISIBILITY[visibilityFromParent], MAP_VISIBILITY[newVisibility]].sort();
    result = MAP_VISIBILITY[arr.pop()];
  }
  return result;
}

function checkVisibility2(props, newVisibility, parent, bo) {
  let result;
  if (props.visibilityVar) newVisibility = apiService.getNestedFromPath(bo, props.visibilityVar);
  newVisibility = newVisibility || VAR_EDITABLE;
  const parentVis = [...parent.map(el => el.visibility || VAR_EDITABLE), newVisibility].reverse();
  result = parentVis.find(el => PROPS_WITHOUT_VALIDATION.includes(el)) || VAR_EDITABLE;
  // console.log(
  //   '... check visibility',
  //   props.id,
  //   newVisibility,
  //   parentVis,
  // )
  return result;
}

function checkVisibility1(props, bo, parents) {
  // if (!apiService.check.val(props?.visibilityVar)) return;
  let result;
  // const myVisibilityVar = apiService.getNested(bo, apiService.check.nestedProps(props.visibilityVar)) || VAR_EDITABLE;
  // const taskObjects = get(taskExeStore)?.definition?.objects || {};
  // let parentsVisibility = parents.map(parentId => taskObjects[parentId].visibility);
  // if (parents?.length > 0) {
  //   parentsVisibility = parents.map(el => el.visibility || VAR_EDITABLE)
  // }
  // parentsVisibility.push(myVisibilityVar);
  // parentsVisibility.reverse();

  // console.log('.... checking visibility',
  //   props.id,
  //   props.type,
  //   // myVisibilityVar, 
  //   parents,
  //   // parentsVisibility,
  //   // taskObjects
  // )

  // check the first value other then editable or else editable:
  // for (let i = 0; i < parentsVisibility; i++) {
  //   if (PROPS_WITHOUT_VALIDATION.includes(parentsVisibility[i])) {
  //     result = parentsVisibility[i];
  //     break;
  //   }
  // }
  // console.log('... result visibility', props.id, result || VAR_EDITABLE);
  return result || VAR_EDITABLE;
}

function checkValidationForAllFields() {
  const bo = get(taskExeBusinessObject);
  const taskObjects = get(taskExeStore)?.definition?.objects || {};
  // console.log('... checkValidationForAllFields', taskDefinition);
  Object.entries(taskObjects).forEach(([key, props]) => {
    checkValidation(props, bo);
  })

}

/**
 * @param {any} props
 * @param {any} [bo]
 */
function checkValidation(props, bo) {
  // console.log('... check validation fn', props.id)
  if (!bo) bo = get(taskExeBusinessObject);
  if (!props) return;
  if (WIDGETS_WITHOUT_VALIDATION.includes(props.type)) return;
  if (PROPS_WITHOUT_VALIDATION.includes(props?.visibility)) {
    // remove error if exists:
    taskExeErrorStore.remove(props.id);
    return;
  }
  if (!props.required) {
    taskExeErrorStore.remove(props.id);
    return;
  }

  // remove all validations:
  // taskExeErrorStore.clear();

  // check if it's required:
  const isRequired = props.required;
  // check if it has validation rule:
  const validationRule = props.validation;
  // get obj variable:
  const val = apiService.getNestedFromPath(bo, props.varName);
  // test against rules (for textarea put multiline):
  const rex = new RegExp(validationRule, 'g' + ['textarea', 'wysiwyg'].includes(props.type) ? 'm' : '');
  const resRex = rex.test(val);
  const resReq = (isRequired === true ? (!apiService.check.val(val) ? false : true) : true);
  const resWoErr = resRex && resReq;
  // console.log('... checkValidation 2', resRex, resReq, resWoErr)
  if (resWoErr === false) {
    taskExeErrorStore.add(props.id);
  } else {
    taskExeErrorStore.remove(props.id);
  }
}

