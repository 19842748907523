<script>
  import apiService from "../../services/api.service";
  import { t, tNow } from "../../services/i18n.service";
  import toasterService from "../../services/toaster.service";
  import { userStore } from "../../stores";
  import FieldInputButton from "../utils/field-input-button.svelte";
  import FieldInput from "../utils/field-input.svelte";
  import UtilModal from "../utils/util-modal.svelte";
  import userService from "./user.service";

  let modalShow = false;
  let message;
  let messagePassInfo = false;
  let user = undefined;
  const modalButtons = [
    { label: tNow("generic.close"), action: "close", class: "btn-secondary" },
    {
      label: tNow("generic.save"),
      action: "save",
      class: "btn-outile-primary",
    },
  ];
  let inOldType = "password";
  let inOldIcon = "eyeslash";
  let inType = "password";
  let inIcon = "eyeslash";
  let inNewType = "password";
  let inNewIcon = "eyeslash";

  userStore.subscribe((res) => {
    if (!res) {
      user = undefined;
      return;
    }

    user = { id: res._id, email: res.email };
  });

  export function init() {
    modalShow = true;
  }

  /**
   * @param {string} action
   */
  function modalClose(action) {
    if ("close" === action) {
      modalShow = false;
      messagePassInfo = false;
      return;
    }
    if ("save" === action) {
      message = null;
      if (
        !apiService.check.val(user.newPass) ||
        user?.newPass !== user?.newPass2
      ) {
        message = tNow("diaChangePass.msgPassNoMatch");
        return;
      }
      userService
        .changePassword(user)
        .then(() => {
          toasterService.success({ msg: tNow("msgs.msgPassChanged") });
          message = null;
          modalShow = false;
        })
        .catch((err) => {
          console.error("err", err);
          if (err?.response?.data?.showInfo === true) {
            message = null;
            messagePassInfo = true;
          }
        });
    }
  }
</script>

<UtilModal
  autoClose={false}
  closeOnEscape={true}
  {modalButtons}
  open={modalShow}
  title={$t("diaChangePass.title")}
  size="md"
  onClosed={modalClose}
>
  {#if message}
    <p class="alert alert-warning">
      {message}
    </p>
  {/if}

  <div>
    <FieldInput
      label={$t("generic.email")}
      name="username"
      value={user.email}
      disabled={true}
    />
    <FieldInputButton
      label={$t("diaChangePass.lblOldPass")}
      bind:value={user.oldPass}
      name="password"
      type={inOldType}
      buttonIcon={inOldIcon}
      fireEvent="on-key"
      fn={(ev) => {
        if (!ev) {
          inOldType = inOldType === "text" ? "password" : "text";
          inOldIcon = inOldIcon === "eyeslash" ? "eye" : "eyeslash";
        } else if (ev.keyCode === 13) {
          // login("local", user);
        }
      }}
    />
    <FieldInputButton
      label={$t("diaChangePass.lblNewPass")}
      bind:value={user.newPass}
      name="password"
      type={inType}
      buttonIcon={inIcon}
      fireEvent="on-key"
      fn={(ev) => {
        if (!ev) {
          inType = inType === "text" ? "password" : "text";
          inIcon = inIcon === "eyeslash" ? "eye" : "eyeslash";
        } else if (ev.keyCode === 13) {
          // login("local", user);
        }
      }}
    />
    <FieldInputButton
      label={$t("diaChangePass.lblConfirmPass")}
      bind:value={user.newPass2}
      name="password"
      type={inNewType}
      buttonIcon={inNewIcon}
      fireEvent="on-key"
      fn={(ev) => {
        if (!ev) {
          inNewType = inNewType === "text" ? "password" : "text";
          inNewIcon = inNewIcon === "eyeslash" ? "eye" : "eyeslash";
        } else if (ev.keyCode === 13) {
          // login("local", user);
        }
      }}
    />
  </div>

  {#if messagePassInfo}
    <p>{@html $t("diaChangePass.passInfo")}</p>
  {/if}
</UtilModal>
