<script>
  import { Scissors } from "svelte-bootstrap-icons";
  import FieldInput from "../utils/field-input.svelte";
  import { t, tNow } from "../../services/i18n.service";
  import organisationService from "../organisation/organisation-service";
  import { onDestroy } from "svelte";
  import { userStore } from "../../stores";
  import UtilCardTitle from "../utils/util-card-title.svelte";
  import authService from "../../services/auth.service";
  import UtilModalConfimation from "../utils/util-modal-confimation.svelte";
  import toasterService from "../../services/toaster.service";

  const icoHeight = 24;
  let hasOwnOrg = false;
  let ownOrgId;
  let user = {};
  let modalConfirmation;
  let currentOrg;

  let userStoreUnsubscribe = userStore.subscribe((res) => {
    if (!res) {
      user = undefined;
      return;
    }

    user = JSON.parse(JSON.stringify(res));
    (user.admin || []).forEach((it) => {
      hasOwnOrg = hasOwnOrg || it.owner;
      if (it.owner) ownOrgId = it.orgId._id;
    });
  });

  onDestroy(() => {
    userStoreUnsubscribe();
  });

  function gotoAccConf() {
    // TODO:
    // $rootScope.data = {username: this.user.email, password: null};
    // $state.go('accountconfirmation');
  }

  /**
   * @param {any} org
   */
  function unlink(org) {
    currentOrg = org;
    modalConfirmation.show({
      modalTitle: tNow("profile.unlinkOrg"),
      modalBody: tNow("profile.msgUnlinkOrg", { name: org.name }),
      modalBtns: [
        {
          label: tNow("generic.close"),
          action: "close",
          class: "btn-secondary",
        },
        {
          label: tNow("generic.yes"),
          action: "unlink",
          class: "btn-outline-primary",
        },
      ],
      modalSize: "md",
      modalHeaderBg: "bg-danger text-white",
    });
  }

  function executeUnlink() {
    organisationService.unlink(currentOrg._id).then((res) => {
      // TODO: implement change of token?!
      toasterService.success({ msg: tNow("generic.success") });
      authService.identifyMe((err, _user) => {
        if (err) return;
        userStore.set(_user);
      });
    });
  }
</script>

{#if user?._id}
  <div class="card">
    <div class="card-header bg-primary">
      <UtilCardTitle title={$t("profile.linkedOrgs")} />
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <!-- {#if hasOwnOrg === false} -->
          <!--   <div class="alert alert-warning"> -->
          <!--     {$t("profile.needOwnOrg")} -->
          <!--     <button -->
          <!--       class="btn btn-sm btn-outline-primary ms-3" -->
          <!--       on:click={() => { -->
          <!--         gotoAccConf(); -->
          <!--       }}>{$t("profile.iWantOwnOrg")}</button -->
          <!--     > -->
          <!--   </div> -->
          <!-- {/if} -->
          <ul class="list-group">
            {#each user.organisations as org}
              <li class="list-group-item">
                <div class="row">
                  <div class="col-sm-3">
                    <FieldInput
                      label={$t("generic.org")}
                      bind:value={org.name}
                      disabled={true}
                    />
                  </div>
                  <div class="col-sm-3">
                    <FieldInput
                      label={$t("generic.email")}
                      bind:value={org.email}
                      disabled={true}
                    />
                  </div>
                  <div class="col-sm-3">
                    <br />
                    {#if org._id === ownOrgId}
                      <div class="text-primary fw-bold">Owner</div>
                    {:else}
                      <button
                        class="btn btn-outline-primary"
                        on:click={() => {
                          unlink(org);
                        }}
                      >
                        <Scissors width={icoHeight} height={icoHeight} />
                        {$t("profile.btnUnlink")}
                      </button>
                    {/if}
                  </div>
                </div>
              </li>
            {:else}
              <li class="list-group-item">{$t("profile.noOrgAvailable")}</li>
            {/each}
          </ul>
        </div>
      </div>
    </div>
  </div>
{/if}

<UtilModalConfimation
  bind:this={modalConfirmation}
  on:unlink={() => {
    executeUnlink();
  }}
  on:close={() => {
    currentOrg = undefined;
  }}
/>
