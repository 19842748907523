<script>
  import { ArrowRepeat, Trash } from "svelte-bootstrap-icons";
  import { t, tNow } from "../../../services/i18n.service";
  import {
    substores,
    taskExeBusinessObject,
    taskExeInfo,
    taskExeStore,
  } from "../task-exe-store";
  import TaskExeTplI18nLabel from "./task-exe-tpl-i18n-label.svelte";
  import taskExeDocumentService from "./../task-exe-document.service";
  import { onDestroy, onMount } from "svelte";
  import jq from "jquery";
  import async from "async";
  import apiService from "../../../services/api.service";
  import { get } from "svelte/store";
  import { isLoading, platformInfoStore } from "../../../stores";
  import toasterService from "../../../services/toaster.service";
  import UtilModalConfimation from "../../utils/util-modal-confimation.svelte";
  import FieldDateFormated from "../../utils/field-date-formated.svelte";
  import taskExeApi from "../task-exe.api";
  import UtilModal from "../../utils/util-modal.svelte";
  import taskExeVisibilityTree from "../task-exe-visibility-tree";
  import constsService from "../../../services/consts.service";

  /** @type {{id: string;}|undefined} */
  export let item = undefined;
  export let props = {};
  /** @type {{id: string; type: string;}[]} */
  export let parent = undefined;
  export let visibilityFromParent = "editable";
  /** @type {{id: string; tplId: string;}|null} */
  export let templateProps = null;

  let uploadInput;
  let modalConfirmationComponent;
  let fileToUpload = []; // [{ progress: 33, file: { name: "file name" } }];
  let fileUploaded = [];
  let icoHeight = 18;
  let totalPercentage;
  let uploadButtonComponent;
  let myVisibility;
  let dynamicBoStore;
  let modalDocShow = false;
  let modalDocurl;
  let taskExeBusinessObjectUnsubscribe;
  let modalDocUrl;
  let getDocumentsInProgress = false;
  let docMetadatasOld;
  let initLoadWidget = true;
  const pathToStore = parent
    .filter((el) => el.type === "tpl-preview")
    .map((el) => el.id)
    .join("_");
  const pathToStoreNamed = pathToStore ? pathToStore : "general";
  const widgetId = pathToStore ? pathToStore + "_" + item.id : item.id;

  const debouncedGetDocuments = apiService.debounce(getDocuments, 100);

  let visibilityStoreUnsubscribe;
  const visibilityStore = taskExeVisibilityTree.checkVisibilityStore(
    item,
    parent,
  );
  if (visibilityStore) {
    visibilityStoreUnsubscribe = visibilityStore.subscribe(
      (/** @type {string} */ res) => {
        myVisibility = res;
      },
    );
  }

  $: checkVisWithParent(myVisibility, visibilityFromParent); 

  function checkVisWithParent(_myVisibility, _visibilityFromParent) {
    myVisibility = taskExeVisibilityTree.checkVisOfElementWithParent(_visibilityFromParent, _myVisibility, props)
  }

  onMount(() => {
    if (pathToStore) {
      if (substores.bos?.[pathToStore]?.data) {
        dynamicBoStore = substores.bos[pathToStore].data;
      } else {
        dynamicBoStore = taskExeBusinessObject;
      }
    } else {
      dynamicBoStore = taskExeBusinessObject;
    }

    taskExeBusinessObjectUnsubscribe = dynamicBoStore.subscribe((bo) => {
      if (!$taskExeStore) return;
      // NOTE: add a derived in order to update document list only on changing one of the following:
      // - props.visibility
      // - docMetas
      // check dockFilter:
      let shouldGetDocuments = false;
      const docFilter = extractDataFromBO(props.docFilter, bo);
      if (JSON.stringify(docFilter || {}) !== docMetadatasOld)
        shouldGetDocuments = true;

      if (shouldGetDocuments) {
        if (getDocumentsInProgress) return;
        if (!["hidden"].includes(myVisibility)) {
          // fileUploaded = [];
          debouncedGetDocuments(initLoadWidget);
        }
      }
    });

    // getDocuments(true);
  });

  onDestroy(() => {
    taskExeBusinessObjectUnsubscribe();
    if (visibilityStoreUnsubscribe) visibilityStoreUnsubscribe();
  });

  /**
   * @param {string} path
   * @param {any} [bo]
   */
  function extractDataFromBO(path, bo) {
    if (!bo) bo = get(dynamicBoStore);
    return apiService.getNestedFromPath(bo, path);
  }

  /**
   * @param {boolean} init
   * @param {boolean} executeActions
   */
  function getDocuments(init, executeActions) {
    if (!props.showUploadedFiles) return;
    totalPercentage = undefined;
    // get filter object:
    const docFilter = extractDataFromBO(props.docFilter);
    docMetadatasOld = JSON.stringify(docFilter || {});
    const query = {
      metadata: docFilter,
    };
    if (query?.metadata?.pathToFile) delete query.metadata.pathToFile;
    if (!query) return;
    delete query?.metadata?.pathToFile;
    query.metadata = JSON.stringify(query.metadata);
    getDocumentsInProgress = true;

    // fileUploaded = [];
    taskExeDocumentService
      .getDocuments(query)
      .then((res) => {
        fileUploaded = res.data;
        // attach documents to bo variable:
        attachDocumentsToBO();
        if (init) {
          getDocumentsInProgress = false;
          initLoadWidget = false;
          return;
        }
        if (executeActions) {
          // submit other actions:
          taskExeApi.submit({
            id: item.id,
            boStore: dynamicBoStore,
            tplId: templateProps?.id,
          });
          getDocumentsInProgress = false;
        } else {
          getDocumentsInProgress = false;
        }
      })
      .catch(() => {
        getDocumentsInProgress = false;
      });
  }

  function attachDocumentsToBO() {
    if (!props.getListOfDocuments) return;
    dynamicBoStore.ensureValue(
      props.getListOfDocuments.replace("local.", ""),
      fileUploaded,
      [pathToStoreNamed],
    );
  }

  function startUpload() {
    // remove current attached files:
    jq(uploadInput).val("");
    jq(uploadInput).trigger("click");
    return;
    let options = {
      type: "file",
      change: fileChanged,
    };
    if (props.multipleUpload) options.multiple = "true";
    jq("<input/>", options).trigger("click");
  }

  /**
   * @param {any} item
   */
  function beforeUploadItem(item) {
    if (props.documentRepository === "cmis") {
      // TODO:
    } else {
    }
  }

  function compressImage(file, fileType, quality, next) {
    // const quality = +props.compressImagePercent;
    let imageBitmap;
    let canvas;
    let newFile;
    async.waterfall(
      [
        (callback) => {
          // Get as image data
          createImageBitmap(file)
            .then((res) => {
              imageBitmap = res;
              callback();
            })
            .catch((err) => callback(err));
        },
        (callback) => {
          // Draw to canvas
          canvas = document.createElement("canvas");
          canvas.width = imageBitmap.width;
          canvas.height = imageBitmap.height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(imageBitmap, 0, 0);
          callback();
        },
        (callback) => {
          // Turn into Blob
          // canvas.toBlob((_newFile) => {
          // newFile = _newFile;
          // callback();
          canvas.toBlob(
            function (blob) {
              const newFileName = file.name;
              newFile = new File([blob], newFileName, {
                type: blob.type,
                lastModified: Date.now(),
              });
              callback();
            },
            fileType,
            quality,
          );
        },
      ],
      (err) => {
        next(err, newFile);
      },
    );
  }

  /**
   * @param {any} ev
   */
  function fileChanged(ev) {
    const files = ev.target.files;
    fileToUpload = [...files];
    // check if there are allowed file extensions:
    if (apiService.check.val(props.acceptedFiles)) {
      fileToUpload = fileToUpload.filter((el) => {
        let isOk = false;
        const acceptedFiles = props.acceptedFiles
          .split(",")
          .map((el) => el.trim().replace(".", ""));
        acceptedFiles.forEach((extension) => {
          if (el.name.includes("." + extension)) isOk = true;
          else isOk = isOk || false;
        });
        if (!isOk)
          toasterService.warning({
            msg: tNow("msgs.errAddingFile") + " " + el.name,
          });
        return isOk ? el : undefined;
      });
    }
    // if (!ev.target.value) return;
    if (props?.showAddedFiles && !props?.settings?.autoUpload) return;
    if (!fileToUpload || fileToUpload.length === 0) return;

    async.waterfall(
      [
        // check if should convert images before uploading:
        (callback) => {
          const shouldConvert = props.compressImage;
          const compressPercent = +props.compressImagePercent;
          if (!shouldConvert) return callback();
          const files = ev.target.files;
          let newFiles = [];
          if (!files.length) return callback();

          return async.each(
            files,
            (file, cb1) => {
              if (!file || !file?.type || !file.type.startsWith("image")) {
                if (!file) return cb1();
                newFiles.push(file);
                return cb1();
              }
              compressImage(
                file,
                "image/jpeg",
                compressPercent / 100,
                (err, compressedFile) => {
                  if (err) return cb1(err);
                  newFiles.push(compressedFile);
                  cb1();
                },
              );
            },
            (err) => {
              if (err) return callback(err);
              fileToUpload = newFiles;
              callback();
            },
          );
        },
      ],
      (err) => {
        if (err) console.error(err);
        async
          .each(
            fileToUpload,
            (/** @type {any} */ file, /** @type {() => void} */ callback) => {
              uploadFile(file, uploadProgress(file)).then(() => callback());
            },
          )
          .then(() => {
            if (ev?.remove) ev.remove();
            getDocuments(false, true);
          })
          .catch((err) => {
            console.error("... error", err);
          });
      },
    );
  }

  function uploadFile(file, uploadProgress) {
    const promise = new Promise((resolve, reject) => {
      const bo = get(dynamicBoStore);
      const taskInfo = get(taskExeInfo);
      let formData = new FormData();
      formData.append("file", file);
      formData.append("oid", item.id);
      Object.entries(taskInfo).forEach(([key, val]) => {
        formData.append(key, val);
      });
      const dataForm = apiService.getNestedFromPath(bo, props.dataForm);
      if (dataForm) {
        Object.entries(dataForm).forEach(([key, val]) => {
          formData.append(key, val);
        });
      }
      taskExeDocumentService
        .uploadFile(formData, uploadProgress)
        .then(() => {
          resolve();
        })
        .catch(() => {
          // reject();
          resolve();
        });
    });
    return promise;
  }

  function openDocument(doc) {
    if (props.documentRepository === "cmis") {
      taskExeDocumentService.cmis
        .open({
          cn: props.settings.conn.connstring,
          id: doc.succinctProperties["cmis:objectId"],
        })
        .then((res) => {
          let blob = new Blob([res.data], {
            type: doc.succinctProperties["cmis:contentStreamMimeType"],
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download =
            doc.succinctProperties["cm:title"] ||
            doc.succinctProperties["cmis:name"];
          link.click();
        });
    } else {
      const dmsrepo = get(platformInfoStore).dmsrepo;
      if (dmsrepo === "tasky") {
        const url =
          "https://storage.googleapis.com/" +
          doc.bucket +
          "/" +
          doc.cloudStorageObject;
        if (props?.openInNewTab) {
          window.open(url, "_blank");
        } else {
          modalDocUrl = url;
          modalDocShow = true;
        }
      } else if (dmsrepo === "filesystem") {
        const url =
          constsService.SERVER_PATH + "/dmsrepo/" + doc.cloudStorageObject;
        if (props?.openInNewTab) {
          window.open(url, "_blank");
        } else {
          modalDocUrl = url;
          modalDocShow = true;
        }
      } else {
        toasterService.error({
          msg: tNow("dms.msgWhichRepo"),
        });
      }
    }
  }

  function showRemoveAllDocuments() {
    modalConfirmationComponent.show({
      modalTitle: tNow("dms.remAllDocs"),
      modalBody: tNow("dms.msgRemAllDocs"),
      modalSize: "sm",
      modalHeaderBg: "bg-danger text-white",
      modalBtns: [
        {
          label: tNow("generic.cancel"),
          action: "close",
          class: "btn-secondary",
        },
        {
          label: tNow("generic.delete"),
          action: "delete-all-documents",
          class: "btn-outline-danger",
        },
      ],
    });
  }

  function removeAllDocuments() {
    async
      .forEach(fileUploaded, (file, callback) => {
        removeDocument(file, callback);
      })
      .then(() => {
        getDocuments(false, true);
      })
      .catch(() => {
        getDocuments(false, false);
      });
  }

  /**
   * @param {any} doc
   */
  function showRemoveDocument(doc) {
    modalConfirmationComponent.show({
      modalData: doc,
      modalTitle: tNow("dms.remDoc"),
      modalBody: tNow("dms.msgRemDoc"),
      modalSize: "sm",
      modalHeaderBg: "bg-danger text-white",
      modalBtns: [
        {
          label: tNow("generic.cancel"),
          action: "close",
          class: "btn-secondary",
        },
        {
          label: tNow("generic.delete"),
          action: "delete-one-document",
          class: "btn-outline-danger",
        },
      ],
    });
  }

  /**
   * @param {any} doc
   * @param {() => any} [next]
   */
  function removeDocument(doc, next) {
    if (props.documentRepository === "cmis") {
      taskExeDocumentService.cmis
        .delete(
          { cn: props.settings.conn.connstring },
          { id: doc.succinctProperties["cmis:objectId"] },
        )
        .then(() => {
          if (next) return next();
          getDocuments(false, true);
        });
    } else {
      taskExeDocumentService.remDocument(doc._id).then(() => {
        if (next) return next();
        getDocuments(false, true);
      });
    }
  }

  /**
   * @param {any} it
   */
  function uploadProgress(it) {
    return function (
      /** @type {{ loaded: any; total: any; }} */ progressEvent,
    ) {
      const { loaded, total } = progressEvent;
      let percent = Math.floor((loaded * 100) / total);
      it.progress = percent;
      // if (percent < 100) {
      // }
      fileToUpload = fileToUpload;
      calculateAllProgress();
    };
  }

  function calculateAllProgress() {
    let total = 0;
    let allProgress = [];

    fileToUpload.forEach((file) => {
      if (!file.progress /* || file.progress === 100 */) return;
      allProgress.push(file.progress);
    });
    total = allProgress.reduce((o, el) => o + el, 0) / allProgress.length;
    totalPercentage = Math.floor(total);
  }

  function modalDocClose(action) {
    if ("close" === action) {
      modalDocShow = false;
      modalDocUrl = null;
    }
  }
</script>

{#if myVisibility !== "hidden"}
  <div id={item.id} class="mb-3">
    {#if !props.dontShowUploadButton}
      <input
        id={widgetId}
        type="file"
        style="display:none;"
        bind:this={uploadInput}
        class="btn btn-primary"
        multiple={props?.multipleUpload ? true : false}
        on:change={fileChanged}
      />
      <button
        class="btn {props?.btnClass
          ? 'btn-' + (props.btnClassOutline ? 'outline-' : '') + props.btnClass
          : 'btn-outline-primary'} {props.class || ''}"
        style={props.style}
        on:click={() => startUpload()}
        bind:this={uploadButtonComponent}
        disabled={myVisibility === "disabled" || $isLoading}
      >
        <TaskExeTplI18nLabel
          bind:item
          bind:props
          defaultLabel={"generic.upload"}
        />
        <!-- {totalPercentage || 0}% -->
      </button>
      {#if props.acceptedFiles}
        <div class="text-muted" style="font-size: 0.8rem;">
          <!-- TODO: translate only -->
          Only: {props.acceptedFiles.split(",").join(", ")}
        </div>
      {/if}
      <div
        class="progress mb-1 mt-1"
        class:d-none={!totalPercentage ||
          totalPercentage <= 0 ||
          totalPercentage >= 100}
        style="width: {uploadButtonComponent?.clientWidth + 1 ||
          0}px; height: 2px;"
      >
        <div
          class="progress-bar"
          role="progressbar"
          style="width: {totalPercentage || 0}%"
        />
      </div>
    {/if}

    {#if !$taskExeStore.definition.objects[item.id].dontShowUploadButton}
      {#if $taskExeStore.definition.objects[item.id].showAddedFiles}
        <ul class="list-group view-height-files-to-upload mt-1">
          {#each fileToUpload as it, itIx}
            <li class="list-group-item" class:d-none={it.progress === 100}>
              <span>{it.name}</span>
              <br />
              <div class="progress mb-1">
                <div
                  class="progress-bar"
                  role="progressbar"
                  style="width: {it.progress || 0}%"
                >
                  {it.progress}%
                </div>
              </div>
              <!-- <span>{it.response}</span> -->
              <button
                class="btn btn-sm btn-success"
                on:click={() => {
                  it.uploaded = false;
                  uploadFile(it, uploadProgress(it)).then(() => {
                    setTimeout(() => {
                      getDocuments(false, false);
                    }, 100);
                  });
                }}
                disabled={myVisibility === "disabled" ||
                  $isLoading ||
                  it.progress}
              >
                {$t("generic.upload")}
              </button>
              <!-- <button class="btn btn-sm btn-warning" -->
              <!--   >{$t("generic.cancel")}</button -->
              <!-- > -->
              <button
                class="btn btn-sm btn-danger"
                on:click={() => {
                  fileToUpload.splice(itIx, 1);
                  fileToUpload = fileToUpload;
                }}
                disabled={myVisibility === "disabled" ||
                  $isLoading ||
                  it.progress}
              >
                {$t("generic.delete")}</button
              >
            </li>
          {/each}
        </ul>
      {/if}
    {/if}

    {#if $taskExeStore.definition.objects[item.id].showUploadedFiles}
      <div class="mt-2">
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <!-- svelte-ignore a11y-no-static-element-interactions -->
        <span
          class="selectable-item text-muted"
          on:click={() => {
            if (myVisibility === "disabled") return;
            getDocuments(false, false);
          }}
        >
          <ArrowRepeat width={icoHeight} height={icoHeight} />
          {$t("generic.refresh")}
          {fileUploaded.length > 0 ? "#" + fileUploaded.length : "#0"}
        </span>
        {#if !props.dontAllowDelete && fileUploaded.length > 0}
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <!-- svelte-ignore a11y-no-static-element-interactions -->
          <span
            class="selectable-item text-muted ms-2"
            on:click={() => {
              if (myVisibility === "disabled") return;
              showRemoveAllDocuments();
            }}
          >
            <Trash width={icoHeight} height={icoHeight} />
            {$t("dms.remAllDocs")}
          </span>
        {/if}
        <ul class="list-group mt-1 view-height">
          {#if getDocumentsInProgress}
            <li class="list-group-item text-muted placeholder-glow">
              <span class="placeholder bg-secondary w-100" />
            </li>
            <li class="list-group-item text-muted placeholder-glow">
              <span class="placeholder bg-secondary w-100" />
            </li>
          {:else}
            {#each fileUploaded as doc}
              <li class="list-group-item">
                <h4
                  class="list-group-item-heading"
                  class:m-0={!props.dontAllowDelete}
                  style="line-height: 10px; font-size: 1em; "
                >
                  <span>
                    <small>
                      {#if !$taskExeStore.definition.objects[item.id].dontAllowDelete}
                        <!-- svelte-ignore a11y-click-events-have-key-events -->
                        <!-- svelte-ignore a11y-no-static-element-interactions -->
                        <span
                          class="selectable-item"
                          on:click={() => {
                            if (myVisibility === "disabled") return;
                            showRemoveDocument(doc);
                          }}
                        >
                          <Trash width={icoHeight} height={icoHeight} />
                        </span>
                      {/if}
                    </small>
                  </span>
                  <!-- svelte-ignore a11y-click-events-have-key-events -->
                  <!-- svelte-ignore a11y-no-static-element-interactions -->
                  <span
                    class="selectable-item"
                    style="position: relative; top: 2px;"
                    on:click={() => {
                      openDocument(doc);
                    }}>{doc.fileName}</span
                  >
                </h4>
                <p
                  class="list-group-item-text"
                  style="line-height: 1.2; margin: 0px;"
                >
                  <span style="display: inline-block; font-size: 0.8em;">
                    <span class="mcm-label">{$t("generic.created")}:</span>
                    <span class="mcm-value">
                      <FieldDateFormated date={doc.created_at} />
                    </span>
                  </span>
                  <span style="display: inline-block; font-size: 0.8em;">
                    <span class="mcm-label">{$t("generic.updated")}:</span>
                    <span class="mcm-value">
                      <FieldDateFormated date={doc.updated_at} />
                    </span>
                  </span>
                  <span style="display: inline-block; font-size: 0.8em;">
                    <span class="mcm-label">{$t("generic.user")}:</span>
                    <span class="mcm-value"
                      >{doc?.userId?.lastName} {doc?.userId?.firstName}</span
                    >
                  </span>
                </p>
                <!--{{doc}}-->
              </li>
            {:else}
              <li class="list-group-item text-muted">
                {$t("dms.noDocHere")}
              </li>
            {/each}
          {/if}
        </ul>
      </div>
    {/if}
  </div>
{/if}

<UtilModalConfimation
  bind:this={modalConfirmationComponent}
  on:delete-one-document={(ev) => {
    removeDocument(ev.detail);
  }}
  on:delete-all-documents={(ev) => {
    removeAllDocuments();
  }}
/>

<UtilModal
  open={modalDocShow}
  size="fullscreen"
  onClosed={modalDocClose}
  autoClose={false}
  closeOnEscape={true}
  headerHide={true}
  title={$t("generic.document")}
>
  <!-- {modalDocUrl} -->
  <iframe
    title={$t("generic.document")}
    height="97%"
    width="100%"
    src={modalDocUrl}
  ></iframe>
</UtilModal>

<!-- {JSON.stringify(item)} -->
<!-- <br /> -->
<!-- {JSON.stringify(props)} -->
<!-- <br /> -->
<!-- {JSON.stringify($taskExeBusinessObject)} -->

<style>
  .mcm-label {
    margin-right: 5px;
    color: gray;
  }
  .mcm-value {
    /* font-weight: bold; */
    border-bottom: 1px dotted gray;
    margin-right: 10px;
  }
  .view-height-files-to-upload {
    height: auto;
    max-height: 250px;
    overflow-y: scroll;
  }
  .view-height {
    height: auto;
    max-height: 150px;
    overflow-y: scroll;
  }
</style>
