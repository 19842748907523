<script>
  import { Diagram3, Play, X } from "svelte-bootstrap-icons";
  import {
    inboxCommandStore,
    inboxExposedStore,
    inboxStateStore,
  } from "./inbox-store";
  import { createEventDispatcher, onDestroy, onMount } from "svelte";
  import processService from "../process/process.service";
  import toasterService from "../../services/toaster.service";
  import { t, tNow } from "../../services/i18n.service";
  import { push } from "svelte-spa-router";
  import TaskExeTplI18nLabel from "../task/task-exe-templates/task-exe-tpl-i18n-label.svelte";
  import jq from "jquery";
  import { get } from "svelte/store";
  import { taskExeCommandStore } from "../task/task-exe-store";
  import InboxRedesignFiltersTasks from "./inbox-redesign-filters-tasks.svelte";
  const dispatch = createEventDispatcher();

  /**
   * @type {any[]}
   */
  let exposed = [];
  let selectedExposed = null;
  let total = 0;
  let query;

  const inboxCommandStoreUnsubscribe = inboxCommandStore.subscribe(
    (command) => {
      if (command === "refresh exposed") {
        getExposed();
      }
    },
  );

  const inboxExposedStoreUnsubscribe = inboxExposedStore.subscribe((res) => {
    exposed = res.exposed;
    total = res.total;
  });

  onMount(() => {
    getExposed();
  });

  onDestroy(() => {
    inboxCommandStoreUnsubscribe();
    inboxExposedStoreUnsubscribe();
  });

  /**
   * @param {any} process
   */
  function startProcess(process) {
    processService.startInstance(process).then(() => {
      // TODO: show message only if the process is not returning an answer
      toasterService.success({ msg: tNow("wsFact.msgProcStarted") });
    });
  }

  /**
   * @param {any} dashboard
   */
  function startDashboard(dashboard) {
    const inboxState = get(inboxStateStore);
    if (inboxState.state === "normal") {
      push(`/task/${dashboard.tid}?dashboard=true`);
    } else {
      taskExeCommandStore.command({
        action: "open",
        details: { details: { tid: dashboard.tid, dashboard: true } },
      });
    }
  }

  function getExposed() {
    inboxExposedStore.refresh();
  }
</script>

<!-- MOBILE MENU -->
<div class="mb-3 p-3 d-block d-md-none">
  <select
    name="starttask"
    id="starttask"
    class="form-control text-uppercase text-primary fw-bold"
    bind:value={selectedExposed}
    on:change={() => {
      const item = exposed.find((el) => el._id === selectedExposed);
      if (!item) return;
      if (item.itemType === "proc") {
        startProcess(item);
        dispatch("exposed-started");
      } else if (item.itemType === "dash") {
        startDashboard({ tid: item._id, dashboard: true });
        dispatch("exposed-started");
      }
      selectedExposed = null;
    }}
  >
    <option value={null}> {$t("generic.showExposed")} </option>
    {#each exposed as item}
      <option value={item._id}>
        {#if item.itemType === "proc"}
          [P]
        {:else if item.itemType === "dash"}
          [D]
        {/if}
        <TaskExeTplI18nLabel bind:props={item} varProp="name" />
      </option>
    {/each}
  </select>
</div>

<!-- NON MOBILE MENU -->
<div class="d-none d-md-block mb-3">
  <div
    class="text-uppercase text-primary fw-bold p-3 w-100 d-flex"
    style="font-size: 0.8rem;"
  >
    {$t("generic.showExposed")}
    <div class="badge bg-primary ms-auto">{total}</div>
  </div>
  {#if total > 5}
    <div class="input-group mb-3">
      <input
        id="inputsearchexposed"
        type="text"
        class="form-control"
        placeholder="search task"
        aria-label="search task"
        aria-describedby="search task"
        bind:value={query}
      />
      <button
        class="btn btn-outline-secondary"
        type="button"
        id="button-addon2"
        on:click={() => {
          query = null;
          jq("#inputsearchexposed").trigger("focus");
        }}
      >
        <X></X>
      </button>
    </div>
  {/if}

  <div style="max-height: 300px; overflow-y: scroll;">
    <div class="list-group">
      {#each query ? exposed.filter((el) => el.name
              .toLowerCase()
              .includes(query)) : exposed as item}
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <!-- svelte-ignore a11y-no-static-element-interactions -->
        <button
          class="list-group-item list-group-item-action rounded-4"
          on:click={() => {
            if (item.itemType === "proc") {
              startProcess(item);
              dispatch("exposed-started");
            } else if (item.itemType === "dash") {
              startDashboard({ tid: item._id, dashboard: true });
              dispatch("exposed-started");
            }
          }}
        >
          {#if item.itemType === "proc"}
            <Diagram3></Diagram3>
          {:else if item.itemType === "dash"}
            <Play></Play>
          {/if}
          <TaskExeTplI18nLabel bind:props={item} varProp="name" />
        </button>
      {:else}
        {#if exposed && (query ? exposed.filter((el) => el.name
                  .toLowerCase()
                  .includes(query)) : exposed)?.length === 0}
          <li class="list-group-item text-muted">
            {$t("sidebar.noTaskAvailable")}
          </li>
        {:else}
          <li class="list-group-item placeholder-glow">
            <span class="placeholder bg-secondary w-100" />
          </li>
          <li class="list-group-item placeholder-glow">
            <span class="placeholder bg-secondary w-100" />
          </li>
        {/if}
      {/each}
    </div>
  </div>
</div>

<div class="mb-3 d-none d-md-block">
  <InboxRedesignFiltersTasks></InboxRedesignFiltersTasks>
</div>

<style>
  .list-group-item {
    border: 0px;
  }
  .list-group-item-action:hover,
  .list-group-item-action:focus {
    background-color: var(--bs-primary-bg-subtle);
  }
</style>
