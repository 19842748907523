<script>
  import { onDestroy, onMount } from "svelte";
  import TaskExeTplI18nLabel from "./task-exe-tpl-i18n-label.svelte";
  import {
    substores,
    taskExeBusinessObject,
    taskExeStore,
  } from "../task-exe-store";
  import apiService from "../../../services/api.service";
  import { get } from "svelte/store";
  import taskExeVisibilityTree from "../task-exe-visibility-tree";

  /** @type {{id: string;}|undefined} */
  export let item = undefined;
  export let props = {};
  /** @type {{id: string; type: string;}[]} */
  export let parent = undefined;
  export let visibilityFromParent = "editable";
  /** @type {{id: string; tplId: string;}|null} */
  export let templateProps = null;

  let value;
  let myVisibility;
  let dynamicBoStore;
  let taskExeBusinessObjectUnsubscribe;
  const pathToStore = parent
    .filter((el) => el.type === "tpl-preview")
    .map((el) => el.id)
    .join("_");
  const pathToStoreNamed = pathToStore ? pathToStore : "general";

  // $: changedVisibility(visibilityFromParent, newVisibility);

  let visibilityStoreUnsubscribe;
  const visibilityStore = taskExeVisibilityTree.checkVisibilityStore(
    item,
    parent,
  );
  if (visibilityStore) {
    visibilityStoreUnsubscribe = visibilityStore.subscribe(
      (/** @type {string} */ res) => {
        myVisibility = res;
      },
    );
  }

  $: checkVisWithParent(myVisibility, visibilityFromParent); 

  function checkVisWithParent(_myVisibility, _visibilityFromParent) {
    myVisibility = taskExeVisibilityTree.checkVisOfElementWithParent(_visibilityFromParent, _myVisibility, props)
  }

  onMount(() => {
    if (pathToStore) {
      if (substores.bos?.[pathToStore]?.data) {
        dynamicBoStore = substores.bos[pathToStore].data;
      } else {
        dynamicBoStore = taskExeBusinessObject;
      }
    } else {
      dynamicBoStore = taskExeBusinessObject;
    }

    value = apiService.getNestedFromPath(get(dynamicBoStore), props.varName);
    dynamicBoStore.ensureValue(props.varName, value, [pathToStoreNamed]);

    taskExeBusinessObjectUnsubscribe = dynamicBoStore.subscribe((bo) => {
      if (!$taskExeStore) return;
      const newTempValue = apiService.getNestedFromPath(bo, props.varName);
      if (value !== newTempValue) value = newTempValue;
    });
  });

  onDestroy(() => {
    taskExeBusinessObjectUnsubscribe();
    if (visibilityStoreUnsubscribe) visibilityStoreUnsubscribe();
  });
</script>

{#if myVisibility !== "hidden"}
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div id={item.id} class="mb-3">
    <!-- svelte-ignore a11y-label-has-associated-control -->
    <label class="form-label d-block">
      <TaskExeTplI18nLabel bind:props />
    </label>
    <span class={props.class || ""} style={props.style}>{value || ""}</span>
  </div>
{/if}
