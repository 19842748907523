<script>
  import { isArray } from "underscore";
  import { taskExeStore } from "./task-exe-store";
  import taskExeVisibilityTree from "./task-exe-visibility-tree";
  import TaskExeTplLayoutContainer from "./task-exe-templates/task-exe-tpl-layout-container.svelte";
  import TaskExeTplLayoutColumn from "./task-exe-templates/task-exe-tpl-layout-column.svelte";
  import TaskExeTplLayoutRow from "./task-exe-templates/task-exe-tpl-layout-row.svelte";
  import TaskExeTplPanel from "./task-exe-templates/task-exe-tpl-panel.svelte";
  import TaskExeTplInput from "./task-exe-templates/task-exe-tpl-input.svelte";
  import TaskExeTplButton from "./task-exe-templates/task-exe-tpl-button.svelte";
  import TaskExeTplCheckbox from "./task-exe-templates/task-exe-tpl-checkbox.svelte";
  import TaskExeTplRadio from "./task-exe-templates/task-exe-tpl-radio.svelte";
  import TaskExeTplDatetime from "./task-exe-templates/task-exe-tpl-datetime.svelte";
  import TaskExeTplOutput from "./task-exe-templates/task-exe-tpl-output.svelte";
  import TaskExeTplTextarea from "./task-exe-templates/task-exe-tpl-textarea.svelte";
  import TaskExeTplWysiwyg from "./task-exe-templates/task-exe-tpl-wysiwyg.svelte";
  import TaskExeTplSelect from "./task-exe-templates/task-exe-tpl-select.svelte";
  import TaskExeTplModal from "./task-exe-templates/task-exe-tpl-modal.svelte";
  import TaskExeTplUpload from "./task-exe-templates/task-exe-tpl-upload.svelte";
  import TaskExeTplTables from "./task-exe-templates/task-exe-tpl-tables.svelte";
  import TaskExeTplHtml_2 from "./task-exe-templates/task-exe-tpl-html-2.svelte";
  import TaskExeTplChart from "./task-exe-templates/task-exe-tpl-chart.svelte";
  import TaskExeTplPreview from "./task-exe-templates/task-exe-tpl-preview.svelte";

  /**
   * @type {any}
   */
  export let item = undefined;
  export let parent;
  export let visibilityFromParent = undefined;
  export let templateProps = null;

  let objMap = {
    "layout-container": TaskExeTplLayoutContainer,
    "layout-column": TaskExeTplLayoutColumn,
    "layout-row": TaskExeTplLayoutRow,
    panel: TaskExeTplPanel,
    input: TaskExeTplInput,
    button: TaskExeTplButton,
    checkbox: TaskExeTplCheckbox,
    radio: TaskExeTplRadio,
    datetime: TaskExeTplDatetime,
    output: TaskExeTplOutput,
    textarea: TaskExeTplTextarea,
    wysiwyg: TaskExeTplWysiwyg,
    select: TaskExeTplSelect,
    modal: TaskExeTplModal,
    html: TaskExeTplHtml_2, //TaskExeTplHtml,
    upload: TaskExeTplUpload,
    chart: TaskExeTplChart,
    "table-s": TaskExeTplTables,
    "tpl-preview": TaskExeTplPreview,
  };

  if (isArray(item)) {
    item.forEach((it) => {
      registerObject(it);
    });
  } else {
    registerObject(item);
  }

  /**
   * @param {{ id: string | number; }} item
   */
  function registerObject(item) {
    const itemProps = {
      ...$taskExeStore.definition.objects[item?.id],
      tplInfo: templateProps,
    };
    taskExeVisibilityTree.registerObject(itemProps, parent, templateProps);
  }
</script>

{#if item && $taskExeStore?.definition}
  {#if isArray(item)}
    {#each item as it}
      {#if it?.id && $taskExeStore.definition.objects?.[it?.id]}
        <!-- ITEM #{it?.type}: {it?.id} -->
        <svelte:component
          this={objMap[it.type]}
          bind:item={it}
          bind:props={$taskExeStore.definition.objects[it?.id]}
          bind:parent
          bind:visibilityFromParent
          bind:templateProps
        />
      {/if}
    {/each}
  {:else if $taskExeStore?.definition?.objects?.[item?.id]}
    <!-- ITEM #{item?.type}: {item?.id} -->
    <svelte:component
      this={objMap[item.type]}
      bind:item
      bind:props={$taskExeStore.definition.objects[item?.id]}
      bind:parent
      bind:visibilityFromParent
      bind:templateProps
    />
  {/if}
{/if}

<!-- {#if item && $taskExeStore && $taskExeStore?.definition} -->
<!--   {#if item && $taskExeStore?.definition?.objects?.[item?.id]} -->
<!--     {#if $taskExeStore?.definition?.objects?.[item?.id]} -->
<!--       <svelte:component -->
<!--         this={objMap[item.type]} -->
<!--         bind:item -->
<!--         bind:props={$taskExeStore.definition.objects[item?.id]} -->
<!--         bind:parent -->
<!--         bind:visibilityFromParent -->
<!--         bind:templateProps -->
<!--       /> -->
<!--     {/if} -->
<!--   {:else if isArray(item)} -->
<!--     {#each item as it} -->
<!--       {#if it && $taskExeStore && it?.id && $taskExeStore.definition.objects?.[it?.id]} -->
<!--         <svelte:component -->
<!--           this={objMap[it.type]} -->
<!--           bind:item={it} -->
<!--           bind:props={$taskExeStore.definition.objects[it?.id]} -->
<!--           bind:parent -->
<!--           bind:visibilityFromParent -->
<!--           bind:templateProps -->
<!--         /> -->
<!--       {/if} -->
<!--     {/each} -->
<!--   {/if} -->
<!-- {/if} -->
