import { writable } from "svelte/store";
import apiService from "../../services/api.service";

/**
 * @typedef {{
 *   id: string;
 *   type: string;
 *   visibilityVar: string;
 *   visibility: string;
 *   tplInfo: {
 *     id: string;
 *     tplId: string;
 *   }
 * }} ItemProps
 *
 * @typedef {{
 *   id: string;
 *   tplId: string;
 * }} TemplateProps
 *
 * @typedef {{
 *   itemId: string;
 *   elementId: string;
 *   elementAndTplId: string;
 *   parentsPath: string;
 *   parentsTplsPath: string;
 *   visibility: string;
 *   visibilityStatic: boolean;
 *   visibilityInitial: string;
 *   item: ItemProps;
 *   treeItem?: VisibilityElement;
 *   children: VisibilityTree;
 *   parent: VisibilityElement;
 * }} VisibilityElement
 *
 * @typedef {{
 *   [key: string]: VisibilityElement
 * }} VisibilityTree
 */

/** @type {VisibilityTree} */
let tree = {};
let widgetsOnCategory = {};
let taskExeVisibilityStores = {};

export default {
  registerObject,
  taskExeVisibilityStores,
  getWidgetVisibilityStore,
  checkVisibilityForWidgetCategory,
  checkVisibilityStore,
  clear,
  checkVisOfElementWithParent,
}

function clear() {
  tree = {};
  widgetsOnCategory = {};
  taskExeVisibilityStores = {};
}

/**
 * @param {ItemProps} item
 * @param {{id: string; type: string;}[]} parents 
 * @param {TemplateProps} template 
 */
function registerObject(item, parents, template) {
  // console.log('... register object', item, parents, template, widgetsOnCategory, tree);
  let lastParent = null;
  const parentsLength = parents.length;
  if (parentsLength > 0) lastParent = parents[parentsLength - 1];
  const parentsPath = parents.map(el => el.id).join('_');
  const parentsTplsPath = parents.filter(el => el.type === 'tpl-preview').map(el => el.id).join('_');
  const parentsTplsId = parentsTplsPath ? parentsTplsPath : 'general';
  const parentId = parentsPath ? parentsPath : 'general';
  const elementId = parentsPath.length > 0 ? parentsPath + '_' + item.id : item.id;
  const elementAndTplId = parentsTplsPath ? parentsTplsPath + '_' + item.id : item.id;
  // NOTE: if element exists then do not register again
  // mainly because the register comes from visibility change (from hidden)
  if (tree[elementId]) return;

  item.tplInfo = template;

  const currentElement = {
    itemId: item.id,
    elementId,
    elementAndTplId,
    parentsPath,
    parentsTplsPath,
    visibility: item.visibility || 'editable',
    visibilityStatic: item.visibilityVar ? false : true,
    visibilityInitial: item.visibility,
    item,
    parent: lastParent?.id ? tree[parentId] : null,
    children: {}
  }

  taskExeVisibilityStores[parentsTplsId][currentElement.itemId].set(currentElement.visibility);

  // set element in tree:
  tree[elementId] = currentElement;

  // set child in parent in tree:
  if (parentsPath) {
    tree[parentsPath].children[elementId] = currentElement;
  }

  // assing widget location in tree in widgetOnCategory:
  Object.assign(
    widgetsOnCategory[parentsTplsId][currentElement.itemId],
    {
      elementId,
      elementAndTplId,
      parentsPath,
      parentsTplsPath,
    }
  )
}

/**
 * @description Function that creates a visibility store for each object 
 * The stores will be categorised by general and template id
 * @param {any} objects
 * @param {string} templateId - Widget template ID (not UI template ID)
 */
function getWidgetVisibilityStore(objects, templateId) {
  let widgetCategory = 'general';
  if (templateId) widgetCategory = templateId;
  widgetsOnCategory[widgetCategory] = objects;
  Object.keys(objects).forEach(key => {
    const obj = objects[key];
    const widgetId = `${obj.id}`;
    if (!taskExeVisibilityStores[widgetCategory]) taskExeVisibilityStores[widgetCategory] = {};
    if (!taskExeVisibilityStores[widgetCategory][widgetId]) {
      taskExeVisibilityStores[widgetCategory][widgetId] = writable();
    }
  });
}

/**
 * @description Check for widgets with dynamic visibility variable in same category
 * - compare if the dinamyc value is changed
 * - if Yes then calculate visibility
 * @param {string} category
 * @param {any} bo 
 */
function checkVisibilityForWidgetCategory(category, bo) {
  if (!category) category = 'general';
  if (!widgetsOnCategory[category]) return;
  // get objects in category that have dynamic visibility:
  let widgets = [];
  Object.keys(widgetsOnCategory[category])
    .forEach(key => {
      // console.log('... key', key, category, bo, widgetsOnCategory)
      const object = widgetsOnCategory[category][key];
      const hasDynamicVisibility = object?.visibilityVar ? true : false
      if (hasDynamicVisibility) {
        widgets.push(object);
      }
    })


  // console.log('... check vis for categ widgets', widgets)
  // check if the value has changed:
  widgets.forEach(widget => {
    const value = apiService.getNestedFromPath(bo, widget.visibilityVar);
    // const pathToWidget = category === 'general' ? widget.id : category + '_' + widget.id;
    const widgetInTree = tree[widget.elementId];
    // console.log('... check vis for categ 2', widget, value, widgetInTree)
    calculateVisibility(widgetInTree, value, category);
  })
}

/**
 * @param {VisibilityElement} widgetInTree
 * @param {null} [newValue]
 * @param {string} [category]
 */
function calculateVisibility(widgetInTree, newValue, category) {
  // console.log('... calc vis', widgetInTree, category)
  let result;
  const currentElement = widgetInTree;
  if (!currentElement) return;
  // correct initial visibility:
  currentElement.visibilityInitial = currentElement.visibilityInitial || 'editable';
  const currentElementVisibility = currentElement.visibility || 'editable';
  const parentElement = currentElement.parent;
  const parentElementVisibility = !parentElement ? 'editable' : parentElement.visibility;
  const calculatedCategory = currentElement.parentsTplsPath ? currentElement.parentsTplsPath : 'general';

  // calculate new visibility value:
  if (newValue) {
    result = newValue;
  } else {
    if (currentElement.visibilityStatic) {
      if (currentElement.visibilityInitial === 'editable') {
        result = parentElementVisibility;
      } else {
        result = currentElement.visibilityInitial || 'editable';
      }
    } else {
      // INFO: if it comes from MAIN BO then keep the same value
      if (category !== calculatedCategory) {
        result = currentElementVisibility;
      } else {
        result = parentElementVisibility;
      }
    }
  }
  currentElement.visibility = result;

  // set associated visibility store:
  taskExeVisibilityStores[calculatedCategory][currentElement.itemId].set(result);
  
  // console.log('... children', currentElement.children, taskExeVisibilityStores)
  // do the same for all children:
  Object.keys(currentElement.children).forEach(key => {
    const child = currentElement.children[key];
    const childCategory = child.parentsTplsPath ? child.parentsTplsPath : 'general';
    const newWidget = tree[child.elementId];
    calculateVisibility(newWidget, null, childCategory);
  })
}

/**
  * @description Function that returns an existing visibility store or null
  * @param {{id: string;}} item 
  * @param {{id: string; type: string;}[]} parents 
  * @returns {any|null} Store
  */
function checkVisibilityStore(item, parents) {
  const parentsTplPath = (parents || []).filter(el => el.type === 'tpl-preview').map(el => el.id).join('_');
  const parentsTplId = parentsTplPath ? parentsTplPath : 'general';
  const elementPathId = item.id;
  // console.log('... vis store', item.id, parents, taskExeVisibilityStores)
  if (taskExeVisibilityStores[parentsTplId][elementPathId]) {
    return taskExeVisibilityStores[parentsTplId][elementPathId];
  } else {
    return null;
  }
}


function checkVisOfElementWithParent (parentVisibility, elVisibility, props) {
  // if item has static visibility then follow the logic:
  // if parent = hidden -> item = hidden
  // if parent = disabled -> item = disabled or hidden
  // if parent = editable -> item = it's visibility
  // if item has dinamyc visibility then follow the logic:
  // if parent = hidden -> item = hidden
  // if parent = disabled -> item = disabled or hidden
  // if parent = editable -> item = it's visibility

  let itemVisibility = 'editable';
  if (parentVisibility === 'hidden') {
    itemVisibility = 'hidden';
  } else if (parentVisibility === 'disabled') {
    if (elVisibility === 'hidden') itemVisibility = 'hidden';
    else if (elVisibility === 'disabled') itemVisibility = 'disabled';
    else itemVisibility = 'disabled';
  } else {
    if (elVisibility === 'hidden') itemVisibility = 'hidden';
    else if (elVisibility === 'disabled') itemVisibility = 'disabled';
    else itemVisibility = 'editable';
  }
  return itemVisibility;
}
