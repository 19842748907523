<script>
  import { Pencil, Lock } from "svelte-bootstrap-icons";
  import FieldInput from "../utils/field-input.svelte";
  import UtilCardTitle from "../utils/util-card-title.svelte";
  import { t } from "../../services/i18n.service";
  import { userStore } from "../../stores";
  import { onDestroy } from "svelte";
  import { get } from "svelte/store";
  import userService from "../user/user.service";
  import authService from "../../services/auth.service";
  import UserChangePassword from "../user/user-change-password.svelte";

  const icoHeight = 24;
  let user = {};
  let editable = false;
  let sessionDetails;
  let userChangePasswordComponent;

  let userStoreUnsubscribe = userStore.subscribe((res) => {
    if (!res) {
      user = undefined;
      return;
    }

    user = JSON.parse(JSON.stringify(res));
    // console.log("user", user);
  });

  onDestroy(() => {
    userStoreUnsubscribe();
  });

  function userProfile_edit() {
    editable = true;
  }

  function userProfile_cancel() {
    user = get(userStore);
    editable = false;
  }

  function userProfile_save() {
    userService
      .save(user)
      .then(() => {
        authService.identifyMe((err, _user) => {
          if (err) return console.error("err", err);
          userStore.set(_user);
          editable = false;
        });
      })
      .catch((err) => {
        console.error("err", err);
      });
  }
</script>

{#if user?._id}
  <div class="card mb-3">
    <div class="card-header bg-primary">
      <UtilCardTitle title={$t("profile.title")} />
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-3">
          <FieldInput
            label={$t("generic.email")}
            bind:value={user.email}
            disabled={true}
          />
        </div>
        <div class="col-md-3">
          <FieldInput
            label={$t("generic.firstName")}
            bind:value={user.firstName}
            disabled={!editable}
          />
        </div>
        <div class="col-md-3">
          <FieldInput
            label={$t("generic.lastName")}
            bind:value={user.lastName}
            disabled={!editable}
          />
        </div>
        <div class="col-md-3">
          <br />
          <div class="d-flex">
            <!-- svelte-ignore a11y-label-has-associated-control -->
            {#if !editable}
              <button
                class="btn btn-outline-primary"
                on:click={() => {
                  userProfile_edit();
                }}
              >
                <Pencil width={icoHeight} height={icoHeight} />
                {$t("generic.edit")}
              </button>
            {/if}
            {#if !editable && (!sessionDetails?.loginad || (sessionDetails?.loginad && sessionDetails?.isOwner))}
              <button
                class="btn btn-outline-primary ms-3"
                on:click={() => {
                  userChangePasswordComponent.init();
                }}
              >
                <Lock width={icoHeight} height={icoHeight} />
                {$t("profile.btnChangePass")}
              </button>
            {/if}
            {#if editable}
              <button
                class="btn btn-outline-primary"
                on:click={() => {
                  userProfile_cancel();
                }}>{$t("generic.cancel")}</button
              >
              <button
                class="btn btn-outline-primary ms-1"
                on:click={() => {
                  userProfile_save();
                }}>{$t("generic.save")}</button
              >
            {/if}
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="panel-footer">
				</div> -->
  </div>
{/if}

<UserChangePassword bind:this={userChangePasswordComponent} />
