<script>
  import _routes from "./routes";
  import Router, { location, push } from "svelte-spa-router";
  import Nav from "./components/layout/nav.svelte";
  import { onMount, onDestroy } from "svelte";
  import {
    toasts,
    ToastContainer,
    BootstrapToast,
    FlatToast,
  } from "svelte-toasts";
  import authService from "./services/auth.service";
  import wsService from "./services/ws.service";
  import { derived } from "svelte/store";
  import {
    isAuth,
    isAuthWaiting,
    platformInfoStore,
    layoutFluid,
  } from "./stores";
  import { t, textDirection } from "./services/i18n.service";
  import jq from "jquery";
  import UtilGridSizeDetector from "./components/utils/util-grid-size-detector.svelte";

  // let styleRtl, headHtml;
  // headHtml = document.getElementsByTagName("head")[0];
  const LinkTag = jq('html head link[href*=".css"');

  export let storeForBacground = derived([isAuth, location], ($values, set) => {
    const isNotAuthed = !$values[0];
    const authLocation = _routes.routesOnlyForUnauth.includes($values[1]);
    let result;
    if (isNotAuthed) result = true;
    else {
      result = isNotAuthed && authLocation;
    }
    set(result);
  });

  const storeForBacgroundUnsubscribe = storeForBacground.subscribe(
    (isLogin) => {
      if (isLogin) {
        authClass("add");
      } else {
        authClass("remove");
      }
    },
  );

  /**
   * @param {string} action
   */
  function authClass(action) {
    if ("remove" === action) {
      document
        .getElementsByTagName("body")[0]
        .classList.remove("tsk-loging-view");
    } else {
      document.getElementsByTagName("body")[0].classList.add("tsk-loging-view");
    }
  }

  const textDirectionUnsubscribe = textDirection.subscribe((res) => {
    const htmlEl = jq("html:eq(0)");
    htmlEl.attr("dir", res.dir);
    htmlEl.attr("lang", res.lang);

    const href = LinkTag.attr("href") || "";
    if (res.dir === "ltr") {
      LinkTag.attr("href", href.replace(".rtl.", "."));
    } else {
      LinkTag.attr("href", href.replace(".rtl.", ".").replace(".", ".rtl."));
    }
  });

  onMount(async () => {
    // check session and get platform info:
    authService
      .getPlatformInfo()
      .then((res) => {
        platformInfoStore.set(res.data);
        wsService.setEnvironment(res.data.environment);
      })
      .catch((err) => {
        console.error("... err", err);
      });

    // check auth:
    try {
      await authService.checkAuth();
      if ($location === "/login") push("/");
    } catch (error) {
      console.error("catch err", error);
    }

    // Toaster set defaults:
    toasts.setDefaults({
      title: "Message title",
      description: "Message body",
      duration: 5000, // 0 or negative to avoid auto-remove
      placement: "top-right",
      type: "info",
      theme: "light", // 'light', 'dark',
      showProgress: true,
    });
  });

  onDestroy(() => {
    storeForBacgroundUnsubscribe();
    textDirectionUnsubscribe();
  });
</script>

<svelte:head>
  {#if $platformInfoStore?._n}
    <title>{$platformInfoStore._n.f} - Portal</title>
  {:else}
    <title>Tasky Cloud - Portal</title>
  {/if}
</svelte:head>

{#if !$isAuthWaiting && $isAuth}
  <Nav />
{/if}

<main class="">
  <ToastContainer placement="bottom-right" let:data>
    <!-- <div class="toast-container"> -->
    <!-- <BootstrapToast {data} /> -->
    <FlatToast {data} />
    <!-- Provider template for your toasts -->
    <!-- </div> -->
  </ToastContainer>
</main>

<!-- <div class="container-fluid {$isAuth ? 'mt-5' : ''}"> -->
<div class="{$layoutFluid || 'container-fluid'} app-container">
  {#if $isAuthWaiting}
    {$t("generic.pleaseWait")}
  {:else if $isAuth}
    <Router
      routes={_routes.routesAuth}
      on:conditionsFailed={_routes.conditionsFailed}
    />
  {:else}
    <Router routes={_routes.routesUnAuth} />
  {/if}
</div>

<UtilGridSizeDetector />

<style>
  .app-container {
    margin-top: 80px;
  }
  /* .toast-container { */
  /*   background-color: white; */
  /*   border-radius: 5px; */
  /*   width: 300px; */
  /*   margin-top: 100px; */
  /* } */
</style>
