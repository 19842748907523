<script>
  import { tooltip } from "./../../services/tooltip.service";
  import { Play, Info, ExclamationTriangle } from "svelte-bootstrap-icons";
  import { t } from "../../services/i18n.service";
  import { push } from "svelte-spa-router";
  import taskService from "../task/task.service";
  import UtilBusinessObject from "../utils/util-business-object.svelte";

  export let item = undefined;
  export let props = {};
  let icoHeight = 16;
  let businessObject;
  let modalBoShow = false;
  let instanceError;
  let modalErrorShow = false;

  /**
   * @param {string} command
   */
  function commandTask(command) {
    switch (command) {
      case "play":
        playTask();
        break;
      case "info":
        showBo();
        break;
      case "alert":
        showError();
        break;
      default:
        break;
    }
  }

  function playTask() {
    // const url =
    //   window.location.origin + "/#/task/" + item._id + "";
    // console.log('... url', url);
    // window.open(url, "_blank");
    push("/task/" + item._id);
  }

  function showBo() {
    businessObject = undefined;
    taskService
      .getBO(item._id)
      .then((res) => {
        businessObject = res.data?.businessObject || {};
        modalBoShow = true;
      })
      .catch((err) => console.error(err));
  }

  /**
   * @param {any} bo
   */
  function saveBO(bo) {
    // console.log("... save bo", bo);
    taskService
      .updateBO(item._id, bo)
      .then(() => {
        businessObject = undefined;
        modalBoShow = false;
        // props.refresh();
      })
      .catch((err) => console.error(err));
  }

  function showError() {
    instanceError = JSON.parse(item.error || {});
    modalErrorShow = true;
  }
</script>

<div class="ms-auto d-flex border border-secondary-subtle d-inline-flex">
  <div
    class="btn-group d-block"
    role="group"
    aria-label="Task buttons"
  >
    {#if ["active"].includes(item.status) && !["integration", "imeget", "imesend", "timer"].includes(item.type)}
      <button
        class="btn btn-sm btn-outline-success border-0 px-1"
        on:click={() => commandTask("play")}
      >
        <span use:tooltip={$t("instCheck.playTask")}>
          <Play width={icoHeight} height={icoHeight} />
        </span>
      </button>
    {/if}

    <button
      class="btn btn-sm btn-outline-info border-0 px-1"
      on:click={() => commandTask("info")}
    >
      <span use:tooltip={$t("instCheck.info")}>
        <Info width={icoHeight} height={icoHeight} />
      </span>
    </button>

    {#if item.error}
      <button
        class="btn btn-sm btn-outline-danger border-0 px-1"
        on:click={() => commandTask("alert")}
      >
        <span use:tooltip={$t("instCheck.error")}>
          <ExclamationTriangle width={icoHeight} height={icoHeight} />
        </span>
      </button>
    {/if}
  </div>
</div>

{#if businessObject}
  <UtilBusinessObject
    title={$t("generic.task") + " #" + $t("generic.bo")}
    size="xl"
    currentItem={businessObject}
    bind:modalShow={modalBoShow}
    on:close={() => (businessObject = undefined)}
    on:save={(ev) => {
      saveBO(ev.detail);
    }}
  />
{/if}

{#if instanceError}
  <UtilBusinessObject
    title={$t("generic.errorDetails")}
    size="xl"
    currentItem={instanceError}
    bind:modalShow={modalErrorShow}
    on:close={() => (instanceError = undefined)}
  />
{/if}
