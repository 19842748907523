<script>
  import { createEventDispatcher, onMount } from "svelte";
  import dashboardService from "./dashboard.service";
  import { t, tNow } from "../../services/i18n.service";
  import FieldChart from "../utils/field-chart.svelte";
  import { Fullscreen } from "svelte-bootstrap-icons";
  const dispatch = createEventDispatcher();

  export let maximizedDashboard = undefined;
  const icoHeight = 20;
  const styles = getComputedStyle(document.documentElement);
  const colors = [
    // styles.getPropertyValue("--bs-primary"),
    styles.getPropertyValue("--bs-info"),
    styles.getPropertyValue("--bs-success"),
    styles.getPropertyValue("--bs-warning"),
    styles.getPropertyValue("--bs-danger"),
    styles.getPropertyValue("--bs-indigo"),
  ];
  let instanceData = { labels: [], values: [], data: {} };
  let instances = {};
  let instanceChart;
  let tasks = {};
  let taskData = { labels: [], values: [] };
  let taskChart;
  let isLoading = false;
  const config = JSON.stringify({
    type: "pie",
    data: {},
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: false,
          text: "",
        },
      },
    },
  });

  onMount(() => {
    getItemsNo();
  });

  function getItemsNo() {
    isLoading = true;
    dashboardService.getItemsNo().then((res) => {
      instances = res.data.instances;
      Object.entries(instances).forEach(([key, value]) => {
        if (key !== "all") {
          instanceData.labels.push(tNow(`instCheck.${key}`));
          instanceData.values.push(value);
        }
      });
      const chartInstances = JSON.parse(config);
      chartInstances.data = {
        labels: instanceData.labels,
        datasets: [
          {
            label: "",
            data: instanceData.values,
            // backgroundColor: colors,
            hoverOffset: 4,
            borderWidth: 0,
          },
        ],
      };
      instanceChart = chartInstances;
      instanceData = instanceData;

      tasks = res.data.tasks;
      Object.entries(tasks).forEach(([key, value]) => {
        if (key !== "all") {
          taskData.labels.push(tNow(`instCheck.${key}`));
          taskData.values.push(value);
        }
      });
      const chartTask = JSON.parse(config);
      chartTask.data = {
        labels: taskData.labels,
        datasets: [
          {
            label: "",
            data: taskData.values,
            // backgroundColor: colors,
            hoverOffset: 4,
            borderWidth: 0,
          },
        ],
      };
      taskChart = chartTask;
      taskData = taskData;
      isLoading = false;
    });
  }
</script>

<div class="card mb-3">
  <div class="d-flex card-header bg-primary text-white">
    <b>{$t("dashs.instance.title")}</b>
    <button
      class="btn btn-sm btn-outline-secondary m-0 p-0 border-0 px-2 py-1 ms-auto text-white"
      on:click={() => {
        const maximized = maximizedDashboard === "instanceOverview";
        if (maximized) {
          dispatch("minimize", "instanceOverview");
        } else {
          dispatch("maximize", "instanceOverview");
        }
      }}
    >
      <Fullscreen width={icoHeight - 4} height={icoHeight - 4} />
    </button>
  </div>
  <div class="card-body">
    <div class="row">
      <div
        class:col-md-6={maximizedDashboard === "instanceOverview"}
        class:col-md-12={maximizedDashboard !== "instanceOverview"}
      >
        {#if instanceChart}
          <div>
            <FieldChart bind:config={instanceChart} />
          </div>
        {/if}
      </div>
      <div
        class:col-md-6={maximizedDashboard === "instanceOverview"}
        class:col-md-12={maximizedDashboard !== "instanceOverview"}
      >
        <div class="table-responsive">
          <table class="table table-striped table-condensed">
            <thead class="text-primary">
              <th>{$t("dashs.status")}</th>
              <th style="text-align: right;">#</th>
            </thead>
            <tbody>
              {#if isLoading}
                {#each new Array(3) as _item}
                  <tr>
                    <td class="placeholder-glow">
                      <span class="placeholder bg-secondary w-100" />
                    </td>
                    <td class="placeholder-glow">
                      <span class="placeholder bg-secondary w-100" />
                    </td>
                  </tr>
                {/each}
              {:else}
                {#each instanceData?.values || [] as item, index}
                  <tr>
                    <td>{instanceData.labels[index]}</td>
                    <td class="text-end">{Number(item).toLocaleString()}</td>
                  </tr>
                {/each}
              {/if}
            </tbody>
            <tfoot>
              {#if isLoading}
                <tr>
                  <td class="placeholder-glow">
                    <span class="placeholder bg-secondary w-100" />
                  </td>
                  <td class="placeholder-glow">
                    <span class="placeholder bg-secondary w-100" />
                  </td>
                </tr>
              {:else}
                <tr class="text-primary fw-bold">
                  <td>{$t("dashs.total")}</td>
                  <td class="text-end"
                    >{Number(instances.all).toLocaleString()}</td
                  >
                </tr>
              {/if}
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
