<script>
  export let title = "";
  export let desc = "";
</script>

<!-- <div class="card bg-primary mb-3"> -->
<!--   <div class="card-body"> -->
    <h5 class="card-title text-white">{title}</h5>
    <small class="card-text text-white">
      {desc}
    </small>
<!--   </div> -->
<!-- </div> -->

<style>
  h5 {
    margin: 0px;
  }
</style>
