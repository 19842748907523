<script>
  import { onDestroy, onMount, tick } from "svelte";
  import { nanoid } from "nanoid";
  // @ts-ignore
  import Quill from "quill";
  import TaskExeTplI18nLabel from "./task-exe-tpl-i18n-label.svelte";
  import {
    substores,
    taskExeBusinessObject,
    taskExeErrorStore,
    taskExeStore,
  } from "../task-exe-store";
  import apiService from "../../../services/api.service";
  import { get } from "svelte/store";
  import { isLoading } from "../../../stores";
  import taskExeErrorAndVisibilityService from "../task-exe-error-and-visibility.service";
  import {
    currentLanguage,
    textDirection,
  } from "../../../services/i18n.service";
  import jq from "jquery";
  import taskExeVisibilityTree from "../task-exe-visibility-tree";

  /** @type {{id: string;}|undefined} */
  export let item = undefined;
  export let props = {};
  /** @type {{id: string; type: string;}[]} */
  export let parent = undefined;
  export let visibilityFromParent = "editable";
  /** @type {{id: string; tplId: string;}|null} */
  export let templateProps = null;

  const DEFAULT_VALUE = "<p><br></p>";
  let quillContainer;
  let quill;
  let uuid;
  let hasError = false;
  let showHelp = false;
  let textDirectionVar = "ltr";
  let myVisibility;
  let dynamicBoStore;
  let taskExeBusinessObjectUnsubscribe;
  let taskExeErrorStoreUnsubscribe;
  const pathToStore = parent
    .filter((el) => el.type === "tpl-preview")
    .map((el) => el.id)
    .join("_");
  const pathToStoreNamed = pathToStore ? pathToStore : "general";
  const widgetId = pathToStore ? pathToStore + "_" + item.id : item.id;

  // $: quill?.enable([null, "editable"].includes(props.visibility));
  $: toggleEnableQuill(myVisibility);

  function toggleEnableQuill(visibility) {
    if (!quill) return;
    const disabled = visibility === "disabled";
    quill?.enable(!disabled);
    // jq(quillContainer)
    //   .parent()
    //   .find(".ql-toolbar")
    //   .find("input,button,select")
    //   .prop("disabled", disabled);
    jq(quillContainer)
      .parent()
      .find(".ql-toolbar,.ql-container")
      .css(
        "background-color",
        disabled ? "var(--bs-form-control-disabled-bg)" : "inherit",
      );
  }

  const isLoadingUnsubscribe = isLoading.subscribe((res) => {
    if (quill) {
      if (res) quill?.enable(false);
      else quill?.enable(true);
    }
  });

  const textDirectionUnsubscribe = textDirection.subscribe((res) => {
    // console.log("... text direction", res);
    textDirectionVar = res?.dir;
    if (!quill) return;
    toggleTextDirection();
  });

  function toggleTextDirection() {
    if (textDirectionVar === "rtl") {
      // console.log("... set to rtl");
      quill.format("direction", "rtl");
      quill.format("align", "right");
    } else {
      // console.log("... set to ltr");
      quill.format("direction", "ltr");
      quill.format("align", "left");
    }
  }

  let visibilityStoreUnsubscribe;
  const visibilityStore = taskExeVisibilityTree.checkVisibilityStore(
    item,
    parent,
  );
  if (visibilityStore) {
    visibilityStoreUnsubscribe = visibilityStore.subscribe(
      (/** @type {string} */ res) => {
        myVisibility = res;
      },
    );
  }

  $: checkVisWithParent(myVisibility, visibilityFromParent); 

  function checkVisWithParent(_myVisibility, _visibilityFromParent) {
    myVisibility = taskExeVisibilityTree.checkVisOfElementWithParent(_visibilityFromParent, _myVisibility, props)
  }

  onMount(() => {
    props.isFirstValidation = true;
    uuid = nanoid(10);

    if (pathToStore) {
      if (substores.bos?.[pathToStore]?.data) {
        dynamicBoStore = substores.bos[pathToStore].data;
      } else {
        dynamicBoStore = taskExeBusinessObject;
      }
    } else {
      dynamicBoStore = taskExeBusinessObject;
    }

    let valueBo = apiService.getNestedFromPath(
      get(dynamicBoStore),
      props.varName,
    );
    try {
      if (valueBo && quillContainer) quillContainer?.setHTML(valueBo);
    } catch (error) {
      console.log("... error wysiwyg", error);
    }
    tick().then(() => {
      // @ts-ignore
      quill = new Quill("#quill-" + uuid, {
        theme: "snow",
      });
      toggleTextDirection();
      // console.log("... container", quillContainer);
      // quill.on("text-change", function (delta, oldDelta, source) {
      // console.log('.... quill', delta, oldDelta, source, quill.getText())
      // quill.getText();
      // if (source == "api") {
      //   console.log("An API call triggered this change.");
      // } else if (source == "user") {
      //   console.log("A user action triggered this change.");
      // }
      // });

      dynamicBoStore.ensureValue(props.varName, valueBo, [pathToStoreNamed]);

      taskExeErrorStoreUnsubscribe = taskExeErrorStore.subscribe(() => {
        if (props.isFirstValidation === true) return;
        const resultErrVars =
          taskExeErrorAndVisibilityService.getErrorVars(props);
        hasError = resultErrVars.hasError;
        showHelp = resultErrVars.showHelp;

        if (hasError) {
          jq(quillContainer)
            .parent()
            .find(".ql-toolbar,.ql-container")
            .addClass("border-danger");
        } else {
          jq(quillContainer)
            .parent()
            .find(".ql-toolbar,.ql-container")
            .removeClass("border-danger");
        }
      });

      taskExeBusinessObjectUnsubscribe = dynamicBoStore.subscribe((bo) => {
        if (!$taskExeStore) return;
        const newValue = apiService.getNestedFromPath(bo, props.varName);
        quill.root.innerHTML = newValue || DEFAULT_VALUE;
      });

      setTimeout(() => {
        props.isFirstValidation = false;
      }, 100);
    });
  });

  onDestroy(() => {
    isLoadingUnsubscribe();
    taskExeBusinessObjectUnsubscribe();
    taskExeErrorStoreUnsubscribe();
    textDirectionUnsubscribe();
    if (visibilityStoreUnsubscribe) visibilityStoreUnsubscribe();
  });

  function valueChanged() {
    dynamicBoStore.ensureValue(props.varName, quill.root.innerHTML, [
      pathToStoreNamed,
    ]);
    taskExeErrorAndVisibilityService.checkValidation(props);
    // taskExeApi.submit(item.id);
  }
</script>

{#if myVisibility !== "hidden"}
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div id={item.id} class="mb-3 {props.class || ''}">
    <label class="form-label d-block" for="dt_{item.id}">
      <TaskExeTplI18nLabel bind:props />{props.required ? "*" : ""}
    </label>
    <div
      id="quill-{uuid}"
      bind:this={quillContainer}
      on:focusout={valueChanged}
    />

    {#if showHelp}
      <div id={props.id + "-HelpBlock"} class="invalid-feedback d-block">
        {props?.validationMsg_i18n?.[$currentLanguage]
          ? props.validationMsg_i18n[$currentLanguage]
          : props.validationMsg}
      </div>
    {/if}
  </div>
{/if}
