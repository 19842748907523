<script>
  import {
    ArrowClockwise,
    Search,
    CaretUp,
    CaretDown,
  } from "svelte-bootstrap-icons";
  import { onMount } from "svelte";
  import FieldInput from "./field-input.svelte";
  import FieldSelect from "./field-select.svelte";
  import apiService from "../../services/api.service";

  export let query = {};
  export let queryFn = function () {};
  export let queryInit = undefined;
  /** @type {{
   * lbl: string,
   * ph: string,
   * path: string,
   * primary: boolean,
   * secondary: boolean,
   * type?: string,
   * translate?: boolean,
   * withoutPlaceholder?: boolean,
   * list?: { key: string | boolean, value: string}[],
   * }[]} fields
   */
  export let fields = [
    {
      lbl: "Prop 1",
      ph: "prop 1",
      path: "path1",
      primary: true,
      secondary: false,
    },
    {
      lbl: "Prop 2",
      ph: "prop 2",
      path: "path2",
      primary: true,
      secondary: false,
    },
    {
      lbl: "Prop 3",
      ph: "prop 3",
      path: "path3",
      primary: true,
      secondary: true,
    },
    {
      lbl: "Prop 4",
      ph: "prop 4",
      path: "path4",
      primary: true,
      secondary: true,
      type: "select",
      translate: false,
      list: [{ key: null, value: "- alege -" }],
    },
  ];

  $: buttonsPrimary = fields.filter((el) => el.primary === true);
  $: buttonsSecondary = fields.filter((el) => el.secondary === true);
  let showSecondary = false;

  onMount(() => {
    if (!queryInit) {
      queryInit = JSON.stringify(query);
    }
  });

  function executeQuery() {
    clearQuery();
    queryFn();
  }

  function resetQuery() {
    query = { ...JSON.parse(queryInit) };
    queryFn();
  }

  function clearQuery() {
    query = {
      ...Object.keys(query).reduce((o, key) => {
        o[key] = apiService.check.val(query[key]) ? query[key] : undefined;
        return o;
      }, {}),
    };
    query.skip = 0;
  }
</script>

{#if !showSecondary}
  <div class="d-md-none mb-3">
    {#each buttonsPrimary as field}
      {#if field.type === "select"}
        <FieldSelect
          placeholder={field.ph}
          bind:value={query[field.path]}
          list={field.list}
          translate={field.translate}
          on:callback={executeQuery}
          withoutPlaceholder={true}
        />
      {:else}
        <FieldInput
          label={field.lbl}
          placeholder={field.ph}
          bind:value={query[field.path]}
          on:key-enter={executeQuery}
          onlyinput={false}
        />
      {/if}
    {/each}
    <div class="text-end">
      <button
        class="btn btn-outline-secondary"
        type="button"
        id="button-addon1"
        on:click={() => resetQuery()}
      >
        <ArrowClockwise />
      </button>
      <button
        class="btn btn-outline-secondary"
        type="button"
        id="button-addon1"
        on:click={() => executeQuery()}
      >
        <Search />
      </button>
      <button
        class="btn btn-outline-secondary d-none d-md-block"
        type="button"
        id="button-addon1"
        on:click={() => {
          showSecondary = true;
        }}
      >
        <CaretDown />
      </button>
    </div>
  </div>
  <div class="d-none d-md-block">
    <div class="input-group mb-3">
      {#each buttonsPrimary as field}
        <span class="input-group-text">{field.lbl}</span>
        {#if field.type === "select"}
          <FieldSelect
            placeholder={field.ph}
            bind:value={query[field.path]}
            list={field.list}
            onlyinput={true}
            withoutPlaceholder={field.withoutPlaceholder}
            translate={field.translate}
            on:callback={executeQuery}
          />
        {:else}
          <FieldInput
            placeholder={field.ph}
            bind:value={query[field.path]}
            on:key-enter={executeQuery}
            onlyinput={true}
          />
        {/if}
      {/each}
      <button
        class="btn btn-outline-secondary"
        type="button"
        id="button-addon1"
        on:click={() => resetQuery()}
      >
        <ArrowClockwise />
      </button>
      <button
        class="btn btn-outline-secondary"
        type="button"
        id="button-addon1"
        on:click={() => executeQuery()}
      >
        <Search />
      </button>
      <button
        class="btn btn-outline-secondary"
        type="button"
        id="button-addon1"
        on:click={() => {
          showSecondary = true;
        }}
      >
        <CaretDown />
      </button>
    </div>
  </div>
{:else}
  <div class="d-none d-md-block card mb-3">
    <div class="card-body">
      <div class="btns-search text-end">
        <button
          class="btn btn-sm btn-outline-secondary"
          on:click={() => resetQuery()}
        >
          <ArrowClockwise />
        </button>
        <button
          class="btn btn-sm btn-outline-secondary"
          on:click={() => executeQuery()}
        >
          <Search />
        </button>
        {#if showSecondary}
          <button
            class="btn btn-sm btn-outline-secondary"
            on:click={() => {
              showSecondary = false;
            }}
          >
            <CaretUp />
          </button>
        {:else}
          <button
            class="btn btn-sm btn-outline-secondary"
            on:click={() => {
              showSecondary = true;
            }}
          >
            <CaretDown />
          </button>
        {/if}
      </div>
      <div class="row">
        {#each buttonsSecondary as field}
          <div class="col-2">
            {#if field.type === "select"}
              <FieldSelect
                label={field.lbl}
                bind:value={query[field.path]}
                on:callback={executeQuery}
                translate={field.translate}
                list={field.list}
              />
            {:else}
              <FieldInput
                label={field.lbl}
                placeholder={field.ph}
                bind:value={query[field.path]}
                on:key-enter={executeQuery}
              />
            {/if}
          </div>
        {/each}
      </div>
    </div>
  </div>
{/if}

<style>
  /* .btns-search { */
  /*   display: flex; */
  /* } */
</style>
