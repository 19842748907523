<script>
  import scheduleService from "../components/schedule/schedule.service";
  import UtilCardTitle from "../components/utils/util-card-title.svelte";
  import UtilPagination from "../components/utils/util-pagination.svelte";
  import UtilSearch from "../components/utils/util-search.svelte";
  import UtilTable from "../components/utils/util-table.svelte";
  import constsService from "../services/consts.service";
  import { currentLanguage, t, tNow } from "../services/i18n.service";
  import { toString } from "cronstrue/i18n";
  import UtilModal from "../components/utils/util-modal.svelte";
  import { onMount, onDestroy } from "svelte";
  import ScheduleConfiguration from "../components/schedule/schedule-configuration.svelte";
  import { push } from "svelte-spa-router";
  import toasterService from "../services/toaster.service";

  let items = [];
  let itemsNo = 0;
  let curLanguage = "en";
  let currentItem;
  let query = {
    skip: 0,
    limit: constsService.QUERY_PAGE_LIMIT,
    sort: "-updated_at",
  };
  let modalShow = false;
  let isLoading = false;

  let unsubscribeCurLang = currentLanguage.subscribe((res) => {
    curLanguage = res;
    translateAllSchedules();
    items = [...items];
  });

  onMount(() => {
    search();
  });

  onDestroy(() => {
    unsubscribeCurLang();
  });

  function search() {
    getItems();
    countItems();
  }

  function getItems() {
    isLoading = true;
    scheduleService
      .get(query)
      .then((res) => {
        items = res.data;
        translateAllSchedules();
        isLoading = false;
      })
      .catch((err) => {
        console.error(err);
        isLoading = false;
      });
  }

  function countItems() {
    scheduleService
      .count(query)
      .then((res) => {
        itemsNo = res.data;
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function translateAllSchedules() {
    // translate schedulers:
    items.forEach((sch) => {
      sch.settings.scheduler.isActiveTranslated = sch.settings.scheduler
        .isActive
        ? tNow("generic.yes")
        : tNow("generic.no");
      sch.settings.scheduler.translated = translateScheduler(
        sch.settings.scheduler.schedule
      );
    });
  }

  /**
   * @param {string} value
   */
  function translateScheduler(value) {
    return toString(value, { locale: curLanguage });
  }

  /**
   * @param {{ pa: { _id: string; }; _id: string; }} item
   */
  function viewItem(item) {
    gotoObject("process", item);
  }

  /**
   * @param {any} item
   */
  function editItem(item) {
    currentItem = JSON.parse(JSON.stringify(item));
    modalShow = true;
  }

  /**
   * @param {string} _objType
   * @param {{ pa: { _id: string; }; _id: string; }} obj
   */
  function gotoObject(_objType, obj) {
    push(`/diagram/${obj._id}`);
  }

  /**
   * @param {string} action
   */
  function modalClose(action) {
    if ("close" === action) {
      modalShow = false;
      return;
    }
    if ("save" === action) {
      scheduleService
        .save(currentItem._id, currentItem.settings.scheduler)
        .then(() => {
          currentItem = undefined;
          modalShow = false;
          toasterService.success({ msg: tNow("generic.saved") });
          search();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
</script>

<div class="card">
  <div class="card-header bg-primary">
    <UtilCardTitle
      title={$t("generic.schedules")}
      desc={$t("home.admin.schedules.desc") +
        " | " +
        $t("generic.total") +
        ": " +
        (itemsNo || 0)}
    />
  </div>
  <div class="card-body">
    <UtilSearch
      bind:query
      queryFn={search}
      fields={[
        {
          lbl: $t("generic.process"),
          ph: null,
          path: "name",
          primary: true,
          secondary: true,
        },
      ]}
    />

    <UtilTable
      data={items}
      bind:query
      queryFn={search}
      bind:dataLoading={isLoading}
      setts={{
        conf: {
          header: {
            add: {},
          },
          items: {
            view: { show: true },
            edit: { show: true },
          },
        },
        cols: [
          {
            lbl: $t("procDiagram.lblProcName"),
            path: "name",
            type: "string",
            sort: true,
          },
          {
            lbl: $t("diaInspMigration.installedAt"),
            path: "installed_at",
            type: "date",
            sort: true,
          },
          {
            lbl: $t("generic.cronActive"),
            path: "settings.scheduler.isActiveTranslated",
            type: "string",
            sort: false,
          },
          {
            lbl: $t("generic.schedule"),
            path: "settings.scheduler.translated",
            type: "string",
            sort: false,
          },
          {
            lbl: $t("appMgmt.procBucket"),
            path: "pa.name",
            type: "string",
            sort: false,
          },
        ],
      }}
      on:view={(ev) => viewItem(ev.detail)}
      on:edit={(ev) => editItem(ev.detail)}
    />

    <UtilPagination
      bind:query
      bind:totalData={itemsNo}
      on:callData={getItems}
    />
  </div>
</div>

<UtilModal
  title={$t("generic.schedule")}
  open={modalShow}
  autoClose={false}
  onClosed={modalClose}
  modalButtons={[
    { label: $t("generic.close"), action: "close", class: "btn-secondary" },
    {
      label: $t("generic.save"),
      action: "save",
      class: "btn-outline-primary",
    },
  ]}
  size="lg"
>
  <div class="card">
    <div class="card-body">
      <ScheduleConfiguration bind:scheduler={currentItem.settings.scheduler} />
    </div>
  </div>
</UtilModal>
