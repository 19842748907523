<script>
  import { push } from "svelte-spa-router";
  import { t } from "../services/i18n.service";
  import FieldInput from "../components/utils/field-input.svelte";
  import FieldInputButton from "../components/utils/field-input-button.svelte";
  import { credsTempStore, platformInfoStore } from "./../stores";
  import { onDestroy } from "svelte";
  import authService from "../services/auth.service";
  import toasterService from "../services/toaster.service";

  let creds = undefined;
  let tnc = undefined;
  let showTerms = false;
  let in1Type = "password";
  let in1Icon = "eyeslash";

  let credsTempStoreUnsubscribe = credsTempStore.subscribe(
    (/** @type {any} */ res) => {
      creds = res;
      if (!creds) push("/login");
    }
  );

  let platformInfoStoreUnsubscribe = platformInfoStore.subscribe((res) => {
    tnc = res?.tnc;
    showTerms = tnc ? true : false;
  });

  onDestroy(() => {
    credsTempStoreUnsubscribe();
    platformInfoStoreUnsubscribe();
  });

  function ok() {
    authService.activateAdminAccountForPortalUser(
      creds,
      (/** @type {any} */ err, /** @type {any} */ res) => {
        if (err) {
          return;
        }
        toasterService.success({ msg: $t("accConf.msgWelcome") });
        push("/" + res.url);
      }
    );
  }
</script>

{#if creds}
  <div class="row">
    <div class="col-md-3" />

    <div class="col-md-6">
      <div class="card">
        <div class="card-body">
          <div>
            <div>
              <h4>
                {$t("accConf.title")}
              </h4>
            </div>
            <div>
              <p class="tsk-color-white">{$t("accConf.p1")}</p>
              <p class="tsk-color-white">{$t("accConf.p2")}</p>
              <ul>
                <li class="tsk-color-white">{$t("accConf.it1")}</li>
                <li class="tsk-color-white">{$t("accConf.it2")}</li>
              </ul>
              {#if showTerms}
                <div>
                  {@html $t("login.tc.p1")}
                  <a href={tnc.privacyStatement} target="_blank">
                    <b>{$t("login.tc.privacyStatement")}</b>
                  </a>
                  {$t("login.tc.p2")}
                  <a href={tnc.termsOfUse} target="_blank">
                    <b>{$t("login.tc.termsOfUse")}</b>
                  </a>
                </div>
              {/if}
              {#if !creds.password}
                <div class="mt-3">
                  <FieldInput
                    label={$t("generic.email")}
                    name="username"
                    value={creds.username}
                    disabled={true}
                  />
                  <FieldInputButton
                    label={$t("generic.pass")}
                    bind:value={creds.password}
                    name="password"
                    type={in1Type}
                    buttonIcon={in1Icon}
                    fireEvent="on-key"
                    fn={(ev) => {
                      if (!ev) {
                        in1Type = in1Type === "text" ? "password" : "text";
                        in1Icon = in1Icon === "eyeslash" ? "eye" : "eyeslash";
                      } else if (ev.keyCode === 13) {
                        ok();
                      }
                    }}
                  />
                </div>
              {/if}
              <div class="row mt-3">
                <div class="col-md-6">
                  <div class="d-grid mt-3">
                    <button
                      class="btn btn-outline-primary"
                      on:click={() => {
                        push("/login");
                      }}
                    >
                      {$t("accConf.btnDontWant")}
                    </button>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="d-grid mt-3">
                    <button
                      class="btn btn-primary"
                      on:click={() => {
                        ok();
                      }}
                    >
                      {$t("accConf.btnWant")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-3" />
  </div>
{/if}

