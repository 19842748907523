import axios from "axios";

export default {
  getDashboardProcessPerformance,
  getDashboardUserPerformance,
  getItemsNo,
}

/**
 * @param {any} query
 */
function getDashboardProcessPerformance(query) {
  return axios.get('/api/bpm/getDashboardProcessPerformance', { params: query });
}

function getDashboardUserPerformance() {
  return axios.get('/api/bpm/getDashboardUserPerformance', { params: {} });
}

function getItemsNo() {
  return axios.get('/api/bpm/getDashboardData', { params: {} });
}
