<script>
  import { onMount, onDestroy } from "svelte";
  import { Chart } from "chart.js/auto";

  export let height = undefined;
  export let config = {};
  let portfolio;
  let myChart;

  onMount(() => {
    const ctx = portfolio.getContext("2d");
    myChart = new Chart(ctx, config);
  });

  onDestroy(() => {
    myChart?.destroy();
  });
</script>

<div class="d-flex justify-content-center" style="{height ? `height: ${height}` : '100px'}">
<!-- <div class="d-flex justify-content-center"> -->
  <canvas bind:this={portfolio} />
</div>
